<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

@media (min-width: 768px) {
    .col-image {
        height: 70vh;
    }
    .column {
        min-height: 70vh;
    }
}
</style>

<template>
    <div>
        <div class="row">
            <div class="col-2">
                <div class="d-none d-xl-flex flex-column mt-5 pl-4 pt-3">
                    <div v-for="(tab, index) in tabs" :key="tab">
                        <div
                            class="pr-2 mr-1 tab text-muted pointer mt-3"
                            :id="'tab' + index"
                            @click="changeActiveTab(index)"
                        >
                            {{ tab }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <h2>Bookings / Resources / Resource Name</h2>
                    </div>
                    <div class="d-flex align-items-center">
                        <CoButton class="flex-shrink-0 mr-2 text-muted" type="text" label="Discard"></CoButton>
                        <CoButton class="flex-shrink-0" type="" label="Save Changes"></CoButton>
                    </div>
                </div>
                <div class="d-none d-xl-block">
                    <div class="d-flex mt-3" v-if="index == 0">
                        <CoCreateResourceGeneralPage :resource="resource"></CoCreateResourceGeneralPage>
                    </div>
                    <div class="d-flex mt-3" v-if="index == 1">
                        <CoCreateResourcePricingPage :resource="resource"></CoCreateResourcePricingPage>
                    </div>
                    <div class="d-flex mt-3" v-if="index == 2">
                        <CoCreateResourceBookingPage :resource="resource"></CoCreateResourceBookingPage>
                    </div>
                    <div class="d-flex mt-3" v-if="index == 3">
                        <CoCreateResourceRangePage :resource="resource"></CoCreateResourceRangePage>
                    </div>
                    <div class="d-flex mt-3" v-if="index == 4">
                        <CoCreateResourceLinkPage :resource="resource"></CoCreateResourceLinkPage>
                    </div>
                    <div class="d-flex mt-3" v-if="index == 5">
                        <CoCreateResourceCheckoutPage :resource="resource"></CoCreateResourceCheckoutPage>
                    </div>
                    <div class="d-flex mt-3" v-if="index == 6">
                        <CoCreateResourceConfirmationPage :resource="resource"></CoCreateResourceConfirmationPage>
                    </div>
                    <div class="d-flex mt-3" v-if="index == 7">
                        <CoCreateResourceCancellationPage :resource="resource"></CoCreateResourceCancellationPage>
                    </div>
                </div>
                <div class="d-block d-xl-none mt-3">
                    <div class="p-3 pb-0 round-unify bg-white" style="margin-top: 1px">
                        <div class="align-items-center w-100 d-flex justify-content-between">
                            <div>General</div>
                            <div v-if="index == 0" @click="changeIndex(10)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-up"></CoIcon>
                            </div>
                            <div v-else @click="changeIndex(0)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-down"></CoIcon>
                            </div>
                        </div>
                        <div class="d-flex" v-if="index == 0">
                            <CoCreateResourceGeneralPage :resource="resource"></CoCreateResourceGeneralPage>
                        </div>
                    </div>
                    <div class="p-3 pb-0 round-unify bg-white" style="margin-top: 1px">
                        <div class="align-items-center w-100 d-flex justify-content-between">
                            <div>Pricing</div>
                            <div v-if="index == 1" @click="changeIndex(10)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-up"></CoIcon>
                            </div>
                            <div v-else @click="changeIndex(1)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-down"></CoIcon>
                            </div>
                        </div>
                        <div class="d-flex mt-3" v-if="index == 1">
                            <CoCreateResourcePricingPage :resource="resource"></CoCreateResourcePricingPage>
                        </div>
                    </div>
                    <div class="p-3 pb-0 round-unify bg-white" style="margin-top: 1px">
                        <div class="align-items-center w-100 d-flex justify-content-between">
                            <div>Booking</div>
                            <div v-if="index == 2" @click="changeIndex(10)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-up"></CoIcon>
                            </div>
                            <div v-else @click="changeIndex(2)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-down"></CoIcon>
                            </div>
                        </div>
                        <div class="d-flex mt-3" v-if="index == 2">
                            <CoCreateResourceBookingPage :resource="resource"></CoCreateResourceBookingPage>
                        </div>
                    </div>
                    <div class="p-3 pb-0 round-unify bg-white" style="margin-top: 1px">
                        <div class="align-items-center w-100 d-flex justify-content-between">
                            <div>Range</div>
                            <div v-if="index == 3" @click="changeIndex(10)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-up"></CoIcon>
                            </div>
                            <div v-else @click="changeIndex(3)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-down"></CoIcon>
                            </div>
                        </div>
                        <div class="d-flex mt-3" v-if="index == 3">
                            <CoCreateResourceRangePage :resource="resource"></CoCreateResourceRangePage>
                        </div>
                    </div>
                    <div class="p-3 pb-0 round-unify bg-white" style="margin-top: 1px">
                        <div class="align-items-center w-100 d-flex justify-content-between">
                            <div>Link</div>
                            <div v-if="index == 4" @click="changeIndex(10)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-up"></CoIcon>
                            </div>
                            <div v-else @click="changeIndex(4)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-down"></CoIcon>
                            </div>
                        </div>
                        <div class="d-flex mt-3" v-if="index == 4">
                            <CoCreateResourceLinkPage :resource="resource"></CoCreateResourceLinkPage>
                        </div>
                    </div>
                    <div class="p-3 pb-0 round-unify bg-white" style="margin-top: 1px">
                        <div class="align-items-center w-100 d-flex justify-content-between">
                            <div>Checkout</div>
                            <div v-if="index == 5" @click="changeIndex(10)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-up"></CoIcon>
                            </div>
                            <div v-else @click="changeIndex(5)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-down"></CoIcon>
                            </div>
                        </div>
                        <div class="d-flex mt-3" v-if="index == 5">
                            <CoCreateResourceCheckoutPage :resource="resource"></CoCreateResourceCheckoutPage>
                        </div>
                    </div>
                    <div class="p-3 pb-0 round-unify bg-white" style="margin-top: 1px">
                        <div class="align-items-center w-100 d-flex justify-content-between">
                            <div>Confirmation</div>
                            <div v-if="index == 6" @click="changeIndex(10)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-up"></CoIcon>
                            </div>
                            <div v-else @click="changeIndex(6)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-down"></CoIcon>
                            </div>
                        </div>
                        <div class="d-flex mt-3" v-if="index == 6">
                            <CoCreateResourceConfirmationPage :resource="resource"></CoCreateResourceConfirmationPage>
                        </div>
                    </div>
                    <div class="p-3 pb-0 round-unify bg-white" style="margin-top: 1px">
                        <div class="align-items-center w-100 d-flex justify-content-between">
                            <div>Cancellation</div>
                            <div v-if="index == 7" @click="changeIndex(10)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-up"></CoIcon>
                            </div>
                            <div v-else @click="changeIndex(7)">
                                <CoIcon class="icon align-self-center pointer" name="chevron-down"></CoIcon>
                            </div>
                        </div>
                        <div class="d-flex mt-3" v-if="index == 7">
                            <CoCreateResourceCancellationPage :resource="resource"></CoCreateResourceCancellationPage>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</template>

<script>
import EventBus from '../../../eventBus';
import axios from 'axios';

export default {
    name: 'CoAdminCreateResource',
    data() {
        return {
            plan: null,
            me: this.$store.state.me,
            imageURL: '',
            loading: false,
            index: 5,

            windowWidth: 0,

            resource: {
                Name: '',
                Description: '',
                Location: '',
                ImageURL: '',
                Color: '',
                PriceInCents: '',
                Currency: 'EUR',
                PriceInterval: 'Per hour',
                Hidden: false,
                BookingInterval: true,
                FixedTimeSpan: false,
            },
            tabs: ['General', 'Pricing', 'Booking', 'Range', 'Link', 'Checkout', 'Confirmation', 'Cancellation'],

            // current or upcoming
        };
    },
    created() {},
    computed: {
        isDesktop: function () {
            return this.windowWidth >= 768;
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
        this.windowWidth = window.innerWidth;

        if (this.isDesktop) {
            var element = document.getElementById('tab0');

            element.classList.remove('text-muted');
        }
    },
    methods: {
        changeActiveTab(index) {
            var oldActive = document.getElementById('tab' + this.index);
            oldActive.classList.add('text-muted');
            var newActive = document.getElementById('tab' + index);
            newActive.classList.remove('text-muted');
            this.index = index;
        },
        changeIndex(index) {
            this.index = index;
        },
    },
};
</script>
