











import Vue from 'vue';
import linkify from 'vue-linkify';
Vue.directive('linkified', linkify);

export default {
    name: 'CoText',
    props: {
        text: {
            type: String,
            required: false,
            default: '',
        },
        variant: {
            default: 'normal',
            type: String,
            required: false,
            validator: (value) => ['normal', 'mute', 'nowrap', 'primary'].includes(value),
        },
        type: {
            default: 'p1',
            type: String,
            validator: (value) => ['p1', 'p2', 'p3', 'p4'].includes(value),
        },
        noWrap: {
            default: false,
            type: Boolean,
        },
        linkify: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        classes() {
            return {
                'co text': true,
                noMargin: this.noMargin,
                nowrap: this.noWrap,
                padding: this.padding,
                [`${this.variant}`]: true,
                [`${this.type}`]: this.type,
            };
        },
    },
};
