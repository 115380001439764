

































































































































































































































import axios from 'axios';
import { get } from 'lodash';

import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoHeading from '@/components/Atoms/co-heading/CoHeading.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoCardPlan from '@/components/Molecules/co-card-plan/CoCardPlan.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoTable from '@/components/Molecules/co-table/CoTable.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoPlanDetail from '@/components/Organisms/co-plan-detail/CoPlanDetail.vue';
import CoPlanSelectConfigCustomise from '@/components/Organisms/co-plan-select-config-customise-modal/CoPlanSelectConfigCustomiseModal.vue';
import CoSubscriptionScheduleCard from '@/components/Organisms/co-subscription-schedule-card/CoSubscriptionScheduleCard.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoPlanChildMemberSelectModal from '../../../../Organisms/co-plan-child-member-select-modal/CoPlanChildMemberSelectModal.vue';
import CoRoundButton from '../../../../Atoms/co-round-button/CoRoundButton.vue';
import CoText from '../../../../Atoms/co-text/CoText.vue';
import CoCheckbox from '../../../../Atoms/co-checkbox/CoCheckbox.vue';
import CoSubscriptionNextInvoicePreview from '@/components/Organisms/co-subscription-next-invoice-preview/CoSubscriptionNextInvoicePreview.vue';
import { UpcommingInvoice } from '@/components/Organisms/co-subscription-next-invoice-preview/models';
import { SubscriptionSchedule } from '@/components/Organisms/co-subscription-schedule-card/models';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import EventBus from '../../../../../eventBus';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoCreateResourceLinkPage from '@/components/Organisms/co-create-resource-link-page/CoCreateResourceLinkPage.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';

interface PlanPerUser {
    plan: object;
    userID: string;
    userName: string;
}

// NewMembership is the type of the object that is sent to the server when creating a new membership.
interface NewMembership {
    plan: PlanPerUser;

    // child plans
    childPlans: PlanPerUser[];

    // Start is the time when the membership starts.
    // if 0 means now
    start: number;
    end: number; // if 0 means forever
    anchorDate: number; // if 0 means same as start
    trialEnd: number; // if 0 means no free trial
    collectionMethod: 'CHARGE_AUTOMATICALLY' | 'SEND_INVOICE';
    prorate: boolean;
    prorationDate: number; // if 0 means undefined
}

export default {
    name: 'MembershipUpdate',
    components: {
        CoFormGroup,
        CoCreateResourceLinkPage,
        CoDropdown,
        CoDropdownItem,
        CoSkeleton,
        CoSubscriptionNextInvoicePreview,
        CoModal,
        CoHeadline,
        CoCard,
        CoHeading,
        CoCardPlan,
        CoAlert,
        CoTable,
        CoButton,
        CoLink,
        CoPlanDetail,
        CoPlanSelectConfigCustomise,
        CoSubscriptionScheduleCard,
        CoPlanChildMemberSelectModal,
        CoText,
        CoRoundButton,
        CoCheckbox,
    },
    props: {
        userID: {
            type: String,
            default: null,
        },
        userName: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            membership: {
                // eslint-disable-next-line no-bitwise
                type: Object as () => NewMembership,
                default: () => ({
                    plan: null,
                    childPlans: [],
                    start: 0,
                    end: 0,
                    anchorDate: 0,
                    trialEnd: 0,
                    collectionMethod: 'CHARGE_AUTOMATICALLY',
                    prorate: false,
                    prorationDate: 0,
                }),
            },
            mainPlanKey: 1,
            childPlansKey: 1000,
            childPlanTableColumns: [
                {
                    title: 'Name',
                    key: 'userName',
                },
                {
                    title: 'Plan',
                    key: 'plan.Name',
                },
                {
                    title: '',
                    key: 'userID',
                    class: 'text-right',
                    style: 'width: 3rem',
                    notSortable: true,
                },
            ],
            childPlans: [] as PlanPerUser[],

            subscriptionSchedule: {} as SubscriptionSchedule,
            updatingSubscription: false,
            error: null,

            loadingPaymentMethods: true,
            paymentMethods: [],
            currentMembership: null,
            editableChildPlan: null as PlanPerUser | null,
            portalStyle: {},

            nextInvoice: {} as UpcommingInvoice,
            nextInvoicePreviewResult: null,
            nextInvoicePreviewLoading: false,
            nextInvoiceRenderKey: 0,

            childPlanModal: true,
            mainPlanModalMode: 'list',
            childPlanModalMode: 'member-select',
        };
    },
    created() {
        this.getUserPaymentMethods();
    },
    mounted() {
        this.getUserMembership(this.userID);
    },
    watch: {
        userID() {
            this.getUserMembership(this.userID);
        },
        membership: {
            handler(value: NewMembership) {
                if (value.prorate && !value.prorationDate) {
                    this.membership.prorationDate = new Date().getTime() / 1000;
                    // remove decimals from timestamp
                    this.membership.prorationDate = Math.floor(this.membership.prorationDate);
                } else if (!value.prorate) {
                    this.membership.prorationDate = 0;
                }
                this.previewInvoice();
            },
            deep: true,
        },
        childPlans: {
            handler(value: PlanPerUser[]) {
                this.previewInvoice();
            },
            deep: true,
        },
        subscriptionSchedule: {
            handler(value: SubscriptionSchedule) {
                this.previewInvoice();
            },
            deep: true,
        },
        nextInvoicePreviewResult: {
            handler(value: any) {
                this.nextInvoiceRenderKey += 1;
            },
            deep: true,
        },
    },
    methods: {
        get, // lodash get
        getScrollableParent(element) {
            while (element && element !== document.body) {
                const { overflowY } = window.getComputedStyle(element);
                if (overflowY === 'auto' || overflowY === 'scroll') {
                    if (!element.classList.contains('co-table')) return element;
                }
                element = element.parentElement;
            }
            return window;
        },
        getUserPaymentMethods(userID = this.userID) {
            this.loadingPaymentMethods = true;
            axios({
                method: 'GET',
                url: `/admin/payment/stripe/payment-methods/${userID}?UserID=${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.paymentMethods = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingPaymentMethods = false;
                });
        },
        discard() {
            this.$router.push({
                name: 'AdminMemberPlan',
                params: {
                    ID: this.userID,
                },
            });
        },
        subscriptionScheduleUpdate(schedule: SubscriptionSchedule) {
            this.subscriptionSchedule = schedule;
            this.nextInvoice.resetBillingCycle = schedule.resetBillingCycle ? schedule.resetBillingCycle : false;
            // update subscription schedule params
            this.nextInvoice.scheduledNow = schedule.Now;
            this.nextInvoice.scheduledNextBillingCycle = schedule.NextBillingCycle;
            this.nextInvoice.scheduledDate = schedule.CustomDate / 1000 || null;
            this.nextInvoice.TimeInterval = get(this.membership, 'plan.plan.TimeInteval', null);

            this.nextInvoiceRenderKey += 1;
            this.previewInvoice();
        },
        prepareUpdateMembershipData() {
            let membership = JSON.parse(JSON.stringify(this.membership));

            if (this.childPlans && this.childPlans.length > 0) {
                membership.childPlans = [];
                this.childPlans.forEach((childPlan) => {
                    membership.childPlans.push({
                        plan: childPlan.plan,
                        userID: childPlan.userID,
                    });
                });
            } else {
                membership.childPlans = [];
            }

            if (membership.plan.plan && membership.plan.plan.TaxRate) {
                if (membership.plan.plan.TaxRate.ID) {
                    membership.plan.plan.TaxRateID = membership.plan.plan.TaxRate.ID;
                } else if (membership.plan.plan.TaxRate.Value) {
                    membership.plan.plan.TaxRateID = membership.plan.plan.TaxRate.Value.ID;
                }
                // remove tax rate object from plan
                delete membership.plan.plan.TaxRate;

                membership.plan.userID = this.userID;
            }
            if (membership.plan.plan && membership.plan.plan.Extras && membership.plan.plan.Extras.length > 0) {
                for (let k = 0; k < membership.plan.plan.Extras.length; k += 1) {
                    if (membership.plan.plan.Extras[k].TaxRate) {
                        membership.plan.plan.Extras[k].TaxRateID = membership.plan.plan.Extras[k].TaxRate.ID;
                        // remove tax rate object from plan
                        delete membership.plan.plan.Extras[k].TaxRate;
                    }
                }
            }

            if (membership.childPlans) {
                for (let i = 0; i < membership.childPlans.length; i += 1) {
                    if (membership.childPlans[i].plan.TaxRate) {
                        if (membership.childPlans[i].plan.TaxRate.ID) {
                            membership.childPlans[i].plan.TaxRateID = membership.childPlans[i].plan.TaxRate.ID;
                        } else if (membership.childPlans[i].plan.TaxRate.Value) {
                            membership.childPlans[i].plan.TaxRateID = membership.childPlans[i].plan.TaxRate.Value.ID;
                        }
                        // remove tax rate object from plan
                        delete membership.childPlans[i].plan.TaxRate;

                        if (membership.childPlans[i].plan.Extras && membership.childPlans[i].plan.Extras.length > 0) {
                            for (let k = 0; k < membership.childPlans[i].plan.Extras.length; k += 1) {
                                if (membership.childPlans[i].plan.Extras[k].TaxRate) {
                                    membership.childPlans[i].plan.Extras[k].TaxRateID =
                                        membership.childPlans[i].plan.Extras[k].TaxRate.ID;
                                    // remove tax rate object from plan
                                    delete membership.childPlans[i].plan.Extras[k].TaxRate;
                                }
                            }
                        }
                    }
                }
            }
            // assign subscription schedule to membership object
            membership = {
                ...membership,
                ...this.subscriptionSchedule,
            };
            membership.end = this.subscriptionSchedule.endDate;
            membership.freeTrialDays = this.subscriptionSchedule.trialDays;
            membership.collectionMethod = this.subscriptionSchedule.paymentCollectionMethod;
            membership.CustomDate = this.subscriptionSchedule.CustomDate / 1000 || null;

            const now = new Date().getTime() / 1000;
            if (
                this.currentMembership &&
                this.currentMembership.trialEnd > 0 &&
                this.currentMembership.trialEnd > now
            ) {
                membership.trialEnd = this.currentMembership.trialEnd;
            }

            return membership;
        },
        updateSubscription() {
            this.error = '';

            const membership = this.prepareUpdateMembershipData();
            const data = JSON.stringify(membership);

            this.updatingSubscription = true;
            axios({
                method: 'PUT',
                url: `/admin/community/member/membership/${this.userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data,
            })
                .then((response) => {
                    if (response) {
                        // cast response to UserMembership
                        EventBus.$emit('INFO', {
                            Message: 'Subscription was updated successfully.',
                            Details: '',
                        });
                        this.$router.push({
                            name: 'AdminMemberPlan',
                            params: {
                                ID: this.userID,
                            },
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.data && error.response.data.message) {
                        this.error = error.response.data.message;
                    }
                })
                .finally(() => {
                    this.updatingSubscription = false;
                });
        },
        showSelectMainPlanModal() {
            this.$refs.selectMainPlanModal.show();
        },
        showChildPlanModal() {
            this.childPlanModalMode = 'member-select';
            this.editableChildPlan = null;
            this.childPlanModal = false;
            this.childPlanModal = true;
            this.$refs.selectChildPlanModal.show();
        },
        clickOnMainPlan(plan: object) {
            this.mainPlanModalMode = 'select';
            this.$refs.selectMainPlanModal.show();
        },
        clickOnChildPlan(plan: object) {
            this.childPlanModalMode = 'select';
            this.editableChildPlan = {
                // @ts-ignore
                plan: plan.plan,
                // @ts-ignore
                userID: plan.user.ID,
                // @ts-ignore
                userName: plan.userName,
            };
            this.childPlanModal = false;
            this.childPlanModal = true;
            this.$refs.selectChildPlanModal.show();
        },
        chooseMainPlan(plan: object) {
            this.membership.plan = {
                plan,
                userID: this.userID,
            };
            this.$refs.selectMainPlanModal.hide();
            this.mainPlanKey += 1;
            this.previewInvoice();
        },
        showRemoveMainPlanModal() {
            this.$refs.removeMainPlanModal.show();
        },
        removeMainPlan() {
            this.membership.plan = null;
            this.childPlans = [];
            this.membership.childPlans = [];
            this.mainPlanKey += 1;
            this.$refs.removeMainPlanModal.hide();
            this.previewInvoice();
        },
        mainPlanActionClick(key: string) {
            if (key === 'remove') {
                if (this.membership.childPlans && this.membership.childPlans.length > 0) {
                    this.showRemoveMainPlanModal();
                } else {
                    this.removeMainPlan();
                }
            }

            if (key === 'change') {
                this.mainPlanModalMode = 'list';
            }
            if (key === 'customise') {
                this.mainPlanModalMode = 'edit';
            }
            this.showSelectMainPlanModal();
        },
        childPlanActionClick(key: string, userID: string) {
            if (key === 'remove') {
                this.removeChildPlan(userID);
            }

            if (key === 'change') {
                this.childPlanModalMode = 'list';
            }
            if (key === 'customise') {
                this.childPlanModalMode = 'edit';
            }
            this.editChildPlan(userID);
        },
        chooseChildPlan(childPlan: object) {
            // if this.childPlans contain plan with childPlan.user.ID then remove it
            // @ts-ignore
            const index = this.childPlans.findIndex((item: any) => item.userID === childPlan.user.ID);
            if (index !== -1) {
                this.childPlans.splice(index, 1);
            }

            const planPerUser = {
                // @ts-ignore
                plan: childPlan.plan,
                // @ts-ignore
                userID: childPlan.user.ID,
                // @ts-ignore
                userName: childPlan.user.Profile.Name,
            };

            this.childPlans.push(planPerUser);
            this.childPlanModal = false;
            this.childPlanModal = true;
            this.$refs.selectChildPlanModal.hide();
            this.childPlansKey += 1;
        },
        resetChildPlanModal() {
            this.editableChildPlan = null;
            this.childPlanModal = false;
            this.childPlanModal = true;
        },
        editChildPlan(userID: string) {
            // find child plan with userID
            // @ts-ignore
            const childPlan = this.childPlans.find((item: any) => item.userID === userID);
            if (childPlan) {
                this.editableChildPlan = childPlan;
                this.childPlanModal = false;
                this.childPlanModal = true;
                this.$refs.selectChildPlanModal.show();
            }
        },
        removeChildPlan(userID: string) {
            const index = this.childPlans.findIndex((item: any) => item.userID === userID);
            if (index !== -1) {
                this.childPlans.splice(index, 1);
            }
            this.childPlansKey += 1;
        },
        getUserMembership(ID) {
            axios({
                method: 'GET',
                url: `/admin/community/member/membership/${ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // cast response to UserMembership
                        // this.Membership = response.data as UserMembership;
                        let membership = response.data;
                        if (membership && membership.currentPlan) {
                            membership.currentPlan.plan = membership.currentPlan.populatedPlan;
                        }

                        if (membership && membership.childPlans) {
                            membership.childPlans.forEach((item: any) => {
                                item.plan = item.populatedPlan;
                            });
                        }

                        this.currentMembership = membership;
                        if (membership.currentPlan) {
                            this.membership.plan = {
                                plan: membership.currentPlan.plan,
                                userID: this.userID,
                            };
                            this.mainPlanKey += 1;
                        }

                        if (membership.childPlans) {
                            this.childPlans = membership.childPlans.map((item: any) => ({
                                plan: item.plan,
                                userID: item.userID,
                                userName: item.userName,
                            }));

                            this.childPlansKey += 1;
                        }

                        if (membership.subscriptionStartDate) {
                            this.nextInvoice.subscriptionStart = membership.subscriptionStartDate;
                        }

                        if (membership.trialEnd) {
                            this.nextInvoice.freeTrialEnd = membership.trialEnd;

                            // calculate free trial days between now and trial end
                            const now = new Date().getTime() / 1000;
                            const freeTrialDays = Math.floor((membership.trialEnd - now) / (24 * 60 * 60));
                            if (freeTrialDays > 0) {
                                this.nextInvoice.freeTrialDays = freeTrialDays;
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {});
        },
        async previewInvoice() {
            this.error = null;
            // check if this.membership is empty
            if (!this.membership || !this.membership.plan || !this.membership.plan.plan) {
                return;
            }

            const membership = this.prepareUpdateMembershipData();
            // pass the proration date to the next invoice
            this.nextInvoice.prorationDate = this.membership.prorationDate;

            if (this.nextInvoicePreviewLoading) {
                return;
            }

            this.nextInvoicePreviewLoading = true;
            // fetch invoice preview method returns a promise that always resolves with {data:null,object, error:null,string}
            const fetchInvoicePreview = function (payload) {
                return new Promise(function (resolve, reject) {
                    var that = this;
                    var data = null;
                    var error = null;
                    axios({
                        method: 'POST',
                        url: `/admin/community/member/membership/preview-update`,
                        data: payload,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then((response) => {
                            data = get(response, 'data', null);
                        })
                        .catch((err) => {
                            error = get(err, 'response.data.message', 'empty error');
                            console.log(err);
                        })
                        .finally(() => {
                            resolve({ data, error });
                        });
                });
            };
            // create the list of promises to fetch
            let previewsToFetch = [fetchInvoicePreview(JSON.stringify(membership))];
            if (this.membership.prorate) {
                previewsToFetch.push(
                    fetchInvoicePreview(
                        JSON.stringify({
                            ...membership,
                            prorate: false,
                            NextBillingCycle: null,
                            prorationDate: null,
                        })
                    )
                );
            }
            // fetch and process the invoice previews
            this.nextInvoicePreviewLoading = true;

            const invoicePreviews = await Promise.all(previewsToFetch);

            this.nextInvoice.TimeInterval = get(this.membership, 'plan.plan.TimeInteval', null);

            this.nextInvoice.Created = get(invoicePreviews, '[0].data.Created');
            this.nextInvoice.Total = get(invoicePreviews, '[0].data.Total');
            this.nextInvoice.Currency = get(invoicePreviews, '[0].data.Currency');
            this.nextInvoice.raw = get(invoicePreviews, '[0].data');
            this.nextInvoice.firstRegularInvoice = get(invoicePreviews, '[1].data');
            this.nextInvoiceRenderKey += 1;

            //handle errors
            let errors = [];
            invoicePreviews.map(({ error }) => {
                if (error) errors.push(error);
            });

            this.error = errors.length > 0 ? errors.join(' ') : null;

            this.nextInvoicePreviewLoading = false;
        },
    },
};
