import { render, staticRenderFns } from "./CoCardResource.vue?vue&type=template&id=632a5939&"
import script from "./CoCardResource.vue?vue&type=script&lang=ts&"
export * from "./CoCardResource.vue?vue&type=script&lang=ts&"
import style0 from "./CoCardResource.vue?vue&type=style&index=0&id=632a5939&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports