import { render, staticRenderFns } from "./ResourceEdit.vue?vue&type=template&id=7d3b916e&scoped=true&"
import script from "./ResourceEdit.vue?vue&type=script&lang=ts&"
export * from "./ResourceEdit.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/less/manage-space/base.less?vue&type=style&index=0&id=7d3b916e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d3b916e",
  null
  
)

export default component.exports