


































































































































































//tiptap stuff
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Link from '@tiptap/extension-link';
import CodeBlock from '@tiptap/extension-code-block';

import { Editor, EditorContent, VueRenderer, BubbleMenu } from '@tiptap/vue-2';

import StarterKit from '@tiptap/starter-kit';

import Placeholder from '@tiptap/extension-placeholder';
import { HardBreak } from '@tiptap/extension-hard-break';

import Cohashtag from '@coapp/cohashtag';
import HashtagSuggestion from '../../Organisms/Editor/hashtags/HashtagSuggestion';

import suggestion from './MentionSuggestion.js';

import { get, map, escape, unescape } from 'lodash';

import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoEmojiPicker from '../co-emoji-picker/CoEmojiPicker.vue';
import CoFileInput from '../../Atoms/co-file-input/CoFileInput.vue';
import CoImage from '../../Atoms/co-image/CoImage.vue';
import CoTippy from '../../Atoms/co-tippy/CoTippy.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';
import CoLoadingIndicator from '../../Atoms/co-loading-indicator/coLoadingIndicator.vue';

export default {
    name: 'CoEditor',
    components: {
        CoRoundButton,
        CoIcon,
        Cohashtag,
        CoHeadline,
        CoEmojiPicker,
        CoFileInput,
        CoImage,
        CoTippy,
        CoThumbnail,
        CoLoadingIndicator,
        BubbleMenu,
    },
    props: {
        placeholder: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hideLoadingIndicator: {
            type: Boolean,
            default: false,
        },
        multipleFiles: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: true,
        },
        sendIcon: {
            type: String,
            default: 'send',
        },
        hideSendButton: {
            type: Boolean,
            default: false,
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
        actionsPosition: {
            type: String,
            default: 'bottom-right',
            validator: (value) => ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(value),
        },
        allowStyling: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            validator: (value) => ['compact', 'full'].includes(value),
            default: 'compact',
        },
        focusOnStart: {
            type: Boolean,
            default: false,
        },
        prefilledContent: {
            type: Object,
            default: () => {},
        },
        mode: {
            type: String,
            default: 'normal',
            validator: (value) => ['normal', 'code'].includes(value),
        },
    },
    data() {
        return {
            expanded: false,
            search: '',
            editor: null,

            query: null,
            filteredItems: [],

            isEditorEmpty: true,

            links: [],

            content: null, //HTML content of the editor
            text: null, //raw text of the editor content
            code: null, // code content of the editor
            files: [],
            mentions: [],
            hashtags: [],
            featuredContent: [],
            // generate the placeholder text on mount
            localPlaceholder: this.placeholder || this.$t('placeholders.posteditor'),
        };
    },
    watch: {
        loading: {
            handler: function (newVal) {
                if (this.editor && this.editor.setOptions) {
                    this.editor.setOptions({
                        editable: !newVal,
                    });
                }
            },
        },
    },
    mounted() {
        this.expanded = this.expandOnStart;
        const that = this;

        //map prefilled content to data
        this.text = get(this.prefilledContent, 'Text', null);
        this.content = get(this.prefilledContent, 'Html', null);
        this.code = get(this.prefilledContent, 'Code', null);
        this.files = get(this.prefilledContent, 'Files', []);
        this.links = get(this.prefilledContent, 'Links', []);
        this.mentions = get(this.prefilledContent, 'Mentions', []);
        this.hashtags = get(this.prefilledContent, 'Hashtags', []);
        this.featuredContent = get(this.prefilledContent, 'Featured', []);

        //initialize the editor document according to the mode
        const CustomDocument = Document.extend({
            content: 'codeBlock',
        });

        this.editor = new Editor({
            element: this.$refs.editor,
            content: this.value,
            extensions: [
                this.mode === 'code' ? CustomDocument : null,
                StarterKit.configure(
                    this.mode === 'code'
                        ? {
                              document: false,
                          }
                        : null
                ),
                Placeholder.configure({
                    includeChildren: false,
                    placeholder: ({ node }) => this.localPlaceholder,
                }),
                BubbleMenu,
                Image,
                Mention.configure({
                    HTMLAttributes: {
                        class: 'mention',
                    },
                    suggestion,
                }),
                Cohashtag.configure({
                    HTMLAttributes: {
                        class: 'hashtag',
                    },
                    suggestion: HashtagSuggestion,
                }),
                Link.configure({
                    openOnClick: true,
                    linkOnPaste: true,
                    HTMLAttributes: {
                        class: 'link',
                    },
                }),
                HardBreak,
                CodeBlock,
            ],
            enableInputRules: [Link],
            enablePasteRules: [Link, Cohashtag],
            onFocus({ editor, event }) {
                // The editor is focused.
                that.onFocus();
            },
            onBlur({ editor, event }) {
                // The editor isn’t focused anymore.
                that.onBlur();
            },
            onUpdate({ editor }) {
                // The editor content has changed.
                that.text = editor.getText();
                that.content = that.text.length > 0 ? editor.getHTML() : '';
                //parse the html content to inventorize the links
                let parser = new DOMParser();
                const doc = parser.parseFromString(that.content, 'text/html');

                //extract featured platform content by matching hostnames
                that.featuredContent = Array.from(doc.querySelectorAll('a.link'))
                    .filter((link) => get(link, 'href', '').includes(window.location.host))
                    .map((link) => {
                        return {
                            href: get(link, 'href', null),
                            text: get(link, 'text', null),
                        };
                    });
                //extract external links
                that.links = Array.from(doc.querySelectorAll('a.link'))
                    .filter((link) => !get(link, 'href', '').includes(window.location.host))
                    .map((link) => {
                        return {
                            href: get(link, 'href', null),
                            text: get(link, 'text', null),
                            isExternal: true,
                        };
                    });
                //extract mentions and hashtags
                that.mentions = Array.from(doc.querySelectorAll('.mention')).map((link) => {
                    return {
                        ...get(link, 'dataset', {}),
                    };
                });
                that.hashtags = Array.from(
                    parser.parseFromString(that.content, 'text/html').querySelectorAll('.hashtag')
                ).map((link) => {
                    return {
                        ...get(link, 'dataset', {}),
                    };
                });
                that.emitInput();
            },
        });
        this.$nextTick(() => {
            //set the editor content on load if prefilled content is available
            if (this.text || this.content) {
                const text = this.text || this.content || '';
                this.editor
                    .chain()
                    .setContent(text, { emitUpdate: true, preserveWhitespace: 'full' })
                    .setHardBreak()
                    .run();
            } else if (this.code) {
                //render embed code in the editor
                this.editor
                    .chain()
                    .setContent(this.escape(this.code), {
                        emitUpdate: true,
                        preserveWhitespace: 'full',
                    })
                    .setCodeBlock()
                    .run();
            }

            if (this.focusOnStart) this.focus();

            this.$emit('ready', this);
        });
    },
    methods: {
        escape,
        unescape,
        /**
         * Clear the editor content
         * @param {boolean} emit - Emit the input event
         * @param {boolean} collapse - Collapse the editor
         * @return {void}
         */
        clear(collapse = false, emit = true) {
            this.files = [];
            this.editor.chain().clearContent(true).blur().run();
            if (collapse) {
                this.collapse();
            }

            if (emit) {
                this.$emit('input', null);
            }
        },

        /**
         * Focus the editor
         * @return {void}
         */
        focus(expand = false) {
            this.editor.commands.focus('end');
            if (expand) {
                this.expand();
            }
        },

        /**
         * Collapse the editor
         * @return {void}
         */
        collapse() {
            this.expanded = false;
        },

        /**
         * Expand the editor
         * @return {void}
         */
        expand() {
            this.expanded = true;
        },
        /**
         * Function called when the editor is focused (onFocus)
         * Used to expand the editor if it's collapsed and to emit the focus event
         * @return {void}
         */
        onFocus() {
            this.$emit('onFocus');
            this.expanded = true;
        },

        /**
         * Function called when the editor is blurred (onBlur)
         * Used to to emit the blur event
         * @return {void}
         */
        onBlur() {
            // if((!this.content || this.content.length === 0) && (!this.files || this.files.length === 0)){
            //     this.expanded = false;
            // }
            this.$emit('onBlur');
        },
        onEscape(event) {
            this.$emit('onEscape', event);
        },
        uploadFile(file) {
            return new Promise((resolve, reject) => {
                resolve(file);
            });
        },
        previewFiles(event) {
            if (!event) return;
            let newFiles = map(event, (file) => {
                return {
                    file: file,
                    url: URL.createObjectURL(file),
                };
            });
            this.files && this.files.length > 0 ? (this.files = [...this.files, ...newFiles]) : (this.files = newFiles);
            this.emitInput();
        },

        removeFile(index) {
            this.files.splice(index, 1);
            this.emitInput();
        },

        textAreaClass() {
            const result = [];

            if (this.taclass) result.push(this.taclass.split(' '));

            if (this.expanded) result.push('active');

            return result;
        },
        insertEmoji(emoji) {
            this.editor.chain().focus().insertContent(emoji).run();
        },

        mention(event) {
            this.editor.chain().focus().run();
            this.editor.chain().insertContent('@').run();
        },
        hashtag(event) {
            this.editor.chain().focus().insertContent(' #').run();
            this.editor.commands.focus();
        },
        send(event) {
            let content = {
                Text: this.text,
                Html: this.content,
                Files: this.files,
                Featured: this.featuredContent,
                Links: this.links,
                Mentions: this.mentions,
                Hashtags: this.hashtags,
            };
            this.$emit('send', content);
        },
        insertMention(label, id) {
            this.editor
                .chain()
                .setContent({
                    type: 'paragraph',
                    content: [
                        {
                            type: 'mention',
                            attrs: {
                                id: id,
                                label: label,
                            },
                        },
                    ],
                })
                .insertContent(' ')
                .run();
        },
        emitInput() {
            let content = {
                Text: unescape(this.text),
                Html: this.content,
                Files: this.files,
                Featured: this.featuredContent,
                Links: this.links,
                Mentions: this.mentions,
                Hashtags: this.hashtags,
            };
            this.$emit('input', content);
        },
    },
    beforeDestroy() {
        this.editor.destroy();
    },
};
