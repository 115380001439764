<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <b-overlay :show="loading" class="h-100">
            <div class="bg-white round-unify-xs p-4 h-100" v-if="space">
                <b-form ref="form0">
                    <div class="w-100 block">
                        <div class="w-100 mb-2 mt-0">
                            <b-form-group
                                id="input-group-title"
                                label="Name"
                                label-for="input-title"
                                label-class="label-align"
                                aria-describedby="title-description"
                            >
                                <b-form-input
                                    id="input-title"
                                    v-model="space['Name']"
                                    type="text"
                                    required
                                    placeholder="Enter your space name"
                                    class="rounded-pill"
                                ></b-form-input>
                                <b-form-text id="title-description" class="ml-3"
                                    >The name of your community</b-form-text
                                >
                            </b-form-group>

                            <b-form-group
                                id="input-group-support-url"
                                label="Help URL"
                                label-for="input-help-url"
                                label-class="label-align"
                                aria-describedby="help-description"
                            >
                                <b-form-input
                                    id="input-help-url"
                                    v-model="space['SupportURL']"
                                    type="text"
                                    placeholder="Support page url"
                                    class="rounded-pill"
                                ></b-form-input>
                                <b-form-text id="help-description" class="ml-3"
                                    >Define an URL under which your users can find help articles or a way to contact
                                    your customer support. This can be an external URL or a page or channel in your
                                    community. The link to this URL will show up as "Help" item in the dropdown menu
                                    when users click on their profile picture.</b-form-text
                                >
                            </b-form-group>

                            <b-form-group
                                id="input-group-DisclaimerForEmail"
                                label="Email Footer/Disclaimer"
                                label-for="input-DisclaimerForEmail"
                                label-class="label-align"
                                aria-describedby="DisclaimerForEmail-description"
                            >
                                <b-form-textarea
                                    id="input-DisclaimerForEmail"
                                    v-model="space['DisclaimerForEmail']"
                                    type="text"
                                    placeholder="Define your Footer/Disclaimer content here"
                                    class="form-control d-block"
                                    style="border-radius: 1rem"
                                ></b-form-textarea>
                                <b-form-text id="DisclaimerForEmail-description" class="ml-3"
                                    >This text will be inserted in the footer of all emails that are sent from your
                                    community.</b-form-text
                                >
                            </b-form-group>
                        </div>
                        <!-- Footer start -->
                        <div class="d-flex justify-content-between mt-4">
                            <b-button
                                variant="outline-danger"
                                @click="hasHistory() ? $router.go(-1) : $router.push('/admin/settings')"
                                >Cancel</b-button
                            >

                            <b-button variant="primary" @click="handleSubmit()">Save</b-button>
                        </div>
                        <!-- Footer end -->
                    </div>
                </b-form>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'GeneralSettings',
    data() {
        return {
            loading: false,
            space: null,
        };
    },

    mounted() {
        this.getSpaceSettings();
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },

        handleSubmit() {
            this.loading = true;

            const data = JSON.stringify(this.space);
            axios({
                method: 'PUT',
                url: '/admin/space/settings',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        const msg = {
                            Message: 'Changes saved',
                            Details: '',
                        };
                        EventBus.$emit('INFO', msg);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to save space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },

        getSpaceSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
    },
};
</script>
