










































import axios from 'axios';
import isEmail from 'validator/lib/isEmail';
import EventBus from '../../../eventBus';
import CoModal from '../co-modal/CoModal.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoTagList from '../co-TagList/coTagList.vue';
import CoText from '../../Atoms/co-text/CoText.vue';

export default {
    name: 'CoUserInvite',
    components: {
        CoModal,
        CoButton,
        CoTagList,
        CoText,
    },
    props: {
        launchButtonLabel: {
            type: String,
            default: 'Invite',
        },
        launchButtonVariant: {
            type: String,
            default: 'primary',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            email: null,
            emailList: [],
            loading: false,
        };
    },
    computed: {
        readyToSend() {
            return this.emailList.length > 0 || this.emailValid ? true : false;
        },
        emailValid() {
            return this.email ? this.isEmail(this.email) : false;
        },
    },
    methods: {
        isEmail(email) {
            if (!email) {
                return false;
            }
            return isEmail(email);
        },
        show() {
            this.$refs.invitemodal.show();
        },
        tagsUpdatedHandler(event) {
            this.emailList = event;
        },
        sendInvite() {
            this.loading = true;
            if (this.emailValid) {
                this.$refs.emailList.addTag();
            }
            const data = {
                Emails: this.emailList.map((el) => {
                    return { Email: el };
                }),
            };
            axios({
                method: 'POST',
                url: '/admin/member/invite/V2',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const msg = {
                        Message: 'Invite link sent',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.resetModal();
                    this.$emit('invite-success');
                    if (this.$refs.invitemodal) {
                        this.$refs.invitemodal.hide();
                    }
                })
                .catch((error) => {
                    this.sendShow = false;
                    const msg = {
                        Message: 'Failed to send invite link',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                    this.$emit('invite-error');
                    console.log(error);
                    this.resetModal();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        resetModal() {
            this.email = null;
            this.emailList = [];
            this.loading = false;
            if (this.$refs.invitemodal) {
                this.$refs.invitemodal.hide();
            }
        },
    },
};
