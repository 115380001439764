import { render, staticRenderFns } from "./CoActiveMembersWidget.vue?vue&type=template&id=3843443a&scoped=true&"
import script from "./CoActiveMembersWidget.vue?vue&type=script&lang=ts&"
export * from "./CoActiveMembersWidget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3843443a",
  null
  
)

export default component.exports