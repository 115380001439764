


















export default {
    name: 'CoLoadingIndicator',
    props: {
        variant: {
            type: String,
            default: 'spinner',
            validator(val) {
                return ['spinner', 'dots', 'loading-bar'].indexOf(val) !== -1;
            },
        },
        current: {
            type: Number,
            required: false,
            default: 0,
        },
        total: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    computed: {
        progressWidth() {
            return `${(this.current / this.total) * 100}%`;
        },
    },
};
