














































































































































import axios from 'axios';
import { find, filter, map, get } from 'lodash';
import CoEmojiPicker from '../co-emoji-picker/CoEmojiPicker.vue';
import EventBus from '../../../eventBus';
import CoTippy from '../../Atoms/co-tippy/CoTippy.vue';

export default {
    components: { CoEmojiPicker, CoTippy },
    name: 'CoReactions',
    props: {
        reactions: {
            type: Object,
            default() {
                return {};
            },
        },
        objectID: {
            type: String,
            required: true,
        },
        objectType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            me: get(this, '$store.state.me') ? this.$store.state.me : {},
            rerender: 0,
        };
    },
    computed: {
        reactionList() {
            return this.reactions;
        },
        uid() {
            return this._uid;
        },
    },
    methods: {
        reactionHandler(emoji) {
            if (this.reactions && this.reactions[emoji] && this.reactions[emoji].Users) {
                // reaction type already exists
                if (find(this.reactions[emoji].Users, { ID: this.me.ID })) {
                    this.reactions[emoji].Users = filter(this.reactions[emoji].Users, (o) => {
                        return o.ID != this.me.ID;
                    });
                    //save
                    this.removeReaction(emoji);
                } else {
                    this.addReaction(emoji);
                    this.reactions[emoji].Users.push(this.me);
                }
            } else {
                // reaction type does not exists
                this.reactions[emoji] = { Users: [this.me] };
                this.addReaction(emoji);
            }
            this.rerender += 1;
        },
        addReaction(emoji) {
            axios({
                method: 'POST',
                url: '/reactions',
                data: {
                    Emoji: emoji,
                    ObjectID: this.objectID,
                    ObjectType: this.objectType,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {})
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to save the reaction',
                        Details: error,
                    });
                });
        },
        removeReaction(emoji) {
            axios({
                method: 'DELETE',
                url: '/reactions',
                data: {
                    Emoji: emoji,
                    ObjectID: this.objectID,
                    ObjectType: this.objectType,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {})
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to save the reaction',
                        Details: error,
                    });
                });
        },
        byMe(users) {
            return find(users, { ID: this.me.ID }) ? true : false;
        },
        getUserNames(users) {
            return map(users, (value) => {
                return value && value.ID === this.me.ID ? this.$t('labels.you') : get(value, 'Name');
            });
        },
    },
};
