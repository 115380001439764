<template>
    <div class="co-overlay-wrapper" :class="{ 'co-overlay-active': show }">
        <slot></slot>
        <div v-if="show" class="co-overlay" :class="{ 'co-overlay-fixed': fixed }">
            <div class="co-overlay-spinner" v-if="showSpinner">
                <CoLoadingIndicator />
            </div>
            <slot name="overlay"></slot>
        </div>
    </div>
</template>

<script>
import CoLoadingIndicator from '../../Atoms/co-loading-indicator/coLoadingIndicator.vue';

export default {
    name: 'CoOverlay',
    components: {
        CoLoadingIndicator,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
        showSpinner: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped>
.co-overlay-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

.co-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
}

.co-overlay-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.co-overlay-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
