<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div class="container-fluid p-0 m-0">
            <div class="row m-0 w-100">
                <div class="col">
                    <CoLoadingIndicator v-if="loading" />
                    <!-- not connected -->
                    <co-card v-if="!loading && status === 0">
                        <div class="d-flex justify-content-between">
                            <h1>Start accepting payments now</h1>
                        </div>
                        <p class="mt-3">
                            coapp uses Stripe to provide payments and billing services for your community. <br />
                            In order to set up coapp Payments by Stripe you have to create a Stripe Account first. Ready
                            to get started?
                        </p>
                        <co-button
                            label="Connect to Stripe"
                            variant="primary"
                            :loading="btnLoading"
                            :disabled="btnLoading"
                            @click.native.prevent="getPaymentAccountConnectLink"
                        />
                    </co-card>
                    <!-- pending -->
                    <co-card v-if="!loading && status === 1" class="bg-yellow">
                        <h1>Stripe connection is pending</h1>
                        <p class="mt-3">
                            Pending means the account is being reviewed or verified by Stripe. This occurs when Stripe
                            is verifying the information that was provided, such as an ID document upload. This might
                            take a moment. If this status doesn't change please
                            <a href="https://support.stripe.com/" target="_blank" title="contact Stripe support"
                                >contact the Stripe-support</a
                            >.
                        </p>
                    </co-card>
                    <!-- need more data -->
                    <co-card v-if="!loading && status === 2" class="bg-yellow">
                        <h1><i class="icon icon-warning"></i>Stripe needs more information</h1>
                        <p class="mt-3">Your Stripe account misses some information, before it can be activated.</p>
                        <co-button
                            label="Complete the setup"
                            variant="primary"
                            :loading="btnLoading"
                            :disabled="btnLoading"
                            @click.native.prevent="getPaymentAccountConnectLink"
                        />
                    </co-card>
                    <!-- connected -->
                    <co-card v-if="!loading && status === 3" class="bg-green">
                        <h1>Stripe is connected and working</h1>
                        <p class="mt-3">
                            coapp uses Stripe to provide payments and billing services for your community. <br />
                            If you want to deattach your stripe account from coapp, please contact our support team.
                        </p>
                        <co-button
                            label="Stripe dashboard"
                            variant="primary"
                            :loading="btnLoading"
                            :disabled="btnLoading"
                            @click.native.prevent="navigateToStripeDashboard"
                        />
                    </co-card>
                    <!-- always show stripe payment prinig -->
                    <co-card variant="midgrey" class="mt-3">
                        <h2>Payment fees</h2>
                        <p>
                            <span v-if="applicationFee">
                                coapp charges a platform fee fee of {{ applicationFee }}% on all revenue processed.
                            </span>
                            <span v-else> coapp charges a platform fee as specified in your subscription. </span>
                            To reduce your application fees
                            <router-link to="/admin/account">upgrade your coapp subscription</router-link>.
                        </p>
                        <p>
                            Stripe charges an additional fee for "Stripe Billing" plus payment fees varying depending on
                            the payment method chosen. An overview of Stripe payment fees can be found
                            <a href="https://stripe.com/pricing" target="_blank" title="Stripe Payment fees">here</a>.
                        </p>
                    </co-card>
                </div>
            </div>
        </div>

        <!-- modals -->
        <!-- modal, to inform that signup strategy is approval and should be changed in order to connect stripe -->
        <CoModal
            v-if="this.signUpSettings.signUpStrategy === 'approval'"
            id="settingsChangeNeeded"
            ref="settingsChangeNeeded"
            size="sm"
            @ok="goToSignupStrategies()"
            ok-variant="primary"
            centered
        >
            <template v-slot:header>
                <CoHeadline is="h2">Sign up strategy</CoHeadline>
            </template>
            <template v-slot:body>
                <CoText>
                    Your sign up strategy is set to approval. In order to connect stripe, please change your sign up
                    strategy to another option.
                </CoText>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-between w-100">
                    <CoButton
                        :label="$t('labels.cancel')"
                        variant="secondary"
                        @click="$refs['settingsChangeNeeded'].hide()"
                    />
                    <CoButton label="Go to signup settings" variant="primary" @click="goToSignupStrategies()" />
                </div>
            </template>
        </CoModal>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoLoadingIndicator from '../../../../components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoCard from '../../../../components/Molecules/co-card/CoCard.vue';
import CoButton from '../../../../components/Atoms/co-button/CoButton.vue';

export default {
    name: 'Stripe',
    components: { CoHeadline, CoText, CoModal, CoLoadingIndicator, CoCard, CoButton },
    data() {
        return {
            status: 0, // 0 not connected, 1 pending, 2 need more data, 3 connected
            applicationFee: null,
            loading: false,
            chargesEnabled: false,
            btnLoading: false,
            isStrategyModal: false,
            signUpSettings: {},
        };
    },
    mounted() {
        this.getApplicationFee();
        this.getPaymentAccountConnectState();
        this.getSignUpSettings();
        window.addEventListener('message', this.handleMessage);
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handleMessage);
    },
    methods: {
        handleMessage(event) {
            if (event.data.type === 'WINDOW_CLOSE') {
                this.signUpSettings.signUpStrategy = event.data.data.strategy;
                if (event.data.data.strategy !== 'approval') {
                    this.getPaymentAccountConnectLink();
                } else {
                    EventBus.$emit('Error', {
                        Message: 'Please change your sign up strategy to connect stripe',
                        Details: '',
                    });
                }
            }
        },
        getApplicationFee() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.ApplicationFee) {
                        this.applicationFee = response.data.ApplicationFee;
                    }
                })
                .catch((error) => {
                    if (console && console.warn) console.warn('could not get application fee for this space');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addPaymentMethod() {
            return '/admin/setup/signup-strategy?closeAfterSave=true';
        },

        goToSignupStrategies() {
            window.open(this.addPaymentMethod(), '_blank').focus();
        },
        getSignUpSettings() {
            this.$store
                .dispatch('getSignupSettings')
                .then((response) => {
                    this.signUpSettings = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getPaymentAccountConnectLink() {
            if (this.signUpSettings.signUpStrategy === 'approval') {
                this.isStrategyModal = true;
                this.$refs.settingsChangeNeeded.show();
            } else {
                this.connectStripe();
            }
        },
        connectStripe() {
            this.btnLoading = true;
            axios({
                method: 'GET',
                url: '/admin/payment/stripe/account-link',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.URL) {
                        window.location = response.data.URL;
                    } else {
                        EventBus.$emit('Error', {
                            Message: 'Failed to connect stripe. Please contact our support team.',
                            Details: '',
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('Error', {
                        Message: 'Failed to connect stripe. Please contact our support team.',
                        Details: '',
                    });
                })
                .finally(() => {
                    this.btnLoading = false;
                });
        },
        getPaymentAccountConnectState() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/payment/stripe/account-state',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.chargesEnabled = response.data.ChargesEnabled;
                        if (this.chargesEnabled) {
                            this.status = 3;
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        navigateToStripeDashboard() {
            window.location.href = 'https://dashboard.stripe.com/dashboard';
        },
    },
};
</script>
