<template>
    <div>
        <!-- header start -->
        <CoCard variant="border" class="mb-3">
            <div class="d-flex align-items-center w-100">
                <CoHeadline class="" :level="2" trunc>{{ $t('labels.plancurrent') }}</CoHeadline>
            </div>
        </CoCard>

        <CoAlert
            v-if="$store.state && $store.state.me && $store.state.me.Status && $store.state.me.Status > 2"
            :text="$t(`messages.userstatus[${$store.state.me.Status}]`)"
            variant="yellow"
            class="mb-3"
        />

        <!-- header end -->
        <b-overlay :show="loading">
            <!-- current plan -->
            <div class="">
                <CoCardPlan
                    v-if="plan"
                    :plan="plan"
                    show-image
                    show-total-price
                    clickable
                    @click="viewPlanDetails('current')"
                >
                    <template v-slot:actions>
                        <CoDropdown
                            v-if="plan && !isMembershipComplex() && (!upcoming || !cancelledTo)"
                            disable-propagation
                        >
                            <template v-slot:button>
                                <CoRoundButton icon="three-dots" variant="icon" />
                            </template>
                            <CoDropdownItem
                                v-if="!cancelledTo"
                                @click="$router.push('/account/plan/change')"
                                class="text-primary"
                                target="_self"
                                >{{ $t('labels.planchange') }}
                            </CoDropdownItem>

                            <CoDropdownItem
                                v-if="!upcoming && plan && cancelledTo"
                                @click="reactivatePlan"
                                class="text-primary"
                                >{{ $t('labels.planreactivate') }}</CoDropdownItem
                            >

                            <CoDropdownItem
                                v-if="!upcoming && plan && !cancelledTo"
                                class="text-secondary"
                                @click="cancelMyPlan"
                                >{{ $t('labels.plancancel') }}</CoDropdownItem
                            >
                        </CoDropdown>
                    </template>
                </CoCardPlan>
                <div v-else class="">
                    <p class="mx-3">{{ $t('noplan') }}</p>
                    <b-button variant="primary" to="/account/plan/change">{{ $t('labels.planselection') }}</b-button>
                </div>
                <CoButton
                    v-if="plan && !isMembershipComplex() && !upcoming && !cancelledTo"
                    variant="primary"
                    @click="$router.push('/account/plan/change')"
                    class="ml-2 mt-3"
                    :label="$t('labels.planchange')"
                />
                <CoAlert v-if="isMembershipComplex()" :text="$t('complexplaninfo')" variant="grey" />
            </div>
            <!-- upcoming plan -->
            <div v-if="upcoming && upcoming.Plan" class="mt-4">
                <div>
                    <div class="d-flex align-items-center justify-content-between px-3">
                        <CoHeadline :level="2" class="">{{ $t('labels.planupcoming') }}</CoHeadline>
                        <CoButton variant="danger" @click="showCancelUpcomingPlanModal">{{
                            $t('labels.cancel')
                        }}</CoButton>
                    </div>
                    <CoText class="ml-3" :text="$t('datetime', { date: upcoming.StartsAt, format: 'date' })" />
                </div>
                <!-- Starts {{ (upcoming.StartsAt + '000') | readabledate }} -->
                <div class="">
                    <CoCardPlan
                        v-if="upcoming.Plan"
                        :plan="upcoming.Plan"
                        show-image
                        show-total-price
                        clickable
                        @click="viewPlanDetails('upcoming')"
                    ></CoCardPlan>
                </div>
            </div>
            <!-- upcoming plan cancelation -->
            <div v-if="!upcoming && plan && cancelledTo" class="mt-4">
                <CoHeadline :level="2" class="p-3">{{ $t('labels.planupcoming') }}</CoHeadline>
                <CoAlert>
                    <p class="text">
                        <i18n path="cancellationconfimation" tag="span" class="">
                            <template v-slot:date>
                                <co-date :date="cancelledTo" format="exact" />
                            </template>
                        </i18n>
                    </p>
                </CoAlert>
                <div class="d-flex mt-3 ml-3">
                    <span class="small">{{ $t('changedyourmind') }}</span>
                    <b-link @click="reactivatePlan" class="ml-1 small">{{ $t('undocancellation') }}</b-link>
                </div>
            </div>
        </b-overlay>

        <!-- cancel-scheduled-change modal -->
        <b-modal
            id="cancel-scheduled-change-modal"
            size="md"
            :title="$t('labels.planchangecancel')"
            @ok="cancelUpcomingPlan"
            centered
            :ok-title="$t('labels.planchangecancel')"
            ok-variant="danger"
            :cancel-title="$t('labels.back')"
        >
            <p class="mb-1">
                {{ $t('renewinfo') }}
            </p>
        </b-modal>
        <!-- cancel-scheduled-change modal -->
    </div>
</template>

<script>
import axios from 'axios';
import i18n from 'vue-i18n';
import Vue from 'vue';
import VueMoment from 'vue-moment';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoCardPlan from '@/components/Molecules/co-card-plan/CoCardPlan.vue';
import CoDate from '../../../components/Molecules/co-date/CoDate.vue';
import EventBus from '../../../eventBus';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';

const moment = require('moment');
Vue.use(require('vue-moment'), {
    moment,
});

export default {
    i18n: {
        messages: {
            en: {
                cancellationconfimation: 'Your subscription was cancelled. It will terminate on {date}',
                renewinfo:
                    'The upcoming change of your subscription will be aborted and your current subscription preserved. Do you want to continue?',
                noplan: 'You currently have no plan.',
                complexplaninfo: 'You have a special plan configuration. To change your plan please contact the team.',
                changedyourmind: 'Changed your mind? ',
                undocancellation: 'Undo your plan cancellation.',
            },
            de: {
                cancellationconfimation: 'Deine Mitgliedschaft ist gekündigt. Sie endet am {date}',
                renewinfo:
                    'Deine geplante Tarifänderung wird storniert und dein bestehender Tarif bleibt bestehen. Willst du fortfahren?',
                noplan: 'Du hast derzeit keinen Tarif.',
                complexplaninfo:
                    'Du hast eine besondere Tarifkonfiguration. Bitte spreche unser Team an, um deinen Tarif zu ändern oder zu kündigen.',
                changedyourmind: 'Du hast es dir anders überlegt?',
                undocancellation: 'Mach deine Kündigung rückgängig.',
            },
        },
    },
    name: 'CurrentPlanView',
    components: {
        CoAlert,
        CoDate,
        CoButton,
        CoCardPlan,
    },
    data() {
        return {
            startsAt: null,
            cancelledTo: null,
            upcoming: null,
            plan: null,
            me: this.$store.state.me,
            imageURL: '',
            loading: false,

            Membership: {},
        };
    },
    created() {
        // redirect to account page if billing is not turned on
        if (this.$store && this.$store.state && !this.$store.state.billingTurnedOn) {
            this.$router.push('/account');
        }
        this.getMyPlan();
        this.getUserMembership();
    },
    methods: {
        showCancelUpcomingPlanModal() {
            this.$bvModal.show('cancel-scheduled-change-modal');
        },
        viewPlanDetails(type) {
            this.$router.push(`/account/plan/view/${type}`);
        },
        isMembershipComplex() {
            return (
                this.Membership &&
                (this.Membership.parentPlanUserID ||
                    (this.Membership.childPlans && this.Membership.childPlans.length > 0))
            );
        },
        getUserMembership() {
            axios({
                method: 'GET',
                url: `/membership`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // cast response to UserMembership
                        this.Membership = response.data;
                    }
                })
                .catch((error) => {
                    this.Membership = {
                        status: 'UNSPECIFIED',
                        stripeCustomerLink: null,
                        stripeCustomerID: '',

                        currentBillingPeriodStart: null, // unix timestamp in seconds when the current billing period started
                        currentBillingPeriodEnd: null, // unix timestamp in seconds when the current billing period ends
                        billingCycleAnchor: null, // unix timestamp in seconds when the billing cycle anchor was set
                        cancelAt: null, // unix timestamp in seconds when the subscription will be canceled
                        canceledAt: null, // unix timestamp in seconds when the subscription was canceled
                        startDate: null, // unix timestamp in seconds when the subscription was created
                        endedAt: null, // unix timestamp in seconds when the subscription ended
                        created: null, // unix timestamp in seconds when the membership was created
                    };
                    console.log(error);
                })
                .finally(() => {});
        },

        getMyPlan() {
            this.loading = true;

            axios({
                method: 'GET',
                url: '/me/plan',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.plan = response.data.current.Plan;
                        this.startsAt = response.data.current.StartsAt;
                        this.cancelledTo = response.data.current.CancelledAt;
                        if (response.data.upcoming) {
                            this.upcoming = response.data.upcoming;
                        } else {
                            this.upcoming = null;
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        cancelMyPlan() {
            this.loading = true;

            axios({
                method: 'DELETE',
                url: '/me/plan',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.getMyPlan();
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        cancelUpcomingPlan() {
            this.loading = true;

            axios({
                method: 'DELETE',
                url: '/me/plan/upcomming',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.getMyPlan();
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        reactivatePlan() {
            this.loading = true;

            axios({
                method: 'POST',
                url: '/me/renew-plan',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.getMyPlan();
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
};
</script>
