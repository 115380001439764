var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-event"},[(_vm.loading)?_c('div',[(_vm.variant === 'full')?_c('div',{style:({
                aspectRatio: 4 / 2,
            })},[_c('CoSkeleton',{attrs:{"variant":"image"}})],1):_vm._e(),_c('div',{staticClass:"title-section",class:{
                'p-3': _vm.variant === 'full',
            }},[_c('div',{staticClass:"d-flex align-items-center headline-section"},[_c('coSkeleton',{staticClass:"mr-5",attrs:{"variant":"text"}}),_c('coSkeleton',{staticClass:"ml-auto",staticStyle:{"width":"100px"},attrs:{"variant":"button"}})],1)])]):_c('div',{style:({
            cursor: _vm.link ? 'pointer' : 'auto',
        }),on:{"click":function($event){_vm.link && _vm.event ? _vm.$router.push(("/event/" + (_vm.get(_vm.event, 'Slug', '')))) : null}}},[(_vm.variant === 'full')?_c('div',{staticClass:"image position-relative",class:{
                'has-image': _vm.get(_vm.event, 'Images[0].ImageURL', null),
            }},[_c('div',{staticClass:"pills mt-3 mx-3 position-relative d-flex align-items-center"},[_c('CoPill',{staticClass:"mr-2 flex-shrink-1",attrs:{"text":_vm.$t('labels.event'),"variant":"lightgrey","truncate":""}}),_vm._l((_vm.event.Types),function(type,index){return _c('CoPill',{key:'typ-' + type.Value + index,staticClass:"mr-2 flex-shrink-1",attrs:{"text":type.Name ? type.Name : '',"variant":'lightgrey',"truncate":""}})})],2),(_vm.get(_vm.event, 'Images[0].ImageURL', null))?_c('CoImage',{attrs:{"src":_vm.get(_vm.event, 'Images[0].ImageURL', null),"alt":_vm.get(_vm.event, 'Images[0].Title', ''),"placeholderFormat":"landscape"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"title-section",class:{ 'p-3': _vm.variant === 'full' }},[_c('div',[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center headline-section"},[_c('div',{staticClass:"title d-flex align-items-center flex-shrink-1 mr-auto mr-md-0 mb-3 mb-md-0"},[(_vm.variant === 'default')?_c('CoThumbnail',{staticClass:"flex-shrink-0",attrs:{"imageURL":_vm.get(_vm.event, 'Images[0].ImageURL', null)}}):_vm._e(),_c('CoHeadline',{staticClass:"pl-2",attrs:{"level":2}},[_vm._v(" "+_vm._s(_vm.event.Title)+" ")])],1),_c('div',{staticClass:"actions-section flex-shrink-0 ml-0 ml-md-auto d-flex"},[_c('div',{staticClass:"people flex-shrink-0 px-2 d-flex align-items-center mr-auto"},[_vm._l((_vm.event.Participants && _vm.event.Participants.length
                                    ? _vm.event.Participants.slice(0, 3)
                                    : []),function(user,index){return _c('CoThumbnail',{key:user.ID + index,attrs:{"imageURL":_vm.get(user, 'ImageURL'),"round":true,"content-type":'user',"link":'/profile/' + user.Slug}})}),(_vm.event.Participants && _vm.event.Participants.length > 3)?_c('CoText',{staticClass:"pl-2 m-0",attrs:{"type":"p2","variant":"mute"}},[_vm._v(" + "+_vm._s(_vm.event.Participants.length - 3)+" ")]):_vm._e()],2),_c('div',{staticClass:"actions flex-shrink-0"},[(_vm.isEventPast)?_c('CoButton',{attrs:{"label":_vm.$t('labels.ended'),"variant":"primary","disabled":true}}):(!_vm.amIattending && !_vm.amIorganising)?_c('div',[(_vm.event.Tickets && _vm.event.Tickets.length > 0)?_c('CoEventTicketCheckout',{attrs:{"tickets":_vm.event.Tickets,"currency":_vm.$store.state.space.Currency,"taxRates":_vm.taxRates,"eventID":_vm.event.ID,"eventSlug":_vm.event.Slug,"eventName":_vm.event.Title}}):_c('CoButton',{attrs:{"label":_vm.$t('labels.attend'),"variant":"primary"},on:{"click":_vm.attendEvent}})],1):(_vm.amIorganising)?_c('CoPill',[_vm._v(" "+_vm._s(_vm.$t('labels.byme'))+" ")]):_c('CoPill',{attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('labels.going'))+" ")])],1)])]),_c('div',{staticClass:"subtitle-section flex-fill mt-3 d-flex"},[_c('CoText',{staticClass:"pl-2"},[_c('CoDate',{attrs:{"date":_vm.event.StartDate,"format":_vm.isEventPast ? 'relative' : 'E, PPp'}}),(_vm.event.Location)?_c('span',[_vm._v(" • ")]):_vm._e(),(_vm.event.Location)?_c('address',{staticClass:"d-inline"},[_vm._v(_vm._s(_vm.event.Location))]):_vm._e()],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }