<template>
    <div class="co drop-down-list">
        <div class="scroll-list">
            <ul>
                <li v-for="item in items" :key="item.ID" @mousedown.capture.stop="$emit('itemClicked', item)">
                    <div v-if="item.Profile">
                        {{ item.Profile.Name }}
                    </div>
                    <div v-else-if="item.Name">
                        {{ item.Name }}
                    </div>
                    <div v-else-if="item.DisplayName">
                        {{ item.DisplayName }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<style lang="less" scoped>
@import '../../../assets/less/variables.less';
@import './CoDropdownList.less';
</style>
<script>
import '../../../assets/less/variables.less';

export default {
    name: 'CoDropdownListObjects',
    props: {
        items: Array, //
    },
    mounted() {},
};
</script>
