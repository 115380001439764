<template>
    <div>
        <!-- header start -->
        <CoCard variant="border" class="mb-3">
            <div class="d-flex align-items-center w-100">
                <CoHeadline class="" :level="2" trunc>{{ $t('labels.planchange') }}</CoHeadline>
            </div>
        </CoCard>
        <b-overlay :show="loading">
            <div v-if="step == 1">
                <coPlanDetail :plan="plan">
                    <template v-slot:header-button>
                        <co-button :label="$t('labels.planchoose')" variant="primary" @click="next" />
                    </template>
                </coPlanDetail>
            </div>
            <div v-if="step === 2 && (!paymentMethods || paymentMethods.length === 0)">
                <!-- create payment method here -->
                <div class="round-unify-xs bg-white p-3">
                    <!-- if loading -->
                    <div class="row text-center m-0 mt-5" v-if="loading">
                        <div class="col">
                            <b-spinner variant="primary" label="loading"></b-spinner>
                        </div>
                    </div>
                    <div class="m-0 mt-4">
                        <div id="message" class="text-center">
                            <!-- Display status message to customers here -->
                        </div>
                        <form id="payment-form">
                            <div id="payment-element">
                                <!-- Elements will create form elements here -->
                            </div>
                            <div id="error-message">
                                <!-- Display error message to customers here -->
                            </div>
                        </form>
                    </div>

                    <!-- footer -->
                    <div class="mt-2 d-flex justify-content-between w-100">
                        <CoButton variant="blacksecondary" @click="back">{{ $t('labels.back') }}</CoButton>

                        <CoButton variant="primary" @click="confirmSetup">{{ $t('labels.save') }}</CoButton>
                    </div>
                </div>
            </div>

            <div v-else-if="step == 2">
                <ChangePlanUpgrades :plan="plan" @nextStep="upgradesNext"></ChangePlanUpgrades>
            </div>
            <div v-if="step == 3">
                <ChangePlanReview
                    :plan="plan"
                    :selected="selected"
                    @changePlan="checkout()"
                    @prevStep="back()"
                ></ChangePlanReview>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import CoButton from '../../../components/Atoms/co-button/CoButton.vue';
let elements = null;

export default {
    components: { CoButton },
    name: 'ChangePlanCheckout',
    data() {
        return {
            plan: null,
            updatedPlan: null,
            extras: [],
            step: this.$route.query.step ? parseInt(this.$route.query.step, 10) : 0,
            selected: [],
            me: this.$store.state.me,
            loading: false,

            paymentMethods: [],

            publicKey: '',
            accountID: '',

            clientSecret: '',
            customerID: '',

            paymentElement: null,

            closeAfterSuccess: this.$route.query.closeAfterSuccess,
        };
    },
    watch: {
        step(val) {
            if (val === 2 && (!this.paymentMethods || this.paymentMethods.length === 0)) {
                this.loading = true;
                this.getPublicKey();
            }
        },
    },
    created() {
        this.listPaymentMethods();

        const { ID } = this.$route.params;

        this.loading = true;
        axios({
            method: 'GET',
            url: `space/plan/${ID}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response && response.data) {
                    this.plan = response.data;
                    if (!this.step) {
                        this.step = 1;
                    }
                    this.loading = false;
                }
            })
            .catch((error) => {
                console.log(error);
                this.loading = false;
            });
    },

    methods: {
        next() {
            this.step += 1;
        },
        back() {
            this.step -= 1;
        },
        upgradesNext(selected) {
            this.selected = selected;
            this.prepareData();
            this.step += 1;
        },
        prepareData() {
            const extras = [];
            if (this.selected) {
                this.selected.forEach((element) => {
                    extras.push({ ID: element.ID, Version: element.Version });
                });
            }

            this.updatedPlan = {
                PlanID: this.plan.ID,
                PlanVersion: this.plan.Version,
                Extras: extras,
            };
        },
        checkout() {
            this.prepareData();
            this.loading = true;

            let changePlanUrl = 'me/change-plan';
            if (this.$unleash.isEnabled('frontend.change-plan')) {
                changePlanUrl = 'me/change-planV2';
            }
            axios({
                method: 'POST',
                url: changePlanUrl,
                withCredentials: true,
                data: JSON.stringify(this.updatedPlan),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Plan successfully updated!',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    if (this.closeAfterSuccess) {
                        window.close();
                    } else {
                        this.$router.push(`/account/plan`);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to update plan',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
        },

        // Payment method related methods
        listPaymentMethods() {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                });
        },

        async confirmSetup() {
            const { ID } = this.$route.params;
            let redirectUrl = `https://${this.$store.state.space.DomainName}/account/plan/change/${ID}?step=2&closeAfterSuccess=${this.closeAfterSuccess}`;
            if (this.$store.state.space.DomainName === 'localhost:8080') {
                redirectUrl = `http://${this.$store.state.space.DomainName}/account/plan/change/${ID}?step=2&closeAfterSuccess=${this.closeAfterSuccess}`;
            }

            const { error } = await this.stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: redirectUrl,
                },
            });

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (e.g., payment
                // details incomplete)
                const messageContainer = document.querySelector('#error-message');
                messageContainer.textContent = error.message;
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        },
        getPublicKey() {
            axios({
                method: 'GET',
                url: '/user/account/billing/public-key',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.publicKey = response.data.Key;
                        this.accountID = response.data.AccountID;
                        this.stripe = Stripe(this.publicKey, {
                            stripeAccount: this.accountID,
                        });

                        const { setup_intent_client_secret } = this.$route.query;
                        if (setup_intent_client_secret) {
                            this.checkSetupIntentStatus(setup_intent_client_secret);
                        } else {
                            this.getClientSecretForNewMethod();
                        }
                    }
                })
                .catch((error) => {});
        },
        getClientSecretForNewMethod() {
            axios({
                method: 'GET',
                url: '/user/account/billing/add-payment-method',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.customerID = response.data.CustomerID;
                        this.clientSecret = response.data.Secret;
                        this.initPaymentView(this.clientSecret);
                    }
                })
                .catch((error) => {});
        },
        initPaymentView(secret) {
            const options = {
                clientSecret: secret,
                // Fully customizable with appearance API.
                appearance: {
                    /* ... */
                },
            };

            // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
            elements = this.stripe.elements(options);
            // Create and mount the Payment Element
            this.paymentElement = elements.create('payment');
            this.paymentElement.mount('#payment-element');

            const that = this;
            this.paymentElement.on('ready', (event) => {
                // Handle ready event
                that.loading = false;
            });
        },
        checkSetupIntentStatus(clientSecret) {
            this.loading = true;
            // Retrieve the "setup_intent_client_secret" query parameter appended to
            // your return_url by Stripe.js
            // const clientSecret = new URLSearchParams(window.location.search).get(
            //   'setup_intent_client_secret'
            // );

            // Retrieve the SetupIntent
            this.stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
                const message = document.querySelector('#message');

                // Inspect the SetupIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (setupIntent.status) {
                    case 'succeeded': {
                        message.innerText = 'Success! Your payment method has been saved.';
                        this.setBillingDetails({
                            CustomerID: this.customerID,
                            PaymentMethodID: setupIntent.payment_method,
                        });
                        this.loading = false;
                        this.step += 1;
                        break;
                    }

                    case 'processing': {
                        message.innerText = "Processing payment details. We'll update you when processing is complete.";
                        this.loading = false;
                        setTimeout(() => {
                            this.checkSetupIntentStatus(clientSecret);
                        }, 100);
                        break;
                    }

                    case 'requires_payment_method': {
                        if (setupIntent.payment_method) {
                            message.innerText = 'Failed to process payment details. Please try another payment method.';

                            // Redirect your user back to your payment page to attempt collecting
                            // payment again
                        }
                        this.initPaymentView(clientSecret);

                        break;
                    }
                }
            });
        },
        setBillingDetails(data) {
            axios({
                method: 'POST',
                url: '/user/account/billing/payment-method/details',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).catch((error) => {
                console.error(error);
            });
        },
    },
};
</script>
