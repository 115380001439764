

























































































































































































































































import i18n from 'vue-i18n';
import EventBus from '@/eventBus.js';
import { get } from 'lodash';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoUserInvite from '@/components/Organisms/co-user-invite/CoUserInvite.vue';
import CoLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';

export default {
    name: 'WelcomeChecklist',
    i18n: {
        messages: {
            en: {
                welcomeToCoapp: 'Welcome to coapp',
                welcomeToCoappMessage:
                    'Activate your community and let them chat, post and pay for memberships, events and bookings - all in one place and under your brand.',
                onlyAdminSeeThisPage: 'This pages is only visible to admins of your community',
                completeThisStep: 'Complete these to get the most out of coapp',
                createCommunity: 'Create community',
                createCommunitySubtitle: 'You made it!',

                inviteTitle: 'Invite your team and members',
                inviteCTA: 'Invite Members',
                inviteSubtitle: "It's what you're here for, so lets get to it",

                downloadAppTitle: 'Download coapp',
                downloadAppCTA: 'Download app',
                downloadAppSubtitle: "Don't miss a thing by taking coapp with you",

                profileTitle: 'Set up your profile',
                profileCTA: 'Your Profile',
                profileSubtitle: "Make sure people know it's really you",

                postTitle: 'Write your first post',
                postCTA: 'Create Post',
                postSubtitle: 'Say hello to everyone',

                logoTitle: 'Upload a logo',
                logoCTA: 'Upload Logo',
                logoSubtitle: 'You can make further design adjustments {0}',
                here: 'here',
                logoSuggestedsizes: 'logo sizes',

                paymentTitle: 'Start receiving payments',
                paymentCTA: 'Connect Stripe',
                paymentSubtitle: 'Let your members pay for memberships, event tickets and more',
            },
            de: {
                welcomeToCoapp: 'Willkommen bei coapp',
                welcomeToCoappMessage:
                    'Aktiviere deine Community und lass sie chatten, posten und für Mitgliedschaften, Veranstaltungen und Buchungen bezahlen - alles an einem Ort und unter deiner Marke.',
                onlyAdminSeeThisPage: 'Diese Seite ist nur für Administrator:innen deiner Community sichtbar',

                completeThisStep: 'Vervollständige diese Schritte, um das Beste aus coapp herauszuholen',

                createCommunity: 'Community erstellen',
                createCommunitySubtitle: 'Erledigt!',

                inviteTitle: 'Lade dein Team und Mitglieder ein',
                inviteCTA: 'Mitglieder einladen',
                inviteSubtitle: 'Deswegen sind wir hier, also legen wir los',

                downloadAppTitle: 'Lade coapp herunter',
                downloadAppCTA: 'App herunterladen',
                downloadAppSubtitle: 'Auch unterwegs nichts mehr verpassen',

                profileTitle: 'Richte dein Profil ein',
                profileCTA: 'Dein Profil',
                profileSubtitle: 'Stell sicher, dass alle wissen, dass du es bist',

                postTitle: 'Schreibe deinen ersten Beitrag',
                postCTA: 'Beitrag erstellen',
                postSubtitle: 'Sag allen Hallo',

                logoTitle: 'Lade ein Logo hoch',
                logoCTA: 'Logo hochladen',
                logoSubtitle: 'Weitere Designanpassungen kannst du {0} vornehmen',
                here: 'hier',
                logoSuggestedsizes: 'Empfohlene Logo-Größen',

                paymentTitle: 'Aktiviere Zahlungen',
                paymentCTA: 'Stripe verbinden',
                paymentSubtitle: 'Lass deine Mitglieder für Mitgliedschaften, Event-Tickets und mehr bezahlen',
            },
        },
    },
    components: {
        CoButton,
        CoIcon,
        CoText,
        CoCard,
        CoHeadline,
        CoUserInvite,
        CoLoadingIndicator,
        CoSkeleton,
    },
    props: {
        welcomeChecklist: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            communityCreated: true,
            showProgress: false,
            loading: false,
        };
    },
    computed: {
        current() {
            let count = 1;

            if (!this.showProgress) {
                return count;
            }

            if (this.welcomeChecklist.DownloadMobileApp) {
                count += 1;
            }

            if (this.welcomeChecklist.FirstPost) {
                count += 1;
            }

            if (this.welcomeChecklist.InvitedMembers) {
                count += 1;
            }

            if (this.welcomeChecklist.Logo) {
                count += 1;
            }

            if (this.welcomeChecklist.PaymentsOn) {
                count += 1;
            }

            if (this.welcomeChecklist.UserProfileSetup) {
                count += 1;
            }

            return count;
        },
    },
    mounted() {
        this.loading = true;
        this.$store
            .dispatch('getMe', null)
            .then((response) => {
                this.userObj = response.data;
                if (!get(response, 'data.Permissions', '').includes('space_admin')) {
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setTimeout(() => {
                    this.loading = false;
                    setTimeout(() => {
                        this.showProgress = true;
                    }, 500);
                }, 1000);
            });
        this.getWelcomeCheclist();
    },
    methods: {
        inviteSuccess() {
            // chanage local invited value
            this.getWelcomeCheclist();

            // confetti
            EventBus.$emit('CONFETTI', {});
        },
        getWelcomeCheclist() {
            this.$store.dispatch('getWelcomeChecklist');
        },
        goToMobileApp() {
            window.open('https://coapp.io/download-coapp-app', '_blank').focus();
        },
        goToMyProfile() {
            this.$router.push('/profile/me');
        },
        createPost() {
            EventBus.$emit('STARTNEWPOST', ``);
        },
        goToCustomisation() {
            this.$router.push('/admin/setup/customization');
        },
        goToPaymentSetup() {
            this.$router.push('/admin/monetization/payments/stripe');
        },
        randomPercents(from = 0, to = 100) {
            const percents = Math.floor(Math.random() * (to - from + 1) + from);
            return `${percents}%`;
        },
    },
};
