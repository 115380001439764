<template>
    <div>
        <div class="px-sm-4 mb-3">
            <coAlert
                v-if="loading && (pagesLoaded > 10 || takesLonger)"
                variant="grey"
                :text="`In larger communities it might take a while to load and process all members. Please be patient. Pages loaded: ${pagesLoaded}`"
            />
            <co-table
                v-if="billingTurnedOn"
                :loading="loading"
                :items="objectsToDisplay"
                :columns="fields"
                :itemsPerPage="100"
                ref="membersTable"
                resizable-columns
                csvDownload
            >
                <template v-slot:upgradeName="slotProps">
                    <CoLink
                        styles="primary truncate"
                        :to="`/admin/monetization/plan-upgrade/${slotProps.item.upgradeID}`"
                        :title="slotProps.item.upgradeName"
                        newTab
                    />
                </template>
                <template v-slot:object\.Profile\.Name="slotProps">
                    <div class="d-flex align-items-center">
                        <co-thumbnail
                            class="mr-3"
                            :imageURL="slotProps.item.object.Profile.ImageURL"
                            :link="`/admin/community/member/view/${slotProps.item.object.ID}`"
                            content-type="user"
                            size="sm"
                            round
                        />
                        <co-link
                            styles="primary truncate"
                            style="min-width: 0"
                            :to="`/admin/community/member/view/${slotProps.item.object.ID}`"
                            >{{ slotProps.item.object.Profile.Name }}
                        </co-link>
                    </div>
                </template>
                <template v-slot:object\.Profile\.Since="slotProps">
                    <coDate :date="slotProps.item.object.Profile.Since" format="relativedate" />
                </template>
                <template v-slot:object\.Address\.Company="slotProps">
                    {{ slotProps.item.object.Address ? slotProps.item.object.Address.Company : null }}
                </template>
                <template v-slot:object\.LastActivity="slotProps">
                    <coDate :date="slotProps.item.object.LastActivity" format="relativedate" />
                </template>
                <template v-slot:object\.Status="slotProps">
                    {{ statusTypes[slotProps.item.object.Status || 0] }}
                </template>
                <template v-slot:object\.Plan\.cancelledAt="slotProps">
                    <coDate
                        v-if="slotProps.item.object.Plan"
                        :date="slotProps.item.object.Plan.cancelledAt"
                        format="relativedate"
                    />
                </template>
            </co-table>
            <coAlert v-else variant="grey">
                <p class="text">
                    Payment and billing is not active. Please
                    <coLink class="d-inline" styles="primary" to="/admin/monetization/payments/stripe"
                        >activate payments</coLink
                    >
                    to use paid member plans and upgrades.
                </p>
            </coAlert>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';

import EventBus from '../../../../eventBus';
import { filter, get, map, groupBy } from 'lodash';

import CoDate from '../../../../components/Molecules/co-date/CoDate.vue';
import CoTable from '../../../../components/Molecules/co-table/CoTable.vue';
import CoThumbnail from '../../../../components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoLink from '../../../../components/Atoms/co-link/CoLink.vue';
import CoAlert from '../../../../components/Molecules/co-alert/CoAlert.vue';

export default {
    name: 'Upgrades',
    components: {
        CoDate,
        CoLink,
        CoTable,
        CoThumbnail,
        CoAlert,
    },
    data() {
        return {
            objectsToDisplay: [],
            csvData: [],
            statusTypes: ['Active', 'Pending', 'Deleted', 'Suspended', 'Cancelled', 'Waiting for approval', 'Rejected'],
            loading: false,
            billingTurnedOn: this.$store.state.billingTurnedOn || null,
            pagesLoaded: 0,
            takesLonger: false,
        };
    },
    computed: {
        fields() {
            return [
                {
                    key: 'upgradeName',
                    title: 'Upgrade',
                    style: 'width: 150px !important',
                },
                {
                    key: 'object.Profile.Name',
                    title: 'Name',
                    style: 'width: 250px !important',
                },
                {
                    key: 'object.Email',
                    title: 'Email',
                    style: 'width: 250px !important',
                },
                {
                    key: 'object.Address.Company',
                    title: 'Company',
                },
                {
                    key: 'object.Profile.Since',
                    title: 'Created',
                },
                {
                    key: 'object.LastActivity',
                    title: 'Last activity',
                },
                {
                    key: 'object.Status',
                    title: 'Status',
                },
                {
                    key: 'object.Plan.plan.Name',
                    title: 'Plan',
                },
                {
                    key: 'object.Plan.cancelledAt',
                    title: 'Plan Cancelled to',
                },
            ];
        },
    },
    mounted() {
        if (this.billingTurnedOn) {
            this.loading = true;
            this.listMembers();
            setTimeout(() => {
                this.takesLonger = true;
            }, 5000);
        }
    },
    watch: {},
    methods: {
        listMembers(next) {
            // Create a new CancelToken
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();

            // Save the cancel token for the current request
            this.ongoingRequest = source;

            let url = '/admin/community/member/listV2';
            if (next != null) {
                url = next;
            }
            axios({
                method: 'GET',
                url,
                headers: {
                    'Content-Type': 'application/json',
                },
                cancelToken: source.token,
            })
                .then((response) => {
                    // process and save new received data
                    const newItems = filter(get(response, 'data.objects', []), (o) => {
                        return get(o, 'object.Plan.plan.Extras', []).length > 0;
                    });

                    var upgrades = [];

                    map(newItems, (o) => {
                        get(o, 'object.Plan.plan.Extras', []).forEach((extra) => {
                            if (extra.ID) {
                                // add entry for every upgrade to the list even if it is the same upgrade
                                upgrades.push({
                                    ...o,
                                    upgradeName: extra.Name,
                                    upgradeID: extra.ID,
                                });
                            }
                        });
                    });

                    // add the new processed items to the list
                    this.objectsToDisplay.push.apply(this.objectsToDisplay, upgrades);

                    // check if there is more data to load and load it
                    if (response.data && response.data.next != null && response.data.next !== '') {
                        this.pagesLoaded++;
                        this.listMembers(response.data.next);
                        return;
                    }

                    // data is completely loaded
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading = false;
                });
        },
    },
};
</script>
