var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-event-view"},[(!_vm.$store.state.isInMobile)?_c('CoCard',{staticClass:"mb-3",attrs:{"variant":"border"}},[_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('CoRoundButton',{staticClass:"mr-2",attrs:{"icon":"chevron-left","variant":"icon"},on:{"click":_vm.backtToEvent}}),_c('CoHeadline',{staticClass:"d-none d-md-block mr-auto",attrs:{"level":3,"trunc":""}},[_vm._v(_vm._s(_vm.event.Title ? _vm.event.Title : _vm.$t('labels.event')))])],1)]):_vm._e(),_c('div',{staticClass:"participants-table mb-3"},[_c('CoTable',{attrs:{"title":_vm.$t('labels.ticketssold'),"items":_vm.ticketsSold,"loading":_vm.loadingTaxRates,"preventHorizontalScroll":true,"resizableColumns":true,"csvDownload":!_vm.$store.state.isInMobile,"columns":[
                {
                    title: _vm.$t('labels.name'),
                    key: 'Name',
                },
                {
                    title: _vm.$t('labels.quantity'),
                    key: 'Quantity',
                },
                {
                    title: _vm.$t('labels.price'),
                    key: 'Price',
                },
                {
                    title: _vm.$t('labels.vat'),
                    key: 'Vat',
                },
                {
                    title: _vm.$t('labels.sum'),
                    key: 'TotalPrice',
                } ]},scopedSlots:_vm._u([{key:"Quantity",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.SoldCount ? item.SoldCount : 0)+" / "+_vm._s(item.Limit)+" ")]}},{key:"Vat",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.TaxRate)+" ")]}}])})],1),_c('div',{staticClass:"participants-table"},[_c('CoTable',{ref:"participantstable",attrs:{"items":_vm.participantsFiltered,"loading":_vm.loadingParticipants,"preventHorizontalScroll":true,"resizableColumns":true,"columns":[
                {
                    title: _vm.$t('labels.name'),
                    key: 'UserName',
                },
                {
                    title: _vm.$t('labels.ticket'),
                    key: 'Name',
                },
                {
                    title: _vm.$t('labels.ticketcode'),
                    key: 'ID',
                },
                {
                    title: _vm.$t('labels.price'),
                    key: 'Price',
                } ]},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"mb-2"},[_c('coHeadline',{staticClass:"m-0 mb-3",attrs:{"level":3,"title":_vm.$t('labels.attendees'),"trunc":""}},[_vm._v(_vm._s(_vm.$t('labels.attendees')))]),_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('CoInput',{attrs:{"background":"transparent","placeholder":"Search","icon":"search"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),(!_vm.$store.state.isInMobile && !_vm.loadingParticipants)?_c('coDownloadAsCsv',{ref:"csvDownloader",staticClass:"ml-auto",attrs:{"title":"Download the data shown in this table as CSV-file","clickMethod":_vm.downloadCsv}}):_vm._e()],1),(_vm.searchInput && (!_vm.participantsFiltered || _vm.participantsFiltered.length === 0))?_c('div',[_c('CoAlert',{attrs:{"variant":"grey","text":_vm.$t('messages.searchnomatches')}})],1):_vm._e()],1)]},proxy:true},{key:"UserName",fn:function(slotProps){return [_c('CoLink',{attrs:{"to":("/profile/" + (slotProps.item.UserID)),"title":slotProps.item.UserName,"trunc":true}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }