<style lang="less" scoped>
.list-item {
    background: white;
    margin-bottom: 10px;
    cursor: pointer;

    .picture {
        width: 131px;
        height: 131px;
        object-fit: cover;
        @media (min-width: 768px) {
            width: 142px;
            height: 142px;
            object-fit: cover;
        }
    }

    .item-data {
        // height: 80px;
        font-size: 0.8rem;
        line-height: 0.8rem;
        .btn {
            padding: 0.2rem 0.8rem;
            font-size: 0.8rem;
            line-height: 0.8rem;
        }

        .small-icon {
            width: 22px !important;
            min-width: 22px !important;
            height: 22px !important;
            min-height: 22px !important;
        }

        .multi-icons {
            margin-left: -11px;
        }

        @media (min-width: 768px) {
            // height: 120px;

            font-size: 1rem;
            line-height: 1rem;
            .btn {
                padding: 0.3rem 0.8rem;
                font-size: 1rem;
                line-height: 1rem;
            }

            .small-icon {
                width: 32px !important;
                min-width: 32px !important;
                height: 32px !important;
                min-height: 32px !important;
            }
        }
    }

    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    &:hover .picture {
        opacity: 0.7;
    }
}
a {
    color: inherit;
    text-decoration: inherit;
}
</style>

<template>
    <router-link
        class="list-item round-unify w-100 d-flex align-items-start"
        tag="a"
        :to="'/event/' + object.object.Slug"
    >
        <div class="picture">
            <b-img :src="filterPictures(imageURL)" class="picture" rounded="left"></b-img>
        </div>
        <div class="d-flex flex-column w-100 align-items-start item-data pl-3 py-2">
            <h2 class="title mt-2 mb-2 mb-xs-3">{{ object.object.Title }}</h2>
            <div class="d-flex w-100">
                <div class="d-flex align-items-center mt-2 mb-2 mb-xs-3">
                    <div class="ml-2">
                        {{
                            $t('datetime', {
                                date: object.object.StartDate,
                                format: 'relative',
                            })
                        }}
                    </div>
                </div>
                <div v-if="participants.length > 0" class="d-none d-xl-flex align-items-center mt-2 mb-2">
                    <div class="d-flex ml-3">
                        <div class="small-icon">
                            <CoThumbnail
                                :imageURL="participants[0].Profile.ImageURL"
                                :round="true"
                                :clickable="false"
                                content-type="user"
                            />
                        </div>
                        <div v-if="participants[1]" class="small-icon multi-icons">
                            <CoThumbnail
                                :imageURL="participants[1].Profile.ImageURL"
                                :round="true"
                                :clickable="false"
                                content-type="user"
                            />
                        </div>
                        <div v-if="participants[2]" class="small-icon multi-icons">
                            <CoThumbnail
                                :imageURL="participants[2].Profile.ImageURL"
                                :round="true"
                                :clickable="false"
                                content-type="user"
                            />
                        </div>
                    </div>
                    <div class="d-none d-xl-flex flex">
                        <div class="ml-2">{{ $tc('labels.goingcount', participants.length) }}</div>
                    </div>
                </div>
            </div>
            <div class="mt-2 mt-xl-1">
                <b-button
                    v-if="isTheParticipantGoing"
                    variant="outline-primary"
                    @click="updateParticipantattendance"
                    class="d-flex align-items-center"
                >
                    <span>{{ $t('labels.going') }}</span>
                    <b-icon icon="caret-down-fill" scale=".7" class="m-0 ml-1"></b-icon>
                </b-button>

                <b-button
                    v-else
                    variant="primary"
                    @click="updateParticipantattendance"
                    class="d-flex align-items-center"
                >
                    <b-icon icon="people-fill" scale="1" class="mr-2"></b-icon>
                    <span>{{ $t('labels.join') }}</span>
                </b-button>
            </div>
        </div>

        <!-- modals -->
        <b-modal
            :id="'apply-modal-' + object.object.ID"
            :ref="'apply-modal-' + object.object.ID"
            size="md"
            :title="$t('cancelattendance')"
            @ok="removeParticipantattendance"
            :ok-title="$t('labels.confirm')"
            :cancel-title="$t('labels.cancel')"
            ok-variant="primary"
            centered
            :busy="canceling"
            no-fade
        >
            <b-overlay :show="canceling">
                <p class="mb-1">{{ $t('cancelmessage') }}</p>
            </b-overlay>
        </b-modal>
    </router-link>
</template>

<script>
import axios from 'axios';
import { get } from 'lodash';
import i18n from 'vue-i18n';

import CoThumbnail from '../Molecules/co-thumbnail/CoThumbnail.vue';

export default {
    i18n: {
        messages: {
            en: {
                cancelattendance: 'Cancel attendance',
                cancelmessage: 'Are you sure about this? This event would be much nicer with you!',
            },
            de: {
                cancelattendance: 'Teilnahme absagen',
                cancelmessage:
                    'Bist du dir sicher, dass du das willst? Diese @:labels.event wäre viel schöner mit dir!',
            },
        },
    },
    name: 'EventCard',
    components: {
        CoThumbnail,
    },
    props: ['object'],
    data() {
        return {
            imageURL: this.object.object.ImageURL,
            name: '',
            windowWidth: window.innerWidth,
            isTheParticipantGoing: false,
            participants: [],
            participantsToShow: [],
            canceling: false,
        };
    },

    watch: {
        object(newVal, oldVal) {
            this.imageURL = this.object.object.ImageURL;
            this.updateParticipantattendance();
        },
    },

    computed: {
        icon() {
            this.iconMap[this.object.type];
        },
        description() {
            this.typeMap[this.object.type];
        },
        isDesktop() {
            return this.windowWidth >= 768;
        },
        joinEventText() {
            return this.isTheParticipantGoing ? 'Not going' : 'Join this event';
        },
        participant() {
            return this.participants[0];
        },
        newEventID() {
            return this.object.object.ID;
        },
    },
    created() {
        this.updateParticipants(get(this.object.object, 'Participants', []));
    },
    mounted() {
        // this.updateParticipantattendance();
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
    methods: {
        showModal(id) {
            this.$refs[id].show();
        },
        updateParticipantattendance(event) {
            event.preventDefault();
            const that = this;
            if (this.isTheParticipantGoing) {
                this.$refs[`apply-modal-${this.object.object.ID}`].show();
            } else {
                const request = JSON.stringify({
                    EventID: this.object.object.ID,
                    ParticipantID: this.$store.state.me.ID,
                });
                that.$store.dispatch('addParticipant', request).then((response) => {
                    that.object.object = response;
                    that.updateParticipants(get(that.object.object, 'Participants', []));
                }, that);
            }
        },
        updateParticipants(userIds) {
            if (userIds.length > 0) {
                this.$store
                    .dispatch('listUsersByIDs', {
                        ids: { IDS: userIds.map((id) => ({ ID: id })) },
                    })
                    .then((response) => {
                        this.participants = response.Users;
                        this.participantsToShow = this.participants.slice(0, 5);
                    });
                this.isTheParticipantGoing = userIds.filter((id) => id === this.$store.state.me.ID).length === 1;
            } else {
                this.isTheParticipantGoing = false;
                this.participants = [];
                this.participantsToShow = [];
            }
        },
        removeParticipantattendance() {
            const that = this;
            const request = JSON.stringify({
                EventID: that.newEventID,
                ParticipantID: that.$store.state.me.ID,
            });
            this.canceling = true;
            that.$store
                .dispatch('removeParticipant', request)
                .then((response) => {
                    this.canceling = false;
                    that.object.object = response;
                    that.$refs[`apply-modal-${this.object.object.ID}`].hide();
                    that.updateParticipants(get(that.object.object, 'Participants', []));
                }, that)
                .catch((error) => {
                    this.canceling = false;
                    that.$refs[`apply-modal-${this.object.object.ID}`].hide();
                    console.log(error);
                });
        },
        viewUser(slug) {
            this.$router.push(`/profile/${slug}`);
        },
        filterPictures(imageurl) {
            if (imageurl) {
                return imageurl;
            }

            const randomItem = parseInt(Math.random() * 16) + 1;
            const images = require.context('@/assets/gradients/', true);
            return images(`./Platform_Gradients-${randomItem}.jpg`);
        },
        trimString(string) {
            if (string == null) {
                return '';
            }
            if (string.length > 10) {
                string = `${string.substring(0, 10 - 1)}...`;
            }
            return string;
        },
    },
};
</script>
