



































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from 'axios';
import EventBus from '@/eventBus';
import JSConfetti from 'js-confetti';
import isURL from 'validator/lib/isURL';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'vue-i18n';
import { get, upperFirst, toLower, map, uniqBy, uniq, remove, add, cloneDeep } from 'lodash';
import { User } from '@/types/GlobalTypes.ts';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoPeople from '@/components/Molecules/co-people/CoPeople.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoPill from '@/components/Atoms/co-pill/CoPill.vue';
import CoShare from '@/components/Organisms/co-share/CoShare.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';
import CoImage from '@/components/Atoms/co-image/CoImage.vue';
import CoDragDropFileUpload from '@/components/Organisms/co-drag-drop-file-upload/CoDragDropFileUpload.vue';
import CoLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoTippy from '@/components/Atoms/co-tippy/CoTippy.vue';
import CoBadge from '@/components/Atoms/co-badge/CoBadge.vue';
import CoTag from '@/components/Atoms/co-tag/CoTag.vue';
import CoContentSection from '@/components/Organisms/сo-content-section/CoContentSection.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoPostEditor from '@/components/Organisms/co-post-editor/coPostEditor.vue';
import CoPost from '@/components/Organisms/co-post/CoPost.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoCheckbox from '@/components/Atoms/co-checkbox/CoCheckbox.vue';
import CoAcceptReject from '@/components/Molecules/co-accept-reject/CoAcceptReject.vue';
import CoMatch from '@/components/Molecules/co-match/CoMatch.vue';
import CoComments from '@/components/Organisms/co-comments/coComments.vue';
import CoReactions from '@/components/Molecules/co-reactions/coReactions.vue';

const jsConfetti = new JSConfetti();

export default {
    name: 'ViewPage',
    components: {
        CoFormGroup,
        CoCheckbox,
        CoInput,
        CoPostEditor,
        CoBadge,
        CoConfirmation,
        CoThumbnail,
        CoText,
        CoRoundButton,
        CoButton,
        CoIcon,
        CoHeadline,
        CoSkeleton,
        CoPeople,
        CoCard,
        CoLink,
        CoPill,
        CoShare,
        CoImage,
        CoDragDropFileUpload,
        CoLoadingIndicator,
        CoTippy,
        CoTag,
        CoContentSection,
        CoDropdown,
        CoDropdownItem,
        CoPost,
        CoAcceptReject,
        CoMatch,
        CoComments,
        CoReactions,
    },
    i18n: {
        messages: {
            en: {
                addmember: 'Add member',
                requestsent: 'Request sent',
                media: 'Media',
                unfollowpage: 'Do you want to unfollow this page?',
                posts: 'Posts',
                joinRequestSent: 'Join request sent',
                joinedPageAsContributor: 'You have joined this page as a contributor',
                aboutthepage: 'About the page',
                contributersexpl: 'Contributors can only create updates',
                nofollowers: 'No followers yet. Be the first one.',
                ownersexpl: 'Owners can add/remove owners and contributors, and create updates',
                ownerishidden: "Owner's profile is hidden",
                unpublishedAdmin: 'Only admins and page members see this.',
                unpublishedDefault: 'Only page members see this.',
                closePreviewAsMember: 'Close Preview',
                previewAsMember: 'Preview as Member',
                privatePageHeadline: 'Private Page',
                communityPageHeadline: 'Community members see this page',
                private: 'Private',
                updatePage: 'Update Page',
                pageVisibility: 'Page Visibility',
                joinrequests: 'These members want to join',
                joinRequestRejected: 'Join request rejected',
                joinRequestAccepted: 'Join request accepted',
                requestToJoinTooltip: 'Request to join this page',
                pageIsInviteOnlyTooltip: 'Page is invite only',
                invalidLink: 'Invalid link',
                attachLink: 'Add a link',
                makeContributor: 'Make @:labels.contributor',
                makeOwner: 'Make @:labels.owner',
                suggestedsizes: 'Suggested size 1280x720px (16:9)',
                leavepage: 'Do you want to leave this page?',
                creatorCanNotLeavePage: 'As the creator of this page, you cannot leave it.',
                joinpermissions: 'How can other members join?',
                joininviteonly: 'By Invitation Only',
                joinapprovalbased: 'Members can request to join',
                joinopen: 'Everyone can join',
                createdthispage: 'created this page',

                visibility: 'Who can see this page?',
                privateinfo: 'Private, only visible to members of this page',
                community: 'Visible to everyone in the community',

                whocanfollow: 'Anyone who can see the page can follow it.',
                rolesexplained1: `Owners can edit and delete the page and can add and remove contributors.`,
                rolesexplained2: `Contributors can publish posts on the page.`,
                rolesexplained3: `Followers receive notifications about every post on the page.`,
            },
            de: {
                addmember: 'Mitglied hinzufügen',
                requestsent: 'Anfrage gesendet',
                media: 'Medien',
                unfollowpage: 'Möchtest du dieser Seite nicht mehr folgen?',
                posts: 'Beiträge',
                joinRequestSent: 'Anfrage gesendet',
                joinedPageAsContributor: 'Du bist jetzt Mitwirkende*r dieser Seite',
                aboutthepage: 'Über diese @:labels.page',
                contributersexpl: '@:labels.contributers können nur @:labels.updates erstellen',
                nofollowers: 'Noch keine Follower. Mach den Anfang.',
                ownersexpl:
                    '@:labels.owners können @:labels.owners und @:labels.contributers hinzufügen/entfernen und @:labels.updates erstellen',
                ownerishidden: 'Das Profil des @:labels.owner ist nicht sichtbar.',
                unpublishedAdmin: 'Nur Admins und Seitenmitglieder sehen dies.',
                unpublishedDefault: 'Nur Seitenmitglieder sehen dies.',
                closePreviewAsMember: 'Vorschau schließen',
                previewAsMember: 'Vorschau als Mitglied',
                privatePageHeadline: 'Private Seite',
                communityPageHeadline: 'Community-Mitglieder sehen diese Seite',
                private: 'Privat',
                updatePage: 'Seite bearbeiten',
                pageVisibility: 'Seiten Sichtbarkeit',
                joinrequests: 'Diese Mitglieder möchten beitreten',
                joinRequestRejected: 'Beitrittsanfrage abgelehnt',
                joinRequestAccepted: 'Beitrittsanfrage angenommen',
                requestToJoinTooltip: 'Anfrage, dieser Seite beizutreten',
                pageIsInviteOnlyTooltip: 'Seite ist nur auf Einladung',
                invalidLink: 'Ungültiger Link',
                attachLink: 'Link hinzufügen',
                makeContributor: 'Zu @:labels.contributor machen',
                makeOwner: 'Zu @:labels.owner machen',
                suggestedsizes: 'Empfohlene Größe 1280x720px (16:9)',
                leavepage: 'Möchtest du diese Seite verlassen?',
                creatorCanNotLeavePage: 'Als Ersteller:in dieser Seite kannst du sie nicht verlassen.',
                joinpermissions: 'Wie können andere Mitglieder beitreten?',
                joininviteonly: 'Nur durch Hinzufügen',
                joinapprovalbased: 'Mitglieder können Beitrittsanfragen stellen',
                joinopen: 'Alle können beitreten',
                createdthispage: 'hat diese Seite erstellt',

                visibility: 'Wer kann diese Seite sehen?',
                privateinfo: 'Privat, nur sichtbar für Mitglieder dieser Seite',
                community: 'Sichtbar für alle in der Community',

                whocanfollow: 'Wer die Seite sehen kann, kann ihr folgen.',
                rolesexplained1: `Inhaber:innen können die Seite bearbeiten und löschen und können Mitwirkende hinzufügen und entfernen.`,
                rolesexplained2: `Mitwirkende können Beiträge auf der Seite veröffentlichen.`,
                rolesexplained3: `Follower*innen erhalten Benachrichtigungen über jeden Beitrag auf der Seite.`,
            },
        },
    },
    props: {
        pageSlug: {
            type: String,
            required: true,
        },
        channelSlug: {
            type: String,
            default: '',
            required: false,
        },
        me: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            page: {},
            followers: [],
            owners: [],
            channel: {},

            channelList: [],

            loadingMembers: false,
            members: [], // list of members with there roles Owner, Contributor, Follower

            newImage: null,
            newImageUploading: false,

            sectionsRerenderKey: 0,

            updates: [],
            updatesBank: [],

            updatesNextPage: null,
            loadingUpdates: false,
            isCirclesOn: this.$store.state.circlesOn,
            nextParams: null,
            updatesRenderKey: 0,

            joinStatus: null,

            membersCandidates: [],

            pendingRequests: [],
            preview: false,

            // join strategy
            inviteOnly: true,
            approvalBased: false,
            open: false,

            // visibility
            private: true,
            community: false,

            links: [],
            newlink: '',

            fetchedUsers: {},
        };
    },
    watch: {
        private(val) {
            if (val) {
                this.community = false;
            }
        },

        community(val) {
            if (val) {
                this.private = false;
            }
        },

        inviteOnly(val) {
            if (val) {
                this.approvalBased = false;
                this.open = false;
                this.page.JoinStrategy = 0;
            }
        },

        approvalBased(val) {
            if (val) {
                this.inviteOnly = false;
                this.open = false;
                this.page.JoinStrategy = 1;
            }
        },

        open(val) {
            if (val) {
                this.inviteOnly = false;
                this.approvalBased = false;
                this.page.JoinStrategy = 2;
            }
        },
        pageSlug() {
            this.getPage();
        },
        page: {
            handler() {
                this.getPageFollowers();
                this.getPageOwners();
                this.getPageContributores();
                this.getPageChannel();
                this.getLinksInfos();
            },
        },
    },
    computed: {
        selectedChannel() {
            if (!this.channelList || this.channelList.length === 0 || !this.page || !this.page.ChannelID) {
                return null;
            }

            const channel = this.channelList.find((c) => c.ID === this.page.ChannelID);
            if (!channel) {
                return null;
            }
            return {
                Name: channel.Value,
                Value: channel.ID,
            };
        },
        channelsForSelect() {
            if (!this.channelList) {
                return [];
            }

            return this.channelList.map((c) => ({
                Name: c.Value,
                Value: c.ID,
            }));
        },
        pageHeaderImageURL(): string {
            if (this.page && this.page.ImageURL && this.page.ImageURL.length > 0) {
                return this.page.ImageURL[0];
            }
            return '';
        },
        noContent() // if no sections and no image and no tags return true
        {
            return (
                (!this.page.Sections || this.page.Sections.length === 0) &&
                (!this.page.ImageURL || this.page.ImageURL.length === 0) &&
                (!this.page.Hashtag || this.page.Hashtag.length === 0)
            );
        },
        joinRequestSent(): boolean {
            if (this.joinStatus && this.joinStatus.Role === 4) {
                return true;
            }
            return false;
        },
        canIEditIgnorePreview(): boolean {
            return this.amIOwner || (this.amIAdmin && this.page.SpaceID === this.me.SpaceID);
        },
        canIEdit(): boolean {
            return (this.amIOwner || (this.amIAdmin && this.page.SpaceID === this.me.SpaceID)) && !this.preview;
        },
        amINewToPage(): boolean {
            if (!this.amIOwner && !this.amIContributor && !this.amIFollowing) {
                return true;
            }
            return false;
        },
        amIOwner(): boolean {
            if (!this.page.Owner) {
                return false;
            }
            // check if page.Owners contains user.ID
            return this.page.Owner && this.page.Owner.includes(this.me.ID);
        },
        amIContributor(): boolean {
            if (!this.page.Contributor) {
                return false;
            }
            // check if page.Contributor contains user.ID
            return this.page.Contributor && this.page.Contributor.includes(this.me.ID);
        },
        amIAdmin(): boolean {
            if (!this.me.Permissions) {
                return false;
            }
            // check if me.Permissions contain space_admin
            return this.me.Permissions && this.me.Permissions.includes('space_admin');
        },
        amIFollowing(): boolean {
            // check if page.FollowedBy contains user.ID
            if (!this.page.FollowedBy) {
                return false;
            }
            return this.page.FollowedBy && this.page.FollowedBy.includes(this.me.ID);
        },
        channelName(): string {
            return upperFirst(toLower(get(this.channel, 'Value', '')));
        },
        pageUrl(): string {
            if (!this.page || !this.page.Slug) return '';

            if (this.channelSlug) {
                return `${window.location.origin}/channel/${this.channelSlug}/${this.page.Slug}`;
            }
            return `${window.location.origin}/project/${this.page.Slug}`;
        },
        pageCreator() {
            if (this.page.UserID && this.members?.length > 0) {
                return this.members.filter((i) => i.ID === this.page.UserID)[0];
            }
            return {};
        },
    },
    created() {
        window.addEventListener('scroll', this.scroll);
        this.getPage();
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    methods: {
        get,
        upperFirst,
        toLower,
        map,
        cloneDeep,
        makeOwner(index, member) {
            // remove from contributors
            const newOwner = {
                ProjectID: this.page.ID,
                UserID: member.ID,
            };
            this.$store
                .dispatch('removeContributor', newOwner)
                .then((response) => {
                    this.$store
                        .dispatch('addOwner', newOwner)
                        .then((response2) => {
                            if (response2) {
                                this.members[index].Role = 'Owner';
                                this.sortMembers();

                                EventBus.$emit('INFO', {
                                    Message: this.$t('labels.changessaved'),
                                    Details: '',
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            EventBus.$emit('ERROR', {
                                Message: this.$t('labels.failedGeneric'),
                                Details: '',
                            });
                        });
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        this.$store
                            .dispatch('addOwner', newOwner)
                            .then((response2) => {
                                if (response2) {
                                    this.members[index].Role = 'Owner';
                                    this.sortMembers();

                                    EventBus.$emit('INFO', {
                                        Message: this.$t('labels.changessaved'),
                                        Details: '',
                                    });
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                EventBus.$emit('ERROR', {
                                    Message: this.$t('labels.failedGeneric'),
                                    Details: '',
                                });
                            });
                    } else {
                        console.log(error);
                        EventBus.$emit('ERROR', {
                            Message: this.$t('labels.failedGeneric'),
                            Details: '',
                        });
                    }
                });

            // add to owners
        },
        makeContributor(index, member) {
            // remove from owners
            const newContributor = {
                ProjectID: this.page.ID,
                UserID: member.ID,
            };
            this.$store
                .dispatch('removeOwner', newContributor)
                .then((response) => {
                    this.$store
                        .dispatch('addContributor', newContributor)
                        .then((response2) => {
                            if (response2) {
                                this.members[index].Role = 'Contributor';
                                this.sortMembers();

                                EventBus.$emit('INFO', {
                                    Message: this.$t('labels.changessaved'),
                                    Details: '',
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            EventBus.$emit('ERROR', {
                                Message: this.$t('labels.failedGeneric'),
                                Details: '',
                            });
                        });
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        this.$store
                            .dispatch('addContributor', newContributor)
                            .then((response2) => {
                                if (response2) {
                                    this.members[index].Role = 'Contributor';
                                    this.sortMembers();

                                    EventBus.$emit('INFO', {
                                        Message: this.$t('labels.changessaved'),
                                        Details: '',
                                    });
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                EventBus.$emit('ERROR', {
                                    Message: this.$t('labels.failedGeneric'),
                                    Details: '',
                                });
                            });
                    } else {
                        console.log(error);
                        EventBus.$emit('ERROR', {
                            Message: this.$t('labels.failedGeneric'),
                            Details: '',
                        });
                    }
                });
            // add to contributors
        },
        remove(index, member) {
            const newContributor = {
                ProjectID: this.page.ID,
                UserID: member.ID,
            };

            const funcName = member.Role === 'Owner' ? 'removeOwner' : 'removeContributor';

            this.$store
                .dispatch(funcName, newContributor)
                .then((response) => {
                    // if user is in followers list change role to follower
                    const i = get(this.page, 'FollowedBy', []).findIndex((ID) => ID === member.ID);
                    if (i !== -1) {
                        this.members[index].Role = 'Follower';
                    } else {
                        this.members.splice(index, 1);
                    }

                    EventBus.$emit('INFO', {
                        Message: this.$t('labels.changessaved'),
                        Details: '',
                    });
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedGeneric'),
                        Details: '',
                    });
                });
        },
        addMembers() {
            // iterate over membersCandidates and add them to page as contributors
            this.membersCandidates.forEach((m) => {
                // if user is already in the list, update the role to Contributor
                const found = this.members.find((member) => member.ID === m.ID);
                if (found && found.Role === 'Follower') {
                    found.Role = 'Contributor';
                    // skip this user
                    return;
                }

                const newContributor = {
                    ProjectID: this.page.ID,
                    UserID: m.ID,
                };

                this.$store
                    .dispatch('addContributor', newContributor)
                    .then((response) => {
                        if (response) {
                            this.members.push({
                                ID: m.ID,
                                Name: m.Name,
                                Slug: m.Slug,
                                ImageURL: m.ImageURL,
                                Role: 'Contributor',
                            });

                            EventBus.$emit('INFO', {
                                Message: this.$t('labels.changessaved'),
                                Details: '',
                            });

                            // remove m from membersCandidates
                            this.membersCandidates = this.membersCandidates.filter((c) => c.ID !== m.ID);
                            this.sortMembers();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        EventBus.$emit('ERROR', {
                            Message: this.$t('labels.failedGeneric'),
                            Details: '',
                        });
                    });
            });
        },

        addLink() {
            if (!this.newlink) {
                return;
            }

            if (!isURL(this.newlink)) {
                EventBus.$emit('ERROR', {
                    Message: this.$t('invalidLink'),
                    Details: '',
                });
                return;
            }

            // if link does not have http or https add http
            if (!this.newlink.includes('http')) {
                this.newlink = `https://${this.newlink}`;
            }

            const u = new URL(this.newlink);
            this.links.push({
                title: u.hostname,
                url: this.newlink,
            });

            if (!this.page.Links) {
                this.page.Links = [];
            }
            this.page.Links.push(this.newlink);
            this.newlink = '';
            this.updatePage();
        },
        removeLink(index, link) {
            this.links.splice(index, 1);

            this.page.Links = this.page.Links.filter((l) => l !== link.url);
            this.updatePage();
        },
        getLinksInfos() {
            if (get(this.page, 'Links', []).length === 0) {
                return;
            }

            // eslint-disable-next-line no-shadow
            this.page.Links.forEach((link: string | URL) => {
                const url = new URL(link);

                axios({
                    method: 'GET',
                    url: `/dashboard/og?url=${link}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        const title = get(response, 'data.title');
                        this.links.push({
                            title: title.length < 3 ? (this.title = url.hostname) : title,
                            url: link,
                        });
                    })
                    .catch((error) => {
                        this.links.push({
                            title: url.hostname,
                            url: link,
                        });
                        console.log('error', error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        acceptRequest(request) {
            const req = {
                UserID: request.UserID,
                PageID: this.page.ID,
            };
            axios({
                method: 'POST',
                url: `/page/accept-join-request`,
                data: req,
            })
                .then((response) => {
                    this.pendingRequests = this.pendingRequests.filter((r) => r.UserID !== request.UserID);

                    // add user to members list and page.Contributor list if not already there
                    // if user is already in the list, update the role to Contributor
                    const index = this.members.findIndex((m) => m.ID === request.UserID);
                    if (index === -1) {
                        this.members.push({
                            ID: request.UserID,
                            Name: request.User.Name,
                            Slug: request.User.Slug,
                            ImageURL: request.User.ImageURL,
                            Role: 'Contributor',
                        });
                        this.page.Contributor.push(request.UserID);
                    } else {
                        this.members[index].Role = 'Contributor';
                    }

                    EventBus.$emit('INFO', {
                        Message: this.$t('joinRequestAccepted'),
                        Details: '',
                    });
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedGeneric'),
                        Details: '',
                    });
                });
        },

        rejectRequest(request) {
            const req = {
                UserID: request.UserID,
                PageID: this.page.ID,
            };

            axios({
                method: 'POST',
                url: `/page/reject-join-request`,
                data: req,
            })
                .then((response) => {
                    this.pendingRequests = this.pendingRequests.filter((r) => r.UserID !== request.UserID);

                    EventBus.$emit('INFO', {
                        Message: this.$t('joinRequestRejected'),
                        Details: '',
                    });
                })
                .catch((error) => {
                    console.log(error);

                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedGeneric'),
                        Details: '',
                    });
                });
        },

        setPageVisibility(val) {
            if (val) {
                this.community = true;
                this.private = false;
                this.updatePage();
            } else {
                this.private = true;
                this.community = false;
                this.updatePage();
            }
        },

        selectChannel(channel) {
            this.page.ChannelID = get(channel, 'Value', null);
        },

        tagsChanged(tags) {
            const tmp = uniq(tags);
            this.page.Hashtag = tmp;
        },

        getPendingRequests() {
            if (!this.amIAdmin) {
                return;
            }

            axios({
                method: 'GET',
                url: `/page/join-requests/${this.page.ID}`,
            })
                .then((response) => {
                    const tmp = get(response, 'data.Participations', []);
                    // map list of contributor ids from page.Contributor to {IDS: [{ID: 1}, {ID: 2}]} to send as payload

                    const ids = tmp.map((p) => ({ ID: p.UserID }));
                    if (!ids.length) {
                        return;
                    }

                    const requestBody = { IDS: ids };
                    axios({
                        method: 'POST',
                        url: `/user/listbyids`,
                        data: requestBody,
                    })
                        .then((response) => {
                            if (response.data && response.data.Users) {
                                // map response data to User object
                                tmp.forEach((participant) => {
                                    const item = response.data.Users.find((u) => u.ID === participant.UserID);
                                    if (item) {
                                        participant.User = {
                                            ID: item.ID,
                                            Name: get(item, 'Profile.Name', ''),
                                            Slug: item.Slug,
                                            ImageURL: get(item, 'Profile.ImageURL', ''),
                                        };
                                    }
                                });

                                this.pendingRequests = [];
                                this.pendingRequests = tmp;
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        removeCandidate(index) {
            this.membersCandidates.splice(index, 1);
        },
        selectCandidate(user) {
            const u = get(user, 'value', null);
            if (!u) {
                return;
            }
            this.membersCandidates.push({
                ID: get(u, 'ID'),
                Name: get(u, 'Profile.Name'),
                Slug: get(u, 'Slug'),
                ImageURL: get(u, 'Profile.ImageURL'),
                Bio: get(u, 'Profile.Bio'),
                Role: 'Contributor',
            });
        },

        searchUsers(query, source) {
            // search for users
            return new Promise((resolve, reject) => {
                // exit if query is less than 2 characters
                if (!query || query.length < 2) {
                    resolve([]);
                    return;
                }
                // fetch the search results
                axios({
                    method: 'GET',
                    url: `/search/user/${query}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cancelToken: source ? source.token : null,
                })
                    .then((response) => {
                        const results = get(response, 'data', []);
                        // filter out users that are already in the membersCandidates list  or in the members list
                        const filtered = results.filter((el) => {
                            const found = this.membersCandidates.find((m) => m.ID === el.ID);
                            if (found) {
                                return false;
                            }
                            const found2 = this.members.find((m) => m.ID === el.ID);
                            if (found2) {
                                return false;
                            }
                            return true;
                        });

                        const mapped = filtered.map((el) => ({
                            label: get(el, 'Profile.Name', ''),
                            disabled: false,
                            value: el,
                        }));
                        resolve(mapped);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        join() {
            axios({
                method: 'POST',
                url: `/page/join/${this.page.ID}`,
            })
                .then((response) => {
                    this.joinStatus = response.data;

                    if (this.joinStatus.Role === 2) {
                        this.members.push({
                            ID: this.$store.state.me.ID,
                            Name: this.$store.state.me.Profile.Name,
                            Slug: this.$store.state.me.Slug,
                            ImageURL: get(this.$store.state.me, 'Profile.ImageURL', ''),
                            Role: 'Contributor',
                        });

                        this.page.Contributor.push(this.$store.state.me.ID);

                        EventBus.$emit('INFO', {
                            Message: this.$t('joinedPageAsContributor'),
                            Details: '',
                        });
                    } else {
                        EventBus.$emit('INFO', {
                            Message: this.$t('joinRequestSent'),
                            Details: '',
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getJoinSatatus() {
            axios({
                method: 'GET',
                url: `/page/join/${this.page.ID}`,
            })
                .then((response) => {
                    if (response.data) {
                        this.joinStatus = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        sortMembers() {
            const { members } = this;
            if (!members) {
                return;
            }
            // iterate over members and remove duplicates with lower role even if there are multiple roles for the same user
            members.forEach((m) => {
                const index = members.findIndex((member) => member.ID === m.ID);
                if (index !== -1) {
                    const roles = members.filter((member) => member.ID === m.ID).map((member) => member.Role);
                    if (roles.includes('Owner')) {
                        m.Role = 'Owner';
                    } else if (roles.includes('Contributor')) {
                        m.Role = 'Contributor';
                    } else {
                        m.Role = 'Follower';
                    }
                }
            });
            const m = uniqBy(members, 'ID');

            this.members = m.sort((a, b) => {
                if (a['Role'] === 'Owner') return -1;
                if (b['Role'] === 'Owner') return 1;
                if (a['Role'] === 'Contributor') return -1;
                if (b['Role'] === 'Contributor') return 1;
                return 0;
            });

            // stable sort by Name if role is the same
            this.members.sort((a, b) => {
                if (a['Role'] === b['Role']) {
                    return a['Name'].localeCompare(b['Name']);
                }
                return 0;
            });
        },
        addedPost(post = {}) {
            if (this.updates && this.updates.length > 0) {
                this.updates.unshift(post);
                jsConfetti.addConfetti();
            } else {
                // celebrate the first post with some confetti :)
                jsConfetti.addConfetti();
                this.updates = [post];
            }
        },
        editPost(post = {}) {
            if (!post) return;
            // map post structure to editor structure
            const postToEdit = {
                ...get(post, 'object', {}),
                Files: map(get(post, 'object.ImageURLs', []), (i) => ({ url: i })),
                Type: 'project-update',
                wasUpdated: true,
            };
            this.$refs.posteditor.show(postToEdit, true);
        },
        editedPost(post = {}) {
            const that = this;
            if (post && this.updates) {
                post['wasUpdated'] = true;
                // find the post in the feed by id and update it
                const index = this.updates.findIndex((p) => get(p, 'object.ID') === get(post, 'object.ID'));
                if (index > -1) {
                    that.updates.splice(index, 1, post);
                }
            }
        },
        showMembers() {
            this.$refs.membersModal.show();
        },
        roleLabelForBadge(role: string): string {
            if (role === 'Owner') return this.$t('labels.owner');
            if (role === 'Contributor') return this.$t('labels.contributor');
            return this.$t(`labels.followerrole`);
        },

        saveSection(section, index) {
            this.page.Sections[index] = section;
            this.sectionsRerenderKey += 1;
            this.updatePage();
        },
        deleteSection(index) {
            if (!this.page.Sections) {
                this.sectionsRerenderKey += 1;
                return;
            }
            this.page.Sections.splice(index, 1);
            this.sectionsRerenderKey += 1;
            this.updatePage();
        },
        moveSectionDown(index) {
            if (!this.page.Sections) {
                this.sectionsRerenderKey += 1;
                return;
            }
            const temp = this.page.Sections[index];
            this.page.Sections[index] = this.page.Sections[index + 1];
            this.page.Sections[index + 1] = temp;
            this.sectionsRerenderKey += 1;
            this.updatePage();
        },
        moveSectionUp(index) {
            if (!this.page.Sections) {
                this.sectionsRerenderKey += 1;
                return;
            }
            const temp = this.page.Sections[index];
            this.page.Sections[index] = this.page.Sections[index - 1];
            this.page.Sections[index - 1] = temp;
            this.sectionsRerenderKey += 1;
            this.updatePage();
        },
        addSection($event, index) {
            if (!this.page.Sections) {
                this.page.Sections = [];
            }
            if ($event === 'text') {
                this.page.Sections.splice(index + 1, 0, {
                    Type: 0,
                    Content: '',
                    StartEditMode: true,
                });
            } else if ($event === 'image') {
                this.page.Sections.splice(index + 1, 0, {
                    Type: 1,
                    Images: [],
                    StartEditMode: true,
                });
            } else if ($event === 'code') {
                this.page.Sections.splice(index + 1, 0, {
                    Type: 2,
                    Content: '',
                    StartEditMode: true,
                });
            }

            this.sectionsRerenderKey += 1;
        },
        removeImage() {
            this.newImage = null;
            this.page.ImageURL = [];
            this.updatePage();
        },
        headerImageInput(image) {
            if (image && image.filePreview) {
                this.newImage = image.filePreview;
                this.newImageUploading = true;
                if (!this.$store) {
                    console.error('Store not available');
                    this.newImageUploading = false;
                    return;
                }
                this.$store
                    .dispatch('imageUpload', {
                        file: image.file,
                        url: '/upload/image/pages',
                    })
                    .then((response) => {
                        this.page.ImageURL = [response];
                        this.updatePage();
                    })
                    .catch((error) => {
                        console.log(error);
                        const errorMsg = {
                            Message: this.$t('messages.imageUploadError'),
                            Details: '',
                        };
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.message === 'Request Entity Too Large'
                        ) {
                            errorMsg.Message = this.$t('messages.imageUploadSizeError');
                        }
                        EventBus.$emit('ERROR', errorMsg);
                    })
                    .finally(() => {
                        this.newImageUploading = false;
                        this.newImage = null;
                    });
            }
        },

        follow() {
            const subs = JSON.stringify({ ProjectID: this.page.ID });
            axios({
                method: 'POST',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    // add me to members list
                    if (!this.page.FollowedBy) {
                        this.page.FollowedBy = [];
                    }
                    this.page.FollowedBy.push(this.$store.state.me.ID);

                    this.members.push({
                        ID: this.$store.state.me.ID,
                        Name: this.$store.state.me.Profile.Name,
                        Slug: this.$store.state.me.Slug,
                        ImageURL: get(this.$store.state.me, 'Profile.ImageURL', ''),
                        Role: 'Follower',
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        leave() {
            axios({
                method: 'POST',
                url: `/page/leave/${this.page.ID}`,
            })
                .then((response) => {
                    // remove me from members list
                    this.members = this.members.filter((item) => item.ID !== this.$store.state.me.ID);
                    this.page.FollowedBy = this.page.FollowedBy.filter((item) => item !== this.$store.state.me.ID);
                    this.joinStatus = null;
                    this.page.Contributor = this.page.Contributor.filter((item) => item !== this.$store.state.me.ID);
                    this.page.Owner = this.page.Owner.filter((item) => item !== this.$store.state.me.ID);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        unfollow() {
            const subs = JSON.stringify({ ProjectID: this.page.ID });
            axios({
                method: 'DELETE',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    // remove me from members list
                    this.members = this.members.filter((item) => item.ID !== this.$store.state.me.ID);
                    this.page.FollowedBy = this.page.FollowedBy.filter((item) => item !== this.$store.state.me.ID);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getPage() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/project/by-slug/${this.pageSlug}`,
            })
                .then((response) => {
                    if (response.data) {
                        const page = response.data;
                        page.ImageURL = get(page, 'ImageURL', []).filter(
                            (image) => !image.includes('/img/Platform_Gradient')
                        );
                        this.page = page;

                        if (get(this.page, 'Sections', []).length === 0 && this.page.Description) {
                            this.page.Sections = [
                                {
                                    Type: 0,
                                    Content: this.page.Description,
                                },
                            ];
                        }

                        if (this.page.JoinStrategy === 1) {
                            this.inviteOnly = false;
                            this.approvalBased = true;
                            this.open = false;
                        } else if (this.page.JoinStrategy === 2) {
                            this.inviteOnly = false;
                            this.approvalBased = false;
                            this.open = true;
                        } else {
                            this.inviteOnly = true;
                            this.approvalBased = false;
                            this.open = false;
                        }

                        if (this.page.Published) {
                            this.private = false;
                            this.community = true;
                        } else {
                            this.private = true;
                            this.community = false;
                        }

                        this.getUpdates();
                        this.getJoinSatatus();
                        this.getPendingRequests();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message.includes('404')) {
                        this.$router.push('/feed/error404').catch(() => {});
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getPageChannel() {
            axios
                .get('/project/channel/list')
                .then((response) => {
                    if (response.data) {
                        const channelList = response.data.objects.map((channel) => channel.object);

                        this.channelList = channelList;

                        if (!channelList || channelList.length === 0) {
                            return;
                        }

                        const channel = channelList.find((c) => c.ID === this.page.ChannelID);
                        if (channel && channel.Slug) {
                            this.channel = channel;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        getPageContributores() {
            this.loading = true;

            if (!this.page.Contributor || !this.page.Contributor.length) {
                this.loading = false;
                return;
            }

            // map list of contributor ids from page.Contributor to {IDS: [{ID: 1}, {ID: 2}]} to send as payload
            const ids = this.page.Contributor.map((id) => ({ ID: id }));
            if (!ids.length) {
                this.loading = false;
                return;
            }

            const requestBody = { IDS: ids };
            axios({
                method: 'POST',
                url: `/user/listbyids`,
                data: requestBody,
            })
                .then((response) => {
                    if (response.data && response.data.Users) {
                        // map response data to User object
                        this.contributors = response.data.Users.map((item) => ({
                            ID: item.ID,
                            Name: get(item, 'Profile.Name', ''),
                            Slug: item.Slug,
                            ImageURL: get(item, 'Profile.ImageURL', ''),
                            Role: 'Contributor',
                            Score: get(item, 'Score', 0),
                        }));

                        // add contributors to members list
                        this.members = this.members.concat(this.contributors);
                        this.sortMembers();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getPageOwners() {
            this.loading = true;

            if (!this.page.Owner || !this.page.Owner.length) {
                this.loading = false;
                return;
            }

            // map list of owner ids from page.Owner to {IDS: [{ID: 1}, {ID: 2}]} to send as payload
            const ids = this.page.Owner.map((id) => ({ ID: id }));
            if (!ids.length) {
                this.loading = false;
                return;
            }

            const requestBody = { IDS: ids };
            axios({
                method: 'POST',
                url: `/user/listbyids`,
                data: requestBody,
            })
                .then((response) => {
                    if (response.data && response.data.Users) {
                        this.owners = response.data.Users.map((item) => ({
                            ID: item.ID,
                            Name: get(item, 'Profile.Name', ''),
                            Slug: item.Slug,
                            ImageURL: get(item, 'Profile.ImageURL', ''),
                            Role: 'Owner',
                            Score: get(item, 'Score', 0),
                        }));

                        // add owners to members list
                        this.members = this.members.concat(this.owners);
                        this.sortMembers();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getPageFollowers() {
            this.loading = true;

            if (!this.page.FollowedBy || !this.page.FollowedBy.length) {
                this.loading = false;
                return;
            }

            // map list of follower ids from page.FollowedBy to {IDS: [{ID: 1}, {ID: 2}]} to send as payload
            const ids = this.page.FollowedBy.map((id) => ({ ID: id }));
            if (!ids.length) {
                this.loading = false;
                return;
            }

            const requestBody = { IDS: ids };
            axios({
                method: 'POST',
                url: `/user/listbyids`,
                data: requestBody,
            })
                .then((response) => {
                    if (response.data && response.data.Users) {
                        // map response data to User object
                        this.followers = response.data.Users.map((item) => ({
                            ID: item.ID,
                            Name: get(item, 'Profile.Name', ''),
                            Slug: item.Slug,
                            ImageURL: get(item, 'Profile.ImageURL', ''),
                            Role: 'Follower',
                            Score: get(item, 'Score', 0),
                        }));

                        // add followers to members list
                        this.members = this.members.concat(this.followers);
                        this.sortMembers();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getUpdates() {
            this.loadingUpdates = true;
            if (this.isCirclesOn) {
                const data = {
                    ProjectID: this.page.ID,
                    Params: this.nextParams,
                };

                this.$store
                    .dispatch('getUpdatesCircle', data)
                    .then(async (response) => {
                        if (response.Objects) {
                            response.Objects.forEach(function (entry, index) {
                                this.updatesBank.push(entry);
                            }, this);

                            if (response.NextParams) {
                                this.nextParams = response.NextParams;
                            } else {
                                this.nextParams = null;
                            }
                            // sort by date
                            this.updatesBank.sort((a, b) => b.object.Created - a.object.Created);

                            // remove duplicates by object.id
                            this.updatesBank = this.updatesBank.filter(
                                (thing, index, self) => index === self.findIndex((t) => t.object.ID === thing.object.ID)
                            );

                            let fetchUsers = [];
                            this.updatesBank.forEach((update) => {
                                if (!this.fetchedUsers[update.object.UserID]) {
                                    fetchUsers.push(update.object.UserID);
                                }
                            });

                            fetchUsers = uniq(fetchUsers);

                            let users = null;
                            if (fetchUsers.length > 0) {
                                try {
                                    users = await axios({
                                        method: 'POST',
                                        url: '/user/listbyids',
                                        data: { IDS: map(fetchUsers, (id) => ({ ID: id })) },
                                        withCredentials: true,
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                    });

                                    users = map(users.data.Users, (user) => ({
                                        ID: get(user, 'ID'),
                                        Name: get(user, 'Profile.Name'),
                                        Slug: get(user, 'Slug'),
                                        ImageURL: get(user, 'Profile.ImageURL'),
                                        Bio: get(user, 'Profile.Bio'),
                                    }));

                                    users.forEach((user) => {
                                        this.fetchedUsers[user.ID] = user;
                                    });
                                } catch (error) {
                                    console.log(error);
                                }
                            }
                            this.updatesBank.forEach((update) => {
                                if (!update.object) return;

                                if (update.object.UserID) {
                                    update.object.Author = this.fetchedUsers[update.object.UserID];
                                }

                                update.object.CreatedAt = update.object.Created;
                            }, this);

                            if (this.updatesBank.length > 3) {
                                this.updates = this.updates.concat(this.updatesBank.slice(0, 3));
                                this.updatesBank = this.updatesBank.slice(3);
                            } else {
                                this.updates = this.updates.concat(this.updatesBank);
                                this.updatesBank = [];
                            }
                            this.updatesRenderKey += 1;
                        } else {
                            this.nextParams = null;
                        }
                        this.loadingUpdates = false;
                    })
                    .catch((error) => {
                        if (this.$route.query.commentid) {
                            this.tabIndex = 2;
                        }
                        this.loadingUpdates = false;
                        console.log(error);
                    });
            } else {
                let params = '';
                if (this.updatesNextPage == null && this.updates.length === 0) {
                    params = `pid=${this.page.ID}`;
                } else if (this.updatesNextPage != null) {
                    params = this.updatesNextPage;
                } else {
                    return;
                }
                this.$store
                    .dispatch('getUpdates', params)
                    .then(async (response) => {
                        if (response.objects) {
                            response.objects.forEach(function (entry, index) {
                                this.updatesBank.push(entry);
                            }, this);

                            if (response.next !== '' && response.next != null) {
                                this.updatesNextPage = response.next;
                            } else {
                                this.updatesNextPage = null;
                            }
                            // sort by date
                            this.updatesBank.sort((a, b) => b.object.Created - a.object.Created);

                            // remove duplicates by object.id
                            this.updatesBank = this.updatesBank.filter(
                                (thing, index, self) => index === self.findIndex((t) => t.object.ID === thing.object.ID)
                            );

                            let fetchUsers = [];
                            this.updatesBank.forEach((update) => {
                                if (!this.fetchedUsers[update.object.UserID]) {
                                    fetchUsers.push(update.object.UserID);
                                }
                            });

                            fetchUsers = uniq(fetchUsers);

                            let users = null;
                            if (fetchUsers.length > 0) {
                                try {
                                    users = await axios({
                                        method: 'POST',
                                        url: '/user/listbyids',
                                        data: { IDS: map(uniq(fetchUsers), (id) => ({ ID: id })) },
                                        withCredentials: true,
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                    }).catch((error) => {
                                        console.log(error);
                                    });

                                    users = map(users.data.Users, (user) => ({
                                        ID: get(user, 'ID'),
                                        Name: get(user, 'Profile.Name'),
                                        Slug: get(user, 'Slug'),
                                        ImageURL: get(user, 'Profile.ImageURL'),
                                        Bio: get(user, 'Profile.Bio'),
                                    }));

                                    users.forEach((user) => {
                                        this.fetchedUsers[user.ID] = user;
                                    });
                                } catch (error) {
                                    console.log(error);
                                }
                            }

                            this.updatesBank.forEach((update) => {
                                // skip if entry.object does not exist
                                if (!update.object) return;
                                if (update.object.UserID) {
                                    update.object.Author = this.fetchedUsers[update.object.UserID];
                                }
                                update.object.CreatedAt = update.object.Created;
                            }, this);

                            if (this.updatesBank.length > 3) {
                                this.updates = this.updates.concat(this.updatesBank.slice(0, 3));
                                this.updatesBank = this.updatesBank.slice(3);
                            } else {
                                this.updates = this.updates.concat(this.updatesBank);
                                this.updatesBank = [];
                            }
                            this.updatesRenderKey += 1;
                        }
                        this.loadingUpdates = false;
                    })
                    .catch((error) => {
                        if (this.$route.query.commentid) {
                            this.tabIndex = 2;
                        }
                        this.loadingUpdates = false;
                        console.log(error);
                    });
            }
        },
        scroll() {
            if (this.updates.length === 0) {
                return;
            }
            window.onscroll = () => {
                const feedContainer = this.$refs['feed-container'];
                if (!feedContainer) return;

                // get the distance from the bottom of the page to the bottom of the feed container
                const distanceFromBottom = feedContainer.getBoundingClientRect().bottom - window.innerHeight;

                const percentage = (distanceFromBottom * 100) / feedContainer.clientHeight;

                if (percentage < 10) {
                    if (this.updatesBank.length !== 0 && this.updatesBank.length > 3) {
                        // this.updates.push(...this.updatesBank.slice(0, 3));
                        this.updates = this.updates.concat(this.updatesBank.slice(0, 3));
                        this.updatesBank = this.updatesBank.slice(3);
                    } else if (this.updatesBank.length !== 0 && this.updatesBank.length <= 3) {
                        // this.updates.push(...this.updatesBank);
                        this.updates = this.updates.concat(this.updatesBank);
                        this.updatesBank = [];
                    } else if ((this.updatesNextPage || this.nextParams) && !this.loadingUpdates) {
                        this.getUpdates();
                    }
                }
            };
        },
        discardPageChanges() {
            this.preview = false;
            this.getPage();
        },
        updatePage() {
            // combine all sections into description field for backward compatibility
            this.page.Description = '';
            if (this.page.Sections && this.page.Sections.length > 0) {
                this.page.Sections.forEach((section) => {
                    // if image section then add images as html img tags
                    if (section.Type === 1 && section.Images && section.Images.length > 0) {
                        section.Images.forEach((img) => {
                            this.page.Description += `<img style="width: 100%;" src="${img}" alt="event image" />\n<br>`;
                        });
                    } else {
                        this.page.Description += section.Content;
                        this.page.Description += '\n<br>';
                    }
                });
            } else {
                this.page.Description = '';
            }

            if (this.community) {
                this.page.Published = true;
                this.page.PublishedAt = Math.floor(Date.now() / 1000);
            } else {
                this.page.Published = false;
                this.page.PublishedAt = 0;
            }

            const update = JSON.stringify(this.page);
            this.loading = true;
            this.$store
                .dispatch('updateProject', update)
                .then((response) => {
                    this.loading = false;
                    const message = {
                        Message: this.$t('labels.changessaved'),
                        Details: null,
                    };
                    EventBus.$emit('INFO', message);
                    this.$emit('saved');
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: this.$t('labels.failedGeneric'),
                            Details: null,
                        });
                    }
                });
        },

        deletePage() {
            this.loading = true;
            const req = JSON.stringify({
                ProjectID: this.page.ID,
                UserID: this.$store.state.me.ID,
            });

            const funcName = this.amIAdmin ? 'deleteProjectAdmin' : 'deleteProject';

            this.$store
                .dispatch(funcName, req)
                .then((response) => {
                    this.$router.push('/profile/my-pages');
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: this.$t('labels.failedGeneric'),
                            Details: null,
                        });
                    }
                });
        },
    },
};
