





































































// see for official documentation
// https://innologica.github.io/vue2-daterange-picker/#installation
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { add, sub, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import i18n from 'vue-i18n';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';

export default {
    name: 'CoDateRangePicker',
    components: {
        DateRangePicker,
        CoDate,
    },
    i18n: {
        messages: {
            en: {
                Today: 'Today',
                Yesterday: 'Yesterday',
                Tomorrow: 'Tomorrow',
                'This month': 'This month',
                'This year': 'This year',
                'Last month': 'Last month',
                'Next month': 'Next month',
            },
            de: {
                Today: 'Heute',
                Yesterday: 'Gestern',
                Tomorrow: 'Morgen',
                'This month': 'Diesen Monat',
                'This year': 'Dieses Jahr',
                'Last month': 'Letzten Monat',
                'Next month': 'Nächsten Monat',
            },
        },
    },
    props: {
        opens: {
            type: String,
            default: 'right',
        },
        locale: {
            type: Object,
            default: () => {
                return {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    firstDay: 5,
                };
            },
        },
        singleDatePicker: {
            type: String || Boolean,
            default: 'range',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        autoApply: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Object,
            default: () => {
                return {
                    startDate: startOfWeek(new Date()),
                    endDate: endOfWeek(new Date()),
                };
            },
        },
        ranges: {
            type: Object,
            default: () => {
                return {
                    Today: [startOfDay(new Date()), endOfDay(new Date())],
                    Yesterday: [sub(startOfDay(new Date()), { days: 1 }), sub(endOfDay(new Date()), { days: 1 })],
                    Tomorrow: [add(startOfDay(new Date()), { days: 1 }), add(endOfDay(new Date()), { days: 1 })],
                    'This month': [startOfMonth(new Date()), endOfMonth(new Date())],
                    'Next month': [
                        add(startOfMonth(new Date()), { months: 1 }),
                        add(endOfMonth(new Date()), { months: 1 }),
                    ],
                    'Last month': [
                        sub(startOfMonth(new Date()), { months: 1 }),
                        sub(endOfMonth(new Date()), { months: 1 }),
                    ],
                };
            },
        },
    },
    watch: {
        dateRange: {
            handler(value) {
                if (!value || !value.startDate || !value.endDate) this.$emit('input', value);
                this.$emit('input', {
                    startDate: startOfDay(value.startDate),
                    endDate: endOfDay(value.endDate),
                });
            },
            deep: true,
        },
    },
    data() {
        return {
            dateRange: this.value,
            rerender: 0,
        };
    },
};
