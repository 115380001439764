<template>
    <div class="d-flex justify-content-center align-items-md-center w-100">
        <vue-headful title="Email Confirmation" description="" :image="this.$store.state.space.LogoURL" />
        <!-- user login -->
        <div class="login-form">
            <CoCard>
                <div class="pb-3">
                    <div v-if="!confirmed" class="d-flex align-items-center justify-content-center mb-3">
                        <CoHeadline :level="2" class="px-2">Email Confirmation</CoHeadline>
                    </div>
                    <CoLoadingIndicator v-if="loading" class="mb-3" />
                    <div class="w-100" v-if="error">
                        <CoAlert :text="error.message" variant="red" />
                    </div>
                    <div v-if="confirmed" class="w-100 d-flex flex-column align-items-center justify-content-middle">
                        <CoHeadline :level="2" class="mb-3">Congratulations!</CoHeadline>
                        <CoText class="mb-3">Your email was verified.</CoText>
                        <CoButton @click="$router.push('/')" variant="primary" class->To community</CoButton>
                    </div>
                </div>
            </CoCard>
        </div>
        <!-- user login -->
    </div>
</template>
<style lang="less" scoped>
// login view
.login-form {
    width: 100%;
    @media (min-width: 500px) {
        width: 340px;
    }
}
</style>

<script>
import axios from 'axios';

import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';

export default {
    name: 'EmailConfirmation',
    components: {
        CoCard,
        CoLoadingIndicator,
        CoHeadline,
        CoAlert,
        CoButton,
        CoText,
    },
    data() {
        return {
            error: null,
            loading: false,
            code: this.$route.params.code,
            confirmed: false,
        };
    },
    mounted() {
        this.confirmEmail();
    },

    methods: {
        confirmEmail() {
            this.loading = true;
            const data = {
                OTP: this.code,
            };
            axios({
                method: 'POST',
                url: `/user/confirm-email`,
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const token = response.headers.Authorization;
                    localStorage.setItem('_authtoken', token);
                    this.confirmed = true;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = {
                        message: 'Link is invalid or expired.',
                    };
                    console.log(error);
                });
        },
    },
};
</script>
