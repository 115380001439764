






import Welcome from '@/components/Templates/User/Welcome/Welcome.vue';

export default {
    name: 'CoWelcomeChecklistPage',
    components: {
        Welcome,
    },
    data() {
        return {};
    },
};
