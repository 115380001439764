<template>
    <div>
        <EventView
            :me="$store.state.me"
            :slug="$route.params.slug"
            :eventTicketsOn="$unleash.isEnabled('frontend.eventTicketsOn')"
            :isPaymentsEnabled="$store.state.billingTurnedOn"
        />
    </div>
</template>

<script>
import EventView from '@/components/Templates/User/Event/View/EventView.vue';

export default {
    name: 'CoViewEvent',
    components: {
        EventView,
    },
};
</script>
