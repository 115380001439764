<template>
    <div>
        <!-- OG/meta -->
        <vue-headful :title="$t('meta_title.createYourCommunity')" description="" />
        <div class="d-flex justify-content-center w-100">
            <!-- <div class="d-flex flex-column p-3 bg-white round-unify-xs text-left "> -->
            <div class="creation-card">
                <CoCard overflow="visible">
                    <div class="label-align ml-0 w-100 text-center">
                        <CoHeadline :level="2" class="mt-2 mb-2">{{ $t('createyourcommunity') }}</CoHeadline>
                        <CoHeadline :level="2" muted>{{ planNameAsLabel }}</CoHeadline>
                        <CoText type="p2" variant="mute" class="pt-3">{{ $t('youcanchange') }}</CoText>
                    </div>
                    <div v-if="step == 1">
                        <div class="w-100">
                            <!-- First and last name -->
                            <div class="d-flex">
                                <CoFormGroup :label="$t('labels.namefirst')" class="pr-1">
                                    <CoInput
                                        v-model="signUpForm.FirstName"
                                        type="text"
                                        required
                                        :placeholder="$t('labels.namefirst')"
                                    />
                                </CoFormGroup>

                                <CoFormGroup :label="$t('labels.namelast')" class="pl-1">
                                    <CoInput
                                        v-model="signUpForm.LastName"
                                        type="text"
                                        required
                                        :placeholder="$t('labels.namelast')"
                                    />
                                </CoFormGroup>
                            </div>

                            <CoFormGroup :label="$t('labels.email')">
                                <CoInput
                                    id="email-input"
                                    v-model="signUpForm.Email"
                                    type="email"
                                    required
                                    :placeholder="$t('placeholders.email')"
                                />
                            </CoFormGroup>

                            <!-- password -->
                            <CoFormGroup :label="$t('labels.password')">
                                <CoInput
                                    id="password-input"
                                    v-model="signUpForm.Password"
                                    type="password"
                                    required
                                    :placeholder="$t('placeholders.password')"
                                />
                            </CoFormGroup>

                            <!-- privacy policy -->
                            <CoCheckbox
                                name="privacy-policy-checkbox"
                                class="my-3"
                                v-model="signUpForm.AcceptPrivacyPolicy"
                                required
                                variant="checkbox"
                            >
                                <template v-slot:label>
                                    <i18n path="labels.acceptprivacyandterms" tag="p" class="mb-0">
                                        <a
                                            class="text-primary mb-0"
                                            href="https://www.coapp.io/privacy-policy"
                                            target="_blank"
                                            :title="$t('labels.privacypolicy')"
                                            >{{ $t('labels.privacypolicy') }}</a
                                        >
                                        <a
                                            class="text-primary mb-0"
                                            href="https://www.coapp.io/terms-of-service"
                                            target="_blank"
                                            :title="$t('labels.termsofservice')"
                                            >{{ $t('labels.termsofservice') }}</a
                                        >
                                    </i18n>
                                </template>
                            </CoCheckbox>

                            <div class="mt-4 mb-3">
                                <altcha-widget
                                    id="altcha"
                                    style="
                                        --altcha-border-width: 0px;
                                        --altcha-border-radius: 1.125rem;
                                        --altcha-color-base: var(--c-midgrey);
                                        --altcha-max-width: 100%;
                                    "
                                    hidelogo
                                    hidefooter
                                    challengeurl="https://api.coapp.io/v1/captcha-challenge"
                                ></altcha-widget>
                            </div>

                            <div class="d-flex justify-content-end">
                                <CoButton
                                    variant="primary"
                                    :disabled="!isAccountDateComplete || !captchaVerified"
                                    @click="step = 2"
                                    >{{ $t('labels.next') }}</CoButton
                                >
                            </div>
                        </div>
                    </div>

                    <div v-if="step == 2">
                        <div class="w-100">
                            <!-- Community name -->
                            <CoFormGroup :label="$t('communityname')">
                                <CoInput
                                    v-model="spaceItem.Name"
                                    type="text"
                                    required
                                    :placeholder="$t('placeholders.communityname')"
                                />
                            </CoFormGroup>

                            <!-- Community timezone -->
                            <CoFormGroup :label="$t('labels.timezone')">
                                <CoSelect
                                    :key="spaceItem.Timezone"
                                    :value="{
                                        Value: spaceItem.Timezone,
                                        Name: spaceItem.Timezone,
                                    }"
                                    :items="listTZ"
                                    style="z-index: 9999"
                                    :placeholder="$t('placeholders.selectTimezone')"
                                ></CoSelect>
                            </CoFormGroup>

                            <div class="d-flex justify-content-between mt-3">
                                <CoButton variant="secondary" @click="back">{{ $t('labels.back') }} </CoButton>
                                <CoButton
                                    variant="primary"
                                    :loading="loading"
                                    @click="createSpace"
                                    :disabled="!spaceItem.Name || spaceItem.Name.trim() === ''"
                                    >{{ $t('labels.next') }}
                                </CoButton>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="step === 3 && error" class="">
                        <CoAlert variant="yellow" class="mb-4">
                            <CoText type="p1">{{ $t('error') }}</CoText>
                        </CoAlert>

                        <div class="d-flex justify-content-between mt-3">
                            <CoButton variant="secondary" @click="back">{{ $t('labels.back') }} </CoButton>
                            <CoButton variant="primary" type="submit" @click="createSpace" :loading="loading"
                                >{{ $t('labels.tryagain') }}
                            </CoButton>
                        </div>
                    </div>
                    <!-- </div> -->
                </CoCard>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
.creation-card {
    min-width: 450px;
    width: 450px;
    @media (max-width: 576px) {
        min-width: 0;
        width: calc(100vw - 0.5rem);
    }
}

.price-table-wrapper {
    width: calc(100vw - 256px);
    @media (max-width: 576px) {
        width: calc(100vw - 40px);
    }
}
</style>

<script>
import isEmail from 'validator/lib/isEmail';
import Vue from 'vue';
import smoothReflow from 'vue-smooth-reflow';
import axios from 'axios';
import momentTZ from 'moment-timezone';
import i18n from 'vue-i18n';
import { debounce, get } from 'lodash';

import EventBus from '@/eventBus';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import Currency from '@/views/admin/Monetization/Payments/Currency.vue';
import { loading } from '@/components/Organisms/co-notification/coNotification.stories';
import Bugsnag from '@bugsnag/js';

export default {
    name: 'CreateSpace',
    mixins: [smoothReflow],
    i18n: {
        messages: {
            en: {
                createyourcommunity: 'Create your Community',
                '14daysfreetrial': '14 days free trial',
                communityhelp: 'This name will be used everywhere in your community platform',
                communitytimezone: 'Select the timezone for your community',
                communityname: 'Community Name',
                youcanchange: 'You can always change this information later',
                selectplan: 'Select your Plan',
                error: 'An error occurred during the account creation. Please contact support via help.coapp.io.',
            },
            de: {
                createyourcommunity: 'Erstelle deine Community',
                '14daysfreetrial': '14 Tage kostenlos testen',
                communityhelp: 'Dieser Name wird überall in deiner Community-Plattform verwendet',
                communitytimezone: 'Wähle die Zeitzone für deine Community',
                communityname: 'Community-Name',
                youcanchange: 'Du kannst alle Angaben später noch ändern',
                selectplan: 'Wähle deinen Tarif',
                error: 'Es ist Fehler während der Kontoerstellung aufgetreten. Bitte kontaktiere den Support über help.coapp.io.',
            },
        },
    },
    components: {
        CoHeadline,
        CoText,
        CoAlert,
        CoFormGroup,
        CoInput,
        CoButton,
        CoSelect,
        CoCard,
    },
    data() {
        return {
            timeZonesList: momentTZ.tz.names(),
            signUpForm: {
                FirstName: '',
                LastName: '',
                Email: '',
                Password: '',
                AcceptPrivacyPolicy: false,
            },
            spaceItem: {
                Name: '',
                Timezone: momentTZ.tz.guess(),
            },

            plan: '',

            error: null,
            loading: false,
            step: 1,

            captchaVerified: false,
            captchaPayload: '',
        };
    },
    computed: {
        isAccountDateComplete() {
            return (
                this.signUpForm.FirstName &&
                this.signUpForm.LastName &&
                this.signUpForm.Email &&
                this.signUpForm.Password &&
                this.signUpForm.AcceptPrivacyPolicy
            );
        },
        planNameAsLabel() {
            let { plan } = this;
            if (!plan) {
                plan = 'free';
            }
            return plan[0].toUpperCase() + plan.slice(1).toLowerCase();
        },

        // listTZ - returns a list of timezones for the CoSelect input
        listTZ() {
            return this.timeZonesList.map((tz) => {
                return {
                    Value: tz,
                    Name: tz,
                };
            });
        },
    },
    beforeCreate() {
        window.onload = (event) => {
            // log matomo event
            if (window && window._paq) {
                window._paq.push(['trackEvent', 'community-creation', 'community-creation-started']);
            }
        };
    },
    created() {
        const newSpace = sessionStorage.getItem('new-space');
        if (newSpace) {
            const data = JSON.parse(newSpace);
            this.spaceItem = data.Space;
            delete data.Space;
            this.signUpForm = data;

            if (this.isAccountDateComplete) {
                this.step = 2;
            } else {
                this.step = 1;
            }
            this.plan = get(data, 'Plan', '');
        }

        if (this.$route.query.step) {
            this.step = parseInt(this.$route.query.step, 10);
        }

        let planName = get(this.$route, 'query.plan', '').toLowerCase();

        // if plan is not in list of available plans, set it to free
        if (!['free', 'professional', 'business'].includes(planName)) {
            planName = 'free';
        }

        this.plan = planName;
    },
    watch: {
        step(newVal) {
            if (!this.isAccountDateComplete) {
                this.step = 1;
            }
            history && history.replaceState ? history.replaceState({}, '', `?step=${newVal}&plan=${this.plan}`) : null;
            // log matomo event
            if (window && window._paq) {
                window._paq.push(['trackEvent', 'community-creation', `community-creation-step-${newVal}`]);
            }
        },
        // save user data on changed form fields
        signUpForm: {
            handler() {
                this.saveToSessionStorage();
            },
            deep: true,
        },

        newSpace: {
            handler() {
                this.saveToSessionStorage();
            },
            deep: true,
        },
        plan(newVal) {
            this.saveToSessionStorage();
        },
    },
    mounted() {
        history && history.replaceState ? history.replaceState({}, '', `?step=${this.step}&plan=${this.plan}`) : null;
        this.$smoothReflow({
            el: '.step-wrapper',
        });

        window.addEventListener('load', () => {
            document.querySelector('#altcha').configure({ auto: 'onload', delay: 300 });

            document.querySelector('#altcha').addEventListener('statechange', (ev) => {
                // state can be: unverified, verifying, verified, error
                // console.log('state:', ev.detail.state);
                if (ev.detail.state === 'verified') {
                    this.captchaVerified = true;
                    // debugger;
                    // payload contains base64 encoded data for the server
                    // console.log('payload:', ev.detail.payload);
                    this.captchaPayload = ev.detail.payload;
                }
            });
        });
    },
    methods: {
        selectTZ(tz) {
            this.spaceItem.Timezone = tz.Value;
        },
        saveToSessionStorage() {
            const newSpace = this.signUpForm;
            newSpace.Space = this.spaceItem;
            newSpace.Plan = this.plan;
            sessionStorage.setItem('new-space', JSON.stringify(newSpace));
        },
        back() {
            this.step = this.step - 1;
        },
        // createSpace - make a request to the backend to create a new space,
        // if plan is free or not provided, create space without payment flow and redirect to the new space URL
        // if plan is paid, get the checkout URL and redirect to it to complete the payment flow
        // if the request fails, return to space creation form
        async createSpace() {
            // get checkout url for plan
            let response = {};
            this.loading = true;
            try {
                const request = {
                    first_name: this.signUpForm.FirstName,
                    last_name: this.signUpForm.LastName,
                    email: this.signUpForm.Email,
                    password: this.signUpForm.Password,
                    space_name: this.spaceItem.Name,
                    timezone: this.spaceItem.Timezone,
                    plan_slug: this.plan,
                    currency: 'EUR',
                    stripe_session_id: '', // will be passed from payment success page
                    captcha_payload: this.captchaPayload,
                };

                if (request.plan_slug === 'free') {
                    request.plan_slug = '';
                }

                response = await axios({
                    // endpoint currently supported query params:  priceid, email, currency
                    method: 'POST',
                    url: '/new-space/create',
                    data: request,
                });

                this.loading = false;
            } catch (error) {
                Bugsnag.notify(error);
                console.warn('Failed to create space or get checkout URL', error);
                this.error = error;
                this.step = 3;
                this.loading = false;
                return;
            }

            if (get(response, 'status', 0) === 200 && get(response, 'data.redirect_url', null)) {
                // log matomo event
                if (window && window._paq) {
                    window._paq.push(['trackEvent', 'community-creation', 'community-creation-payment-started']);
                }
                // redirect to checkout URL
                window.location = response.data.redirect_url;
            } else if (get(response, 'status', 0) === 200) {
                sessionStorage.removeItem('new-space');
                const userEmail = get(this, 'signUpForm.Email', '').trim();
                // log matomo event
                if (window && window._paq) {
                    window._paq.push(['trackEvent', 'community-creation', 'community-creation-success']);
                    window._paq.push(['trackEvent', 'community-creation', 'community-creation-completed']); // needed for funnel tracking
                }
                const token = response.data.Token;

                // redirect to new space URL

                this.$router.push({
                    name: 'CreateSpaceSuccess',
                    params: { domain: `${response.data.DomainName}/welcome?apptoken=${token}` },
                });
            }
        },
    },
};
</script>
