import { render, staticRenderFns } from "./coPostEditor.vue?vue&type=template&id=dcd1f6b0&scoped=true&"
import script from "./coPostEditor.vue?vue&type=script&lang=ts&"
export * from "./coPostEditor.vue?vue&type=script&lang=ts&"
import style0 from "./coPostEditor.vue?vue&type=style&index=0&id=dcd1f6b0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcd1f6b0",
  null
  
)

export default component.exports