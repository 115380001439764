










import EventCreate from '@/components/Templates/User/Event/Create/EventCreate.vue';

export default {
    name: 'CoEventCreate',
    components: {
        EventCreate,
    },
};
