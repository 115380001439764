


































































































































































































































































import JSConfetti from 'js-confetti';
import EventBus from '@/eventBus.js';
import Bugsnag from '@bugsnag/js';
import i18n from 'vue-i18n';

import { get } from 'lodash';
import axios from 'axios';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoCardTicket from '@/components/Molecules/co-card-ticket/CoCardTicket.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoCheckbox from '@/components/Atoms/co-checkbox/CoCheckbox.vue';
import { ca } from 'date-fns/locale';
import CoLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoMyTicket from '@/components/Organisms/co-my-ticket/CoMyTicket.vue';
import CoForm from '@/components/Molecules/co-form/CoForm.vue';
import CoCardBillingAddress from '@/components/Organisms/co-card-billing-address/CoCardBillingAddress.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';

const jsConfetti = new JSConfetti();

interface EventTicket {
    ID?: string;
    Name: string;
    Description?: string;
    PriceInCents: number;
    TaxRateID?: string;
    Limit: number;
    CreatedAt?: number;
    UpdatedAt?: number;
    CancellationNotice?: number;
    CancellationFee?: number;
    TermsAndConditions?: string;
    Deleted?: boolean;
    SoldOut?: boolean;
}

interface BillingAddress {
    Phone?: string;
    Company?: string;
    AdditionalInfo?: string;
    Address: string;
    PostalCode: string;
    City: string;
    Country: string;
    VATNumber?: string;
}

export default {
    name: 'CoEventTicketCheckout',
    components: {
        CoText,
        CoCardTicket,
        CoModal,
        CoHeadline,
        CoButton,
        CoCheckbox,
        CoLoadingIndicator,
        CoMyTicket,
        CoForm,
        CoCardBillingAddress,
        CoCard,
        CoLink,
    },
    props: {
        eventName: {
            type: String,
            required: false,
            default: '',
        },
        eventID: {
            type: String,
            required: true,
        },
        eventSlug: {
            type: String,
            required: true,
        },
        tickets: {
            type: Array as () => EventTicket[],
            required: false,
            default: () => [] as EventTicket[],
        },
        taxRates: {
            type: Array as () => Array<any> | null,
            default: () => [],
        },
        currency: {
            type: String,
            required: false,
            default: '',
        },
        ticketPurchaseState: {
            type: String,
            required: false,
            default: 'buy',
            validator(value: string) {
                return ['buy', 'success', ''].includes(value);
            },
        },
    },
    data() {
        return {
            step: 1, // 1 - select tickets, 2 - billing address, 3 - Checkout view
            loading: false,
            selectedTicket: null as EventTicket | null,
            acceptTermsAndConditions: false,

            loadingMyTicket: false,
            myTicket: null,

            billingAddress: this.$store.state.me.Address
                ? (this.$store.state.me.Address as BillingAddress)
                : ({} as BillingAddress),
        };
    },
    watch: {
        ticketPurchaseState() {
            if (this.ticketPurchaseState === 'success') {
                this.openModal();
                this.getMyTicket();
            }
        },
    },
    computed: {
        canICheckout() {
            if (
                !this.selectedTicket ||
                (this.selectedTicket && this.selectedTicket.TermsAndConditions && !this.acceptTermsAndConditions)
            ) {
                return false;
            }

            if (
                !get(this.billingAddress, 'Address', '') ||
                !get(this.billingAddress, 'PostalCode', '') ||
                !get(this.billingAddress, 'City', '') ||
                !get(this.billingAddress, 'Country', '')
            ) {
                return false;
            }
            return true;
        },

        total() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }
            const priceInCents = this.selectedTicket.PriceInCents ? this.selectedTicket.PriceInCents : 0;

            if (this.selectedTicket.TaxRateID) {
                const taxRate = this.taxRates.find((taxRate) => taxRate.ID === this.selectedTicket.TaxRateID);
                if (taxRate && !taxRate.Inclusive) {
                    const percentage = taxRate.Percentage ? taxRate.Percentage : 0;
                    const price = priceInCents * (1 + percentage / 100);
                    let vatStr = (price / 100).toFixed(2);
                    vatStr = vatStr.replace('.', ',');

                    return `(${percentage}%) ${vatStr} ${this.currencyChar}`;
                }
            }
            let priceStr = (priceInCents / 100).toFixed(2);
            priceStr = priceStr.replace('.', ',');
            return `${priceStr} ${this.currencyChar}`;
        },
        vat() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }

            if (this.selectedTicket.TaxRateID) {
                const taxRate = this.taxRates.find((taxRate) => taxRate.ID === this.selectedTicket.TaxRateID);
                if (taxRate) {
                    const priceInCents = this.selectedTicket.PriceInCents ? this.selectedTicket.PriceInCents : 0;

                    const percentage = taxRate.Percentage ? taxRate.Percentage : 0;
                    const price = priceInCents * (percentage / 100);
                    let vatStr = (price / 100).toFixed(2);
                    vatStr = vatStr.replace('.', ',');

                    return `(${percentage}%) ${vatStr} ${this.currencyChar}`;
                }
            }

            return `0,00 ${this.currencyChar}`;
        },
        netPrice() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }

            if (this.selectedTicket.TaxRateID) {
                const taxRate = this.taxRates.find((taxRate) => taxRate.ID === this.selectedTicket.TaxRateID);
                if (taxRate && taxRate.Inclusive) {
                    const price =
                        this.selectedTicket.PriceInCents -
                        this.selectedTicket.PriceInCents * (taxRate.Percentage / 100);
                    let priceStr = (price / 100).toFixed(2);
                    priceStr = priceStr.replace('.', ',');
                    return `${priceStr} ${this.currencyChar}`;
                }
            }

            let priceStr = (this.selectedTicket.PriceInCents / 100).toFixed(2);
            priceStr = priceStr.replace('.', ',');

            return `${priceStr} ${this.currencyChar}`;
        },

        subtotalTicketPrice() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }

            let priceStr = (this.selectedTicket.PriceInCents / 100).toFixed(2);
            priceStr = priceStr.replace('.', ',');

            return `${priceStr} ${this.currencyChar}`;
        },
        ticketPrice() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }

            let priceStr = (this.selectedTicket.PriceInCents / 100).toFixed(2);
            priceStr = priceStr.replace('.', ',');

            return `${priceStr} ${this.currencyChar}`;
        },

        currencyChar() {
            if (!this.currency) {
                return '€';
            }

            const currency = this.currency.toUpperCase();

            if (currency === 'EUR') {
                return '€';
            }
            if (currency === 'USD') {
                return '$';
            }

            return currency;
        },
        listTickets() {
            // Filter out deleted tickets and sort by price in ascending order also filter sold out tickets as last
            return this.tickets
                .filter((ticket) => !ticket.Deleted)
                .sort((a, b) => a.PriceInCents - b.PriceInCents)
                .sort((a, b) => (a.SoldOut ? 1 : 0) - (b.SoldOut ? 1 : 0));
        },
    },
    mounted() {
        if (this.ticketPurchaseState === 'success') {
            this.openModal();
            this.getMyTicket();
        }
    },
    methods: {
        next() {
            // if Price is 0 skip billing address part
            if (get(this.selectedTicket, 'PriceInCents', 0) === 0) {
                this.loading = true;
                const successURL = `${window.location.origin}/event/${this.eventSlug}?tp=success&ticket_type_id=${this.selectedTicket?.ID}`;
                const cancelURL = `${window.location.origin}/event/${this.eventSlug}?tp=cancel&ticket_type_id=${this.selectedTicket?.ID}`;

                let req = {
                    ticket_type_id: this.selectedTicket?.ID,
                    event_id: this.eventID,
                    success_url: successURL,
                    cancel_url: cancelURL,
                };
                axios({
                    method: 'POST',
                    url: '/event/ticket',
                    data: req,
                })
                    .then((response) => {
                        // redirect to payment page
                        const paymentURL = get(response, 'data.ticket.PaymentURL', '');
                        if (paymentURL) {
                            window.location.href = paymentURL;
                            this.loading = false;
                        } else if (get(response, 'data.ticket.Status', null) === 2) {
                            this.myTicket = get(response, 'data.ticket', null);
                            jsConfetti.addConfetti();
                            this.ticketPurchaseState = 'success';
                        } else {
                            console.error('No payment URL found');
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        console.error(error);
                        Bugsnag.notify(error);
                        EventBus.$emit('ERROR', { Message: this.$t('errors.failedGeneric') });
                    })
                    .finally(() => {});
            } else {
                this.step = 2;
            }
        },
        showEventDetails() {
            // replace url
            this.$router.replace(`/event/${this.eventSlug}`);

            // hide modal
            this.$refs['co-event-ticket-checkout'].hide();
        },
        hideModal() {
            this.$refs['co-event-ticket-checkout'].hide();
            this.selectedTicket = null;
        },
        openModal() {
            if (this.$store && this.$store.state.isInMobile) {
                // replace url quiry params with resource id
                const urltoResource = `/event/${this.eventSlug}?tp=buy`;
                const queryParam = {
                    ...this.$route.query,
                    m: 'mobile',
                    openinbrowser: urltoResource,
                    t: new Date().getTime(),
                };
                this.$router.push({ query: queryParam });
                return;
            }

            this.$refs['co-event-ticket-checkout'].show();
        },
        selectTicket(ticket: EventTicket) {
            if (this.loading) {
                return;
            }
            if (!ticket.PriceInCents) {
                ticket.PriceInCents = 0;
            }

            this.selectedTicket = ticket;
        },
        getMyTicket() {
            this.loadingMyTicket = true;
            axios
                .get(`/event/ticket/my/for-event-id/${this.eventID}`)
                .then((response) => {
                    this.myTicket = get(response, 'data.ticket', null);
                    jsConfetti.addConfetti();
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingMyTicket = false;
                });
        },

        createOrder() {
            this.loading = true;

            // call to update billing address
            const data = { Address: this.billingAddress };
            axios({
                method: 'PUT',
                url: '/dashboard/me-billing',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data,
            })
                .then((response) => {
                    // checkout call

                    const successURL = `${window.location.origin}/event/${this.eventSlug}?tp=success&ticket_type_id=${this.selectedTicket?.ID}`;
                    const cancelURL = `${window.location.origin}/event/${this.eventSlug}?tp=cancel&ticket_type_id=${this.selectedTicket?.ID}`;

                    let req = {
                        ticket_type_id: this.selectedTicket?.ID,
                        event_id: this.eventID,
                        success_url: successURL,
                        cancel_url: cancelURL,
                    };
                    axios({
                        method: 'POST',
                        url: '/event/ticket',
                        data: req,
                    })
                        .then((response) => {
                            // redirect to payment page
                            const paymentURL = get(response, 'data.ticket.PaymentURL', '');
                            if (paymentURL) {
                                window.location.href = paymentURL;
                                this.loading = false;
                            } else if (get(response, 'data.ticket.Status', null) === 2) {
                                this.myTicket = get(response, 'data.ticket', null);
                                jsConfetti.addConfetti();
                                this.ticketPurchaseState = 'success';
                            } else {
                                console.error('No payment URL found');
                            }
                        })
                        .catch((error) => {
                            this.loading = false;
                            console.error(error);
                            Bugsnag.notify(error);
                            EventBus.$emit('ERROR', { Message: this.$t('errors.failedGeneric') });
                        })
                        .finally(() => {});
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                    Bugsnag.notify(error);

                    if (get(error, 'response.data', '').match('tax_id_invalid')) {
                        EventBus.$emit('ERROR', {
                            Message: this.$t('labels.vatid') + ' ' + this.$t('errors.invalid'),
                        });
                    } else {
                        EventBus.$emit('ERROR', { Message: this.$t('errors.failedGeneric') });
                    }
                })
                .finally(() => {});
        },

        saveBillingAddressData(billingAddress) {
            this.billingAddress = billingAddress;
            const data = { Address: billingAddress };
            return new Promise<void>((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/dashboard/me-billing',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data,
                })
                    .then((response) => {
                        EventBus.$emit('INFO', { Message: this.$t('messages.changessaved') });
                    })
                    .catch((error) => {
                        console.log(error);
                        Bugsnag.notify(error);

                        if (get(error, 'response.data', '').match('tax_id_invalid')) {
                            EventBus.$emit('ERROR', {
                                Message: this.$t('labels.vatid') + ' ' + this.$t('errors.invalid'),
                            });
                        } else {
                            EventBus.$emit('ERROR', { Message: this.$t('errors.failedGeneric') });
                        }
                    })
                    .finally(() => {
                        resolve();
                    });
            });
        },
    },
};
