






























export default {
    name: 'CoWeekInterval',
    props: {
        withTime: Boolean,
        startValue: String,
        endValue: String,
        index: Number,
    },
    data() {
        return {
            weekdays: [
                { Name: 'Mo', Selected: true, Number: 0 },
                { Name: 'Tu', Selected: true, Number: 1 },
                { Name: 'We', Selected: true, Number: 2 },
                { Name: 'Th', Selected: true, Number: 3 },
                { Name: 'Fr', Selected: true, Number: 4 },
                { Name: 'Sa', Selected: false, Number: 5 },
                { Name: 'Su', Selected: false, Number: 6 },
            ],

            startTime: this.startValue,
            endTime: this.endValue,
        };
    },
    methods: {
        setStartTime(time) {
            this.startTime = time;
            this.$emit('selectStartTime', time);
        },
        setEndTime(time) {
            this.endTime = time;
            this.$emit('selectEndTime', time);
        },

        selecting(index) {
            this.weekdays[index].Selected = !this.weekdays[index].Selected;

            var weekdays = this.weekdays.filter((item) => item.Selected);
            const exportWeekdays = weekdays.map((item) => item.Number);

            this.$emit('selectedDays', exportWeekdays);
        },
        removeInterval(index) {
            this.$emit('removeInterval', index);
        },
    },
};
