

























































import axios from 'axios';

import EventBus from '@/eventBus';
import i18n from 'vue-i18n';

import { map } from 'lodash';
import CoCardMarketItem from '@/components/Molecules/co-card-market-item/CoCardMarketItem.vue';
import { MarketItem } from '@/components/Molecules/co-card-market-item/models.ts';
import { Event } from '@/components/Molecules/co-card-event/models.ts';
import CoCardEvent from '@/components/Molecules/co-card-event/CoCardEvent.vue';
import { Page, User } from '../../components/Molecules/co-card-page/models.ts';
import CoCardPage from '../../components/Molecules/co-card-page/CoCardPage.vue';
import CoCardMember from '../../components/Molecules/co-card-member/CoCardMember.vue';

export default {
    i18n: {
        messages: {
            en: {
                searching: "Searching for '{query}' ...",
                results: '1 result | {n} results',
                noresultsforkeyword: "We couldn't find anything for '{query}'.",
                searchhelp: 'Try different or less specific keywords.',
            },
            de: {
                searching: "Suche nach '{query}' ...",
                results: '1 Ergebnis | {n} Ergebnisse',
                noresultsforkeyword: "Wir konnten nichts zu '{query}' finden.",
                searchhelp: 'Versuches mit einer anderen oder weniger spezifischen Suche.',
            },
        },
    },
    name: 'Search',
    components: {
        CoCardEvent,
        CoCardMarketItem,
        CoCardPage,
        CoCardMember,
    },
    data() {
        return {
            searchResults: false,
            searchLoading: true,
            search: this.$route.query.term,
            keycode: '',
            feed: [],
            feedBank: [],
            feedAdd: [],
            results: 0,

            isMarketOn: this.$store.state.space.MarkeplaceOn,

            authors: {},
            owners: {},
            channelList: null,
            me: this.$store.state.me,
            circlesOn: this.$store.state.circlesOn,
        };
    },
    computed: {
        query() {
            return this.$route.query.term;
        },
    },
    watch: {
        '$route.query.term': async function (term) {
            if (this.circlesOn) {
                await this.makeExperimentalSearch();
                this.searchLoading = false;
            } else {
                await this.makeSearch();
                this.searchLoading = false;
            }
        },
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        this.scroll();
        this.runAsyncLoadingFunctions();
    },
    methods: {
        async runAsyncLoadingFunctions() {
            this.searchLoading = true;
            if (this.circlesOn) {
                await Promise.all([this.makeExperimentalSearch(), this.getChannelList()]);
            } else {
                await Promise.all([this.makeSearch(), this.getChannelList()]);
            }
            this.searchLoading = false;
        },
        objectHash(obj) {
            // convert object to string and hash it to use as key
            const str = JSON.stringify(obj);

            // hash the string
            let hash = 0;

            let char;
            for (let i = 0; i < str.length; i++) {
                char = str.charCodeAt(i);
                hash = (hash << 5) - hash + char;
                hash &= hash;
            }
            return hash;
        },

        viewItem(id, type) {
            if (type === 'project') {
                this.$router.push(`/project/${id}`);
            } else if (type == 'user') {
                this.$router.push(`/profile/${id}`);
            }
        },

        async makeSearch() {
            this.searchLoading = true;
            this.feed = [];
            this.feedBank = [];
            this.feedAdd = [];
            await axios({
                method: 'GET',
                url: `/search/v2/${this.$route.query.term}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response && response.data) {
                        if (response.data.objects) {
                            // filter out the jobs if the market is off
                            let { objects } = response.data;
                            if (!this.isMarketOn) {
                                objects = objects.filter((item) => item.type !== 'job');
                            }

                            // add key to each object
                            objects = objects.map((item) => {
                                item.key = Math.random();
                                return item;
                            });

                            this.$set(this.$data, 'feedBank', objects);
                            this.results = this.feedBank.length;
                            if (this.feedBank.length > 20) {
                                this.feedAdd.push(...this.feedBank.slice(0, 20));
                                this.feedBank = this.feedBank.slice(20);
                            } else {
                                this.feedAdd.push(...this.feedBank);
                                this.feedBank = [];
                            }
                        } else {
                            this.$set(this.$data, 'feedBank', []);
                            this.$set(this.$data, 'feedAdd', []);
                        }
                        await Promise.all([this.getOwners(this.feedAdd), this.getAuthors(this.feedAdd)]);
                        await this.mapFeedAddItems();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async makeExperimentalSearch() {
            this.searchLoading = true;
            this.feed = [];
            this.feedBank = [];
            this.feedAdd = [];
            await axios({
                method: 'GET',
                url: `/search/tmp/${this.$route.query.term}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response && response.data) {
                        if (response.data.objects) {
                            // filter out the jobs if the market is off
                            let { objects } = response.data;
                            if (!this.isMarketOn) {
                                objects = objects.filter((item) => item.type !== 'job');
                            }

                            // add key to each object
                            objects = objects.map((item) => {
                                item.key = Math.random();
                                return item;
                            });

                            this.$set(this.$data, 'feedBank', objects);
                            this.results = this.feedBank.length;
                            if (this.feedBank.length > 20) {
                                this.feedAdd.push(...this.feedBank.slice(0, 20));
                                this.feedBank = this.feedBank.slice(20);
                            } else {
                                this.feedAdd.push(...this.feedBank);
                                this.feedBank = [];
                            }
                        } else {
                            this.$set(this.$data, 'feedBank', []);
                            this.$set(this.$data, 'feedAdd', []);
                        }
                        await Promise.all([this.getOwners(this.feedAdd), this.getAuthors(this.feedAdd)]);
                        await this.mapFeedAddItems();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async getOwners(feed) {
            const ownerIDMap = new Map();

            // Collect all unique owner IDs
            for (let i = 0; i < feed.length; i++) {
                const obj = feed[i].object;

                if (feed[i].type !== 'project') {
                    continue;
                }

                if (!this.owners[obj.ID]) {
                    obj.Owner.forEach((entry) => {
                        if (!ownerIDMap.has(entry)) {
                            ownerIDMap.set(entry, []);
                        }
                        ownerIDMap.get(entry).push(obj.ID);
                    });
                }
            }

            if (ownerIDMap.size > 0) {
                const ids = Array.from(ownerIDMap.keys()).map((ID) => ({ ID }));
                const ownersIDs = { IDS: ids };
                const data = JSON.stringify(ownersIDs);

                const response = await this.$store.dispatch('listUsersByIDs', { ids: data });

                if (response.Users) {
                    let owners = response.Users;

                    owners = owners.map((owner) => {
                        const tmp = {
                            id: owner.ID,
                            slug: owner.Slug,
                            name: `${owner.Profile.FirstName} ${owner.Profile.LastName}`,
                            image: owner.Profile.ImageURL,
                        };

                        if (!owner.Profile.FirstName) {
                            tmp.name = owner.Profile.Name;
                        }

                        return tmp;
                    });

                    // Map the owners to the respective objects
                    owners.forEach((owner) => {
                        ownerIDMap.get(owner.id).forEach((objID) => {
                            if (!this.owners[objID]) {
                                this.owners[objID] = [];
                            }
                            this.owners[objID].push(owner);
                        });
                    });
                }
            }
        },
        async getAuthors(items) {
            const authorsIDs = [];

            // loop through items
            for (let i = 0; i < items.length; i += 1) {
                if (items[i].type !== 'job') {
                    continue;
                }
                // get author id
                const obj = items[i].object;
                // check if author id is in authorsIDs, if not add it
                if (!authorsIDs.includes(obj.AuthorID)) {
                    authorsIDs.push(obj.AuthorID);
                }
            }

            if (authorsIDs.length === 0) {
                return;
            }

            const ids = authorsIDs.map((id) => ({ ID: id }));
            const authorID = { IDS: ids };
            const data = JSON.stringify(authorID);

            this.$store.dispatch('listUsersByIDs', { ids: data }).then((response) => {
                if (response.Users) {
                    let authors = response.Users;

                    authors = authors.map((owner) => {
                        // add owner to item
                        const tmp: User = {
                            id: owner.ID,
                            slug: owner.Slug,
                            name: `${owner.Profile.FirstName} ${owner.Profile.LastName}`,
                            image: owner.Profile.ImageURL,
                        };

                        if (!owner.Profile.FirstName) {
                            tmp.name = owner.Profile.Name;
                        }
                        return tmp;
                    });

                    this.authors = authors.reduce((acc, cur) => {
                        acc[cur.id] = acc[cur.id] || [];
                        acc[cur.id].push(cur);
                        return acc;
                    }, {});
                }
            });
        },

        async getChannelList() {
            /* get the list of channels and save them in the channelList map with slug as keys */
            axios
                .get('/project/channel/list')
                .then((response) => {
                    if (response.data) {
                        // find channel title by slug
                        this.channelList = response.data.objects.map((channel) => channel.object);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(async () => {
                    await this.mapFeedAddItems();
                });
        },

        async mapFeedAddItems() {
            // map the items to the feed based on the type
            this.feedAdd = this.feedAdd.map((object) => {
                if (object.type === 'project') {
                    return this.mapPageStructure(object);
                }
                if (object.type === 'job') {
                    return this.mapMarketItemStructure(object);
                }
                if (object.type === 'event') {
                    return this.mapEventStructure(object);
                }
                return object;
            });

            // add the feedAdd to the feed
            this.feed.push(...this.feedAdd);
            this.feedAdd = [];
        },

        mapEventStructure(object) {
            const resObject = object;
            const event = object.object;
            const spaceItem = object.space;
            const e: Event = {
                id: event.ID,
                title: event.Title,
                description: event.Description,
                start: event.StartDate,
                end: event.EndDate,
                slug: event.Slug,
                image: event.ImageURL,
            };

            if (event.Participants && event.Participants.length > 0) {
                e.attend = event.Participants.includes(this.me.ID);
            }

            if (spaceItem && spaceItem.ID !== this.$store.state.space.ID) {
                e.space = {
                    id: spaceItem.ID,
                    name: spaceItem.Name,
                    favicon: spaceItem.FaviconURL,
                    primaryColor: spaceItem.PrimaryColor,
                };
            }

            resObject.event = e;

            if (!resObject.key) {
                resObject.key = Math.random();
            } else {
                resObject.key += 1;
            }

            return resObject;
        },
        mapMarketItemStructure(object) {
            const resObject = object;
            const marketItem = object.object;
            const spaceItem = object.space;
            const e: MarketItem = {
                id: marketItem.ID,
                title: marketItem.Title,
                description: marketItem.Description,
                slug: marketItem.Slug,
                image: marketItem.ImageURL,
                authorId: marketItem.AuthorID,
                author: this.authors[marketItem.AuthorID] ? this.authors[marketItem.AuthorID][0] : null,
                open: marketItem.Open,
            };

            if (marketItem.Participants && marketItem.Participants.length > 0) {
                e.followed = marketItem.Participants.includes(this.me.ID);
            }

            if (spaceItem && spaceItem.ID !== this.$store.state.space.ID) {
                e.space = {
                    id: spaceItem.ID,
                    name: spaceItem.Name,
                    favicon: spaceItem.FaviconURL,
                    primaryColor: spaceItem.PrimaryColor,
                };
            }

            resObject.marketItem = e;

            if (!resObject.key) {
                resObject.key = Math.random();
            } else {
                resObject.key += 1;
            }

            return resObject;
        },
        mapPageStructure(object) {
            const resObject = object;
            let channelSlug = this.getChannelSlugByID(resObject.object.ChannelID);
            if (!channelSlug) {
                channelSlug = 'pages';
            }
            const owners = this.owners[resObject.object.ID];
            const p: Page = {
                id: resObject.object.ID,
                title: resObject.object.Title,
                description: resObject.object.Description,
                image: resObject.object.ImageURL && resObject.object.ImageURL[0] ? resObject.object.ImageURL[0] : null,
                slug: resObject.object.Slug,
                owners,
                // Add the remaining properties here
                channelSlug,
                followed: !!(resObject.object.FollowedBy && resObject.object.FollowedBy.indexOf(this.me.ID) > -1),
                private: !resObject.object.Published,
            };

            if (resObject.space) {
                p.space = this.mapSpaceObject(resObject.space);
            }

            resObject.page = p;

            if (!resObject.key) {
                resObject.key = Math.random();
            } else {
                resObject.key += 1;
            }

            return resObject;
        },
        mapSpaceObject(spaceItem) {
            if (!spaceItem) {
                return null;
            }

            if (!spaceItem.ID) {
                return null;
            }

            if (spaceItem.ID === this.$store.state.space.ID) {
                return null;
            }
            const obj = {
                id: spaceItem.ID,
                name: spaceItem.Name,
                favicon: spaceItem.FaviconURL,
                primaryColor: spaceItem.PrimaryColor,
            };
            return obj;
        },

        getChannelSlugByID(ID) {
            // get channel slug by ID
            const slug = '';

            if (!this.channelList) {
                return slug;
            }

            // clone the channel list map to avoid mutation
            const channelList = JSON.parse(JSON.stringify(this.channelList));

            const channel = channelList.find((c) => c.ID === ID);
            return channel ? channel.Slug : slug;
        },

        follow(page) {
            const subs = JSON.stringify({ ProjectID: page.id });
            axios({
                method: 'POST',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Page followed',
                        Details: null,
                    });

                    // update page in pages array
                    const index = this.feed.findIndex((p) => {
                        if (p.page) {
                            return p.page.id === page.id;
                        }
                        return false;
                    });
                    this.feed[index].page.followed = true;
                    this.feed[index].key += 1;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Page follow failed',
                        Details: null,
                    });
                });
        },
        unfollow(page) {
            const subs = JSON.stringify({ ProjectID: page.id });
            axios({
                method: 'DELETE',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Page unfollowed',
                        Details: null,
                    });

                    // update page in pages array
                    const index = this.feed.findIndex((p) => {
                        if (p.page) {
                            return p.page.id === page.id;
                        }
                        return false;
                    });
                    this.feed[index].page.followed = false;
                    this.feed[index].key += 1;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Page unfollow failed',
                        Details: null,
                    });
                });
        },
        sendMessage(userObj: User) {
            if (!userObj || !userObj.id) return;
            const thread = {
                Participants: [this.$store.state.me.ID, userObj.id],
            };
            axios({
                method: 'POST',
                url: '/chat/t',
                withCredentials: true,
                data: JSON.stringify(thread),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$router.push(`/messenger/${response.data.ID}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        scroll() {
            window.onscroll = () => {
                const scrollTop = window.scrollY || document.documentElement.scrollTop || 0;
                const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
                const scrollPercentage = (scrollTop / totalScroll) * 100;

                if (scrollPercentage > 85) {
                    if (this.feedBank.length !== 0 && this.feedBank.length > 10) {
                        this.feedAdd.push(...this.feedBank.slice(0, 10));
                        this.feedBank = this.feedBank.slice(10);
                    } else if (this.feedBank.length !== 0 && this.feedBank.length <= 10) {
                        this.feedAdd.push(...this.feedBank);
                        this.feedBank = [];
                    }
                    Promise.all([this.getOwners(this.feedAdd), this.getAuthors(this.feedAdd)]);
                    this.mapFeedAddItems();
                }
            };
        },
    },
};
