






























































import axios from 'axios';
import EventBus from '@/eventBus';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoPill from '@/components/Atoms/co-pill/CoPill.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';

export default {
    name: 'CoPaymentMethodCard',
    components: {
        CoRoundButton,
        CoSkeleton,
        CoText,
        CoDropdown,
        CoDropdownItem,
        CoHeadline,
        CoThumbnail,
        CoPill,
        CoDate,
    },
    props: {
        object: {
            type: Object,
            default: null,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disableDelete: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        title() {
            if (this.object && this.object.Details) {
                if (this.object.Details.CardBrand) {
                    switch (this.object.Details.CardBrand) {
                        case 'amex':
                            return 'American Express';
                        case 'diners':
                            return 'Diners';
                        case 'discover':
                            return 'Discover';
                        case 'jcb':
                            return 'JCB';
                        case 'mastercard':
                            return 'Mastercard';
                        case 'unionpay':
                            return 'UnionPay';
                        case 'visa':
                            return 'VISA';
                        default:
                            return this.object.Details.CardBrand;
                    }
                } else {
                    return 'SEPA';
                }
            }

            return 'undefined';
        },
    },
    data() {
        return {
            localloading: false,
        };
    },
    methods: {
        setAsDefault() {
            this.localloading = true;
            const data = {
                ID: this.object.ID,
            };
            axios({
                method: 'PUT',
                url: '/user/account/billing/payment-methods/default',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$emit('setAsDefault', response.data);
                    this.localloading = false;
                    EventBus.$emit('INFO', {
                        Message: this.$t('messages.changessaved'),
                        Details: '',
                    });
                })
                .catch((error) => {
                    this.localloading = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to set this method as standard',
                        Details: '',
                    });
                    console.log(error);
                });
        },

        deletePM() {
            this.localloading = true;
            const paymentMethodId = this.object.ID;
            axios({
                method: 'DELETE',
                url: `/user/account/billing/delete-payment-method`,
                data: JSON.stringify({ ID: paymentMethodId }),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.localloading = false;
                    EventBus.$emit('INFO', {
                        Message: this.$t('messages.changessaved'),
                        Details: '',
                    });
                    this.$emit('delete', this.object);
                })
                .catch((error) => {
                    this.localloading = false;
                    EventBus.$emit('ERROR', {
                        Message: this.$t('errors.failedGeneric'),
                        Details: '',
                    });
                    console.log(error);
                });
        },
    },
};
