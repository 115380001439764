



























































































































































































































































































































































































import axios from 'axios';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import {
    differenceInMinutes,
    isBefore,
    isEqual,
    format,
    add,
    parse as parsedatefns,
    startOfDay,
    isAfter,
} from 'date-fns';
import { get } from 'lodash';
import i18n from 'vue-i18n';
import EventBus from '../../../eventBus';

import CoTextArea from '../../Molecules/co-TextArea/CoTextArea.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoAlert from '../../Molecules/co-alert/CoAlert.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';

interface bookingRequest {
    start: number;
    end: number;
    resourceid: string;
    couponIDs: string[];
}

interface Booking {
    Title: string;
    Resource: object;
    CouponIDs: string[];
    Start: Date;
    End: Date;
    Comment: string;
}

export default {
    i18n: {
        messages: {
            en: {
                calendar: 'Add this booking to your calendar',
                cancellationpolicy:
                    'This booking can be cancelled until its start time free of charge. After that, this booking is non-refundable. | This booking can be cancelled until {count} hours before its start time free of charge. After that, this booking is non-refundable.',
                bookingheader: 'Book {resource}',
                error: 'Unfortunately something went wrong while creating your booking',
                processing: 'Your booking is processed.',
                seebookings: 'See my bookings',
                success: 'Your booking was successful',
                isexhausted: 'The selected time is not available for booking.',
                toolong:
                    'This resource allows a maximum booking duration of {0} minutes. The end time has been adjusted accordingly.',
                tooshort:
                    'This resource requires a minimum booking duration of {0} minutes. The end time has been adjusted accordingly.',
                couponnotneded: 'The booking is already free of charge for you, so the coupon will not be applied.',
            },
            de: {
                calendar: 'Buchung in deinem Kalender speichern',
                cancellationpolicy:
                    'Diese Buchung kann bis zu ihrer Anfangszeit kostenlos storniert werden. Danach ist diese Buchung nicht mehr erstattungsfähig. | Diese Buchung kann bis {count} Stunden vor ihrer Anfangszeit kostenlos storniert werden. Danach ist diese Buchung nicht mehr erstattungsfähig.',
                bookingheader: '{resource} buchen',
                error: 'Leider ist bei der Buchung etwas schiefgegangen.',
                processing: 'Deine Buchung wird verarbeitet.',
                seebookings: 'Meine Buchungen ansehen',
                success: 'Deine Buchung war erfolgreich',
                isexhausted: 'Die gewählte Zeit ist nicht buchbar.',
                toolong:
                    'Diese Ressource erlaubt eine maximale Buchungsdauer von {0} Minuten. Die Endzeit wurde entsprechend angepasst.',
                tooshort:
                    'Diese Ressource erlaubt eine minimale Buchungsdauer von {0} Minuten. Die Endzeit wurde entsprechend angepasst.',
                couponnotneded: 'Die Buchung ist für dich bereits kostenlos, der Gutschein wird also nicht angewendet.',
            },
        },
    },
    name: 'CoBookingCheckoutForUser',
    components: {
        CoTextArea,
        CoAlert,
        CoInput,
    },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        startTimeSlot: {
            default: null,
        },

        // is a time slot where the booking ends
        // it can be the same as startTimeSlot
        // eslint-disable-next-line vue/require-prop-types
        endTimeSlot: {
            default: null,
        },

        start: {
            type: Date,
            default: new Date(),
        },

        end: {
            type: Date,
            default: new Date(),
        },

        resource: {
            type: Object || null,
            default: null,
        },
        display: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            bookingStartDate: format(new Date(), 'yyyy-MM-dd'),
            bookingEndDate: format(new Date(), 'yyyy-MM-dd'),
            bookingStartString: '00:00',
            bookingEndString: '00:00',
            bookingEndSelectorKey: 0,

            bookingStartOptions: [],
            bookingEndOptions: [],

            // variable of type Booking
            booking: {
                Title: `${this.$store.state.me.Profile.Name}'s booking`,
                Resource: {},
                CouponIDs: [],
                Start: new Date(),
                End: new Date(),
                Comment: '',
            } as Booking,

            loading: false,
            success: false,
            error: false,

            tooShort: false,
            tooLong: false,
            resetWarnings: false,
            initialSetup: true,
            acceptedTerms: false,

            couponID: '',
            coupon: null,

            bookingPrice: null,
            bookingPriceLoading: false,
            bookingPriceError: null,

            IsExhausted: false,
            IsExhaustedLoading: false,
            previewBookingPriceCancelTokens: {},
            pad: 0,

            switchedToAnotherTab: false,
            isBillingTurnedOn: this.$store.state.billingTurnedOn,
        };
    },

    watch: {
        bookingStartString(newVal) {
            if (this.resetWarnings) {
                this.tooShort = false;
                this.tooLong = false;
                this.IsExhausted = false;
                this.resetWarnings = false;
            }
            const timeStart = newVal.split(':');
            const startHours = parseInt(timeStart[0], 10);
            const startMinutes = parseInt(timeStart[1], 10);
            const from = this.start;
            if (from) {
                from.setHours(startHours);
                from.setMinutes(startMinutes);

                this.booking.Start = from;
            }

            if (this.endDateHasBeenChanged) {
                this.checkIfSlotExausted();
            } else {
                if (this.initialSetup) {
                    this.initialSetup = false;
                } else {
                    let interval = this.interval();
                    if (this.resource.MinBookingDuration && this.resource.MinBookingDuration !== 0) {
                        interval = this.resource.MinBookingDuration;
                    }

                    const end = add(this.booking.Start, { minutes: interval });
                    this.bookingEndString = format(end, 'HH:mm');
                }
                this.updateToSelectOptions();
            }
        },

        bookingEndDate(newVal, oldVal) {
            if (this.resetWarnings) {
                this.tooShort = false;
                this.tooLong = false;
                this.IsExhausted = false;
                this.resetWarnings = false;
            }

            // make date object from string
            const date = new Date(newVal);

            this.booking.End = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                this.booking.End.getHours(),
                this.booking.End.getMinutes()
            );

            if (this.endDateHasBeenChanged) {
                this.updateToSelectOptions();
                this.checkIfSlotExausted();
            } else {
                this.IsExhausted = false;
                this.updateToSelectOptions();
                this.calculatePrice();
            }
        },
        bookingEndString(newVal) {
            if (this.resetWarnings) {
                this.tooShort = false;
                this.tooLong = false;
                this.IsExhausted = false;
                this.resetWarnings = false;
            }

            const timeEnd = newVal.split(':');
            const endHours = parseInt(timeEnd[0], 10);
            const endMinutes = parseInt(timeEnd[1], 10);
            const to = this.booking.End;
            if (!to) {
                return;
            }
            to.setHours(endHours);
            to.setMinutes(endMinutes);
            if (to) {
                this.booking.End = to;
            }
            if (this.endDateHasBeenChanged) {
                this.checkIfSlotExausted();
            } else if (this.endTimeSlot && isAfter(to, this.endTimeSlot.end)) {
                // check if end time is after slot end time, if so, set state to exhausted
                this.IsExhausted = true;
                this.bookingPrice = null;
            } else {
                this.IsExhausted = false;
                this.calculatePrice();
            }
        },

        display(newVal, oldVal) {
            if (newVal) {
                this.show();
            }
        },
    },
    computed: {
        endDateHasBeenChanged() {
            const enddateStr = this.formatdate(this.end, 'yyyy-MM-dd');
            const { bookingEndDate } = this;
            if (bookingEndDate === enddateStr) {
                return false;
            }
            return true;
        },
        bookingTimeZone() {
            try {
                return Intl.DateTimeFormat(this.start).resolvedOptions().timeZone;
            } catch (error) {
                return null;
            }
        },
    },

    created() {
        window.addEventListener('blur', this.onTabBlur);
        window.addEventListener('focus', this.onTabFocus);
    },
    methods: {
        onTabBlur() {
            this.switchedToAnotherTab = true;
        },

        onTabFocus() {
            if (this.switchedToAnotherTab) {
                this.switchedToAnotherTab = false;
                this.calculatePrice();
            }
        },

        resetWarningMessages() {
            this.resetWarnings = true;
        },

        formatdate(date, layout) {
            return format(date, layout);
        },

        cleanUp() {
            this.booking = {
                Title: `${this.$store.state.me.Profile.Name}'s booking`,
            };
            this.bookingStartString = '00:00';
            this.bookingEndString = '00:00';
            this.bookingStartOptions = [];
            this.bookingEndOptions = [];
            this.couponID = '';
            this.coupon = null;
            this.acceptedTerms = false;
            this.bookingPrice = null;
            this.bookingPriceLoading = false;

            this.IsExhausted = false;
            this.IsExhaustedLoading = false;

            this.tooShort = false;
            this.tooLong = false;

            this.loading = false;
            this.success = false;
            this.error = false;
        },

        close() {
            this.cleanUp();
            this.$emit('close');
        },

        addPaymentMethod(authWindow) {
            authWindow.location.href = '/account/billing/add-payment-method?closeAfterSuccess=true';
        },

        addMembership(authWindow) {
            authWindow.location.href = '/account/plan?closeAfterSuccess=true';
        },

        checkIfSlotExausted() {
            this.IsExhaustedLoading = true;
            // get dates from start to end
            const dates = this.datesOfInterval(this.booking.Start, this.booking.End);

            // get all slots for each date
            const freeslots = [];

            let numberOfdays = dates.length;
            dates.forEach((date) => {
                axios({
                    method: 'GET',
                    url: `/booking/v2/resources/free-slots/${this.resource.ID}/${date}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        const slots = get(response, 'data.FreeSlots', []);
                        slots.forEach((entry) => {
                            const startstr = entry.StartTime.replace('UTC', '');
                            const endstr = entry.EndTime.replace('UTC', '');
                            const startdate = parsedatefns(startstr, 'yyyy-MM-dd HH:mm:ss XX', new Date());
                            const enddate = parsedatefns(endstr, 'yyyy-MM-dd HH:mm:ss XX', new Date());
                            const freeBusySlot = {
                                start: startdate,
                                end: enddate,
                                free: true,
                            };

                            freeslots.push(freeBusySlot);
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        numberOfdays -= 1;
                        if (numberOfdays === 0) {
                            // check if slots have gaps
                            const hasGaps = this.hasGaps(freeslots);
                            this.IsExhausted = hasGaps;
                            this.IsExhaustedLoading = false;

                            if (!this.IsExhausted) {
                                this.calculatePrice();
                            } else {
                                this.bookingPrice = null;
                            }
                        }
                    });
            });
        },

        hasGaps(freeslots) {
            freeslots.sort((a, b) => a.start.getTime() - b.start.getTime());

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < freeslots.length - 1; i++) {
                const slot = freeslots[i];
                const nextSlot = freeslots[i + 1];
                if (slot.end.getTime() !== nextSlot.start.getTime()) {
                    return true;
                }
            }

            const slotStart = Math.round(freeslots[0].start.getTime() / 1000);
            const slotEnd = Math.round(freeslots[freeslots.length - 1].end.getTime() / 1000);

            const start = Math.round(this.booking.Start.getTime() / 1000);
            // convert  this.booking.End to unix timestamp
            const end = Math.round(this.booking.End.getTime() / 1000);
            if (start < slotStart || end > slotEnd) {
                return true;
            }
            return false;
        },

        datesOfInterval(start, end) {
            const dates = [];
            let currentDate = start;

            while (currentDate <= end) {
                const dateStr = format(currentDate, 'yyyy-MM-dd');
                dates.push(dateStr);
                currentDate = add(startOfDay(currentDate), { days: 1 });
            }
            return dates;
        },

        applyCoupon() {
            if (this.couponID === '') {
                return;
            }
            if (this.bookingPrice && this.bookingPrice.GrossPriceInCents === 0) {
                EventBus.$emit('INFO', {
                    Message: this.$t('couponnotneded'),
                });
                this.coupon = null;
                this.couponID = '';
                return;
            }
            axios
                .get(`/space/coupon/${this.couponID}`)
                .then((res) => {
                    this.coupon = res.data;
                    this.calculatePrice();
                })
                .catch((err) => {
                    this.coupon = null;
                    EventBus.$emit('ERROR', {
                        Message: 'Coupon code is not valid',
                    });
                    console.log(err);
                });
        },
        updateCoupon() {
            if (!this.couponID) {
                this.coupon = null;
                this.calculatePrice();
            }
        },

        removeCoupon() {
            this.coupon = null;
            this.couponID = '';
            this.calculatePrice();
        },

        termsAccepted() {
            if (this.resource.TermsAndConditionsLink) {
                return this.acceptedTerms;
            }

            return true;
        },
        interval() {
            if (this.$store.state.space.ID === 'localhost:8080') {
                return 60;
            }
            if (
                this.$store.state.space.ID === 'c97c5a2253a56e8e9027881658c37c54a458bd1a2b5d59a81bacde6a52f42af5' &&
                this.resource.CobotID === '84c900fb6d352df1b1cbc63bac7a3979'
            ) {
                return 60;
            }
            if (
                this.$store.state.space.ID ===
                'hwMwnRdbGeXIido_GODOx_gqSSs4sMMoORTPY7dTJLz7UJCQ4NcwVTSpf0_yLuTiN0ivjTW57YxcjIFKsvRbsQ=='
            ) {
                return 60;
            }

            return 30;
        },

        // calculatePrice calculates price
        calculatePrice() {
            // keep day information, but update hours and minutes with choosen time from select
            if (!this.booking.Start || !this.booking.End) {
                return;
            }

            if (!this.resource) {
                return;
            }

            const diffTime = Math.abs(this.booking.End - this.booking.Start);
            const durationInMinutes = Math.ceil(diffTime / (1000 * 60));

            const min = this.resource.MinBookingDuration;
            const max = this.resource.MaxBookingDuration;
            const bookWholeSlot = this.resource.BookWholeSlot;

            if (min && min !== 0 && min > durationInMinutes && !bookWholeSlot) {
                this.tooShort = true;
                this.tooLong = false;
                const newEnd = add(this.booking.Start, { minutes: min });
                this.booking.End = newEnd;
                this.bookingEndString = format(newEnd, 'HH:mm');
                this.bookingEndOptions.push(format(newEnd, 'HH:mm'));
                this.bookingEndDate = format(newEnd, 'yyyy-MM-dd');
                return;
            }
            if (max && max !== 0 && max < durationInMinutes && !bookWholeSlot) {
                this.tooLong = true;
                this.tooShort = false;
                const newEnd = add(this.booking.Start, { minutes: max });
                this.booking.End = newEnd;
                this.bookingEndString = format(newEnd, 'HH:mm');
                this.bookingEndOptions.push(format(newEnd, 'HH:mm'));
                this.bookingEndDate = format(newEnd, 'yyyy-MM-dd');
                return;
            }

            // sort bookingEndOptions alphabetically
            this.bookingEndOptions.sort();

            // remove duplicates
            this.bookingEndOptions = this.bookingEndOptions.filter(
                (item, pos) => this.bookingEndOptions.indexOf(item) === pos
            );

            this.bookingEndSelectorKey += 1;

            // get unix time for start and end
            const start = Math.round(this.booking.Start.getTime() / 1000);
            const end = Math.round(this.booking.End.getTime() / 1000);
            // call price preview
            const req: bookingRequest = {
                start,
                end,
                resourceid: this.resource.Id,
                couponIDs: [],
            };
            if (this.coupon) {
                req.couponIDs.push(this.coupon.ID);
            }

            this.previewBookingPrice(req);
        },

        cancelPricePreviewRequests() {
            const keys = Object.keys(this.previewBookingPriceCancelTokens);

            keys.forEach((key) => {
                this.previewBookingPriceCancelTokens[key]('cancel price preview request due to new request');
            });
        },
        previewBookingPrice(req: bookingRequest) {
            this.bookingPriceLoading = true;
            this.bookingPrice = null;
            this.cancelPricePreviewRequests();
            // Create a new CancelToken
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();

            this.pad += 1;
            this.previewBookingPriceCancelTokens[this.pad] = source.cancel;

            axios({
                method: 'POST',
                url: `/booking/preview`,
                withCredentials: true,
                data: req,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.bookingPrice = response.data;
                    this.bookingPriceError = null;
                })
                .catch((error) => {
                    const message = get(error, 'response.data.message');
                    if (String(message).includes('failed to get tax rate')) {
                        this.bookingPriceError = 'Resource is missing tax rate. Please contact the team.';
                        return;
                    }
                    this.bookingPriceError =
                        'Could not calculate price. Please, make sure you have a valid membership and payment method.';
                })
                .finally(() => {
                    this.bookingPriceLoading = false;
                    // delete the cancel token from the map
                    delete this.previewBookingPriceCancelTokens[this.pad];
                });
        },

        // convert cents to euro/dollar/...
        convertPrice(price: number) {
            if (!price) {
                return this.$n(0, 'currency');
            }

            return this.$n(price / 100, 'currency');
        },

        closeBookingModal() {
            this.loading = false;
            this.cleanUp();
            this.$bvModal.hide('makebookingmodal');
        },
        show() {
            this.$bvModal.show('makebookingmodal');
            this.initialSetup = true;
            this.parseChoosenSlotData();
        },

        parseChoosenSlotData() {
            // initial setup on changed time slot data
            // set up the booking params

            const { start } = this;
            const { end } = this;

            this.bookingStartDate = format(start, 'yyyy-MM-dd');
            this.bookingEndDate = format(end, 'yyyy-MM-dd');

            this.bookingStartString = format(start, 'HH:mm');
            this.bookingEndString = format(end, 'HH:mm');

            this.booking.Start = this.start;
            this.booking.End = this.end;

            const interval = this.interval();
            // update From-Select. max begin time = slot end time - 15 mins

            this.bookingStartOptions = [this.bookingStartString];

            let loopHour = this.startTimeSlot.start;
            let diff = differenceInMinutes(this.startTimeSlot.end, loopHour);
            let str = format(this.startTimeSlot.start, 'HH:mm');
            const endOfSlot = add(this.endTimeSlot.end, { minutes: -interval });
            while (isBefore(loopHour, endOfSlot) && diff >= interval) {
                str = format(loopHour, 'HH:mm');
                this.bookingStartOptions.push(str);
                loopHour = add(loopHour, { minutes: interval });
                diff = differenceInMinutes(this.endTimeSlot.end, loopHour);
            }

            // sort bookingStartOptions alphabetically
            this.bookingStartOptions.sort();

            // remove duplicates
            this.bookingStartOptions = this.bookingStartOptions.filter(
                (item, pos) => this.bookingStartOptions.indexOf(item) === pos
            );
            // update To-Select options
            this.updateToSelectOptions();
        },
        updateToSelectOptions() {
            // clear booking End Options
            this.bookingEndOptions = [this.bookingEndString];

            // min booking time = start + 15 mins
            const interval = this.interval();

            let slotsStart = this.endTimeSlot.start;
            let slotsEnd = this.endTimeSlot.end;
            if (this.endDateHasBeenChanged) {
                // add 1 day to end time and set hours and minutes to 0
                slotsEnd = add(slotsEnd, { days: 1 });
                slotsEnd.setHours(0);
                slotsEnd.setMinutes(0);
                slotsEnd.setSeconds(0);

                slotsStart.setHours(0);
                slotsStart.setMinutes(0);
                slotsStart.setSeconds(0);
            } else {
                slotsStart = add(this.start, { minutes: interval });
            }

            let loopHour = slotsStart;
            // max booking time = slot end time
            while (isBefore(loopHour, slotsEnd)) {
                const str = format(loopHour, 'HH:mm');
                this.bookingEndOptions.push(str);
                loopHour = add(loopHour, { minutes: interval });
            }

            // sort bookingEndOptions alphabetically
            this.bookingEndOptions.sort();

            // remove duplicates
            this.bookingEndOptions = this.bookingEndOptions.filter(
                (item, pos) => this.bookingEndOptions.indexOf(item) === pos
            );
        },

        retry() {
            this.success = false;
            this.error = false;
            const el1 = this.$el.getElementsByClassName('circle-loader')[0];
            el1.classList.remove('load-complete');
            var el2 = this.$el.getElementsByClassName('checkmark')[0];
            el2.style.display = 'none';
            var el2 = this.$el.getElementsByClassName('xmark')[0];
            el2.style.display = 'none';

            this.confirm();
        },
        createBooking() {
            // start loader
            if (!this.bookingPrice || (this.bookingPrice && this.bookingPrice.ActiveMembershipRequired)) {
                const authWindow = window.open('', '_blank');
                this.addMembership(authWindow);
            } else if (this.bookingPrice && this.bookingPrice.PaymentMethodeRequired) {
                const authWindow = window.open('', '_blank');
                this.addPaymentMethod(authWindow);
            } else {
                this.confirm();
            }
        },

        confirm() {
            // start loader
            this.loading = true;

            const start = Math.round(this.booking.Start.getTime() / 1000);
            const end = Math.round(this.booking.End.getTime() / 1000);

            const booking = {
                From: start.toString(),
                To: end.toString(),
                ResourceID: this.resource.Id,
                Title: this.booking.Title,
                CouponIDs: [],
                Comment: this.booking.Comment,
            };

            if (this.coupon) {
                booking.CouponIDs = [this.coupon.ID];
            }

            const data = JSON.stringify(booking);

            axios({
                method: 'POST',
                url: '/booking/v2/create',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$emit('created', response.data);
                    setTimeout(() => {
                        this.error = false;
                        this.success = true;
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.error = true;
                        this.success = false;
                    }, 300);
                });
        },
        convertToPlain(html) {
            const tempDivElement = document.createElement('div');
            tempDivElement.innerHTML = html;
            return tempDivElement.textContent || tempDivElement.innerText || '';
        },
        makeCalendarLink(booking, type) {
            this.calendarEvent = {
                title: booking.Title,
                description: this.convertToPlain(this.resource.Name),
                start: new Date(booking.Start).toISOString(),
                end: new Date(booking.End).toISOString(),
            };

            // Then fetch the link
            if (type === 'google') {
                this.googleCalendarLink = google(this.calendarEvent);
                window.location.href = this.googleCalendarLink;
            } else if (type === 'outlook') {
                this.outlookCalendarLink = outlook(this.calendarEvent);
                window.location.href = this.outlookCalendarLink;
            } else if (type === 'ics') {
                this.icsCalendarLink = ics(this.calendarEvent);
                window.location.href = this.icsCalendarLink;
            }
        },
    },
};
