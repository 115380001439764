import { render, staticRenderFns } from "./EventCreate.vue?vue&type=template&id=78c6b0ed&scoped=true&"
import script from "./EventCreate.vue?vue&type=script&lang=ts&"
export * from "./EventCreate.vue?vue&type=script&lang=ts&"
import style0 from "./EventCreate.vue?vue&type=style&index=0&id=78c6b0ed&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c6b0ed",
  null
  
)

export default component.exports