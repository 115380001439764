





















import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoPrice from '@/components/Atoms/co-price/coPrice.vue';
import CoPill from '@/components/Atoms/co-pill/CoPill.vue';

interface EventTicket {
    ID?: string;
    Name: string;
    Description?: string;
    PriceInCents: number;
    TaxRateID?: string;
    Limit: number;
    CreatedAt?: number;
    UpdatedAt?: number;
    CancellationNotice?: number;
    CancellationFee?: number;
    TermsAndConditions?: string;
    Delete?: boolean;
}

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string;
}

export default {
    name: 'CoCardTicket',
    components: {
        CoIcon,
        CoPrice,
        CoCard,
        CoHeadline,
        CoPill,
    },
    props: {
        ticket: {
            type: Object as () => EventTicket,
            required: true,
        },
        taxRates: {
            type: Array as () => Array<any> | null,
            default: () => [],
        },
        currency: {
            type: String,
            required: true,
            default: '',
        },
        selected: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        iconName() {
            return this.selected ? 'circle-fill' : 'circle';
        },
        classes() {
            return {
                default: !this.ticket.SoldOut,
                'sold-out': this.ticket.SoldOut,
            };
        },
    },
    watch: {},
    data() {
        return {};
    },
    methods: {
        priceObject() {
            const priceObj: Price = {
                PriceInCents: this.ticket.PriceInCents,
                Currency: this.currency,
                TaxName: '',
                TaxRate: 0,
                Inclusive: false,
            };

            if (this.ticket.TaxRateID) {
                const taxRate = this.taxRates.find((tr) => tr.ID === this.ticket.TaxRateID);
                if (taxRate) {
                    priceObj.TaxRate = taxRate.Percentage;
                    priceObj.TaxName = taxRate.Name;
                    priceObj.Inclusive = taxRate.Inclusive;
                }
            }
            return priceObj;
        },

        click(event) {
            if (this.ticket.SoldOut) {
                return;
            }
            const r = JSON.parse(JSON.stringify(this.ticket));
            this.$emit('click', r);
        },
    },
};
