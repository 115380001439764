





























export default {
    name: 'CoDatePicker',
    props: {
        label: String,
        value: String,
        placeholder: String,
        readonly: Boolean,
        icon: String,
        minDate: String,
        maxDate: String,
        required: Boolean,
    },
    data() {
        return {
            content: this.value,
        };
    },
    methods: {
        handleInput(e) {
            this.content = e.target.value;
            this.$emit('input', e.target.value);
        },
        handleFocus(e) {
            this.$emit('focus');
        },
    },
};
