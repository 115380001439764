var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.invoice)?_c('coTable',{attrs:{"items":this.items,"sortable":false,"loading":this.loading,"showItemsCount":false,"limit":100,"preventHorizontalScroll":true,"columns":[
        {
            title: '',
            key: 'Description',
        },
        {
            title: _vm.$t('labels.tax'),
            key: 'TaxRates[0].Percentage',
            class: 'text-right d-none d-md-table-cell',
            style: 'width: 5rem',
        },
        {
            title: _vm.$t('labels.amount'),
            key: 'Amount',
            class: 'text-right',
            style: 'width: 9rem',
        } ]},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('CoHeadline',{attrs:{"level":3}},[_c('CoDate',{attrs:{"date":_vm.invoice && _vm.invoice.Created ? _vm.invoice.Created : null,"customFormat":"PP"}})],1)]},proxy:true},{key:"Amount",fn:function(slotProps){return [_c('co-price',{attrs:{"inherit-text-sizing":"","priceObj":{
                PriceInCents: slotProps.item['Amount'],
                Currency: _vm.invoice.Currency,
            }}})]}},{key:"TaxRates[0].Percentage",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.item.TaxRates && slotProps.item.TaxRates[0] && slotProps.item.TaxRates[0].Percentage ? slotProps.item.TaxRates[0].Percentage : '0')+" % ")]}},{key:"table-footer",fn:function(){return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('labels.pricesubtotal')))]),_c('td',{staticClass:"d-none d-md-table-cell"}),_c('td',{staticClass:"text-right"},[_c('CoPrice',{attrs:{"inheritTextSizing":true,"priceObj":{
                        PriceInCents: _vm.subtotal,
                        Currency: _vm.invoice.Currency,
                    }}})],1)]),_vm._l((_vm.tax),function(item,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(key)+" % "+_vm._s(_vm.$t('labels.tax'))+" ( "),_c('CoPrice',{attrs:{"inheritTextSizing":true,"priceObj":{
                        PriceInCents: item.netAmount,
                        Currency: _vm.invoice.Currency,
                    }}}),_vm._v(" ) ")],1),_c('td',{staticClass:"d-none d-md-table-cell"}),_c('td',{staticClass:"text-right"},[_c('CoPrice',{attrs:{"inheritTextSizing":true,"priceObj":{
                        PriceInCents: item.taxAmount,
                        Currency: _vm.invoice.Currency,
                    }}})],1)])}),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('labels.total')))]),_c('td',{staticClass:"d-none d-md-table-cell"}),_c('td',{staticClass:"text-right"},[_c('CoPrice',{attrs:{"priceObj":{
                        PriceInCents: _vm.invoice.Total,
                        Currency: _vm.invoice.Currency,
                    },"inheritTextSizing":true}})],1)])]},proxy:true}],null,false,3051189754)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }