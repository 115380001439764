<template>
    <div class="">
        <!-- header -->
        <CoCard variant="border" class="mb-3">
            <div class="d-flex align-items-center w-100">
                <CoHeadline class="" :level="2" trunc>{{ $t('labels.timepass') }}</CoHeadline>
                <CoButton
                    v-if="timepassesSpace && timepassesSpace.length != 0"
                    variant="primary"
                    :label="$t('labels.buy')"
                    @click.native="$refs.buyTimePasses.show()"
                    class="ml-auto"
                />
            </div>
        </CoCard>
        <!-- active time pass -->
        <div class="">
            <div v-if="activeTimepass && activeTimepass.WalletItemID" class="mt-4">
                <div class="mb-3">
                    <CoText class="ml-4">{{ $t('activetimepass') }}</CoText>
                    <CoMyTicket
                        :ticket="{
                            Name: activeTimepass.WalletItem.Name,
                            ID: activeTimepass.WalletItemID,
                            validUntil: activeTimepass.ValidUntil,
                        }"
                        :myName="$store.state.me.Profile.Name"
                        :title="
                            $t('labels.redeemed') +
                            ' ' +
                            $t('datetime', { date: activeTimepass.Timestamp, format: 'relative' })
                        "
                        :hideContextMenu="true"
                    />
                </div>
            </div>
            <!-- time pass wallet -->
            <div v-if="wallet && wallet.length != 0">
                <CoText class="ml-4">{{ $t('redeemablepasses') }}</CoText>
                <CoTable
                    :items="wallet"
                    :loading="loading"
                    :columns="[
                        { key: 'Name', title: $t('labels.name') },
                        { key: 'UsedNumber', title: $t('labels.redeemed') },
                        { key: 'Actions', style: 'text-align: right' },
                    ]"
                    :sortable="false"
                    :showItemsCount="false"
                    class="w-100 px-3"
                >
                    <template v-slot:UsedNumber="slotProps">
                        {{ slotProps.item.UsedNumber || 0 }} / {{ slotProps.item.Number }}
                    </template>
                    <template v-slot:Actions="slotProps">
                        <CoConfirmation
                            v-if="!activeTimepass || !activeTimepass.WalletItemID"
                            :message="$t('usetimepassconfirmation')"
                            :confirmLabel="$t('usetimepass')"
                            confirmVariant="primary"
                            @confirm="applyTimePassUser(slotProps.item['ID'])"
                        >
                            <CoLink class="float-right" styles="primary" :title="$t('usetimepass')" isExternalLink />
                        </CoConfirmation>
                        <CoButton v-else class="float-right" variant="primary" :label="$t('usetimepass')" disabled />
                    </template>
                </CoTable>
            </div>
            <!-- no time passes in wallet -->
            <CoText v-else class="ml-4">{{ $t('nopasses') }}</CoText>
            <!-- no time passes available to buy -->
            <CoText v-if="!timepassesSpace || timepassesSpace.length == 0" class="ml-4">{{
                $t('notimepassestobuy')
            }}</CoText>
        </div>
        <!-- buy time passes modal -->
        <CoModal
            v-if="timepassesSpace && timepassesSpace.length != 0"
            class="mt-4"
            ref="buyTimePasses"
            :title="$t('buytimepasses')"
            background="grey"
        >
            <template v-slot:body>
                <div class="" v-for="(timepass, index) in timepassesSpace" :key="timepass.ID">
                    <TimepassCard
                        class="w-100"
                        @click.native=""
                        @checkPayment="checkPaymentMethods(timepass.ID)"
                        @refreshWallet="updateWallet()"
                        :timepass="timepass"
                        :active="false"
                        :isAdminSpace="false"
                        :user="me"
                    ></TimepassCard>
                </div>
            </template>
        </CoModal>
        <div v-if="errorMsg && index == 0" class="d-flex align-items-center pt-4">
            <b-alert show variant="danger">{{ errorMsg }}</b-alert>
        </div>
    </div>
</template>

<script>
import i18n from 'vue-i18n';
import axios from 'axios';
import TimepassCard from '@/components/cards/TimepassCard.vue';
import TimepassCardWallet from '@/components/cards/TimepassCardWallet.vue';
import { format, fromUnixTime } from 'date-fns';
import { orderBy } from 'lodash';
import EventBus from '../../../eventBus';

import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoMyTicket from '@/components/Organisms/co-my-ticket/CoMyTicket.vue';
import CoTable from '@/components/Molecules/co-table/CoTable.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';

export default {
    name: 'TimepassOverview',
    components: {
        TimepassCardWallet,
        TimepassCard,
        CoAlert,
        CoModal,
        CoTable,
        CoText,
        CoButton,
        CoLink,
        CoConfirmation,
    },
    i18n: {
        messages: {
            en: {
                activetimepass: 'Active time pass',
                redeemablepasses: 'Redeemable time passes',
                nopasses: 'No time passes in your wallet',
                noactivepasses: 'No active time pass',
                buytimepasses: 'Buy time passes',
                notimepassestobuy: 'There are currently no time passes available for purchase.',
                usetimepass: 'Use now',
                usetimepassconfirmation:
                    'If you proceed this pass will be activated immediately and redeemed. Are you sure?',
            },
            de: {
                activetimepass: 'Aktiver Zeitpass',
                redeemablepasses: 'Einlösbare Zeitpässe',
                nopasses: 'Du hast derzeit keine einlösbaren Zeitpässe in deinem Wallet.',
                noactivepasses: 'Kein aktiver Zeitpass',
                buytimepasses: 'Zeitpässe kaufen',
                notimepassestobuy: 'Derzeit sind keine Zeitpässe zum Kauf verfügbar.',
                usetimepass: 'Jetzt einlösen',
                usetimepassconfirmation:
                    'Wenn du fortfährst wird dieser Pass sofort aktiviert und entwertet. Bist du sicher?',
            },
        },
    },
    data() {
        return {
            me: this.$store.state.me,
            paymentMethods: [],
            errorMsg: '',
            isAdmin: false,
            loading: false,
            wallet: [],
            timepassesSpace: [],
            activeTimepass: {},
        };
    },
    mounted() {
        this.getTimePassesWallet();
        this.getTimepassesSpace();
        this.getActiveTimepass();
    },
    methods: {
        getTimePassesWallet() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/user/time-pass/wallet`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.wallet = orderBy(response.data.WalletItems, 'CreatedAt', 'desc');
                    } else {
                        this.wallet = [];
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.wallet = [];
                    this.loading = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to get time passes from wallet',
                        Details: '',
                    });
                });
        },
        getTimepassesSpace() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/space/time-passes/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        response.data.Passes.forEach((element) => {
                            this.timepassesSpace.push(element);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        updateWallet() {
            this.getTimePassesWallet();
            this.getActiveTimepass();
            this.$refs.buyTimePasses.hide(); // close modal
        },

        checkPaymentMethods(ID) {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                    if (this.paymentMethods && this.paymentMethods.length === 0) {
                        this.$router.push(`/account/time-passes/buy/${ID}`);
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    this.$router.push(`/account/time-passes/buy/${ID}`);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    }
                });
        },
        getActiveTimepass() {
            axios({
                method: 'GET',
                url: '/user/time-pass/acitve',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.activeTimepass = response.data;
                        this.activeTimepass.appliedTime = format(
                            fromUnixTime(this.activeTimepass.Timestamp),
                            'dd.MM.yyyy HH:mm'
                        );
                        this.activeTimepass.ValidUntillTime = format(
                            fromUnixTime(this.activeTimepass.ValidUntil),
                            'dd.MM.yyyy HH:mm'
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        applyTimePassUser(ID) {
            this.loading = true;
            this.applyTimepassRequest = {
                WalletItemID: ID,
                SpaceID: this.$store.state.spaceID,
                ForUser: this.$store.state.me.ID,
                UserID: this.$store.state.me.ID,
                ByUser: this.$store.state.me.ID,
            };
            axios({
                method: 'POST',
                url: 'user/time-pass/apply',
                withCredentials: true,
                data: JSON.stringify(this.applyTimepassRequest),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Successfully applied Time Pass',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    // this.$router.push(`/account/time-passes`);
                    this.$router.go(0);
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to apply Time Pass',
                        Details: error.response.data,
                    };
                    if (error.response.data.message === 'user has active time pass') {
                        msg.Message = 'You already have an active Time Pass';
                    }
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
        },
    },
};
</script>
