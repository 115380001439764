<style lang="less" scoped></style>

<template>
    <div>
        <!-- header start -->
        <CoCard variant="border" class="mb-3">
            <div class="d-flex align-items-center w-100">
                <CoHeadline class="" :level="2" trunc>{{ $t('labels.planchange') }}</CoHeadline>
            </div>
        </CoCard>
        <!-- header end -->
        <div>
            <transition name="fade">
                <!-- not plans message -->
                <div v-if="!loading && (!plans || plans.length === 0)">
                    <div class="d-flex align-items-center justify-content-center pt-3">
                        <CoText> {{ $t('messages.noplanavailable') }}</CoText>
                    </div>
                </div>

                <!-- Plans list -->
                <div v-if="plans && plans.length > 0">
                    <div v-for="plan in plans" :key="plan.ID" class="mb-3">
                        <CoCardPlan :plan="plan" clickable show-image @click="selectPlan(plan.ID)"></CoCardPlan>
                    </div>
                </div>

                <!-- skeleton for loading state -->
                <div v-if="this.loading">
                    <CoCard v-for="index in 4" class="pointer mb-3" :key="index">
                        <div class="d-flex align-items-center">
                            <CoSkeleton variant="thumbnail" class="mr-2" />
                            <CoSkeleton variant="text" :width="randomPercents(45, 65)" />
                        </div>
                        <div>
                            <CoSkeleton variant="text" :width="randomPercents(60, 75)" class="mb-0" />
                        </div>
                    </CoCard>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import i18n from 'vue-i18n';
import CoCardPlan from '@/components/Molecules/co-card-plan/CoCardPlan.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';

export default {
    name: 'ChangePlan',
    components: {
        CoCardPlan,
        CoText,
        CoSkeleton,
        CoCard,
        CoHeadline,
    },
    data() {
        return {
            plans: [],
            loading: false,
            errorMsg: null,
            paymentMethods: [],
        };
    },
    created() {
        this.listPlans();
    },
    methods: {
        randomPercents(from = 0, to = 100) {
            const percents = Math.floor(Math.random() * (to - from + 1) + from);
            return `${percents}%`;
        },
        selectPlan(id) {
            this.$router.push(`/account/plan/change/${id}`);
        },
        listPlans() {
            this.loading = true;
            axios({
                method: 'GET',
                url: 'space/plan/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        setTimeout(() => {
                            this.plans = response.data;
                        }, 500);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                });
        },
    },
};
</script>
