


















































































































import i18n from 'vue-i18n';

import axios from 'axios';
import { get, uniq, map, uniqBy, upperFirst, toLower, clone, sum } from 'lodash';
import JSConfetti from 'js-confetti';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';

const jsConfetti = new JSConfetti();

export default {
    name: 'ViewEventParticipants',
    i18n: {
        messages: {
            en: {
                labels: {
                    ticketssold: 'Tickets Sold',
                    sum: 'Sum',
                    updateEvent: 'Update Event Details',
                    drafteventheadline: 'This event is in a draft mode',
                    eventVisability: 'Visibility',
                    publiceventheadline: 'This event is public and visible to everyone',
                    communityeventheadline: 'This event is visible to community members only',
                    draft: 'Draft',
                    public: 'Public',
                    communityOnly: 'Community Only',
                    closePreviewAsMember: 'Close Preview',
                    previewAsMember: 'Preview as Member',
                    addsection: 'Add Section',
                    textSection: 'Text Section',
                    imageSection: 'Image Section',
                    cancelattendance: 'Cancel attendance',
                    addaddress: 'Add a new address',
                    ticketrequiredwarning: 'This event requires a ticket.',
                    getticket: 'Get Ticket',
                    google: 'Google',
                    outlook: 'Outlook',
                    ics: 'ICS',
                    dragdropimage: 'Drag an image here or click to select.',
                    suggestedsizes: 'Suggested size 1280x720px (16:9)',
                    savetocalendar: 'save to calendar',
                    noupdatesyet: 'Be the first to post something',
                    posts: 'Posts',
                },

                messages: {
                    cancelmessage: 'Are you sure about this? This event would be much nicer with you!',
                    makePublic: 'Event will be visible to everyone outside the community.',
                    communityOnly: 'Event will be visible only to community members.',
                    deleteImageHeaderWarning: 'Are you sure you want to delete the header image?',
                    draftEvent: 'Event will be saved as a draft making it hidden.',
                    eventdeleted: 'Event has been deleted.',
                    noupdatesyet: 'Be the first to post something',
                },
            },
            de: {
                labels: {
                    sum: 'Summe',
                    ticketssold: 'Verkaufte Tickets',
                    addaddress: 'Neue Adresse hinzufügen',
                    updateEvent: 'Veranstaltungsdetails aktualisieren',
                    drafteventheadline: 'Dieses Event ist im Entwurfsmodus',
                    eventVisability: 'Sichtbarkeit',
                    publiceventheadline: 'Dieses Event ist öffentlich und für alle sichtbar',
                    communityeventheadline: 'Dieses Event ist nur für Community-Mitglieder sichtbar',
                    draft: 'Entwurf',
                    public: 'Öffentlich',
                    communityOnly: 'Nur für die Community',
                    closePreviewAsMember: 'Vorschau schließen',
                    previewAsMember: 'Vorschau als Mitglied',
                    addsection: 'Abschnitt hinzufügen',
                    textSection: 'Textabschnitt',
                    imageSection: 'Bildabschnitt',
                    cancelattendance: 'Teilnahme absagen',
                    ticketrequiredwarning: 'Für diese Veranstaltung ist ein Ticket erforderlich.',
                    getticket: 'Tickets',

                    dragdropimage: 'Ziehe ein Bild hierher oder klicke zum Auswählen',
                    suggestedsizes: 'Empfohlene Größe 1280x720px (16:9)',
                    savetocalendar: 'in Kalender speichern',
                    noupdatesyet: 'Sei die erste Person, die etwas postet',
                    posts: 'Beiträge',
                },

                messages: {
                    cancelmessage:
                        'Bist du dir sicher, dass du das willst? Diese @:labels.event wäre viel schöner mit dir!',
                    makePublic: 'Die Veranstaltung wird für alle außerhalb der Community sichtbar.',
                    communityOnly: 'Die Veranstaltung wird nur für Community-Mitglieder sichtbar.',
                    deleteImageHeaderWarning: 'Möchtest du das Titelbild wirklich löschen?',
                    draftEvent: 'Die Veranstaltung wird als Entwurf gespeichert und versteckt.',
                    eventdeleted: 'Die Veranstaltung wurde gelöscht.',
                    noupdatesyet: 'Sei die erste Person, die etwas postet',
                },
            },
        },
    },
    components: {
        CoRoundButton,
        CoHeadline,
        CoCard,
        CoInput,
        CoLink,
    },
    props: {
        slug: {
            type: String,
            required: false,
            default: 'test',
        },
        me: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        eventTicketsOn: {
            type: Boolean,
            required: false,
            default: false,
        },
        isPaymentsEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            event: {},
            createdBy: {},

            loadingParticipants: false,
            participants: [],

            loadingTaxRates: false,
            taxRates: [],

            currency: this.$store.state.space.Currency,
            searchInput: '',
        };
    },
    watch: {},
    computed: {
        // if search input is empty, return all participants
        // if search input is not empty, return filtered participants by name or ticketcode
        participantsFiltered() {
            const tmp = this.participants.map((participant) => {
                if (!participant.PriceInCents) {
                    return {
                        ...participant,
                        Price: `0.00 ${this.currencyChar}`,
                    };
                }
                return {
                    ...participant,
                    Price: `${(participant.PriceInCents / 100).toFixed(2)} ${this.currencyChar}`,
                };
            });

            if (!this.searchInput) {
                return tmp;
            }

            return tmp.filter(
                (participant) =>
                    participant.UserName.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                    participant.ID.toLowerCase().includes(this.searchInput.toLowerCase())
            );
        },
        currencyChar() {
            if (!this.currency) {
                return '€';
            }

            const currency = this.currency.toUpperCase();

            if (currency === 'EUR') {
                return '€';
            }
            if (currency === 'USD') {
                return '$';
            }

            return currency;
        },
        canIEdit() {
            return (this.amIorganising || this.amIAdmin) && !this.preview && this.event.SpaceID === this.me.SpaceID;
        },
        amIorganising() {
            return this.event.Organizers && this.event.Organizers.some((o) => o === this.me.ID);
        },
        amIAdmin() {
            return get(this.me, 'Permissions', '').includes('space_admin');
        },
        ticketsSold() {
            let ticketsSold = clone(this.event.Tickets);
            if (!ticketsSold) {
                return [];
            }

            // add tax rate to tickets
            ticketsSold = ticketsSold.map((ticket) => {
                if (!ticket.SoldCount) {
                    ticket.SoldCount = 0;
                }

                if (!ticket.PriceInCents) {
                    ticket.PriceInCents = 0;
                }
                ticket.TaxRate = this.getTaxRateForTicket(ticket);
                ticket.Price = `${(ticket.PriceInCents / 100).toFixed(2)} ${this.currencyChar}`;
                ticket.TotalPrice = `${((ticket.PriceInCents * ticket.SoldCount) / 100).toFixed(2)} ${
                    this.currencyChar
                }`;
                return ticket;
            });

            return ticketsSold;
        },
    },
    created() {
        if (!this.amIAdmin) {
            this.$router.push(`/events/${this.slug}`);
        }

        this.getEvent();

        if (this.amIAdmin) {
            this.getTaxRates();
        }
    },

    methods: {
        downloadCsv() {
            let tableData = this.$refs.participantstable?.getTableData();
            if (!tableData || tableData.length === 0) return;
            this.$refs.csvDownloader?.exportAsCSV(tableData);
        },
        backtToEvent() {
            this.$router.push(`/events/${this.event.Slug}`);
        },

        getTaxRateForTicket(ticket) {
            const taxrate = this.taxRates.find((taxrate) => taxrate.ID === ticket.TaxRateID);

            if (!taxrate) {
                return '-';
            }

            if (!taxrate.Percentage) {
                return '0%';
            }

            return `${taxrate.Percentage}%`;
        },

        isEnabled(feature) {
            if (!this.$unleash) {
                console.error('Unleash not available');
                return false;
            }
            return this.$unleash.isEnabled(feature);
        },

        get,
        map,
        upperFirst,
        toLower,
        async getEvent() {
            this.loadingEvent = true;
            try {
                const response = await axios.get(`/event/by-slug/${this.slug}`);
                this.event = response.data;
                this.getParticipants();
            } catch (error) {
                console.error(error);
            }
            this.loadingEvent = false;
        },

        async getParticipants() {
            this.loadingParticipants = true;

            try {
                const result = await axios({
                    method: 'GET',
                    url: `/event/participant/list/for-event-id/${this.event.ID}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                this.participants = get(result, 'data.Participants', []);

                this.loadingParticipants = false;
            } catch (error) {
                console.log(error);
                this.loadingParticipants = false;
            }
        },

        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/space/tax/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
    },
};
