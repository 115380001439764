

























import Bugsnag from '@bugsnag/js';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoText from '../../Atoms/co-text/CoText.vue';

export default {
    name: 'CoDropdownItem',
    components: {
        CoText,
        CoIcon,
    },
    props: {
        to: {
            type: String,
            default: '',
            required: false,
        },
        href: {
            type: String,
            default: '',
            required: false,
        },
        target: {
            type: String,
            default: '_blank',
            required: false,
            validator: (value: string) => ['_blank', '_self', '_parent', '_top'].includes(value),
        },
        title: {
            type: String,
            default: '',
            required: false,
        },
        icon: {
            type: String,
            default: '',
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        divider: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        // routerPush function that wraps the router push function and catches errors
        // it will ignore the error if the error is a NavigationDuplicated error or Redundant Navigation error and will not log it to bugsnag
        // this is because the error is thrown when the user tries to navigate to the same route they are currently on and is not a real error
        routerPush(route) {
            this.$router.push(route).catch((error) => {
                if (
                    error.name !== 'NavigationDuplicated' &&
                    error.name !== 'NavigationCancelled' &&
                    error.name !== 'Redundant Navigation'
                ) {
                    Bugsnag.notify(error);
                }
            });
        },
        clicked() {
            if (this.disabled || this.divider) return;
            this.$emit('click');

            if (this.to) {
                this.routerPush(this.to);
            }
        },
    },
};
