



























import CoModal from '../co-modal/CoModal.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';
import CoPrice from '../../Atoms/co-price/coPrice.vue';

export default {
    name: 'CoBookingDetailView',
    components: {
        CoModal,
        CoHeadline,
        CoIcon,
        CoInput,
        CoPrice,
    },
    props: {
        booking: {
            type: Object,
            default() {
                return {};
            },
        },
        resource: {
            type: Object,
            default() {
                return {};
            },
        },
        loading: Boolean,
    },
    methods: {
        discard() {
            this.$refs.bookingDetailModal.discard();
        },
        show() {
            this.$refs.bookingDetailModal.show();
        },
        hide() {
            this.$refs.bookingDetailModal.hide();
        },
    },
};
