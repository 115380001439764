<template>
    <div class="co drop-down-list">
        <div class="scroll-list">
            <ul>
                <li v-for="item in items" :key="item.ID" @mousedown.capture.stop="selectToken(item)">
                    {{ item.ID.substring(0, 16) }}... -
                    {{ formatTimestamp(item.CreatedAt) }}
                </li>
            </ul>
        </div>
    </div>
</template>
<style lang="less" scoped>
@import '../../../assets/less/variables.less';
@import './CoDropdownList.less';
</style>
<script>
import '../../../assets/less/variables.less';
import moment from 'moment';
export default {
    name: 'CoDropdownListTokens',
    props: {
        items: Array, //
    },
    methods: {
        formatTimestamp(timestamp) {
            if (!timestamp) return 'n/a';
            return moment.unix(timestamp).format('DD/MM/YYYY HH:mm');
        },
        selectToken(token) {
            token.DisplayName = token.ID.substring(0, 16) + '... - ' + this.formatTimestamp(token.CreatedAt);
            this.$emit('itemClicked', token);
        },
    },
};
</script>
