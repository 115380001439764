

















































































import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';

export default {
    name: 'CoMenuItem',
    components: {
        CoIcon,
        CoText,
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        secondIcon: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: 'Home',
        },
        to: {
            type: String,
            default: '/',
        },
        subitems: {
            type: Array,
            default: () => [],
            required: false,
        },
        openInNewTab: {
            type: Boolean,
            default: false,
            required: false,
        },
        variant: {
            type: String,
            default: '',
            validator(val) {
                return ['', 'lila'].indexOf(val) !== -1;
            },
        },
    },
    data() {
        return {
            iconName: '',
            loading: false,
            expand: true, // show subitems
            rerender: 0,
        };
    },

    watch: {
        expand(val) {
            this.iconName = val ? 'chevron-up' : 'chevron-down';
            this.rerender += 1;
        },
    },
    computed: {
        classes() {
            return {
                [`${this.variant}`]: true,
            };
        },
    },

    mounted() {
        this.iconName = this.icon;
    },
    methods: {
        clickOnSubItem(item) {
            this.$router.push({
                name: item.routeName,
                params: item.params,
            });
        },
        clickOnItem() {
            if (this.subitems && this.subitems.length > 0) {
                this.expand = !this.expand;
            } else if (this.openInNewTab && this.to) {
                window.open(this.to, '_blank');
            } else if (this.to && /\./.test(this.to)) {
                window.location.assign(this.to);
            } else {
                this.$router.push(this.to);
            }
            this.$emit('click');
        },
    },
};
