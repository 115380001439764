<template>
    <div v-if="authMethods.length > 0" class="mt-3 auth-method-section border-top w-100 pt-3">
        <div
            v-for="authMethod in authMethods"
            class="d-flex align-items-center pr-4 mb-2 auth-method"
            :style="bgColorStyle"
            :key="authMethod.ID"
            @click="redirectToAuth(authMethod.ID)"
        >
            <!-- image as base64 -->

            <img v-if="authMethod.Icon" :src="authMethod.Icon" width="36" height="36" class="p-2" />
            <img v-else :src="getImageSrc(authMethod.Provider)" width="36" height="36" class="" />
            <span class="d-block ml-2">
                {{ isLogin ? authMethod.LoginCTATitle : authMethod.SignUpCTATitle }}
            </span>
        </div>
    </div>
</template>

<script>
import { defaultTo } from 'lodash';

export default {
    name: 'OA2Providers',
    props: {
        isLogin: {
            type: Boolean,
        },
        paddingLeft: {
            type: String,
        },
        paddingRight: {
            type: String,
        },
        backGroundColor: {
            type: String,
        },
    },
    data() {
        return {
            authMethods: [],
        };
    },
    mounted() {
        this.listAuthMethods();
    },
    methods: {
        getImageSrc(provider) {
            // try to get the image from the assets folder
            // if not found, return the default image
            try {
                return require(`../../assets/auth-methods/${provider}.svg`);
            } catch (e) {
                return require(`@/assets/picture-placeholder.png`);
            }
        },
        listAuthMethods() {
            this.$store
                .dispatch('fetchAuthMethods')
                .then((methods) => {
                    this.authMethods = defaultTo(methods, []);
                    if (this.authMethods.length > 0) {
                        this.$emit('haveAuthMethods', true);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        redirectToAuth(id) {
            this.$store
                .dispatch('getAuthMethodURL', id)
                .then((url) => {
                    window.location.href = url;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
    computed: {
        bgColorStyle() {
            return `background-color: ${this.backGroundColor};`;
        },
    },
};
</script>

<style scoped>
.auth-method-section {
}

.auth-method {
    border-radius: 2em;
    cursor: pointer;
}
</style>
