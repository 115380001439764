




























import Vue from 'vue';
import CoTopNavbarPublic from '@/components/Organisms/co-top-nav-bar-public/CoTopNavBarPublic.vue';
import CoFooterPublic from '@/components/Organisms/co-footer-public/CoFooterPublic.vue';
import Bugsnag from '@bugsnag/js';

export default {
    name: 'MainPublic',
    components: {
        CoTopNavbarPublic,
        CoFooterPublic,
    },
    data() {
        return {
            showSignup: false,
            signUpStrategy: {},
            inviteOnly: false,
        };
    },
    computed: {
        cobranded() {
            if (!this.$store) return false;
            if (!this.$store.state.space) return false;

            if (this.$store.state.space.PlanSlug === 'free') {
                return true;
            }
            return false;
        },
        hideSignup() {
            if (this.inviteOnly) {
                return true;
            }

            if (!this.showSignup) {
                return true;
            }

            return false;
        },
        // get space name from store
        // Don't user lodash get here, it doesn't work with reactive data
        spaceName() {
            if (!this.$store) return '';
            return this.$store.state.space.Name;
        },
        // Don't user lodash get here, it doesn't work with reactive data
        logoURL() {
            if (!this.$store) return '';
            return this.$store.state.space.LogoURL;
        },
        // Don't user lodash get here, it doesn't work with reactive data
        path() {
            return this.$route.path;
        },
    },
    created() {
        this.$unleash.on('update', () => {
            if (this.$unleash.isEnabled('frontend.login.hide-signup-link')) {
                this.showSignup = false;
            } else {
                this.showSignup = true;
            }
        });
        if (this.$unleash.isEnabled('frontend.login.hide-signup-link')) {
            this.showSignup = false;
        } else {
            this.showSignup = true;
        }
        this.getSignUpStrategy();
    },

    methods: {
        getSignUpStrategy() {
            this.$store
                .dispatch('getSignupSettings')
                .then((response) => {
                    if (response && response.data) {
                        this.signUpStrategy = response.data;

                        if (this.signUpStrategy.signUpStrategy === 'invite-only') {
                            this.inviteOnly = true;
                        }
                    }
                })
                .catch((error) => {
                    Bugsnag.notify(error);
                });
        },
    },
};
