











































































































































































































































































import axios from 'axios';
import { get, unescape, isEqual } from 'lodash';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoImage from '@/components/Atoms/co-image/CoImage.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoSlider from '@/components/Molecules/co-slider/CoSlider.vue';
import CoEditor from '@/components/Molecules/co-editor/CoEditor.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';
import CoDragDropFileUpload from '@/components/Organisms/co-drag-drop-file-upload/CoDragDropFileUpload.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import EventBus from '@/eventBus';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoCheckbox from '@/components/Atoms/co-checkbox/CoCheckbox.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoLabel from '@/components/Atoms/co-label/CoLabel.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';

export default {
    name: 'CoContentSection',
    components: {
        CoCard,
        CoImage,
        CoRoundButton,
        CoSlider,
        CoEditor,
        CoButton,
        CoConfirmation,
        CoDragDropFileUpload,
        CoThumbnail,
        CoDropdown,
        CoDropdownItem,
        CoAlert,
        CoLabel,
        CoCheckbox,
        CoIcon,
        CoInput,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        canIEdit: {
            type: Boolean,
            default: false,
        },
        disableMoveUp: {
            type: Boolean,
            default: false,
        },
        disableMoveDown: {
            type: Boolean,
            default: false,
        },
        me: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isEditMode: false,
            content: {},
            originalContent: {},

            candidateImages: [], // {preview, file}
            loading: false,
            canIsave: false,
            meLocal: this.me || this.$store?.state?.me,
            //code options utility
            aspectratio: this.value?.Options?.aspectratio || 'landscape',
            backgroundcolor: this.value?.Options?.backgroundcolor || '#ffffff',
        };
    },
    watch: {
        value: {
            handler() {
                this.content = this.value;
                if (this.content?.StartEditMode) {
                    this.isEditMode = true;
                }
            },
            deep: true,
        },
        content: {
            handler() {
                this.canISave = !isEqual(this.content, this.originalContent);
                if (this.content && this.content.Type === 1) {
                    this.canISave = this.candidateImages.length > 0 || this.content.Images.length > 0;
                }
            },
            deep: true,
        },
        candidateImages: {
            handler() {
                if (this.content && this.content.Type === 1) {
                    this.canISave = this.candidateImages.length > 0 || this.content.Images.length > 0;
                }
            },
            deep: true,
        },
        aspectratio(val) {
            if (this.content) {
                this.content.Options = {
                    ...this.content?.Options,
                    aspectratio: val,
                };
                this.canISave = true;
            }
        },
        backgroundcolor(val) {
            if (this.content) {
                this.content.Options = {
                    ...this.content?.Options,
                    backgroundcolor: val,
                };
                this.canISave = true;
            }
        },
    },
    computed: {
        amIAdmin() {
            return get(this.meLocal, 'Permissions', '').includes('space_admin');
        },
    },
    created() {
        this.content = this.value;
        if (this.content?.StartEditMode) {
            this.isEditMode = true;
        }
    },
    mounted() {
        this.originalContent = this.value ? JSON.parse(JSON.stringify(this.value)) : null;
    },
    methods: {
        get,
        unescape,
        removeImage(index: number, cadidate = false) {
            if (cadidate) {
                this.candidateImages.splice(index, 1);
                return;
            }
            this.content.Images.splice(index, 1);
        },
        async save() {
            this.loading = true;
            if (this.content.Type === 1) {
                const imageUpload = await this.uploadFiles().catch((error) => {
                    console.log(error);
                    const errorMsg = {
                        Message: this.$t('messages.imageUploadError'),
                        Details: '',
                    };
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        errorMsg.Message = this.$t('messages.imageUploadSizeError');
                    }
                    EventBus.$emit('ERROR', errorMsg);
                });
                const imageURLs = imageUpload ? imageUpload.map((response) => response.data.url) : [];
                if (this.content.Images && this.content.Images.length > 0) {
                    this.content.Images = this.content.Images.concat(imageURLs);
                } else {
                    this.content.Images = imageURLs;
                }
                this.loading = false;
            }

            this.isEditMode = false;
            if (this.content.StartEditMode) {
                this.content.StartEditMode = false;
            }
            this.$emit('save', this.content);
        },
        discardChanges() {
            this.isEditMode = false;
            //restore original content
            this.content = this.originalContent;
            this.aspectratio = this.originalContent?.Options?.aspectratio || 'landscape';
            this.backgroundcolor = this.originalContent?.Options?.backgroundcolor || null;
            if (!this.content.Content && get(this.content, 'Images', []).length === 0 && this.content.StartEditMode) {
                this.deleteSection(this.content);
            }
        },
        editSection(section: any) {
            this.isEditMode = true;
        },
        deleteSection(section: any) {
            this.$emit('delete', section);
        },
        moveSectionDown(section: any) {
            this.$emit('down', section);
        },
        moveSectionUp(section: any) {
            this.$emit('up', section);
        },
        createSectionBelow(type: string) {
            this.$emit('add', type);
        },
        handleFileSelect(file: any) {
            this.candidateImages.push(file);
        },

        uploadFiles() {
            return new Promise((resolve, reject) => {
                // return null if no files
                if (!this.candidateImages || !Array.isArray(this.candidateImages) || this.candidateImages.length === 0)
                    resolve(null);

                // upload all images and return the urls
                const promises = this.candidateImages.map((file) => {
                    // only upload if a file is present

                    const data = new FormData();
                    data.append('file', file.file);
                    return axios.post('/upload/image/content-section', data, {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'image/*',
                        },
                    });
                });

                Promise.all(promises)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
    },
};
