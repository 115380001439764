











































































import axios from 'axios';
import EventBus from '../../../eventBus';

import { get, uniq, orderBy } from 'lodash';
import { isValid, startOfWeek, endOfWeek, format } from 'date-fns';
import CoTable from '../../Molecules/co-table/CoTable.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoSelect from '../../Molecules/co-select/CoSelect.vue';
import CoDownloadAsCsv from '../co-download-as-csv/CoDownloadAsCsv.vue';
import CoDate from '../../Molecules/co-date/CoDate.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoDateRangePicker from '@/components/Atoms/co-date-range-picker/CoDateRangePicker.vue';

export default {
    name: 'CoBookingStatistics',
    components: {
        CoTable,
        CoIcon,
        CoDownloadAsCsv,
        CoSelect,
        CoDate,
        CoDateRangePicker,
        CoLink,
    },
    data() {
        return {
            fields: [
                {
                    key: 'Resource.Name',
                    title: 'Resource Name',
                    sortable: true,
                },
                {
                    key: 'FromDate',
                    title: 'Booking Start',
                    sortable: true,
                },
                {
                    key: 'Title',
                    title: 'Title',
                    sortable: true,
                },
                {
                    key: 'Duration',
                    title: 'Duration (h)',
                    sortable: true,
                    sum: true,
                    class: 'text-right',
                },
                {
                    key: 'PriceNet',
                    title: 'Price (net)',
                    sortable: true,
                    sum: true,
                    class: 'text-right',
                },
                {
                    key: 'CreatedDate',
                    title: 'Created',
                    sortable: true,
                },
                {
                    key: 'User.Profile.Name',
                    title: 'User',
                    sortable: true,
                },
            ],

            bookings: [],
            bookingsLoading: false,
            bookingsError: false,

            resourcesList: [],
            resourcesLoading: false,
            resource: null,
            dateRange: {
                startDate: startOfWeek(new Date()),
                endDate: endOfWeek(new Date()),
            },
        };
    },
    async mounted() {
        this.bookingsLoading = true;
        //fetch resources
        await this.listResources();
        //fetch bookings
        this.listBookings();
    },
    watch: {
        dateRange: {
            handler() {
                this.listBookings();
            },
            deep: true,
        },
        resource() {
            this.listBookings();
        },
    },
    methods: {
        get,
        listResources() {
            this.resourcesLoading = true;
            var resources = [];

            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/admin/booking/resource/list',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (response.data && response.data.Resources.length != 0) {
                            resources = response.data.Resources.map((item) => {
                                return {
                                    Name: item.Name,
                                    Value: item.ID,
                                };
                            });

                            resources.sort((a, b) => a.Name.localeCompare(b.Name));
                        }
                        //always add the default value to the select as first item
                        this.resourcesList = [
                            {
                                Name: 'All resources',
                                Value: null,
                            },
                        ].concat(resources);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        resolve(true);
                        this.resourcesLoading = false;
                    });
            });
        },
        listBookings() {
            this.bookingsLoading = true;
            this.bookingsError = false;

            let start = format(this.dateRange?.startDate, 't');
            let end = format(this.dateRange?.endDate, 't');

            var url = `/admin/booking/list?start=${start}&end=${end}`;

            if (this.resource?.Value) {
                url = `/admin/booking/list/${this.resource?.Value}/${start}/${end}`;
            }
            axios({
                method: 'GET',
                url: url,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response && response.data && response.data.Bookings) {
                        //fetch user info for each booking
                        let userIDs = [];
                        response.data.Bookings.map((item) => {
                            if (item.UserID) userIDs.push(item.UserID);
                        });
                        let userData =
                            userIDs.length > 0
                                ? await this.$store.dispatch('listUsersByIDs', {
                                      ids: { IDS: uniq(userIDs).map((id) => ({ ID: id })) },
                                  })
                                : { Users: [] };
                        //map the booking data
                        let bookings = response.data.Bookings.map((item) => {
                            item.PriceInCentsNet ? (item.PriceNet = item.PriceInCentsNet / 100).toFixed(2) : null;
                            item.CreatedDate = isValid(item.CreatedAt * 1000)
                                ? new Date(item.CreatedAt * 1000).toISOString()
                                : null;
                            item.FromDate = isValid(item.Start * 1000)
                                ? new Date(item.Start * 1000).toISOString()
                                : null;
                            item.ToDate = isValid(item.End * 1000) ? new Date(item.End * 1000).toISOString() : null;
                            item.Duration = item.Start && item.End ? (item.End - item.Start) / 60 / 60 : null;
                            item.User = userData.Users.find((user) => user.ID === item.UserID);
                            return item;
                        });
                        this.bookings = orderBy(bookings, ['Start'], ['asc']);
                    } else {
                        this.bookings = [];
                    }
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: get(error, 'response.data.message', 'An errror occured'),
                        Details: error,
                    });
                    this.bookings = [];
                    this.bookingsError = true;
                })
                .finally(() => {
                    this.bookingsLoading = false;
                });
        },
    },
};
