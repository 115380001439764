<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <CoCard
            v-if="$store.state.limits['MaxAdmins'] && $store.state.limits['MaxAdmins'] <= objects.length && !loading"
            class="mb-3"
            variant="error"
        >
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center justify-content-between">
                    <CoHeadline :level="3" class="">{{ $t('labels.limitUsersReached') }}</CoHeadline>
                </div>
                <div class="d-flex flex-column mt-3 pt-2">
                    <CoText class="mb-3">
                        {{ $t('messages.limitAdminsReached') }}
                    </CoText>
                    <CoButton class="align-self-end" variant="black" @click="$router.push('/admin/account')">
                        {{ $t('labels.upgradeSubscription') }}
                    </CoButton>
                </div>
            </div>
        </CoCard>

        <div class="d-flex align-items-center justify-content-between">
            <CoInput type="search" icon="search" placeholder="Search" v-model="searchInput" />
            <CoConfirmation
                :confirmLabel="$t('labels.save')"
                :message="$t('labels.adminadd')"
                confirm-variant="primary"
                modal-size="sm"
                @confirm="save()"
            >
                <CoButton variant="primary" :stopEventPropagation="false" :disabled="!canIAddAdmins">{{
                    $t('labels.add')
                }}</CoButton>

                <template v-slot:messageBody>
                    <div style="min-height: 50vh">
                        <CoInput
                            :placeholder="$t('placeholders.searchmembers')"
                            :resetonselect="true"
                            :suggester="searchUsers"
                            icon="search"
                            @select="selectCandidate"
                        />
                        <div
                            v-for="(candidate, index) in candidates"
                            :key="index"
                            class="d-flex mt-3 align-items-center"
                        >
                            <CoThumbnail
                                :content-type="'user'"
                                :imageURL="get(candidate, 'ImageURL', '')"
                                :link="'/profile/' + candidate.Slug"
                                :round="true"
                            />
                            <CoText class="pl-2 m-0">
                                <CoLink class="" styles="co-link primary nowrap">{{ candidate.Name }} </CoLink>
                            </CoText>
                            <CoRoundButton
                                class="ml-auto"
                                icon="trash"
                                variant="icon"
                                @click="removeCandidate(index)"
                            />
                        </div>
                    </div>
                </template>
            </CoConfirmation>
        </div>
        <CoTable
            :loading="loading"
            :itemsPerPage="10"
            :items="items"
            resizableColumns
            :columns="[
                {
                    key: 'Profile.Name',
                    title: 'Name',
                    style: 'width: 250px !important',
                },
                {
                    key: 'actions',
                    notSortable: true,
                    style: 'text-align:right; width: 50px;',
                },
            ]"
        >
            <template v-slot:Profile\.Name="slotProps">
                <div class="d-flex align-items-center">
                    <CoThumbnail
                        class="mr-3"
                        :imageURL="get(slotProps, 'item.Profile.ImageURL', '') || get(slotProps, '.ImageURL', '')"
                        :link="`/admin/community/member/view/${slotProps.item.ID}`"
                        content-type="user"
                        size="sm"
                        round
                    />
                    <CoLink
                        styles="primary truncate"
                        style="min-width: 0"
                        :to="`/admin/community/member/view/${slotProps.item.ID}`"
                        >{{ get(slotProps, 'item.Profile.Name', '') || get(slotProps, 'Name', '') }}
                    </CoLink>
                </div>
            </template>

            <template v-slot:actions="slotProps">
                <!-- delete action -->

                <CoTippy :text="$t('labels.adminremove')">
                    <CoConfirmation
                        :confirmLabel="$t('labels.delete')"
                        confirm-variant="danger"
                        :cancelLabel="$t('labels.discard')"
                        @confirm="removeAdminRights(slotProps.item)"
                        :message="$t('labels.adminremove')"
                    >
                        <CoRoundButton variant="icon" icon="trash" :disabled="objects.length === 1" />
                    </CoConfirmation>
                </CoTippy>
            </template>
        </CoTable>
    </div>
</template>

<script>
import { get, clone } from 'lodash';
import axios from 'axios';
import Vue from 'vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoTable from '@/components/Molecules/co-table/CoTable.vue';
import CoTippy from '@/components/Atoms/co-tippy/CoTippy.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';

import EventBus from '@/eventBus';

export default {
    name: 'AdministratorsList',
    components: { CoLink, CoTable, CoThumbnail, CoConfirmation, CoTippy, CoInput, CoButton, CoRoundButton },
    data() {
        return {
            objects: [],
            fields: [
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                },
            ],

            loading: false,

            // create form data
            name: '',
            nameState: null,

            searchInput: '',

            candidates: [],
        };
    },
    computed: {
        items() {
            if (this.searchInput) {
                return this.objects.filter(
                    (u) =>
                        u.Profile.Name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        u.Email.toLowerCase().includes(this.searchInput.toLowerCase())
                );
            }
            return this.objects;
        },
        canIAddAdmins() {
            if (
                this.$store.state.limits['MaxAdmins'] &&
                this.$store.state.limits['MaxAdmins'] <= this.objects.length &&
                !this.loading
            ) {
                return false;
            }
            return true;
        },
    },
    mounted() {
        this.listAdmins();
    },
    methods: {
        get,
        searchUsers(query, source) {
            // search for users
            return new Promise((resolve, reject) => {
                // exit if query is less than 2 characters
                if (!query || query.length < 2) {
                    resolve([]);
                    return;
                }
                // fetch the search results
                axios({
                    method: 'GET',
                    url: `/admin/member/search/${query}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cancelToken: source ? source.token : null,
                })
                    .then((response) => {
                        const results = get(response, 'data.objects', []);
                        const mapped = results.map((el) => ({
                            label: get(el, 'object.Profile.Name', ''),
                            value: el.object,
                        }));
                        resolve(mapped);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        selectCandidate(user) {
            const u = get(user, 'value', null);
            if (!u) {
                return;
            }
            const fu = this.candidates.find((item) => item.ID === u.ID);
            if (fu) {
                return;
            }

            this.candidates.push({
                ID: get(u, 'ID'),
                Name: get(u, 'Profile.Name'),
                Slug: get(u, 'Slug'),
                ImageURL: get(u, 'Profile.ImageURL'),
            });
        },
        removeCandidate(index) {
            this.candidates.splice(index, 1);
        },
        listAdmins() {
            axios({
                method: 'GET',
                url: '/admin/administrator/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Users.length !== 0) {
                        this.objects = response.data.Users;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        save() {
            this.candidates.forEach((item) => {
                this.makeUserAdmin(clone(item));
            });

            this.candidates = [];
        },

        makeUserAdmin(obj) {
            this.loading = true;
            const data = JSON.stringify(obj);

            axios({
                method: 'POST',
                url: '/admin/administrator/',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.name = '';
                    this.loading = false;
                    EventBus.$emit('INFO', {
                        Message: this.$t('labels.changessaved'),
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedGeneric'),
                        Details: error,
                    });
                });
        },

        removeAdminRights(obj) {
            this.loading = true;
            const data = JSON.stringify(obj);

            axios({
                method: 'DELETE',
                url: '/admin/administrator/',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.listAdmins();
                    this.loading = false;
                    EventBus.$emit('INFO', {
                        Message: this.$t('labels.changessaved'),
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedGeneric'),
                        Details: error,
                    });
                });
        },
    },
};
</script>
