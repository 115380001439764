





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from 'axios';
import clone from 'lodash/clone';
import EventBus from '@/eventBus';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoTable from '@/components/Molecules/co-table/CoTable.vue';
import Nuki from '../Settings/Integrations/Nuki.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';

export default {
    name: 'EditBookingResource',
    components: { CoCard, CoText, CoInput, CoButton, CoTable, CoDropdown, CoDropdownItem },
    data() {
        return {
            from: '{{from}}',
            to: '{{to}}',
            pin: '{{pin}}',
            resource_name: '{{resource_name}}',
            comment: '{{comment}}',
            locations: [],
            loading: false,
            resources: [],

            dateOverride: [],

            form: {
                LocationID: '',
                PricePerHour: 0.0,
                TaxRate: 16.0,
                Capacity: 1,
                CancellationPeriod: 0,
                MinBookingDuration: 0,
                MaxBookingDuration: 0,
                BookingTimes: [],
                ChargeForLikedResources: false,
                NotifyEmails: [''],
                AfterBooking: {
                    Title: '',
                    Text: '',
                    CallToActionURL: '',
                    CallToActionTitle: '',
                },
            },
            previewImg: null,
            previewImgURL: null,

            intervalKey: 0,

            taxRates: [],
            FinalPrice: 0.0,
            PricePerHour: 0.0,

            // list of resource to block on booking of this resource
            resourcesToBlock: [],
            newBlockingRule: {
                resource: null,
                slots: 1,
            },

            // list of tokens granted by user to access external calendars
            tokens: [],
            selectedToken: null,

            // list of external calendars available for selected token
            calendars: [],
            calendarsLoading: false,
            selectedCalendar: null,
            selectedCalendarID: null,
            syncing: false,
            resourceAlreadySynced: false,

            // list of locks for this space
            locks: [],
            validTimeBeforeBooking: 10,
            validTimeAfterBooking: 10,
            selectedLock: null,

            locksEnabled: false,
            isBillingTurnedOn: this.$store.state.billingTurnedOn,

            plans: [],

            allUpgrades: [],
            canditateUpgrade: null, // selected upgrade to add to list
            loadingUpgrades: false,
        };
    },
    computed: {
        isTherePlans() {
            if (!this.plans || this.plans.length === 0) {
                return false;
            }

            const filtered = this.plans.filter((p) => p.Active);
            if (filtered && filtered.length > 0) {
                return true;
            }

            return false;
        },
    },
    created() {
        this.listLocations();
        this.getResource();
        this.getTaxRates();
        this.listLocks();
        this.fetchPlans();
        this.getUpgrades();
    },
    methods: {
        goToCreateUpgrade() {
            window.open(`https://${window.location.hostname}/admin/monetization/plan-upgrade/create`, '_blank');
        },
        editUpgrade(val) {
            this.canditateUpgrade = val;
            this.$refs['add-booking-upgrade-modal'].show();
        },
        removeUpgradeFromForm(id) {
            if (!this.form.Upgrades) return;

            this.form.Upgrades = this.form.Upgrades.filter((up) => up.OriginalID !== id);
        },
        saveUpdateCandidate() {
            if (!this.form.Upgrades) {
                this.form['Upgrades'] = [clone(this.canditateUpgrade)];
                this.discardUpgradeCandidate();
                return;
            }

            const found = this.form.Upgrades.find((up) => up.OriginalID === this.canditateUpgrade.OriginalID);
            if (found) {
                this.form.Upgrades = this.form.Upgrades.map((up) => {
                    if (up.OriginalID === this.canditateUpgrade.OriginalID) {
                        return this.canditateUpgrade;
                    }
                    return up;
                });
                this.discardUpgradeCandidate();
                return;
            }

            this.form.Upgrades.push(clone(this.canditateUpgrade));
            this.discardUpgradeCandidate();
        },
        discardUpgradeCandidate() {
            this.canditateUpgrade = null;
            this.$refs['add-booking-upgrade-modal'].hide();
        },
        deleteUpgrade(val) {
            this.canditateUpgrade = null;
        },
        selectUpgrade(event, vals) {
            if (!vals || vals.length === 0) return;
            [this.canditateUpgrade] = vals;
            this.canditateUpgrade['MinAmount'] = 0;
            this.canditateUpgrade['MaxAmount'] = 0;
            this.canditateUpgrade['OriginalID'] = this.canditateUpgrade.ID;
        },
        getUpgrades() {
            this.loadingUpgrades = true;
            axios({
                method: 'GET',
                url: `/admin/extra/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.allUpgrades = response.data.Extras ? response.data.Extras : [];
                    }
                    this.loadingUpgrades = false;
                })
                .catch((error) => {
                    this.loadingUpgrades = false;
                });
        },
        // fetches community membership plans
        fetchPlans() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/plan/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.plans = response.data.Plans;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        isLocksEnabled() {
            this.locksEnabled = this.$unleash.isEnabled('frontend.locks-for-booking-resources.admin');
            return this.locksEnabled;
        },
        hasHistory() {
            return window.history.length > 2;
        },

        formatTimestamp(timestamp) {
            if (!timestamp) return 'n/a';
            const dateTime = new Date(timestamp * 1000).toString();
            return dateTime;
        },

        onTokenChange(token) {
            this.getCalendars(this.selectedToken.ID);
        },

        onCalendarChange(calendarID) {
            if (
                this.form.ExternalCalendars &&
                this.form.ExternalCalendars.find((calendar) => calendar.ID == calendarID)
            ) {
                this.selectedCalendar = this.form.ExternalCalendars.find((calendar) => calendar.ID == calendarID);
            } else {
                this.selectedCalendar = this.calendars.find((calendar) => calendar.ID == calendarID);
            }
        },

        /**
         * Get calendars accessable by selected token
         * @param {string} tokenID
         * @return {void}
         */
        getCalendars(tokenID) {
            this.calendarsLoading = true;

            axios({
                method: 'GET',
                url: `/admin/booking/resource/external-calendars/${tokenID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.calendars = response.data.Calendars;
                    }
                    this.calendarsLoading = false;
                })
                .catch((error) => {
                    this.calendarsLoading = false;
                    EventBus.$emit('ERROR', {
                        Message: "Can't list external calendars",
                    });
                });
        },

        /**
         * Get tokens granted by user to access external calendar
         * @function getTokens
         * @return {void}
         * @param {void}
         */
        getTokens() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/oauth2/google/calendar/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Tokens) {
                        this.tokens = response.data.Tokens;
                        this.parseExternalCalendars();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },

        setPricePerHour(price) {
            this.PricePerHour = price;
            this.priceChange();
        },
        priceChange() {
            if (this.form.TaxRateID) {
                this.calculateFinalPrice(this.form.TaxRateID);
            }
        },
        calculateFinalPrice(taxID) {
            const tax = this.taxRates.find((item) => item.ID === taxID);
            if (tax.Inclusive) {
                this.FinalPrice = this.PricePerHour;
            } else {
                this.FinalPrice = (this.PricePerHour * (tax.Percentage / 100 + 1)).toFixed(2);
            }
        },

        removeEmail(index) {
            this.form.NotifyEmails.splice(index, 1);
        },
        getResource() {
            axios({
                method: 'GET',
                url: `/admin/booking/resource/${this.$route.params.id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data) {
                        const tmp = response.data;
                        if (tmp.BookingTimes) {
                            const tmpBookingTimes = tmp.BookingTimes;
                            tmp.BookingTimes.forEach((element, index) => {
                                tmpBookingTimes[index].buttons = this.parseBookingTimes(element);
                            });
                            tmp.BookingTimes = tmpBookingTimes;
                        }

                        if (!tmp.AfterBookingEmailContent) {
                            tmp.AfterBookingEmailContent = `Thanks for your booking!\n\nWhat: {{resource_name}}\nFrom: {{from}}\nTo: {{to}}`;
                        }

                        if (!tmp.NotifyEmails) {
                            tmp.NotifyEmails = [''];
                        }

                        if (!tmp.AfterBooking) {
                            tmp.AfterBooking = {
                                Title: '',
                                Text: '',
                                CallToActionURL: '',
                                CallToActionTitle: '',
                            };
                        }

                        if (!tmp.MinBookingDuration) {
                            tmp.MinBookingDuration = 0;
                        }

                        if (!tmp.MaxBookingDuration) {
                            tmp.MaxBookingDuration = 0;
                        }

                        if (!tmp.CancellationPeriod) {
                            tmp.CancellationPeriod = 0;
                        }

                        if (tmp.PriceInCentsPerHour) {
                            this.PricePerHour = tmp.PriceInCentsPerHour / 100.0;
                        } else {
                            this.PricePerHour = 0.0;
                        }

                        if (tmp.Locks && tmp.Locks.length > 0) {
                            this.selectedLock = tmp.Locks[0].ID;
                            this.validTimeBeforeBooking = tmp.Locks[0].BeforeUnitXTimesBeforBookingStart;
                            this.validTimeAfterBooking = tmp.Locks[0].AfterUnitXTimesAfterBookingEnd;
                        }

                        const that = this;
                        if (tmp.BookingTimeOverride) {
                            that.dateOverride = [];
                            Object.keys(tmp.BookingTimeOverride).forEach((key) => {
                                const value = tmp.BookingTimeOverride[key];
                                that.dateOverride.push({
                                    date: key,
                                    from: value.From,
                                    to: value.To,
                                    IsAllDay: value.Block,
                                    Block: value.Block,
                                });
                            });
                        }

                        this.form = tmp;
                        if (this.$store.state.space.Currency) {
                            this.form.Currency = this.$store.state.space.Currency;
                        }
                        this.listResources();
                        this.getTokens();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        parseBookingTimes(bookingtimes) {
            let buttons = [
                { caption: 'Mo', state: false, value: 1 },
                { caption: 'Tue', state: false, value: 2 },
                { caption: 'Wed', state: false, value: 3 },
                { caption: 'Th', state: false, value: 4 },
                { caption: 'Fr', state: false, value: 5 },
                { caption: 'Sa', state: false, value: 6 },
                { caption: 'Su', state: false, value: 7 },
            ];
            if (bookingtimes.Weekdays) {
                bookingtimes.Weekdays.forEach((element) => {
                    const tmp = buttons[element - 1];
                    tmp.state = true;
                    buttons[element - 1] = tmp;
                });

                return buttons;
            }

            buttons = [
                { caption: 'Mo', state: true, value: 1 },
                { caption: 'Tue', state: true, value: 2 },
                { caption: 'Wed', state: true, value: 3 },
                { caption: 'Th', state: true, value: 4 },
                { caption: 'Fr', state: true, value: 5 },
                { caption: 'Sa', state: true, value: 6 },
                { caption: 'Su', state: true, value: 7 },
            ];
            return buttons;
        },
        addDateOverride() {
            this.dateOverride.push({
                date: null,
                from: '09:00',
                to: '19:00',
            });
        },

        removeDateOverride(index) {
            this.dateOverride.splice(index, 1);
        },

        addInterval() {
            const newInterval = {
                From: '09:00',
                To: '17:00',
                Weekdays: [1],
                buttons: [
                    { caption: 'Mo', state: true, value: 1 },
                    { caption: 'Tue', state: true, value: 2 },
                    { caption: 'Wed', state: true, value: 3 },
                    { caption: 'Th', state: true, value: 4 },
                    { caption: 'Fr', state: true, value: 5 },
                    { caption: 'Sa', state: false, value: 6 },
                    { caption: 'Su', state: false, value: 7 },
                ],
            };
            if (this.form.BookingTimes != null) {
                this.form.BookingTimes.push(newInterval);
            } else {
                this.form.BookingTimes = [newInterval];
            }
            this.intervalKey++;
        },
        removeInterval(index) {
            this.form.BookingTimes.splice(index, 1);
            this.intervalKey--;
        },
        previewImage(event) {
            this.previewImg = event.target.files[0];
            this.previewImgURL = URL.createObjectURL(this.previewImg);
        },

        handleCreateSubmit(event) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }

            this.loading = true;
            if (this.previewImg) {
                this.uploadImage();
            } else {
                this.updateResource();
            }
        },
        checkFormValidity() {
            const valid = this.$refs.createResource.checkValidity();
            return valid;
        },

        uploadImage() {
            const url = '/upload/image/booking-resources';
            const data = new FormData();
            data.append('file', this.previewImg);
            axios({
                method: 'POST',
                url,
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'image/*',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.url) {
                        this.form.ImageURL = response.data.url;
                        this.updateResource();
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not upload image.',
                        });
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Could not upload image.',
                    });
                    this.loading = false;
                });
        },

        prepearBookingTimes() {
            const newBookingTimes = [];
            if (this.form.BookingTimes == null) {
                this.form.BookingTimes = newBookingTimes;
                return;
            }
            this.form.BookingTimes.forEach((entry) => {
                const weekdays = [];
                entry.buttons.forEach((item) => {
                    if (item.state) {
                        weekdays.push(item.value);
                    }
                });

                if (weekdays.length != 0) {
                    newBookingTimes.push({
                        From: entry.From,
                        To: entry.To,
                        Weekdays: weekdays,
                    });
                }
            });
            this.form.BookingTimes = newBookingTimes;
        },

        prepearDateOverrides() {
            const bookingTimeOverride = {};
            this.dateOverride.forEach((entry) => {
                bookingTimeOverride[entry.date] = {
                    from: entry.from,
                    to: entry.to,
                    IsAllDay: entry.Block,
                    Block: entry.Block,
                };
            });
            this.form.BookingTimeOverride = bookingTimeOverride;
        },

        prepateBlockingRules() {
            const rules = this.resourcesToBlock.map((item) => ({
                BlockResourceID: item.resource.ID,
                NumberOfSlots: item.slots,
            }));
            return rules;
        },

        prepareExternalCalendars() {
            const externalCalendars = [];

            if (this.selectedCalendar) {
                externalCalendars.push({
                    ID: this.selectedCalendar.ID,
                    Name: this.selectedCalendar.Name,
                    tokenID: this.selectedToken.ID,
                    SyncToken: this.selectedCalendar.SyncToken,
                    Subscribed: this.selectedCalendar.Subscribed,
                    ChannelExpiration: this.selectedCalendar.ChannelExpiration,
                    LastSync: this.selectedCalendar.LastSync,
                });
            }

            return externalCalendars;
        },

        parseExternalCalendars() {
            if (this.form.ExternalCalendars && this.form.ExternalCalendars.length > 0) {
                this.selectedCalendar = this.form.ExternalCalendars[0];
                this.selectedCalendarID = this.selectedCalendar.ID;
                this.selectedToken = this.tokens.find((item) => item.ID === this.selectedCalendar.TokenID);
                this.getCalendars(this.selectedToken.ID);
            }
        },
        updateResource(andSync = false) {
            this.prepearBookingTimes();
            this.prepearDateOverrides();
            this.form.Rules = this.prepateBlockingRules();
            if (this.form.NotifyEmails) {
                this.form.NotifyEmails = this.form.NotifyEmails.filter((el) => el != '');
            }

            this.form.ExternalCalendars = this.prepareExternalCalendars();
            this.form.PriceInCentsPerHour = Math.ceil(this.PricePerHour * 100);

            if (this.selectedLock) {
                const slock = this.locks.find((item) => item.ID === this.selectedLock);
                if (slock) {
                    this.form.Locks = [
                        {
                            ID: slock.ID,
                            Name: slock.Name,
                            Action: 0, // pin generations
                            BeforeUnitXTimesBeforBookingStart: this.validTimeBeforeBooking,
                            BeforeUnit: this.validTimeBeforeBookingUnit,

                            AfterUnitXTimesAfterBookingEnd: this.validTimeAfterBooking,
                            AfterUnit: this.validTimeAfterBookingUnit,
                        },
                    ];
                }
            }

            const data = JSON.stringify(this.form);
            axios({
                method: 'PUT',
                url: '/admin/booking/resource',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.form = null;
                    this.getResource();
                    const message = {
                        Message: 'Changes saved',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                })
                .catch((error) => {
                    console.log(error);
                    // todo error processing
                    const message = {
                        Message: 'Could not save changes',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    this.loading = false;
                });
        },

        syncResource() {
            this.loading = true;
            this.$refs['modal-sync'].hide();

            const data = {
                calendar_id: this.selectedCalendar.ID,
            };

            axios({
                method: 'POST',
                url: `/admin/booking/sync/${this.form.ID}`,
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.syncing = true;
                    this.getResource();
                    const message = {
                        Message: 'Resource sync started',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                })
                .catch((error) => {
                    console.log(error);
                    // todo error processing
                    const message = {
                        Message: 'Could not sync resource',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    this.loading = false;
                });
        },

        /**
         * listResources - return list of resources for space
         */
        listResources() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Resources.length != 0) {
                        response.data.Resources.forEach((item) => {
                            if (this.form.ID != null && this.form.ID != item.ID) {
                                this.resources.push(item);
                            }
                        });

                        if (this.form.Rules) {
                            // filter matching resources
                            const filtered = this.form.Rules.filter((rule) => {
                                const match = this.resources.find((item) => rule.BlockResourceID === item.ID);
                                if (match) {
                                    return true;
                                }
                                return false;
                            });

                            // map to correct structure
                            this.resourcesToBlock = filtered.map((rule) => {
                                const match = this.resources.find((r) => rule.BlockResourceID === r.ID);
                                return {
                                    resource: match,
                                    slots: rule.NumberOfSlots,
                                };
                            });
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        removeBlockingRule(index) {
            this.resourcesToBlock.splice(index, 1);
        },
        cancelAddBlockingRule() {
            this.$refs['modal-add-resource'].hide();
            this.newBlockingRule = {
                resource: null,
                slots: 1,
            };
        },
        // add blocking rule
        addBlockingRule() {
            const rule = this.resourcesToBlock.find((item) => item.resource.ID === this.newBlockingRule.resource.ID);

            if (rule) {
                const message = {
                    Message: 'This resource already present in blocking rules',
                    Details: '',
                };
                EventBus.$emit('ERROR', message);
                return;
            }
            this.resourcesToBlock.push(this.newBlockingRule);
            this.$refs['modal-add-resource'].hide();
            this.newBlockingRule = {
                resource: null,
                slots: 1,
            };
        },
        listLocations() {
            this.$store
                .dispatch('listLocations')
                .then((locations) => {
                    this.locations = locations;
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        /**
         * listLocks - return list of locks for space
         */
        listLocks() {
            this.loading = true;
            axios
                .get('/admin/space/access/lock/list')
                .then((response) => {
                    if (response && response.data) {
                        this.locks = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
};
