<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center bg-white search-bar-wrapper rounded-pill">
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="search"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <b-button
                variant="primary"
                to="/admin/monetization/payments/tax-rate/create"
                :disabled="!$store.state.billingTurnedOn"
                >Create
            </b-button>
        </div>
        <!-- search and actions end -->

        <!-- tax rate start -->
        <div class="container-fluid p-0 m-0">
            <!-- if stripe payment was not connected -->
            <div class="row m-0 mt-5 px-sm-4" v-if="!$store.state.billingTurnedOn">
                <CoCard variant="warning">
                    <CoHeadline>First things first</CoHeadline>
                    <CoText> Before creating tax rates, you have to setup your Stripe Connection first. </CoText>
                    <CoButton variant="primary" @click="$router.push('/admin/monetization/payments/stripe')"
                        >Setup payments</CoButton
                    >
                </CoCard>
            </div>
            <!-- if emtpy and not loading -->
            <div class="row m-0 mt-5 px-sm-4" v-else-if="list.length === 0 && !loading">
                <CoCard>
                    <CoHeadline>Get started with tax rates</CoHeadline>
                    <CoText>You have not created any tax rates yet.</CoText>
                    <CoButton @click="$router.push('/admin/monetization/payments/tax-rate/create')" variant="primary"
                        >Create your first tax rate</CoButton
                    >
                </CoCard>
            </div>
            <!-- if loading -->
            <div class="row text-center m-0 mt-5" v-else-if="list.length === 0 && loading">
                <div class="col">
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>
            </div>
            <div class="m-0 mt-4 px-sm-4">
                <div class="" v-for="(item, i) in filteredItems" :key="item.ID">
                    <TaxRateCard
                        :object="item"
                        :to="`/admin/monetization/payments/tax-rate/${item.ID}`"
                        @editCallBack="edit"
                        class="mb-3"
                    ></TaxRateCard>
                </div>
            </div>
        </div>
        <!-- tax rate end -->
    </div>
</template>

<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<script>
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import axios from 'axios';

export default {
    name: 'ListTaxRates',
    components: {
        CoButton,
        CoHeadline,
        CoText,
        CoCard,
    },
    data() {
        return {
            form: {},
            loading: false,
            list: [],
            search: '',
        };
    },
    mounted() {
        this.getTaxRates();
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        filteredItems() {
            return this.list.filter((item) => {
                if (item.DisplayName.toLowerCase().indexOf(this.search.toLowerCase()) > -1 == true) {
                    return true;
                }
            });
        },
    },
    methods: {
        edit(id) {
            this.$router.push(`/admin/monetization/payments/tax-rate/${id}`);
        },
        getTaxRates() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.list = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
    },
};
</script>
