























































import axios from 'axios';
import EventBus from '../../../eventBus';

import CoModal from '../co-modal/CoModal.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';
import CoSelect from '../../Molecules/co-select/CoSelect.vue';
import CoFormGroup from '../../Molecules/co-form-group/CoFormGroup.vue';
import CoHeading from '../../Atoms/co-heading/CoHeading.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';

export default {
    name: 'CoUserCreateAdminModal',
    components: {
        CoModal,
        CoButton,
        CoInput,
        CoSelect,
        CoFormGroup,
        CoHeading,
        CoRoundButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            salutation: null,

            validForm: false,
            creatingUser: false,
        };
    },
    watch: {
        firstName() {
            this.validateForm();
        },
        lastName() {
            this.validateForm();
        },
        email() {
            this.validateForm();
        },
        salutation() {
            this.validateForm();
        },
    },
    methods: {
        validateForm() {
            if (this.firstName && this.lastName && this.email && this.salutation) {
                // check if email is valid
                const emailRegex = /\S+@\S+\.\S+/;
                if (!emailRegex.test(this.email)) {
                    this.validForm = false;
                    return;
                }

                this.validForm = true;
            } else {
                this.validForm = false;
            }
        },
        selectSalutation(salutation) {
            this.salutation = salutation.Value;
        },
        discard() {
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.hide();
        },
        showCreateUserModal() {
            this.$refs.createUserModal.show();
        },
        hide() {
            this.$refs.createUserModal.hide();
        },

        createUser() {
            const data = JSON.stringify({
                FirstName: this.firstName,
                LastName: this.lastName,
                Email: this.email,
                Salutation: this.salutation,
            });
            this.creatingUser = true;
            axios({
                method: 'POST',
                url: `/admin/community/member`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data,
            })
                .then((response) => {
                    if (response && response.data) {
                        // cast response to UserMembership
                        EventBus.$emit('INFO', {
                            Message: 'User created successfully',
                            Details: '',
                        });
                        this.$router.push({
                            name: 'Community Member Account',
                            params: {
                                ID: response.data.ID,
                            },
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response && error.response.data && error.response.data.includes('AlreadyExists')) {
                        EventBus.$emit('ERROR', {
                            Message: 'User with this email already exists',
                            Details: '',
                        });
                    } else if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: `Error: ${error.response.data.message}`,
                            Details: '',
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Something went wrong',
                            Details: '',
                        });
                    }
                })
                .finally(() => {
                    this.creatingUser = false;
                });
        },
    },
};
