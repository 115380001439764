










































































































































































































































































import Bugsnag from '@bugsnag/js';
import i18n from 'vue-i18n';
import axios from 'axios';

import EventBus from '../../../eventBus';

import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';

const moment = require('moment');

export default {
    name: 'Billing',
    components: {
        CoCard,
    },
    i18n: {
        messages: {
            en: {
                buymoreseats: 'Would you like to buy more users or admin seats? {0}.',
                sepaDirectDebitInfo:
                    'Would you rather like to pay by SEPA direct debit or bank transfer? Would you like to discuss Enterprise pricing or custom solutions? {0}.',
                contactsaleslink: 'Contact our sales team',
            },
            de: {
                buymoreseats: 'Möchtest du mehr Benutzer oder Admin-Plätze kaufen? {0}.',
                sepaDirectDebitInfo:
                    'Möchtest du lieber per SEPA-Lastschrift oder Überweisung zahlen? Möchtest du über Enterprise-Preise oder individuelle Lösungen sprechen? {0}.',
                contactsaleslink: 'Kontaktiere unser Vertriebsteam',
            },
        },
    },
    data() {
        return {
            loading: false,
            billingAdmin: null,
            manageUrl: null,
            space: this.$store?.state?.space_configuration_admin || {},
        };
    },
    computed: {
        planName() {
            if (this.space?.PlanName?.length > 1) {
                // capitalize first letter
                return this.space.PlanName.charAt(0).toUpperCase() + this.space.PlanName.slice(1);
            }
            return 'Legacy';
        },
        canIManage() {
            return this.$store.state.me.ID === this.space.PaymentMethodeCreatedBy;
        },
        communityIsCancelled() {
            if (!this.space || !this.space.SubscriptionCanceledTo) return false;
            const now = Date.now() / 1000;
            return this.space.SubscriptionCanceledTo < now;
        },
    },
    mounted() {
        this.getAdminSpaceSettings();
    },
    methods: {
        getAdminSpaceSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$store.commit('SET_SPACE_OBJECT_FOR_ADMIN', response.data);
                        this.space = response.data;
                        this.getAccountSettings();
                    } else {
                        const message = {
                            Message: 'Can`t get billing admin data',
                            Details: '',
                        };
                        EventBus.$emit('ERROR', message);
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t get billing admin data',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.loading = false;
                });
        },
        getAccountSettings() {
            let urlLoading = true;
            let billingAdminLoading = true;
            this.loading = true;
            // fetch customer portal url

            axios
                .get('/admin/space/billing/customer-portal-url')
                .then((response) => {
                    this.manageUrl = response?.data?.URL || null;
                })
                .catch((error) => {
                    if (this.canIManage) {
                        const msg = {
                            Message: 'Failed to generate customer portal url. Please contact support.',
                            Details: '',
                        };
                        EventBus.$emit('ERROR', msg);
                    }
                })
                .finally(() => {
                    urlLoading = false;
                    if (!urlLoading && !billingAdminLoading) {
                        this.loading = false;
                    }
                });

            // fetch billing admin profile
            if (this.space.PaymentMethodeCreatedBy) {
                this.$store
                    .dispatch('adminGetUserByID', this.space.PaymentMethodeCreatedBy)
                    .then((response) => {
                        this.billingAdmin = response;
                    })
                    .catch((error) => {
                        const message = {
                            Message: 'Can`t get billing admin data',
                            Details: error.response.data,
                        };
                        EventBus.$emit('ERROR', message);
                    })
                    .finally(() => {
                        billingAdminLoading = false;
                        if (!urlLoading && !billingAdminLoading) {
                            this.loading = false;
                        }
                    });
            } else {
                billingAdminLoading = false;
                if (!urlLoading && !billingAdminLoading) {
                    this.loading = false;
                }
            }
        },
        openCustomerPortalURL() {
            if (this.manageUrl && this.manageUrl.length > 0) {
                window.open(this.manageUrl).focus();
            } else {
                let error = {
                    Message: 'No valid customer portal URL found. Please contact support.',
                    Details: '',
                };
                EventBus.$emit('ERROR', error);
                Bugsnag.notify(new Error('No valid customer portal URL found. Please contact support.'));
            }
        },
    },
};
