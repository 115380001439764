<template>
    <div class="dashboard-container-small mb-5">
        <CoCard class="overflow-visible">
            <div class="d-flex align-items-center justify-content-between">
                <CoHeadline :level="2">{{ $t('labels.pagecreate') }}</CoHeadline>
                <CoRoundButton icon="x-lg" variant="icon" @click="discard" :disabled="loading" />
            </div>
            <div class="d-flex flex-column justify-content-between">
                <CoFormGroup :label="$t('labels.title')" class="mb-3">
                    <CoInput
                        v-model="title"
                        :placeholder="$t('pagenameplaceholder')"
                        :disabled="loading"
                        ref="title"
                        @enter="postProject"
                        required
                    />
                    <div class="d-flex align-items-center px-2" style="height: 2.25rem">
                        <CoText type="p3" variant="mute">{{ $t('pagecreateinfo') }}</CoText>
                    </div>
                </CoFormGroup>

                <CoFormGroup :label="$t('labels.channel')" class="mb-3">
                    <CoSelect
                        :value="selectedChannelForCoSelect"
                        :items="channelsList"
                        style="z-index: 1001"
                        :disabled="loading"
                        @select="selectChannel"
                    />
                </CoFormGroup>

                <!-- Page visability -->
                <CoFormGroup :label="$t('visibility')" class="mb-3">
                    <div class="d-flex align-items-center px-2 mb-2" style="">
                        <CoText type="p2" variant="mute">{{ $t('whocanfollow') }}</CoText>
                    </div>
                    <CoCheckbox v-model="private" :name="$t('private')" />
                    <CoCheckbox v-model="community" :name="$t('community')" />
                </CoFormGroup>

                <!-- join strategy -->
                <CoFormGroup :label="$t('joinpermissions')" class="mb-3">
                    <div class="d-flex align-items-center px-2 mb-2" style="">
                        <CoText type="p2" variant="mute"
                            >{{ $t('rolesexplained1') }}<br />{{ $t('rolesexplained2') }}<br />{{
                                $t('rolesexplained3')
                            }}</CoText
                        >
                    </div>
                    <CoCheckbox v-model="inviteOnly" :label="$t('joininviteonly')" :name="$t('joininviteonly')" />
                    <CoCheckbox
                        v-model="approvalBased"
                        :label="$t('joinapprovalbased')"
                        :name="$t('joinapprovalbased')"
                    />
                    <CoCheckbox v-model="open" :label="$t('joinopen')" :name="$t('joinopen')" />
                </CoFormGroup>

                <!-- buttons "save and publish" "save as draft" -->
                <div class="d-flex flex-column">
                    <CoButton
                        variant="primary"
                        @click="postProject"
                        :loading="loading"
                        :disabled="!canICreatePage"
                        class="center-text"
                    >
                        {{ $t('labels.create') }}
                    </CoButton>
                </div>
            </div>
        </CoCard>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import i18n from 'vue-i18n';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoCheckbox from '@/components/Atoms/co-checkbox/CoCheckbox.vue';
import EventBus from '../../eventBus';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import { tr } from 'date-fns/locale';
import { set } from 'lodash';

export default {
    components: { CoCheckbox, CoFormGroup },
    i18n: {
        messages: {
            en: {
                pagecreateinfo: 'You can always change it later.',
                pagenameplaceholder: "Your @:labels.page's name",
                joinpermissions: 'How can other members join?',
                joininviteonly: 'By Invitation Only',
                joinapprovalbased: 'Members can request to join',
                joinopen: 'Everyone can join',

                visibility: 'Who can see this page?',
                private: 'Private, only visible to members of this page',
                community: 'Visible to everyone in the community',

                whocanfollow: 'Anyone who can see the page can follow it.',
                rolesexplained1: `Owners can edit and delete the page and can add and remove contributors.`,
                rolesexplained2: `Contributors can publish posts on the page.`,
                rolesexplained3: `Followers receive notifications about every post on the page.`,
            },
            de: {
                pagecreateinfo: 'Du kannst es jederzeit anpassen.',
                pagenameplaceholder: 'Name deiner @:labels.page',
                joinpermissions: 'Wie können andere Mitglieder beitreten?',
                joininviteonly: 'Nur durch Hinzufügen',
                joinapprovalbased: 'Mitglieder können Beitrittsanfragen stellen',
                joinopen: 'Alle können beitreten',

                visibility: 'Wer kann diese Seite sehen?',
                private: 'Privat, nur sichtbar für Mitglieder dieser Seite',
                community: 'Sichtbar für alle in der Community',

                whocanfollow: 'Wer die Seite sehen kann, kann ihr folgen.',
                rolesexplained1: `Inhaber:innen können die Seite bearbeiten und löschen und können Mitwirkende hinzufügen und entfernen.`,
                rolesexplained2: `Mitwirkende können Beiträge auf der Seite veröffentlichen.`,
                rolesexplained3: `Follower*innen erhalten Benachrichtigungen über jeden Beitrag auf der Seite.`,
            },
        },
    },
    name: 'CreateProject',
    data() {
        return {
            isAdmin: this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin'),
            title: '',
            channels: [],
            preselectedChannelSlug: this.$route.query.channel,
            channel: null,
            // join strategy
            inviteOnly: false,
            approvalBased: true,
            open: false,

            // visibility
            private: true,
            community: false,

            loading: false,
        };
    },
    watch: {
        channels(val) {
            if (val && val.length > 0) {
                if (this.preselectedChannelSlug) {
                    this.channel = val.find((element) => element.Slug === this.preselectedChannelSlug);
                } else {
                    this.channel = val[0];
                }
            }
        },

        private(val) {
            if (val) {
                this.community = false;
            }
        },

        community(val) {
            if (val) {
                this.private = false;
            }
        },

        inviteOnly(val) {
            if (val) {
                this.approvalBased = false;
                this.open = false;
            }
        },
        approvalBased(val) {
            if (val) {
                this.inviteOnly = false;
                this.open = false;
            }
        },
        open(val) {
            if (val) {
                this.inviteOnly = false;
                this.approvalBased = false;
            }
        },
    },
    computed: {
        // canICreatePage returns true if title and channel are not empty
        canICreatePage() {
            return this.title.trim() !== '' && this.channel && this.channel.ID;
        },

        selectedChannelForCoSelect() {
            if (!this.channel) {
                return null;
            }
            return {
                Value: this.channel.ID,
                Name: this.channel.Value,
            };
        },
        // return channel list for coselect component
        channelsList() {
            if (!this.channels || this.channels.length === 0) {
                return [];
            }

            return this.channels.map((element) => ({
                Value: element.ID,
                Name: element.Value,
            }));
        },
        me() {
            return this.$store.state.me;
        },
    },
    mounted() {
        // focus on title input
        this.$refs.title.focus();

        this.$nextTick(() => {
            // Code that will run only after the
            // entire view has been rendered
            this.listchannels();
        });
    },
    methods: {
        discard() {
            if (window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
        selectChannel(channel) {
            this.channel = get(channel, 'Value', null);
        },

        listchannels() {
            this.channels = [];
            axios({
                method: 'GET',
                url: '/project/project-type/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.objects && response.data.objects.length !== 0) {
                        if (this.isAdmin) {
                            this.channels = response.data.objects.map((element) => element.object);
                        } else {
                            this.channels = response.data.objects
                                .filter((element) => element.object.AddPagesPermission !== 1)
                                .map((element) => element.object);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        hasHistory() {
            return window.history.length > 2;
        },

        emitEventToIntercom() {
            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
                this.$intercom.trackEvent('created-project');
            }
        },

        postProject() {
            this.loading = true;
            if (this.title === '') {
                return;
            }

            let page = {
                Title: this.title,
                ChannelID: this.channel.ID,
                UserID: this.$store.state.me.ID,
                Contributor: [this.$store.state.me.ID],
                Owner: [this.$store.state.me.ID],
                ImageURL: [],
                JoinStrategy: 1,
                Published: false,
                PublishedAt: 0,
            };

            if (this.inviteOnly) {
                page.JoinStrategy = 0;
            } else if (this.approvalBased) {
                page.JoinStrategy = 1;
            } else if (this.open) {
                page.JoinStrategy = 2;
            }

            if (this.community) {
                page.Published = true;
                page.PublishedAt = Math.floor(Date.now() / 1000);
            }

            axios({
                method: 'POST',
                url: '/project',
                data: page,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('intercomTrackEvent', {
                        eventName: 'created-project',
                    });

                    setTimeout(() => {
                        this.emitEventToIntercom();

                        EventBus.$emit('INFO', {
                            Message: this.$t('labels.pagecreated'),
                            Details: null,
                        });

                        this.$router.push(`/channels/${this.channel}/${response.data.Slug}`);
                        EventBus.$emit('CONFETTI', {});
                    }, 300);
                })
                .catch((error) => {
                    console.log(error);
                    const message = {
                        Message: this.$t('labels.pagecreated'),
                        Details: null,
                    };
                    EventBus.$emit('ERROR', message);

                    this.loading = false;
                });
        },
    },
};
</script>
