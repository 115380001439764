





















import i18n from 'vue-i18n';
import { formattedDate } from '../../../language/dateTimeFormatter.ts';
export default {
    name: 'CoDate',
    i18n: {
        messages: {
            en: {
                datetime: ({ named }) =>
                    formattedDate(
                        named('date'),
                        named('format'),
                        named('locale') ? named('locale') : null,
                        named('customFormat')
                    ),
            },
            de: {
                datetime: ({ named }) =>
                    formattedDate(
                        named('date'),
                        named('format'),
                        named('locale') ? named('locale') : 'de',
                        named('customFormat')
                    ),
            },
        },
    },
    props: {
        date: {
            type: [Date, Number, String],
        },
        format: {
            type: String,
        },
        locale: {
            type: String,
        },
        customFormat: {
            type: String,
        },
    },
};
