

















































































































































import i18n from 'vue-i18n';
import axios from 'axios';
import EventBus from '../../../../../eventBus';
import { format, fromUnixTime } from 'date-fns';
import { get, orderBy } from 'lodash';

import TimepassCard from '@/components/cards/TimepassCard.vue';
import TimepassCardWallet from '@/components/cards/TimepassCardWallet.vue';

import CoTable from '@/components/Molecules/co-table/CoTable.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoMyTicket from '@/components/Organisms/co-my-ticket/CoMyTicket.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';
import CoTippy from '@/components/Atoms/co-tippy/CoTippy.vue';
import CoPrice from '@/components/Atoms/co-price/coPrice.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';

export default {
    name: 'MemberAccountTimePass',
    components: {
        TimepassCardWallet,
        TimepassCard,
        CoTable,
        CoAlert,
        CoText,
        CoButton,
        CoModal,
        CoMyTicket,
        CoLink,
        CoTippy,
        CoConfirmation,
    },
    i18n: {
        messages: {
            en: {
                activetimepass: 'Active time pass',
                redeemablepasses: 'Redeemable time passes',
                nopasses: 'There are currently no redeemable time passes in this wallet',
                noactivepasses: 'No active time pass',
                addtimepasses: 'Add time passes',
                notimepassestobuy: 'There are currently no time passes available for purchase.',
                usetimepass: 'Use now',
                usetimepassconfirmation:
                    'If you proceed this pass will be activated immediately and redeemed. Are you sure?',
                userneedsactiveplan:
                    'User needs an active plan so that the time time pass purchase can be added to the next invoice. The plan can be paid or free.',
                assignplannow: 'Assign a plan now',
                createtimepasses: 'Create time passes',
                canonlyredeemone: 'You can only use one time pass at a time',
            },
            de: {
                activetimepass: 'Aktiver Zeitpass',
                redeemablepasses: 'Einlösbare Zeitpässe',
                nopasses: 'Es gibt derzeit keine einlösbaren Zeitpässe in diesem Wallet.',
                noactivepasses: 'Kein aktiver Zeitpass',
                addtimepasses: 'Zeitpässe hinzufügen',
                notimepassestobuy: 'Derzeit sind keine Zeitpässe zum Kauf verfügbar.',
                usetimepass: 'Jetzt einlösen',
                usetimepassconfirmation:
                    'Wenn du fortfährst wird dieser Pass sofort aktiviert und entwertet. Bist du sicher?',
                userneedsactiveplan:
                    'Benutzer benötigt einen aktiven Plan, damit der Kauf des Zeitpasses zur nächsten Rechnung hinzugefügt werden kann. Der Plan kann kostenpflichtig oder kostenlos sein.',
                assignplannow: 'Weise jetzt einen Plan zu',
                createtimepasses: 'Zeitpässe erstellen',
                canonlyredeemone: 'Du kannst nur einen Zeitpass gleichzeitig benutzen',
            },
        },
    },
    data() {
        return {
            me: this.$store.state.me,
            paymentMethods: [],
            errorMsg: '',
            user: {},
            loading: false,

            timepassesWallet: [],
            timepassesSpace: [],
            activeTimepass: {},

            hasPlan: true,
        };
    },
    created() {
        const { ID } = this.$route.params;
        this.adminGetUserByID(ID);
    },
    computed: {
        userID() {
            return this.$route?.params?.ID;
        },
    },
    mounted() {
        this.getTimepassesWallet();
        this.getTimepassesSpace();
        this.getActiveTimePass();
        this.getPlanByID();
    },
    methods: {
        getPlanByID() {
            axios
                .get(`/admin/community/member/plan?userid=${this.$route.params.ID}`)
                .then((response) => {
                    if (!get(response, 'data.current.Plan', null)) this.hasPlan = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.hasPlan = false;
                });
        },
        setBreadcrumbs() {
            this.$store.commit('SET_BREADCRUMBS', [
                {
                    text: 'Manage',
                    to: '/admin',
                },
                {
                    text: 'Members',
                    to: '/admin/community/member/list',
                },
                {
                    text: this.user.Profile.Name,
                    to: `/admin/community/member/view/${this.$route.params.ID}`,
                },
                {
                    text: 'Plan',
                },
            ]);
        },
        adminGetUserByID(ID) {
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    this.user = response;
                    if (this.user.Profile && this.user.Profile.Name) {
                        if (this.user.Profile.FirstName === '' || this.user.Profile.FirstName == null) {
                            const names = this.user.Profile.Name.split(' ');
                            this.user.Profile.FirstName = names[0];
                            names.splice(0, 1);
                            if (names.length > 1) {
                                this.user.Profile.LastName = names.join(' ');
                            } else {
                                this.user.Profile.LastName = names[0];
                            }
                        }
                    }
                    this.setBreadcrumbs();
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t get user data',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.$router.push(`/admin/community/member/list`);
                });
        },
        getActiveTimePass() {
            this.loading = true;
            const { ID } = this.$route.params;
            axios({
                method: 'GET',
                url: `/admin/time-pass/active/${ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.activeTimepass = response.data;
                        this.activeTimepass.appliedTime = format(
                            fromUnixTime(this.activeTimepass.Timestamp),
                            'dd.MM.yyyy HH:mm'
                        );
                        this.activeTimepass.ValidUntillTime = format(
                            fromUnixTime(this.activeTimepass.ValidUntil),
                            'dd.MM.yyyy HH:mm'
                        );
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        getTimepassesWallet() {
            this.loading = true;
            const { ID } = this.$route.params;
            axios({
                method: 'GET',
                url: `/admin/time-pass/wallet/${ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.timepassesWallet = [];
                        orderBy(response.data.WalletItems, 'CreatedAt', ['desc']).forEach((element) => {
                            this.timepassesWallet.push(element);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getTimepassesSpace() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/time-pass/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        response.data.Passes.forEach((element) => {
                            this.timepassesSpace.push(element);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        updateWallet() {
            this.$refs.buyTimePasses.hide();
            this.getTimepassesWallet();
            this.getActiveTimePass();
        },
        checkPaymentMethods(ID) {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                    if (this.paymentMethods && this.paymentMethods.length === 0) {
                        this.$router.push(`/account/time-passes/buy/${ID}`);
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    this.$router.push(`/account/time-passes/buy/${ID}`);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    }
                });
        },
        applyTimePassAdmin(ID) {
            this.loading = true;
            this.applyTimepassRequest = {
                WalletItemID: ID,
                SpaceID: this.$store.state.spaceID,
                ForUser: this.userID,
                UserID: this.userID,
                ByUser: this.$store.state.me.ID,
            };
            axios({
                method: 'POST',
                url: 'admin/time-pass/apply',
                withCredentials: true,
                data: JSON.stringify(this.applyTimepassRequest),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Successfully applied Time Pass',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.updateWallet();
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to apply Time Pass',
                        Details: error.response.data,
                    };
                    if (error.response.data.message === 'user has active time pass') {
                        msg.Message = 'You already have an active Time Pass';
                    }
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
        },
    },
};
