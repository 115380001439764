<template>
    <div class="minievent" v-on:click="viewItem(object.object.Slug)">
        <div class="d-flex w-100">
            <div class="menu-icon mr-3"><b-icon icon="soundwave"></b-icon></div>
            <div>
                <p v-bind:class="timeCssClasses(object.object.StartDate + '000')" class="mb-0 small">
                    <coDate :date="object.object.StartDate" format="relative" />
                </p>
                <span class="p mb-1" v-html="object.object.Title"></span>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

// styling for event time-date
//colors
@orange: #ff4713;
@blue: #0055b8;
.minievent .today,
.screen .today {
    // color: darken(@orange, 10%);
    color: @purple;
}

.minievent .tomorrow,
.screen .tomorrow {
    color: @purple;
}
</style>

<script>
import moment from 'moment';

export default {
    name: 'MiniEventCard',
    props: ['object'],
    data() {
        return {
            timer: null,
            currentIndex: 0,
            imageURL: '',
        };
    },

    mounted() {},
    methods: {
        timeCssClasses(value) {
            const time = parseInt(value);
            const timeDelta = time - Date.now();
            const dayInMs = 86400000;
            const result = [];

            if (moment(time).isSame(moment(), 'day')) {
                result.push('today');
            } else if (moment(time).isSame(moment().add(1, 'days'), 'day')) {
                result.push('tomorrow');
            }
            return result;
        },

        formatDate(value, format) {
            return moment(value).format('dd, DD.MM.YY, h:mm:ss');
        },
        viewItem(slug) {
            this.$router.push(`/event/${slug}`);
        },

        filterPictures(imageurl) {
            const randomItem = parseInt(Math.random() * 16) + 1;
            const images = require.context('@/assets/gradients/', true);
            if (imageurl && imageurl.match(/\/$/gi)) {
                return images(`./Platform_Gradients-${randomItem}.jpg`);
            }
            return imageurl;
        },
    },
};
</script>

<style scoped>
.container {
    height: 100%;
    width: 100%;
    padding: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.image-view {
    position: relative;
    height: 100%;
    width: 100%;
    /* padding-top: 66.66%; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-view img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    object-fit: cover;
}

.left {
    position: absolute;
    top: calc(50% - 18px);
    left: 0;
    /* display: none; */
}
.right {
    position: absolute;
    top: calc(50% - 18px);
    right: 0;
}

::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #ff0000;
}

@media (max-width: 1000px) {
    .container {
        margin-right: 0px !important;
        margin-left: 0px !important;
        width: 100%;

        max-width: unset;
    }
}
</style>
