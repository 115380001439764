<style lang="less" scoped>
@import './CoCreateResourceLinkPage.less';

@import '~@/assets/less/variables.less';
.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

@media (min-width: 768px) {
    .col-image {
        height: 70vh;
    }
    .column {
        min-height: 70vh;
    }
}
</style>

<template>
    <div class="bg-white p-0 p-lg-3 w-100 round-unify pb-5 column" style="overflow-y: visible !important">
        <div>
            <div v-for="(linkedResource, index) in linkedResources" :key="linkedResource.ID + index" class="mb-3">
                <div class="">
                    <div class="d-flex flex-wrap align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div>
                                <CoSelect
                                    label="Book/link another resource"
                                    :items="['Book', 'Link']"
                                    :value="'Book'"
                                    :listOfStrings="true"
                                    @select="selectBookOrLink($event, index)"
                                ></CoSelect>
                            </div>
                            <div>
                                <CoSelect
                                    label="resource"
                                    class="ml-2"
                                    :items="resources"
                                    placeholder="Select resource"
                                    :listOfStrings="false"
                                    @select="selectedItem($event, index)"
                                ></CoSelect>
                            </div>
                        </div>
                        <div v-if="index > 0" @click="removeLinkedResource(index)" class="mt-1">
                            <CoLabel text=" "></CoLabel>
                            <CoIcon name="trash" class="pointer"></CoIcon>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <CoInput label="Number of total capacity" placeholder="0" @input="capacityInput($event, index)">
                    </CoInput>
                </div>
                <div class="mt-2">
                    <div>
                        <CoLabel text="Total up the cost"></CoLabel>
                    </div>
                    <div>
                        <CoCheckbox
                            name="Charge for linked resource"
                            v-model="linkedResource.ChargeForResource"
                        ></CoCheckbox>
                    </div>
                </div>
                <div v-if="index == linkedResources.length - 1" class="mt-2">
                    <CoButton
                        class="text-muted"
                        iconleft="plus"
                        label="Add another resource"
                        type="outline"
                        @onClick="addLinkedResource"
                    ></CoButton>
                </div>
            </div>
        </div>
        <div>
            <h3 class="mt-4 ml-2">Synchronization</h3>
            <CoLabel text="Select target"></CoLabel>
            <div class="d-flex align-items-center">
                <div>
                    <CoSelect :items="['Google Calendar']"></CoSelect>
                </div>
                <div class="ml-2">
                    <CoButton
                        label="Connect"
                        style="color: white"
                        type="button"
                        @onClick="getGoogleCalendarOAuth2Url"
                    ></CoButton>
                </div>
            </div>
            <div class="mt-2">
                <CoSelect
                    label="Select Calendar Account"
                    placeholder="Select account"
                    :tokens="true"
                    :items="tokens"
                    @select="selectingToken"
                ></CoSelect>
            </div>
            <div class="mt-2">
                <CoSelect
                    label="Select the calendar you want to sync with"
                    placeholder="Select calendar"
                    :listOfStrings="true"
                    :disabled="calendars.length == 0"
                ></CoSelect>
            </div>
        </div>
        <!-- <div>
            <h3 class="mt-4 ml-2">Link a lock</h3>
        </div> -->
    </div>
</template>

<script>
import EventBus from '../../../eventBus';
import axios from 'axios';
import moment from 'moment';

export default {
    name: 'CoCreateResourceLinkPage',

    data() {
        return {
            me: this.$store.state.me,

            windowWidth: window.innerWidth,
            minWidth: true,
            calendarSelected: false,
            calendars: [],
            calendarsLoading: false,
            tokens: [],

            loading: false,
            loadingOAuth2URL: true,
            resources: [],
            linkedResources: [{ Name: '', Capacity: '', ID: '', Book: 'Book', Resource: {} }],

            id: this.$route.params.ID, // current or upcoming
        };
    },
    created() {},

    mounted() {
        this.getTokens();
        this.listResources();
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth >= 768) {
                this.minWidth = true;
            } else {
                this.minWidth = false;
            }
        });
    },
    watch: {},
    methods: {
        getGoogleCalendarOAuth2Url() {
            this.loadingOAuth2URL = true;
            axios({
                method: 'GET',
                url: `/admin/oauth2/google/calendar`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        window.open(response.data.url, '_blank').focus();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Error while getting google calendar auth url.',
                        Details: error,
                    });
                })
                .finally(() => {
                    this.loadingOAuth2URL = false;
                });
        },
        capacityInput(item, index) {
            this.linkedResources[index].Capacity = item;
        },
        selectedItem(item, index) {
            var linkedResource = this.linkedResources[index];

            linkedResource.ID = item.ID;
            linkedResource.Resource = item;

            this.linkedResources[index] = linkedResource;
        },
        selectBookOrLink(item, index) {
            this.linkedResources[index].Book = item;
        },
        prepareExternalCalendars() {
            var externalCalendars = [];

            if (this.selectedCalendar) {
                externalCalendars.push({
                    ID: this.selectedCalendar.ID,
                    Name: this.selectedCalendar.Name,
                    tokenID: this.selectedToken.ID,
                });
            }

            return externalCalendars;
        },
        selectingToken(token) {
            this.token = token;

            this.getCalendars(token.ID);
        },
        formatTimestamp(timestamp) {
            if (!timestamp) return 'n/a';
            return moment.unix(timestamp).format('DD/MM/YYYY HH:mm');
        },
        getTokens() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/oauth2/google/calendar/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Tokens) {
                        this.tokens = response.data.Tokens;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getCalendars(tokenID) {
            this.calendarsLoading = true;

            axios({
                method: 'GET',
                url: `/admin/booking/resource/external-calendars/${tokenID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.calendars = response.data.Calendars;
                    }
                    this.calendarsLoading = false;
                })
                .catch((error) => {
                    this.calendarsLoading = false;
                    EventBus.$emit('ERROR', {
                        Message: "Can't list external calendars",
                    });
                });
        },
        addLinkedResource() {
            this.linkedResources.push({
                Name: '',
                Resource: {},
                Capacity: '',
                Book: '',
                ID: '',
            });
        },
        onTokenChange(token) {
            this.getCalendars(this.selectedToken.ID);
        },
        listResources() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response.data && response.data['Resources'].length != 0) {
                        this.resources = response.data['Resources'];

                        this.resources.sort(function (a, b) {
                            return a.Name.localeCompare(b.Name);
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        removeLinkedResource(index) {
            this.linkedResources.splice(index, 1);
        },
    },
};
</script>
