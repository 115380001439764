<style lang="less" scoped>
h3 {
    font-weight: bold;
}
</style>

<template>
    <div class="">
        <!-- header start -->
        <CoCard variant="border" class="mb-3">
            <div class="d-flex align-items-center w-100">
                <CoHeadline :level="2" class="">{{ $t('labels.paymentmethods') }}</CoHeadline>
                <CoButton
                    variant="primary"
                    @click="$router.push('/account/billing/add-payment-method')"
                    class="d-none d-md-block ml-auto"
                    :label="$t('labels.add')"
                />
            </div>
        </CoCard>
        <!-- header end -->
        <!-- payment methods -->
        <div class="">
            <!-- payment methods -->
            <transition name="fade">
                <div v-if="paymentMethods && paymentMethods.length > 0 && !loading">
                    <CoPaymentMethodCard
                        v-for="pm of paymentMethods"
                        :key="pm.ID"
                        :object="pm"
                        :disableDelete="paymentMethods.length === 1"
                        class="mb-3"
                        @delete="deletePM"
                        @setAsDefault="setAsDefault"
                    ></CoPaymentMethodCard>
                </div>
            </transition>
            <!-- if emtpy and not loading -->
            <transition name="fade">
                <div class="text-center m-0 mt-5" v-if="(!paymentMethods || paymentMethods.length === 0) && !loading">
                    <div class="col">
                        <div class="mb-4">{{ $t('nopaymentmethod') }}</div>
                    </div>
                </div>
            </transition>
            <!-- if loading show sckeletons -->
            <transition name="fade">
                <div v-if="loading">
                    <CoPaymentMethodCard loading class="mb-3" />
                    <CoPaymentMethodCard loading class="mb-3" />
                    <CoPaymentMethodCard loading class="mb-3" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import i18n from 'vue-i18n';
import axios from 'axios';
import EventBus from '@/eventBus';

import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoPaymentMethodCard from '@/components/Organisms/co-payment-method-card/CoPaymentMethodCard.vue';

export default {
    i18n: {
        messages: {
            en: {
                nopaymentmethod: "You don't have any payment methods.",
            },
            de: {
                nopaymentmethod: 'Du hast noch keine Zahlungsmethoden.',
            },
        },
    },
    name: 'PaymentMethods',
    components: {
        CoButton,
        CoCard,
        CoPaymentMethodCard,
    },
    data() {
        return {
            paymentMethods: [],
            loading: true,
            errorMsg: null,

            publicKey: '',
            accountID: '',
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    setTimeout(() => {
                        this.loading = false;
                        this.paymentMethods = [];
                        this.paymentMethods = paymentMethods;
                    }, 1000);
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                });
        },
        setAsDefault(val) {
            this.paymentMethods = this.paymentMethods.map((item) => ({
                ...item,
                Default: item.ID === val.ID,
            }));
        },

        deletePM(val) {
            this.paymentMethods = this.paymentMethods.filter((pm) => pm.ID !== val.ID);
            if (this.paymentMethods.length === 1) {
                this.paymentMethods[0].Default = true;
            }
        },
    },
};
</script>
