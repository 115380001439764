
































































































import axios from 'axios';
import { get } from 'lodash';
import Bugsnag from '@bugsnag/js';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';

// Create a new axios instance to avoid conflicts with the global instance headers
const axiosInstance = axios.create();

export default {
    name: 'CoLinkPreview',
    components: { CoCard, CoHeadline, CoIcon, CoSkeleton },
    props: {
        url: {
            type: String,
        },
        defaultClasses: {
            type: Object,
            default: null,
        },
        embedClasses: {
            type: Object,
            default: null,
        },
        videoClasses: {
            type: Object,
            default: null,
        },
        preventEmbedding: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: null,
            loading: false,
            embedHTML: null,
            media: null,
            isInvalidURL: false,
        };
    },
    computed: {
        isEmailLink() {
            return /mailto:/gi.test(this.url);
        },
    },
    mounted() {
        if (!this.url || this.url === '') return;
        this.processLinkPreview();
    },
    watch: {
        url() {
            this.processLinkPreview();
        },
    },
    methods: {
        processLinkPreview() {
            if (/mailto:/gi.test(this.url || '')) {
                this.title = this.url.replace(/mailto:/gi, '');
                return;
            }

            if (this.preventEmbedding) {
                this.getLinkInfo();
                return;
            }

            //make sure url is valid before proceeding
            try {
                new URL(this.url);
            } catch (err) {
                this.isInvalidURL = true;
                Bugsnag.notify(err);
                return;
            }

            let oembedApi = null;
            let mediaObj = {};
            this.media = {};

            switch (true) {
                case /youtube-nocookie\.com\/|youtube\.com\/|youtu\.be\//gi.test(this.url):
                    mediaObj['name'] = 'youtube';
                    mediaObj['type'] = 'video';
                    oembedApi = `https://www.youtube.com/oembed?url=${encodeURIComponent(this.url)}`;
                    break;
                case /vimeo\.com/gi.test(this.url):
                    mediaObj['name'] = 'vimeo';
                    mediaObj['type'] = 'video';
                    oembedApi = `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(this.url)}`;
                    break;
                case /spotify\.com/gi.test(this.url):
                    mediaObj['name'] = 'spotify';
                    mediaObj['type'] = 'audio';
                    oembedApi = `https://open.spotify.com/oembed?url=${encodeURIComponent(this.url)}`;
                    break;
                case /soundcloud\.com/gi.test(this.url):
                    mediaObj['name'] = 'soundcloud';
                    mediaObj['type'] = 'audio';
                    oembedApi = `https://soundcloud.com/oembed?maxheight=166&format=json&url=${encodeURIComponent(
                        this.url
                    )}`;
                    break;
                case /tiktok\.com/gi.test(this.url):
                    mediaObj['name'] = 'tiktok';
                    mediaObj['type'] = 'video';
                    oembedApi = `https://www.tiktok.com/oembed?url=${encodeURIComponent(this.url)}`;
                    break;
            }
            if (oembedApi) {
                axiosInstance
                    .get(oembedApi)
                    .then((response) => {
                        this.embedHTML = response.data.html;
                        this.media = mediaObj;
                    })
                    .catch((err) => {
                        this.media = null;
                        this.getLinkInfo();
                        console.warn('could not load oembed code for ' + mediaObj['type']);
                    });
            } else {
                this.media = null;
                this.getLinkInfo();
            }
        },
        getLinkInfo() {
            var url = new URL(this.url);
            this.loading = true;
            axios({
                method: 'GET',
                url: `/dashboard/og?url=${this.url}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.title = get(response, 'data.title');
                    this.title.length < 3 ? (this.title = url.hostname) : null;
                })
                .catch((error) => {
                    this.title = url.hostname;
                    console.log('error', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openURL(url) {
            if (!url) return false;
            window.open(url, '_blank').focus();
        },
    },
};
