<template>
    <div>
        <ViewEventParticipants
            :me="$store.state.me"
            :slug="$route.params.slug"
            :eventTicketsOn="$unleash.isEnabled('frontend.eventTicketsOn')"
            :isPaymentsEnabled="$store.state.billingTurnedOn"
        />
    </div>
</template>

<script>
import ViewEventParticipants from '@/components/Templates/User/Event/Participants/ViewEventParticipants.vue';

export default {
    name: 'CoViewEventParticipants',
    components: {
        ViewEventParticipants,
    },
};
</script>
