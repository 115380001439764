<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center bg-white search-bar-wrapper rounded-pill">
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="search"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <b-button
                variant="primary"
                to="/admin/monetization/plan-upgrade/create"
                :disabled="!$store.state.billingTurnedOn"
                >Create</b-button
            >
        </div>
        <!-- search and actions end -->

        <!-- plan upgrades start -->
        <div class="container-fluid p-0 m-0">
            <!-- if stripe payment was not connected -->
            <div class="row m-0 mt-5 px-sm-4" v-if="!$store.state.billingTurnedOn">
                <CoCard variant="warning">
                    <CoHeadline>First things first</CoHeadline>
                    <CoText>
                        Before creating paid plans and upgrades, you have to setup automated payments using Stripe and
                        create at least one Tax rate.
                    </CoText>
                    <CoButton variant="primary" @click="$router.push('/admin/monetization/payments/stripe')"
                        >Setup payments</CoButton
                    >
                </CoCard>
            </div>
            <!-- if emtpy and not loading -->
            <div class="row m-0 mt-5 px-sm-4" v-else-if="feed.length === 0 && !loading">
                <div class="co-card bg-secondary w-100">
                    <h1>Let's get started.</h1>
                    <p>You have not created any upgrades yet.</p>
                    <router-link
                        class="btn btn-primary"
                        to="/admin/monetization/plan-upgrade/create"
                        :disabled="!$store.state.billingTurnedOn"
                    >
                        Create your first upgrade
                    </router-link>
                </div>
            </div>
            <!-- if loading -->
            <div class="row text-center m-0 mt-5" v-else-if="feed.length === 0 && loading">
                <div class="col">
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>
            </div>
            <div class="m-0 mt-4 px-sm-4">
                <div class="mb-3" v-for="(item, i) in filteredItems" :key="item.ID">
                    <CoCardUpgrade :upgrade="item" variant="white" clickable @click="view(item.ID)">
                        <template v-slot:actions>
                            <CoDropdown to-right disable-propagation>
                                <template v-slot:button>
                                    <CoRoundButton icon="three-dots" variant="icon" />
                                </template>
                                <CoDropdownItem @click="edit(item.ID)">Edit</CoDropdownItem>
                            </CoDropdown>
                        </template>
                    </CoCardUpgrade>
                </div>
            </div>
        </div>
        <!-- plan upgrades end -->
    </div>
</template>

<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<script>
import axios from 'axios';

import CoCardUpgrade from '@/components/Molecules/co-card-upgrade/CoCardUpgrade.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';

export default {
    name: 'ListUpgrades',
    components: { CoCardUpgrade, CoDropdown, CoDropdownItem, CoRoundButton, CoCard, CoHeadline, CoText, CoButton },
    data() {
        return {
            form: {},
            loading: false,
            feed: [],
            search: '',
        };
    },
    mounted() {
        this.getPlanUpgrades();
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        filteredItems() {
            return this.feed.filter((item) => {
                if (item.Name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 == true) {
                    return true;
                }
                if (item.PriceInCents.indexOf(this.search) > -1 == true) {
                    return true;
                }
                return false;
            });
        },
    },
    methods: {
        view(id) {
            this.$router.push(`/admin/monetization/plan-upgrade/${id}`);
        },
        edit(id) {
            this.$router.push(`/admin/monetization/plan-upgrade/edit/${id}`);
        },
        getPlanUpgrades() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/extra/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        response.data.Extras.forEach((element) => {
                            this.feed.push(element);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
    },
};
</script>
