




























































import CoImage from '@/components/Atoms/co-image/CoImage.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';

export default {
    name: 'CoCoappBadge',
    components: {
        CoImage,
        CoText,
        CoIcon,
        CoConfirmation,
    },
    props: {
        asAdmin: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    mounted() {
        // do something
    },
    methods: {
        click() {
            // if admin show modal
            if (this.asAdmin) {
                this.$refs['remove-badge'].show();
            } else {
                // else redirect to coapp landing page
                window.open(
                    'https://www.coapp.io?utm_source=in-app&utm_medium=in-app&utm_campaign=powered-by',
                    '_blank'
                );
            }
        },
        upgrade() {
            this.$router.push('/admin/account');
        },
    },
};
