var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co input position-relative",class:( _obj = {
        'has-icon': _vm.icon
    }, _obj[("type-" + _vm.type)] = true, _obj.readonly = _vm.readonly, _obj.disabled = _vm.disabled, _obj['has-error'] =  _vm.validationError, _obj )},[(_vm.label)?_c('CoLabel',{staticClass:"d-block",attrs:{"text":_vm.label}}):_vm._e(),_c('div',{staticClass:"d-flex"},[(_vm.icon)?_c('CoIcon',{staticClass:"icon align-self-center",attrs:{"name":_vm.icon}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.content),expression:"content",modifiers:{"trim":true}}],ref:"coInputElem",staticClass:"align-self-center",class:( _obj$1 = {
                'has-icon': _vm.icon,
                'has-icon-right': _vm.validationError
            }, _obj$1[("type-" + _vm.type)] = true, _obj$1['has-error'] =  _vm.validationError, _obj$1['bg-grey'] =  _vm.greyTheme, _obj$1.mobile = _vm.isMobile && _vm.reduce, _obj$1[_vm.background] = true, _obj$1['no-border'] =  _vm.noBorder, _obj$1 ),style:({ width: _vm.width }),attrs:{"enterkeyhint":_vm.enterkeyhint,"name":(_vm.name || _vm.label || '').replace(/\W/g, '_').toLowerCase(),"type":_vm.passwordRevealed ? 'text' : _vm.type,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"disabled":_vm.disabled,"required":_vm.required,"min":_vm.min,"max":_vm.max,"step":_vm.step,"pattern":_vm.type === 'number' ? '[0-9]+([\.,][0-9]+)?' : null,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value,"value":(_vm.content)},on:{"paste":_vm.handleInput,"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value.trim()},_vm.handleInput],"change":_vm.handleChange,"focus":_vm.handleFocus,"blur":[_vm.handleBlur,function($event){return _vm.$forceUpdate()}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter($event)},"keydown":_vm.handleKeyDown}}),(_vm.type === 'password' && !_vm.passwordRevealed)?_c('CoIcon',{staticClass:"icon align-self-center",staticStyle:{"cursor":"pointer"},attrs:{"name":"eye"},nativeOn:{"click":function($event){_vm.passwordRevealed = !_vm.passwordRevealed}}}):(_vm.type === 'password' && _vm.passwordRevealed)?_c('CoIcon',{staticClass:"icon align-self-center",staticStyle:{"cursor":"pointer"},attrs:{"name":"eye-slash"},nativeOn:{"click":function($event){_vm.passwordRevealed = !_vm.passwordRevealed}}}):_vm._e(),(_vm.validationError)?_c('CoTippy',{ref:"tippyErrorMessage",attrs:{"tippyOptions":{
                showOnCreate: _vm.validationError ? true : null,
            },"text":_vm.required
                    ? _vm.$t('errors.inputrequired') + (_vm.validationHint ? '. ' + _vm.validationHint : '')
                    : _vm.validationHint || _vm.$t('errors.inputinvalid')}},[_c('CoIcon',{staticClass:"icon align-self-center",attrs:{"name":"exclamation-circle"}})],1):_vm._e()],1),(_vm.suggester && (_vm.suggestionsLoading || _vm.suggestions.length > 0))?_c('CoDropdown',{staticClass:"co-dropdown-suggestions position-absolute",style:(_vm.suggestionsLoading ? 'line-height: 0;' : ''),attrs:{"loading":_vm.suggestionsLoading,"tippyOptions":{
            showOnCreate: true,
            hideOnClick: false,
        }}},_vm._l((_vm.suggestions),function(suggestion,index){return _c('CoDropdownItem',{attrs:{"title":suggestion.label,"icon":suggestion.icon ? suggestion.icon : null,"thumbnail":suggestion.thumbnail ? suggestion.thumbnail : null,"active":_vm.selectedItem === index},on:{"click":function($event){return _vm.handleSelect(suggestion)}}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }