var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-image",class:{
        rounded: _vm.rounded,
    }},[_c('div',{directives:[{name:"lazyload",rawName:"v-lazyload"}],ref:"imageWrapper",staticClass:"image_wrapper"},[_c('img',{staticClass:"image_item",class:( _obj = {}, _obj[_vm.objectFit] = true, _obj.pointer = _vm.lightbox, _obj.imageWidthAuto = _vm.imageWidthAuto, _obj ),attrs:{"data-url":_vm.src,"alt":_vm.alt,"src":""},on:{"click":function($event){return _vm.imgClick($event)},"imageloaded":_vm.imageLoaded}}),_c('div',{staticClass:"placeholder align-items-center justify-content-center",class:( _obj$1 = {}, _obj$1[_vm.placeholderFormat] = true, _obj$1['d-none'] =  _vm.placeholderDisabled, _obj$1['d-flex'] =  !_vm.placeholderDisabled, _obj$1 )},[_c('div',{staticClass:"placeholder-icon",class:( _obj$2 = {}, _obj$2[_vm.placeholder] = true, _obj$2.rounded = _vm.rounded, _obj$2 ),style:({
                    backgroundSize: '100%',
                    width: _vm.placeholderSize,
                    height: _vm.placeholderSize,
                })})])]),(_vm.lightbox)?_c('portal',{attrs:{"selector":"#portal-target-for-modal"}},[(_vm.lightboxShow)?_c('div',{staticClass:"lightbox-wrapper d-flex align-items-stretch"},[_c('div',{staticClass:"lightbox flex-fill d-flex align-items-center justify-content-center",on:{"click":function($event){$event.stopPropagation();return _vm.hideLightbox($event)}}},[_c('div',{staticClass:"lightbox-content",class:{
                        loaded: _vm.lightboxLoaded,
                    }},[_c('CoRoundButton',{staticClass:"close-btn",attrs:{"icon":"x-lg","variant":"light"}}),_c('img',{attrs:{"alt":_vm.alt,"src":_vm.src},on:{"load":function($event){return _vm.lightboxLoad($event)}}})],1)])]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }