































// replaces sidebar.vue to implement updated page grid
import { get } from 'lodash';
import CoLeftMainNavigation from '@/components/Organisms/co-left-main-navigation/CoLeftMainNavigation.vue';
import CoActiveMembersWidget from '@/components/Organisms/co-active-members-widget/CoActiveMembersWidget.vue';
import CoUpcomingEventsWidget from '@/components/Organisms/co-upcoming-events-widget/CoUpcomingEventsWidget.vue';

import UserMainGrid from '../../Templates/User/MainGrid/UserMainGrid.vue';

export default {
    name: 'UserMain',
    components: {
        UserMainGrid,
        CoLeftMainNavigation,
        CoActiveMembersWidget,
        CoUpcomingEventsWidget,
    },
    data() {
        return {
            isCirclesOn: get(this.$store, 'state.circlesOn', null),
            spaceID: get(this.$store, 'state.space.ID', null),
            space: get(this.$store, 'state.space', {}),
            isInMobile: get(this.$store, 'state.isInMobile', false),
            metaGridOptions: {},
        };
    },
    computed: {
        showUpgradeBar() {
            if (
                this.$store &&
                this.$store.state &&
                this.$store.state.space &&
                this.$store.state.space.PlanSlug === 'free'
            ) {
                return true;
            }
            return false;
        },

        asAdmin: {
            get() {
                if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
                    return true;
                }
                return false;
            },
        },

        showWelcomeToCoapp() {
            if (
                this.$store.state.welcome_checklist.DownloadMobileApp &&
                this.$store.state.welcome_checklist.FirstPost &&
                this.$store.state.welcome_checklist.InvitedMembers &&
                this.$store.state.welcome_checklist.Logo &&
                this.$store.state.welcome_checklist.PaymentsOn &&
                this.$store.state.welcome_checklist.UserProfileSetup
            ) {
                return false;
            }

            return true;
        },
    },
    watch: {
        // get route meta options for custom rendering of the main grid
        $route(to, from) {
            this.metaGridOptions = get(to, 'meta.mainGridOptions', {});
        },
    },
    mounted() {
        this.metaGridOptions = get(this.$route, 'meta.mainGridOptions', {});
    },
};
