











































































export default {
    name: 'CoLink',
    props: {
        to: {
            type: String,
            default: '/',
        },

        styles: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        newTab: {
            type: Boolean,
            default: false,
        },
        isExternalLink: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            let classList = this.styles.split(' ');
            if (this.disabled) {
                classList.push('disabled');
            }
            return classList.join(' ');
        },
    },

    data() {
        return {};
    },
    methods: {
        click(event) {
            if (this.disabled) return;
            this.$emit('click', event);
        },
    },
};
