






















import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';

import { get } from 'lodash';
import axios from 'axios';

export default {
    name: 'TermsAndConditions',
    components: {
        CoSkeleton,
        CoHeadline,
        CoText,
        CoDate,
    },
    data() {
        return {
            TermsAndConditions: null,
            loading: true,
            spaceName: get(this, '$store.state.space.Name', null),
            logoURL: get(this, '$store.state.space.logoURL', null),
            CreatedAt: null,
        };
    },
    mounted() {
        this.loading = true;
        axios({
            method: 'GET',
            url: '/space/get-terms-and-conditions',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                this.TermsAndConditions = get(response, 'data.Content', '');
                this.CreatedAt = get(response, 'data.CreatedAt', null);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.loading = false;
            });
    },
};
