<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center bg-white search-bar-wrapper rounded-pill">
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="tableFilter"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <b-button variant="primary" v-b-modal.modal-create-location>Create</b-button>
        </div>
        <!-- search and actions end -->

        <div class="px-sm-4">
            <b-overlay :show="loading">
                <div class="mt-4">
                    <b-table
                        striped
                        hover
                        :items="objects"
                        :fields="fields"
                        sort-icon-left
                        :per-page="perPage"
                        stacked="md"
                        :filter="tableFilter"
                        :current-page="currentPage"
                        @filtered="onFiltered"
                        class="table table-borderless table-responsive w-100 d-md-table"
                    >
                        <template v-slot:cell(Name)="data">
                            <div @click="openEditModal(data.item)">
                                {{ data.item.Name }}
                            </div>
                        </template>
                        <template v-slot:cell(Actions)="row">
                            <!-- <b-button size="sm" @click="viewEvent(row.item.object.Slug)" class="mr-2">Edit</b-button> -->
                            <b-button size="sm" class="mr-2" variant="outline-primary" @click="openEditModal(row.item)"
                                >Edit
                            </b-button>
                            <b-button size="sm" class="mr-2" variant="danger" @click="deleteLocation(row.item)"
                                >Delete
                            </b-button>
                        </template>
                    </b-table>
                    <div v-if="objects.length == 0" class="my-3">You don't have any locations yet. Create some.</div>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        aria-controls="my-table"
                    ></b-pagination>
                </div>
            </b-overlay>
        </div>

        <!-- Modals -->
        <!-- Create -->
        <b-modal
            id="modal-create-location"
            ref="modal"
            title="Creating new location"
            @show="resetCreateModal"
            @hidden="resetCreateModal"
            @ok="handelCreateModalOk"
            centered
        >
            <form ref="createform" @submit.stop.prevent="handleCreateSubmit">
                <b-form-group
                    :state="nameState"
                    label="Name your location"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                >
                    <b-form-input
                        id="name"
                        v-model="name"
                        placeholder="Name"
                        class="rounded-pill"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    :state="addressState"
                    label="Add address location"
                    label-for="address-input"
                    invalid-feedback="Address is required"
                >
                    <b-form-input
                        id="address"
                        v-model="address"
                        placeholder="Address"
                        class="rounded-pill"
                        required
                    ></b-form-input>
                </b-form-group>
            </form>
            <b-spinner v-if="loading" variant="success" label="Spinning"></b-spinner>
        </b-modal>
        <b-modal
            id="modal-edit-location"
            ref="modal2"
            title="Editing location"
            @hidden="resetEditModal"
            @ok="handelEditModalOk"
            centered
        >
            <form ref="editform" @submit.stop.prevent="handleEditSubmit">
                <b-form-group
                    :state="nameState"
                    label="Location name"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                >
                    <b-form-input
                        id="name"
                        v-model="name"
                        placeholder="Name"
                        class="rounded-pill"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    :state="addressState"
                    label="Location address"
                    label-for="address-input"
                    invalid-feedback="Address is required"
                >
                    <b-form-input
                        id="address"
                        v-model="address"
                        placeholder="Address"
                        class="rounded-pill"
                        required
                    ></b-form-input>
                </b-form-group>
            </form>
            <b-spinner v-if="loading" variant="success" label="Spinning"></b-spinner>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';

export default {
    name: 'SpaceLocations',

    data() {
        return {
            view: 1,
            tableFilter: '',
            perPage: 10,
            currentPage: 1,
            objects: [],

            fields: [
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Address',
                    label: 'Address',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                },
            ],

            totalRows: 0,
            loading: false,

            // create form data
            name: '',
            nameState: null,
            address: '',
            addressState: null,
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.listLocations();
    },
    watch: {},
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        listLocations() {
            axios({
                method: 'GET',
                url: '/admin/location/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Locations.length != 0) {
                        this.objects = response.data.Locations;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        deleteLocation(obj) {
            const data = JSON.stringify(obj);
            axios({
                method: 'DELETE',
                url: '/admin/location',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.listLocations();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        createLocation(obj) {
            const data = JSON.stringify(obj);

            axios({
                method: 'POST',
                url: '/admin/location',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.name = '';
                    this.listLocations();

                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-create-location');
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        updateLocation(obj) {
            const data = JSON.stringify(obj);

            axios({
                method: 'PUT',
                url: '/admin/location',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.name = '';
                    this.listLocations();

                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-edit-location');
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        resetCreateModal(event) {
            this.name = '';
            this.address = '';
        },
        handelCreateModalOk(event) {
            // Prevent modal from closing
            event.preventDefault();
            // Trigger submit handler
            this.handleCreateSubmit();
        },

        handleCreateSubmit(event) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }

            this.createLocation({ Name: this.name, Address: this.address });
        },
        checkFormValidity() {
            const valid = this.$refs.createform.checkValidity();
            this.nameState = valid;
            return valid;
        },
        checkEditFormValidity() {
            const valid = this.$refs.editform.checkValidity();
            this.nameState = valid;
            return valid;
        },

        openEditModal(obj) {
            this.id = obj.ID;
            this.name = obj.Name;
            this.address = obj.Address;
            this.$nextTick(() => {
                this.$bvModal.show('modal-edit-location');
            });
        },
        resetEditModal(event) {
            this.id = '';
            this.name = '';
            this.address = '';
        },
        handelEditModalOk(event) {
            // Prevent modal from closing
            event.preventDefault();
            // Trigger submit handler
            this.handleEditSubmit();
        },
        handleEditSubmit(event) {
            // Exit when the form isn't valid
            if (!this.checkEditFormValidity()) {
                return;
            }

            this.updateLocation({ ID: this.id, Name: this.name, Address: this.address });
        },
    },
};
</script>
