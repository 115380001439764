var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-editor d-flex align-content-stretch mh-100",class:( _obj = {}, _obj[this.mode] = true, _obj[this.variant] = true, _obj['align-self-stretch'] =  this.variant && this.variant === 'full', _obj['flex-fill'] =  this.variant && this.variant === 'full', _obj )},[_c('div',{staticClass:"editorWrapper mh-100 flex-fill d-flex flex-column",class:{
            'textarea-expanded': this.expanded,
            'overflow-hidden': !this.expanded,
            loading: this.loading,
            border: this.border,
        }},[(_vm.loading && !_vm.hideLoadingIndicator)?_c('div',{staticClass:"loading-wrapper d-flex align-items-center"},[_c('co-loading-indicator')],1):_vm._e(),(_vm.editor && _vm.allowStyling)?_c('bubble-menu',{staticClass:"bubble-menu",attrs:{"editor":_vm.editor,"tippy-options":{ duration: 100 }}},[_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 1 }) },on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 1 }).run()}}},[_c('coIcon',{staticClass:"p-0 m-0",attrs:{"name":"type-h1"}})],1),_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 2 }) },on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 2 }).run()}}},[_c('coIcon',{attrs:{"name":"type-h2"}})],1),_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 3 }) },on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 3 }).run()}}},[_c('coIcon',{attrs:{"name":"type-h3"}})],1),_c('button',{class:{ 'is-active': _vm.editor.isActive('bold') },on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},[_c('coIcon',{attrs:{"name":"type-bold"}})],1),_c('button',{class:{ 'is-active': _vm.editor.isActive('italic') },on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},[_c('coIcon',{attrs:{"name":"type-italic"}})],1),_c('button',{class:{ 'is-active': _vm.editor.isActive('strike') },on:{"click":function($event){_vm.editor.chain().focus().toggleStrike().run()}}},[_c('coIcon',{attrs:{"name":"type-strikethrough"}})],1),_c('button',{on:{"click":function($event){_vm.editor.chain().focus().setParagraph().unsetAllMarks().run()}}},[_c('coIcon',{attrs:{"name":"eraser"}})],1)]):_vm._e(),_c('div',{ref:"editor",staticClass:"editor h-auto order-1",class:{
                'flex-fill': this.variant === 'full',
            },on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onEscape($event)}}}),_c('div',{staticClass:"actions flex-shrink-0 d-flex",class:{
                expanded: this.expanded && !this.hideActions && this.variant === 'compact',
                'order-0': _vm.actionsPosition.includes('top'),
                'order-1': _vm.actionsPosition.includes('bottom'),
                'mt-3': this.variant === 'full' && _vm.actionsPosition.includes('bottom'),
            },style:({
                'margin-top':
                    this.variant === 'compact' && this.expanded && _vm.actionsPosition.includes('top')
                        ? '-.25rem !important'
                        : 'inherit',
                'margin-bottom':
                    this.variant === 'compact' && this.expanded && _vm.actionsPosition.includes('bottom')
                        ? '-.25rem !important'
                        : 'inherit',
            })},[(!_vm.hideActions)?_c('div',{class:{
                    'ml-auto': _vm.actionsPosition.includes('right'),
                    'mr-auto': _vm.actionsPosition.includes('left'),
                    'order-0': _vm.actionsPosition.includes('left'),
                    'mb-3': _vm.actionsPosition.includes('top'),
                }},[_c('co-tippy',{attrs:{"text":_vm.$t('labels.imageadd')}},[_c('CoFileInput',{attrs:{"accept":"image/*","multiple":_vm.multipleFiles},on:{"change":_vm.previewFiles}},[_c('CoRoundButton',{attrs:{"icon":"camera","variant":_vm.variant === 'full' ? 'secondary' : 'icon'}})],1)],1),_c('co-tippy',{attrs:{"text":_vm.$t('labels.mentionsmo')}},[_c('CoRoundButton',{attrs:{"icon":"person","variant":_vm.variant === 'full' ? 'secondary' : 'icon'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.mention($event)}}})],1),_c('co-tippy',{attrs:{"text":_vm.$t('labels.hashtagadd')}},[_c('CoRoundButton',{attrs:{"icon":"hash","variant":_vm.variant === 'full' ? 'secondary' : 'icon'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.hashtag($event)}}})],1),_c('co-emoji-picker',{on:{"picked":_vm.insertEmoji}},[_c('CoRoundButton',{attrs:{"icon":"custom-emoji-add","variant":_vm.variant === 'full' ? 'secondary' : 'icon'}})],1)],1):_vm._e(),(!_vm.hideSendButton)?_c('div',{staticClass:"ml-4",class:{
                    'ml-auto': this.hideActions,
                }},[_c('co-tippy',{attrs:{"text":_vm.$t('labels.send')}},[_c('CoRoundButton',{staticClass:"mr-0",attrs:{"icon":_vm.sendIcon,"variant":_vm.variant === 'full' ? 'primary' : 'icon',"disabled":!_vm.text || _vm.text.length < 1},nativeOn:{"click":function($event){$event.stopPropagation();!_vm.text || _vm.text.length < 1 ? null : _vm.send($event)}}})],1)],1):_vm._e()]),(_vm.files)?_c('div',{staticClass:"image flex-shrink-0 order-1"},_vm._l((_vm.files),function(file,index){return _c('CoThumbnail',{staticClass:"editor-thumbnail mr-2",attrs:{"content-type":"image","imageURL":file.url,"size":"lg","deletable":""},on:{"delete":function($event){$event.stopPropagation();return _vm.removeFile(index)}}})}),1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }