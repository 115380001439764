import { render, staticRenderFns } from "./ViewPage.vue?vue&type=template&id=e68ae562&scoped=true&"
import script from "./ViewPage.vue?vue&type=script&lang=ts&"
export * from "./ViewPage.vue?vue&type=script&lang=ts&"
import style0 from "./ViewPage.vue?vue&type=style&index=0&id=e68ae562&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e68ae562",
  null
  
)

export default component.exports