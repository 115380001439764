











































































































































import i18n from 'vue-i18n';
import { get } from 'lodash';
import { isEmail } from 'validator';
import Bugsnag from '@bugsnag/js';
import OA2Providers from '@/components/auth_methods/OA2Providers.vue';

import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Login',
    i18n: {
        // `i18n` option, setup component specific language and translations
        messages: {
            en: {
                messages: {
                    invalidcredentials:
                        'This combination of email and password does not exist. Please double-check and try again.',
                    newhere: 'New to {space}?',
                    signup: 'Sign up here.',
                },
            },
            de: {
                messages: {
                    invalidcredentials:
                        'Diese Kombination aus E-Mail und Passwort existiert nicht. Bitte überprüfe die Eingaben und versuche es erneut.',
                    newhere: 'Neu bei {space}?',
                    signup: 'Registriere dich hier.',
                },
            },
        },
    },
    components: {
        OA2Providers,
        CoButton,
        CoModal,
        CoHeadline,
        CoText,
        CoCard,
        CoFormGroup,
        CoInput,
        CoAlert,
        CoLink,
    },
    data() {
        return {
            username: null,
            password: null,
            error: null,

            loading: false,
            redirect: null,
            messageHeading: 'Du bist noch nicht Mitglied?',
            messageText: 'Einfach ab 15 € monatlich ausprobieren',
            btnText: 'Mitglied werden',
            btnTitle: 'Hafven Mitgliedschaftstarife ansehen',
            btnLink:
                'https://hafven.de/tarife?utm_source=community%20web%20app&utm_medium=login%20screen&utm_campaign=mitglied%20werden',

            invalidInviteLink: this.$route.query.invalidInviteLink,
            errorContentVisible: true,
        };
    },
    computed: {
        SupportURL() {
            if (typeof this.$store?.state?.space?.SupportURL === 'string') {
                let url = this.$store.state.space.SupportURL.trim();
                if (isEmail(url)) {
                    url = `mailto:${url}`;
                } else {
                    url = url.startsWith('http') ? url : `https://${url}`;
                }
                return url.trim();
            } else {
                return null;
            }
        },
        registrationsAreClosed() {
            if (this.$route.query.registrationsAreClosed) {
                return true;
            }
            return false;
        },
    },
    mounted() {
        this.$store.commit('RESET_MEMBERSHIP_PROVIDER');

        if (this.invalidInviteLink) {
            this.$refs.invalidInviteLinkMessageModal.show();
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // eslint-disable-next-line no-param-reassign
            vm.redirect = to.query.redirect;
        });
    },
    methods: {
        // routerPush function that wraps the router push function and catches errors
        // it will ignore the error if the error is a NavigationDuplicated error or Redundant Navigation error and will not log it to bugsnag
        // this is because the error is thrown when the user tries to navigate to the same route they are currently on and is not a real error
        routerPush(route) {
            this.$router.push(route).catch((error) => {
                if (
                    error.name !== 'NavigationDuplicated' &&
                    error.name !== 'NavigationCancelled' &&
                    error.name !== 'Redundant Navigation'
                ) {
                    Bugsnag.notify(error);
                }
            });
        },
        removeInvalidInviteLink() {
            this.invalidInviteLink = null;
            // remove from url
            this.$router.replace({ query: {} });
            this.$refs.invalidInviteLinkMessageModal.hide();
        },
        retryInvalidInviteLink() {
            this.$refs.invalidInviteLinkMessageModal.hide();
            this.$router.go(this.invalidInviteLink);
        },

        login() {
            this.error = null;
            if (
                !this.username ||
                !this.validateEmail(this.username) ||
                !this.password ||
                !this.validatePassword(this.password)
            ) {
                this.error = {
                    message: this.$t('errors.invalidLoginCredentials'),
                };
                // set focus to first invalid input
                if (!this.username || !this.validateEmail(this.username)) {
                    this.$refs.email.focus();
                } else if (!this.password || !this.validatePassword(this.password)) {
                    this.$refs.password.focus();
                }

                return;
            }
            // remove whitespace from email
            const username = this.username.trim().toLowerCase();

            this.loading = true;
            this.$store
                .dispatch('loginV2', {
                    email: username,
                    password: this.password,
                })
                .then(() => {
                    this.$store
                        .dispatch('getMe', null)
                        .then((response2) => {
                            this.$root.$data.$me = response2.data;
                            this.loading = false;
                            if (this.redirect && this.redirect !== '%2F' && this.redirect !== '/') {
                                this.routerPush(this.redirect);
                            } else {
                                this.routerPush('/');
                            }
                        })
                        .catch((error) => {
                            this.routerPush('/');
                            const { message } = error.response.data;
                            const err = String('missing or malformed jwt');

                            if (message === err) {
                                this.$store.commit('LOGOUT');
                            }
                        });
                })
                .catch((error) => {
                    // if status is 401 and error code 10003 redirect to password reset
                    this.loading = false;
                    // remove from error all config data
                    delete error.config;
                    delete error.request;
                    delete error.headers;

                    this.error = error;

                    Bugsnag.notify(error);

                    console.log(`Login error: ${error}`);

                    if (
                        (get(error, 'response.status') === 401 && get(error, 'response.data.Code') === '10003') ||
                        get(error, 'response.data').includes('must change password')
                    ) {
                        this.routerPush('/force-password-reset');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        validateEmail(value) {
            if (!value) {
                return false;
            }
            return isEmail(value);
        },

        validatePassword(value) {
            if (!value) {
                return false;
            }

            if (value.length < 1) {
                return false;
            }

            if (value.length > 56) {
                return false;
            }

            return true;
        },
        hideMaxUsersReachedError() {
            this.$router.push({
                path: '/login',
            });
            this.registrationsAreClosed = false;
        },
    },
};
