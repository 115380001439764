<style lang="less" scoped>
@import '~@/assets/less/manage-space/base.less';

.clear-button {
    cursor: pointer;
    background-color: transparent;
    border: transparent;
    color: #1d2124;
}
</style>

<template>
    <div>
        <div class="px-sm-4">
            <!-- members limit 80% -->
            <CoCard
                class="mb-3"
                variant="warning"
                v-if="$store.state.limits.UserThresholdReached && !$store.state.limits.UserLimitReached"
            >
                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center justify-content-between">
                        <CoHeadline :level="3" class="">{{ $t('labels.limitUsersAlmostReached') }}</CoHeadline>
                    </div>

                    <div class="d-flex flex-column mt-3 pt-2">
                        <CoText class="mb-3">
                            {{ $t('messages.limitUsersAlmostReached') }}
                        </CoText>
                        <CoButton class="align-self-end" variant="black" @click="$router.push('/admin/account')">
                            {{ $t('labels.upgradeSubscription') }}
                        </CoButton>
                    </div>
                </div>
            </CoCard>

            <CoCard v-if="$store.state.limits.UserLimitReached" class="mb-3" variant="error">
                <div class="d-flex flex-column">
                    <div class="d-flex align-items-center justify-content-between">
                        <CoHeadline :level="3" class="">{{ $t('labels.limitUsersReached') }}</CoHeadline>
                    </div>
                    <div class="d-flex flex-column mt-3 pt-2">
                        <CoText class="mb-3">
                            {{ $t('messages.limitUsersReached') }}
                        </CoText>
                        <CoButton class="align-self-end" variant="black" @click="$router.push('/admin/account')">
                            {{ $t('labels.upgradeSubscription') }}
                        </CoButton>
                    </div>
                </div>
            </CoCard>
        </div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="filter-items">
                <div class="d-flex w-100 align-items-center bg-white search-bar-wrapper rounded-pill">
                    <b-icon class="mr-2" icon="search"></b-icon>
                    <b-input-group class="search-input-wrapper">
                        <b-form-input
                            class="search-input"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            v-model="searchText"
                            @input="searchMembers"
                        />
                        <b-input-group-append>
                            <b-input-group-text class="clear-button">
                                <b-icon v-if="searchText === ''"></b-icon>
                                <b-icon
                                    v-else
                                    @click="clearSearchText"
                                    v-on:mouseover="mouseOverCleanButton"
                                    v-on:mouseleave="mouseLeaveCleanButton"
                                    v-bind:icon="cleanButtonIcon"
                                ></b-icon>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <coDownloadAsCsv
                    class="mr-2"
                    title="Download the data shown in this table as CSV-file"
                    ref="csvDownloader"
                    :clickMethod="downloadCsv"
                />
                <CoUserInvite
                    class="mr-2"
                    @invite-success="$router.push('/admin/community/member/invitations')"
                    @invite-error="$router.push('/admin/community/member/invitations')"
                    :disabled="$store.state.limits.UserLimitReached"
                />
                <CoUserCreateAdminModal :disabled="$store.state.limits.UserLimitReached" />
            </div>
        </div>
        <!-- search and actions end -->
        <div class="px-sm-4 mt-3">
            <co-table
                :loading="requestLoading"
                :items="this.objects"
                :columns="this.fields"
                :itemsPerPage="15"
                ref="membersTable"
                resizable-columns
            >
                <template v-slot:object\.Profile\.Name="slotProps">
                    <div class="d-flex align-items-center">
                        <co-thumbnail
                            class="mr-3"
                            :imageURL="slotProps.item.object.Profile.ImageURL"
                            :link="`/admin/community/member/view/${slotProps.item.object.ID}`"
                            content-type="user"
                            size="sm"
                            round
                        />
                        <co-link
                            styles="primary truncate"
                            style="min-width: 0"
                            :to="`/admin/community/member/view/${slotProps.item.object.ID}`"
                            >{{ slotProps.item.object.Profile.Name }}
                        </co-link>
                    </div>
                </template>
                <template v-slot:object\.Profile\.Since="slotProps">
                    <coDate :date="slotProps.item.object.Profile.Since" format="relativedate" />
                </template>
                <template v-slot:object\.Address\.Company="slotProps">
                    {{ slotProps.item.object.Address ? slotProps.item.object.Address.Company : null }}
                </template>
                <template v-slot:object\.LastActivity="slotProps">
                    <coDate :date="slotProps.item.object.LastActivity" format="relativedate" />
                </template>
                <template v-slot:object\.Status="slotProps">
                    {{ statusTypes[slotProps.item.object.Status || 0] }}
                </template>
                <template v-slot:object\.Plan\.cancelledAt="slotProps">
                    <coDate
                        v-if="slotProps.item.object.Plan"
                        :date="slotProps.item.object.Plan.cancelledAt"
                        format="relativedate"
                    />
                </template>
            </co-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import { McWysiwyg } from '@mycure/vue-wysiwyg';
import EventBus from '../../../../eventBus';

import CoUserCreateAdminModal from '../../../../components/Organisms/co-user-create-admin-modal/CoUserCreateAdminModal.vue';
import CoDate from '../../../../components/Molecules/co-date/CoDate.vue';
import CoDownloadAsCsv from '../../../../components/Organisms/co-download-as-csv/CoDownloadAsCsv.vue';
import CoUserInvite from '../../../../components/Organisms/co-user-invite/CoUserInvite.vue';
import CoTable from '../../../../components/Molecules/co-table/CoTable.vue';
import CoThumbnail from '../../../../components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoLink from '../../../../components/Atoms/co-link/CoLink.vue';

export default {
    name: 'Members',
    components: {
        CoDate,
        CoUserCreateAdminModal,
        CoLink,
        McWysiwyg,
        VueTagsInput,
        CoDownloadAsCsv,
        CoUserInvite,
        CoTable,
        CoThumbnail,
    },
    data() {
        return {
            cleanButtonIcon: 'x-circle',
            searchText: '',
            view: 1,
            tableFilter: null,
            perPage: 15,
            currentPage: 1,
            objects: [],
            errorFalseMes: false,
            errorManyMes: false,
            sendShow: false,
            errorSend: false,
            ongoingRequest: null,
            csvData: [],
            statusTypes: ['Active', 'Pending', 'Deleted', 'Suspended', 'Cancelled', 'Waiting for approval', 'Rejected'],

            requestLoading: false,
            billingTurnedOn: this.$store.state.billingTurnedOn || null,
        };
    },
    computed: {
        errorMany() {
            return this.emails.length > 10;
        },
        fields() {
            if (this.billingTurnedOn) {
                return [
                    {
                        key: 'object.Profile.Name',
                        title: 'Name',
                        style: 'width: 250px !important',
                    },
                    {
                        key: 'object.Email',
                        title: 'Email',
                        style: 'width: 250px !important',
                    },
                    {
                        key: 'object.Address.Company',
                        title: 'Company',
                    },
                    {
                        key: 'object.Profile.Since',
                        title: 'Created',
                    },
                    {
                        key: 'object.LastActivity',
                        title: 'Last activity',
                    },
                    {
                        key: 'object.Status',
                        title: 'Status',
                    },
                    {
                        key: 'object.Plan.plan.Name',
                        title: 'Plan',
                    },
                    {
                        key: 'object.Plan.cancelledAt',
                        title: 'Plan Cancelled to',
                    },
                ];
            }
            return [
                {
                    key: 'object.Profile.Name',
                    title: 'Name',
                },
                {
                    key: 'object.Email',
                    title: 'Email',
                },
                {
                    key: 'object.Profile.Since',
                    title: 'Created',
                },
                {
                    key: 'object.LastActivity',
                    title: 'Last activity',
                },
                {
                    key: 'object.Status',
                    title: 'Status',
                },
            ];
        },
    },
    mounted() {
        this.requestLoading = true;
        this.listMembers();
    },
    watch: {},
    methods: {
        goToInvitations() {
            this.$router.push('/admin/community/member/invitations');
        },

        listMembers(next) {
            // Create a new CancelToken
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();

            // Save the cancel token for the current request
            this.ongoingRequest = source;

            //  this.objects.push.apply(this.objects, response["objects"]);
            let url = '/admin/community/member/listV2';
            if (next != null) {
                url = next;
            }
            axios({
                method: 'GET',
                url,
                headers: {
                    'Content-Type': 'application/json',
                },
                cancelToken: source.token,
            })
                .then((response) => {
                    this.requestLoading = false;

                    if (response && response.data && response.data.objects) {
                        this.objects.push.apply(this.objects, response.data.objects);
                        if (response.data.next != null && response.data.next !== '') {
                            this.listMembers(response.data.next);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        searchMembers() {
            if (this.ongoingRequest != null) {
                this.ongoingRequest.cancel('Operation canceled by new request.');
                this.ongoingRequest = null;
            }

            // Create a new CancelToken
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();

            // Store the cancel token for the current request
            this.ongoingRequest = source;

            this.requestLoading = true;

            if (this.searchText.trim() === '') {
                this.requestLoading = false;
                this.objects = [];
                this.listMembers();
                return;
            }

            if (this.searchText.trim().length < 3) {
                this.requestLoading = false;
                return;
            }

            this.$store
                .dispatch('adminSearchMembers', {
                    searchText: this.searchText,
                    cancelToken: source.token,
                })
                .then((objects) => {
                    if (objects && objects.length > 0) {
                        this.objects = objects;
                    } else {
                        this.objects = [];
                    }
                    this.requestLoading = false;
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        // handle errors that are not thrown by axios.CancelToken
                        console.error(err);
                        this.requestLoading = false;
                    }
                });
        },
        mouseOverCleanButton() {
            this.cleanButtonIcon = 'x-circle-fill';
        },
        mouseLeaveCleanButton() {
            this.cleanButtonIcon = 'x-circle';
        },
        clearSearchText() {
            this.searchText = '';
            this.cleanButtonIcon = 'x-circle';
            this.objects = [];
            if (this.ongoingRequest != null) {
                this.ongoingRequest.cancel('Operation canceled by new request.');
                this.ongoingRequest = null;
            }
            this.listMembers();
        },
        downloadCsv() {
            this.csvData = this.$refs.membersTable.getTableData();
            this.$refs.csvDownloader.exportAsCSV(this.csvData);
        },
    },
};
</script>
