








































import CoImage from '@/components/Atoms/co-image/CoImage.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import i18n from 'vue-i18n';

export default {
    name: 'CoFooterPublic',
    components: {
        CoImage,
        CoText,
        CoLink,
    },
    i18n: {
        messages: {
            en: {
                createyourcommunity: 'Create your own community for free',
            },
            de: {
                createyourcommunity: 'Erstelle deine eigene Community kostenlos',
            },
        },
    },
    props: {
        cobranding: {
            type: Boolean,
            default: true,
        },
    },
};
