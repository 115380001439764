<template>
    <div>
        <div class="px-sm-4">
            <b-overlay :show="loading" class="p-0 m-0">
                <div class="my-3 bg-white round-unify p-3">
                    <b-form @submit.stop.prevent="handleCreateSubmit" ref="createResource" class="d-flex flex-column">
                        <b-form-group
                            label-cols-lg="3"
                            label="General Information"
                            label-size="lg"
                            label-class="font-weight-bold pt-0"
                            class=""
                        >
                            <b-form-group
                                id="input-group-name"
                                label="Name"
                                label-for="input-name"
                                aria-describedby="name-description"
                                label-class="label-align"
                            >
                                <b-form-input
                                    id="input-name"
                                    v-model="form['Name']"
                                    type="text"
                                    required
                                    placeholder="Enter resource name"
                                    class="rounded-pill"
                                    ref="input_name"
                                ></b-form-input>
                                <b-form-text id="name-description" class="ml-3"
                                    >Name will be displayed to users on booking page.
                                </b-form-text>
                            </b-form-group>

                            <b-form-group
                                id="input-group-description"
                                label="Description (Optional)"
                                label-for="input-description"
                                label-class="label-align"
                                aria-describedby="description-description"
                            >
                                <b-form-textarea
                                    id="input-description"
                                    placeholder="Text (Optional)"
                                    rows="3"
                                    style="border-radius: 16px"
                                    v-model="form['Description']"
                                ></b-form-textarea>
                                <b-form-text id="description-description" class="ml-3"
                                    >Short description and/or important notes about this resource.
                                </b-form-text>
                            </b-form-group>

                            <!-- location -->
                            <b-form-group
                                id="input-group-location"
                                label="Location"
                                label-for="input-location"
                                label-class="label-align"
                                description="Where is this resource located?"
                            >
                                <b-form-select v-model="form['LocationID']" class="rounded-pill">
                                    <option :value="null" selected>Select location</option>
                                    <option
                                        v-for="(selectOption, indexOpt) in locations"
                                        :key="indexOpt"
                                        :value="selectOption.ID"
                                    >
                                        {{ selectOption.Name }}
                                    </option>
                                </b-form-select>
                            </b-form-group>

                            <b-form-group
                                id="input-group-image"
                                label="Image"
                                label-for="input-image"
                                aria-describedby="image-description"
                                label-class="label-align"
                            >
                                <b-form-file
                                    accept="image/*"
                                    id="input-image"
                                    @change="previewImage"
                                    ref="previewImg"
                                    class="rounded-pill mb-2"
                                    s
                                ></b-form-file>
                                <b-form-text id="image-description" class="ml-3">Image, max file size 2MB.</b-form-text>

                                <div v-show="previewImgURL">
                                    <b-aspect aspect="2">
                                        <div class="position-relative h-100">
                                            <img
                                                class="resource-image position-absolute w-100 d-block img-fluid h-100"
                                                :src="previewImgURL"
                                                img-blank-color="#ffffff"
                                                alt
                                            />
                                        </div>
                                    </b-aspect>
                                </div>
                            </b-form-group>
                            <b-form-group
                                id="input-group-hidden"
                                label="Hidden"
                                label-for="input-linked-hidden"
                                label-class="label-align"
                                description="If set, this resource will not be visible to users. Only admins will be able to see it."
                            >
                                <b-form-checkbox v-model="form['Hidden']" switch size="lg"></b-form-checkbox>
                            </b-form-group>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg="3"
                            label="Price and Limits"
                            label-size="lg"
                            label-class="font-weight-bold pt-0"
                        >
                            <div>
                                <b-form-group
                                    id="input-group-price"
                                    label="Price per Hour"
                                    label-for="input-price-per-hour"
                                    aria-describedby="price-description"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-price-per-hour"
                                        v-model="PricePerHour"
                                        @change="priceChange"
                                        type="number"
                                        number
                                        class="rounded-pill"
                                        step="0.01"
                                        :disabled="!isBillingTurnedOn"
                                    ></b-form-input>

                                    <CoAlert
                                        variant="yellow"
                                        size="sm"
                                        class="mt-1"
                                        text="Only users with an active membership can book paid resources."
                                    >
                                    </CoAlert>

                                    <CoCard
                                        variant="gray"
                                        class="mt-1"
                                        v-if="!isTherePlans || !$store.state.billingTurnedOn"
                                    >
                                        <!-- if payment on show infor and link to payment page -->
                                        <CoText variant="mute" v-if="!$store.state.billingTurnedOn">
                                            Payment not activated: <br />
                                            To give a resource a price you need to activate payment.
                                            <CoLink
                                                styles="co-link nowrap"
                                                new-tab
                                                to="/admin/monetization/payments/stripe"
                                            >
                                                Activate payment
                                            </CoLink>
                                        </CoText>
                                        <CoText variant="mute" v-if="$store.state.billingTurnedOn && !isTherePlans">
                                            Payment activated: <br />
                                            To set a price you need to create at least one public plan.
                                            <CoLink
                                                styles="co-link nowrap"
                                                new-tab
                                                to="/admin/monetization/plan/create"
                                            >
                                                Create a plan
                                            </CoLink>
                                        </CoText>
                                    </CoCard>
                                </b-form-group>
                            </div>
                            <div v-if="isBillingTurnedOn">
                                <b-form-group
                                    id="input-group-tax-rate"
                                    label="Tax rate"
                                    label-for="input-tax-rate"
                                    label-class="label-align"
                                >
                                    <b-form-select
                                        :disabled="!$store.state.billingTurnedOn"
                                        id="select-tax-rate"
                                        v-model="form.TaxRateID"
                                        class="form-control rounded-pill d-block"
                                        @change="calculateFinalPrice($event)"
                                    >
                                        <b-form-select-option
                                            v-for="(item, _) in taxRates"
                                            :value="item.ID"
                                            :key="item.ID"
                                            >{{ item.Percentage ? item.Percentage : 0 }}% -

                                            <span v-if="item.Inclusive">Inclusive</span>
                                            <span v-else>Exclusive</span>
                                            - {{ item.DisplayName }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <b-form-group
                                v-if="isBillingTurnedOn"
                                id="input-group-currency"
                                label="Currency"
                                label-for="input-currency"
                                label-class="label-align"
                                description="Currency for plan"
                            >
                                <b-form-select
                                    id="select-currency"
                                    v-model="form['Currency']"
                                    class="form-control rounded-pill d-block"
                                    :disabled="true"
                                >
                                    <b-form-select-option :value="form['Currency']"
                                        >{{ form['Currency'] }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group
                                id="input-group-min-duration"
                                label="Min booking duration"
                                label-for="input-min-duration"
                                label-class="label-align"
                                aria-describedby="min-description"
                            >
                                <b-form-input
                                    id="input-min-duration"
                                    v-model="form['MinBookingDuration']"
                                    type="number"
                                    number
                                    required
                                    class="rounded-pill"
                                ></b-form-input>
                                <b-form-text id="min-description" class="ml-3">In minutes</b-form-text>
                            </b-form-group>

                            <b-form-group
                                id="input-group-max-duration"
                                label="Max booking duration"
                                label-for="input-max-duration"
                                aria-describedby="max-description"
                                label-class="label-align"
                            >
                                <b-form-input
                                    id="input-max-duration"
                                    v-model="form['MaxBookingDuration']"
                                    type="number"
                                    number
                                    required
                                    class="rounded-pill"
                                ></b-form-input>
                                <b-form-text id="max-description" class="ml-3">In minutes</b-form-text>
                            </b-form-group>

                            <b-form-group
                                id="input-group-capacity"
                                label="Capacity"
                                label-for="input-capacity"
                                aria-describedby="capacity-description"
                                label-class="label-align"
                            >
                                <b-form-input
                                    id="input-capacity"
                                    v-model="form['Capacity']"
                                    type="number"
                                    number
                                    required
                                    class="rounded-pill"
                                ></b-form-input>
                                <b-form-text id="capacity-description" class="ml-3"
                                    >How many users can book this at the same time
                                </b-form-text>
                            </b-form-group>

                            <b-form-group
                                id="input-group-cancellation-period"
                                label="Cancellation Period"
                                label-for="input-cancellation-period"
                                aria-describedby="CancellationPeriod-description"
                                label-class="label-align"
                            >
                                <b-form-input
                                    id="input-cancellation-period"
                                    v-model="form['CancellationPeriod']"
                                    type="number"
                                    number
                                    required
                                    class="rounded-pill"
                                ></b-form-input>
                                <b-form-text id="CancellationPeriod-description" class="ml-3"
                                    >Prevent members from cancelling bookings within x hours before a booking starts.
                                </b-form-text>
                            </b-form-group>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg="3"
                            label="Dates and Times"
                            label-size="lg"
                            label-class="font-weight-bold pt-0"
                        >
                            <b-form-group
                                id="input-group-cancellation-period"
                                label="Available for booking"
                                label-class="label-align"
                            >
                                <b-list-group>
                                    <b-list-group-item
                                        v-for="(item, i) in form['BookingTimes']"
                                        :key="i"
                                        class="d-flex col text-left align-items-center justify-content-begin"
                                    >
                                        <div class="d-none d-md-flex align-items-center mr-2 ml-2">
                                            <b-button-group size="sm">
                                                <b-button
                                                    v-for="(btn, idx) in item['buttons']"
                                                    :key="idx"
                                                    :pressed.sync="btn.state"
                                                    variant="outline-primary"
                                                >
                                                    {{ btn.caption }}
                                                </b-button>
                                            </b-button-group>
                                        </div>
                                        <div class="d-flex">
                                            <b-form-group class="text-left mb-0 mr-3 mr-md-0">
                                                <b-form-input
                                                    v-model="item['From']"
                                                    type="time"
                                                    required
                                                    class="rounded-pill"
                                                ></b-form-input>
                                            </b-form-group>
                                            <div class="d-none d-md-flex align-items-center mr-2 ml-2">to</div>
                                            <b-form-group class="text-left mb-0 mr-3 mr-md-0">
                                                <b-form-input
                                                    v-model="item['To']"
                                                    type="time"
                                                    required
                                                    class="rounded-pill"
                                                ></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <b-button size="sm" class="mr-2" variant="danger" @click="removeInterval(i)"
                                            >Delete
                                        </b-button>
                                    </b-list-group-item>
                                </b-list-group>
                                <b-button variant="outline-primary" class="my-2" @click="addInterval">
                                    <b-icon icon="plus"></b-icon>
                                    add interval
                                </b-button>
                            </b-form-group>

                            <b-form-group
                                id="input-group-date-override"
                                label="Date override"
                                label-for="input-date-override"
                            >
                                <div
                                    v-for="(item, index) in dateOverride"
                                    :key="index"
                                    class="d-flex flex-column flex-md-row mb-3"
                                >
                                    <div class="mb-1 mb-md-0">
                                        <label :for="`picker-date-${index}`" class="label-align">Date</label>
                                        <b-form-datepicker
                                            :id="`picker-date-${index}`"
                                            v-model="item.date"
                                            class="rounded-pill"
                                            :date-format-options="{
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                            }"
                                        ></b-form-datepicker>
                                    </div>
                                    <div class="mb-1 mb-md-0 mx-md-2">
                                        <label :for="`timepicker-from-${index}`" class="label-align">From</label>
                                        <b-form-timepicker
                                            :id="`timepicker-from-${index}`"
                                            v-model="item.from"
                                            locale="en"
                                            :hour12="false"
                                            class="rounded-pill"
                                            aria-label="from"
                                        ></b-form-timepicker>
                                    </div>
                                    <div>
                                        <label :for="`timepicker-to-${index}`" class="label-align">To</label>
                                        <b-form-timepicker
                                            :id="`timepicker-to-${index}`"
                                            v-model="item.to"
                                            locale="en"
                                            :hour12="false"
                                            class="rounded-pill"
                                        ></b-form-timepicker>
                                    </div>
                                    <div class="align-self-end">
                                        <label class="label-align"></label>
                                        <span
                                            v-if="dateOverride.length > 1"
                                            v-on:click="removeDateOverride(index)"
                                            class="pointer ml-2"
                                        >
                                            <b-icon font-scale="2" icon="x-circle" variant="danger"></b-icon>
                                        </span>
                                    </div>
                                </div>
                                <b-button variant="outline-primary" class="my-2" @click="addDateOverride">
                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                    add override
                                </b-button>
                            </b-form-group>
                        </b-form-group>

                        <b-form-group
                            label-cols-lg="3"
                            label="Booking slot"
                            label-size="lg"
                            label-class="font-weight-bold pt-0"
                        >
                            <b-form-group
                                id="input-group-BookWholeSlot"
                                label="Book whole slot"
                                label-for="input-linked-BookWholeSlot"
                                label-class="label-align"
                                description="If activated, min and max duration will be ignored and the resource is booked for whole booking slot available within the day of the booking."
                            >
                                <b-form-checkbox v-model="form['BookWholeSlot']" switch size="lg"></b-form-checkbox>
                            </b-form-group>

                            <b-form-group
                                v-if="isBillingTurnedOn"
                                id="input-group-PricePerSlot"
                                label="Price per slot"
                                label-for="input-linked-PricePerSlot"
                                label-class="label-align"
                                description="If set, the user is charged this price for the whole slot booking instead of calculating the price based on the hours booked."
                            >
                                <b-form-checkbox
                                    v-model="form['PricePerSlot']"
                                    switch
                                    size="lg"
                                    :disabled="!form.BookWholeSlot"
                                >
                                </b-form-checkbox>
                            </b-form-group>
                        </b-form-group>

                        <b-form-group
                            label-cols-lg="3"
                            label="After booking behavior"
                            label-size="lg"
                            label-class="font-weight-bold pt-0"
                        >
                            <b-form-group
                                id="input-group-SendEmailAfterBooking"
                                label="Send email to user after booking"
                                label-for="input-linked-SendEmailAfterBooking"
                                label-class="label-align"
                            >
                                <b-form-checkbox
                                    v-model="form['SendEmailAfterBooking']"
                                    switch
                                    size="lg"
                                ></b-form-checkbox>
                            </b-form-group>

                            <b-form-group
                                v-if="form['SendEmailAfterBooking']"
                                id="input-group-description"
                                label="Email content"
                                label-for="input-AfterBookingEmailContent"
                                label-class="label-align"
                                aria-describedby="AfterBookingEmailContent-description"
                            >
                                <b-form-textarea
                                    id="input-AfterBookingEmailContent"
                                    placeholder="Text (Optional)"
                                    rows="5"
                                    style="border-radius: 16px"
                                    v-model="form['AfterBookingEmailContent']"
                                ></b-form-textarea>
                                <b-form-text id="AfterBookingEmailContent-description" class="ml-3">
                                    <p>
                                        Placeholders <code>{{ resource_name }}</code
                                        >, <code>{{ comment }}</code
                                        >, <code>{{ from }}</code> and <code>{{ to }}</code> will be replaced by actuall
                                        booking times.

                                        <span v-if="locksEnabled"
                                            >User placeholder <code>{{ pin }}</code> for linked lock pin code.</span
                                        >
                                    </p>
                                </b-form-text>
                            </b-form-group>

                            <b-form-group
                                id="input-group-notifyEmails"
                                label="Emails to notify about booking"
                                label-for="input-notifyEmails"
                                label-class="label-align"
                                aria-describedby="notifyEmails-description"
                            >
                                <div
                                    v-for="(email, index) in form['NotifyEmails']"
                                    :key="index"
                                    class="d-flex align-items-center justify-content-between mb-2"
                                >
                                    <b-form-input
                                        :id="'email-' + index"
                                        v-model="form['NotifyEmails'][index]"
                                        type="email"
                                        placeholder="email"
                                        class="rounded-pill"
                                    ></b-form-input>

                                    <span
                                        v-if="form['NotifyEmails'].length > 1"
                                        v-on:click="removeEmail(index)"
                                        class="pointer ml-2"
                                    >
                                        <b-icon font-scale="2" icon="x-circle" variant="danger"></b-icon>
                                    </span>
                                </div>

                                <b-button variant="outline-primary" class="my-2" @click="form['NotifyEmails'].push('')">
                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                    add email
                                </b-button>

                                <b-form-text id="notifyEmails-description" class="ml-3">
                                    <p>To these emails will be sent booking infomation and user contact data.</p>
                                </b-form-text>
                            </b-form-group>

                            <h3 class="mt-4 mb-2">Information for user to show after booking confirmation</h3>
                            <b-form-group
                                id="input-group-AfterBookingTitle"
                                label="After booking title (Optional)"
                                label-for="input-AfterBookingTitle"
                                label-class="label-align"
                            >
                                <b-form-input
                                    id="input-AfterBookingTitle"
                                    v-model="form['AfterBooking']['Title']"
                                    type="text"
                                    placeholder="Enter title"
                                    class="rounded-pill"
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-AfterBookingText"
                                label="After booking text (Optional)"
                                label-for="input-AfterBookingText"
                                label-class="label-align"
                            >
                                <b-form-textarea
                                    id="input-AfterBookingText"
                                    placeholder="Text (Optional)"
                                    rows="3"
                                    style="border-radius: 16px"
                                    v-model="form['AfterBooking']['Text']"
                                ></b-form-textarea>
                            </b-form-group>
                            <b-form-group
                                id="input-group-AfterBookingButtonTitle"
                                label="After booking button title (Optional)"
                                label-for="input-AfterBookingButtonTitle"
                                label-class="label-align"
                            >
                                <b-form-input
                                    id="input-AfterBookingButtonTitle"
                                    v-model="form['AfterBooking']['CallToActionTitle']"
                                    type="text"
                                    placeholder="Enter title for action button"
                                    class="rounded-pill"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                id="input-group-AfterBookingButtonURL"
                                label="After booking button url (Optional)"
                                label-for="input-AfterBookingButtonURL"
                                label-class="label-align"
                            >
                                <b-form-input
                                    id="input-AfterBookingButtonURL"
                                    v-model="form['AfterBooking']['CallToActionURL']"
                                    type="url"
                                    placeholder="Enter url/link for action button"
                                    class="rounded-pill"
                                ></b-form-input>
                            </b-form-group>
                        </b-form-group>

                        <b-form-group
                            label-cols-lg="3"
                            label="Blocking rules"
                            label-size="lg"
                            label-class="font-weight-bold pt-0"
                        >
                            <b-form-group
                                id="input-group-cancellation-period"
                                label="When this resource is booked, the following resources are also blocked for the same period."
                            >
                                <div class="d-flex w-100 text-muted" v-if="resourcesToBlock.length === 0">
                                    There is no resource to block.
                                </div>
                                <b-list-group>
                                    <b-list-group-item
                                        v-for="(item, i) in resourcesToBlock"
                                        :key="i"
                                        class="d-flex col text-left align-items-center justify-content-between"
                                    >
                                        <div class="align mr-2 ml-2">
                                            <h2 class="mb-1">{{ item.resource.Name }}</h2>
                                            <span class="text-muted">Slots: {{ item.slots }}</span>
                                        </div>
                                        <b-button size="sm" class="mr-2" variant="danger" @click="removeBlockingRule(i)"
                                            >Delete
                                        </b-button>
                                    </b-list-group-item>
                                </b-list-group>
                                <b-button variant="outline-primary" class="my-2" v-b-modal.modal-add-resource
                                    >add rule
                                </b-button>
                            </b-form-group>
                        </b-form-group>

                        <b-form-group
                            label-cols-lg="3"
                            label="Calendar synchronization"
                            label-size="lg"
                            label-class="font-weight-bold pt-0"
                        >
                            <!-- if not tokens show text with link to integrations page -->
                            <div v-if="tokens.length === 0">
                                <b-form-text class="text-muted" id="calendar-sync-description"
                                    >To enable calendar synchronization, you need to connect your account to your
                                    calendar.
                                </b-form-text>
                                <b-button
                                    variant="outline-primary"
                                    class="my-2"
                                    target="_blank"
                                    to="/admin/settings/integrations/google-calendar"
                                    >connect
                                </b-button>
                            </div>
                            <b-form-group
                                id="input-group-calendar-sync-account"
                                label="Select account that has access to your calendar"
                            >
                                <b-form-select
                                    class="rounded-pill"
                                    v-model="selectedToken"
                                    :disabled="tokens.length === 0"
                                    @change="onTokenChange"
                                >
                                    <option :value="null" selected>Select connected account</option>
                                    <option
                                        v-for="(selectOption, indexOpt) in tokens"
                                        :key="indexOpt"
                                        :value="selectOption"
                                    >
                                        {{ selectOption.ID.substring(0, 16) }}... -
                                        {{ formatTimestamp(selectOption.CreatedAt) }}
                                    </option>
                                </b-form-select>
                            </b-form-group>

                            <b-form-group id="input-group-calendar-sync-account" label="Select calendar to sync with">
                                <b-form-select
                                    class="rounded-pill"
                                    v-model="selectedCalendar"
                                    :disabled="calendars.length === 0"
                                >
                                    <option v-if="calendarsLoading" :value="null" selected>
                                        <span>Loading...</span>
                                    </option>
                                    <option v-else :value="null" selected>Select calendar</option>
                                    <option
                                        v-for="(selectOption, indexOpt) in calendars"
                                        :key="indexOpt"
                                        :value="selectOption"
                                    >
                                        {{ selectOption.Name }}
                                    </option>
                                </b-form-select>
                            </b-form-group>
                        </b-form-group>

                        <div v-if="$unleash.isEnabled('frontend.booking-upgrades')">
                            <b-form-group
                                label-cols-lg="3"
                                label="Available booking upgrades"
                                label-size="lg"
                                label-class="font-weight-bold pt-0"
                            >
                                <CoTable
                                    :items="form.Upgrades"
                                    :preventHorizontalScroll="true"
                                    :loading="false"
                                    :columns="[
                                        { key: 'Name', title: $t('labels.name') },
                                        { key: 'MinAmount', title: $t('labels.minamount') },
                                        { key: 'MaxAmount', title: $t('labels.maxamount') },
                                        { key: 'actions', title: '' },
                                    ]"
                                    :sortable="false"
                                >
                                    <!-- Actions -->
                                    <template v-slot:actions="{ item }">
                                        <CoDropdown @click.stop>
                                            <template v-slot:button>
                                                <CoRoundButton icon="three-dots" variant="icon" />
                                            </template>
                                            <CoDropdownItem :title="$t('labels.edit')" @click="editUpgrade(item)">
                                            </CoDropdownItem>
                                            <CoDropdownItem
                                                :title="$t('labels.remove')"
                                                @click="removeUpgradeFromForm(item.OriginalID)"
                                            >
                                            </CoDropdownItem>
                                        </CoDropdown>
                                    </template>
                                </CoTable>

                                <CoButton variant="secondary" @click="$refs['add-booking-upgrade-modal'].show()"
                                    >Add upgrade</CoButton
                                >
                            </b-form-group>
                        </div>

                        <div class="w-100 d-flex justify-content-between">
                            <CoButton @click="hasHistory() ? $router.go(-1) : $router.push('/admin/booking/resource')"
                                >{{ $t('labels.cancel') }}
                            </CoButton>

                            <CoButton variant="primary" @click="handleCreateSubmit">{{ $t('labels.create') }}</CoButton>
                        </div>
                    </b-form>
                </div>
            </b-overlay>
        </div>

        <!-- add resource  modal-->
        <b-modal id="modal-add-resource" ref="modal-add-resource" title="Add blocking rule">
            <!-- select resource -->
            <b-form-group
                id="input-group-resource"
                label="Booking resource"
                label-for="input-booking-resource"
                label-class="label-align"
            >
                <b-form-select class="rounded-pill" v-model="newBlockingRule.resource">
                    <option :value="null" selected>Select resource</option>
                    <option v-for="(selectOption, indexOpt) in resources" :key="indexOpt" :value="selectOption">
                        {{ selectOption.Name }}
                    </option>
                </b-form-select>
            </b-form-group>

            <!-- slots input  -->
            <b-form-group
                id="input-group-slots"
                label="Slots"
                label-for="input-slots"
                label-class="label-align"
                description="Number of slots to be blocked for this resource."
            >
                <b-form-input
                    number
                    min="1"
                    v-model="newBlockingRule.slots"
                    step="1"
                    class="form-control rounded-pill d-block"
                    required
                ></b-form-input>
            </b-form-group>

            <template #modal-footer>
                <div class="d-flex justify-content-between w-100 m-0">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button size="sm" variant="danger" @click="cancelAddBlockingRule">Cancel</b-button>
                    <b-button size="sm" variant="primary" @click="addBlockingRule" :disabled="!newBlockingRule.resource"
                        >Add
                    </b-button>
                </div>
            </template>
        </b-modal>
        <!-- add resource -->

        <CoModal ref="add-booking-upgrade-modal" background="white" title="Booking upgrade" maxHeight>
            <template slot="body">
                <div v-if="canditateUpgrade" class="h-100">
                    <CoText :level="3" class="px-3">Selected upgrade</CoText>
                    <CoCardUpgrade
                        :upgrade="canditateUpgrade"
                        :withDelete="true"
                        @addUpgrade="selectUpgrade"
                        @deleteUpgrade="deleteUpgrade"
                    ></CoCardUpgrade>

                    <CoFormGroup label="Min. bookable amount">
                        <CoInput type="number" :value="1" v-model="canditateUpgrade.MinAmount"
                    /></CoFormGroup>
                    <CoFormGroup label="Max. bookable amount">
                        <CoInput type="number" :value="0" v-model="canditateUpgrade.MaxAmount" />
                        <CoText type="p3" variant="mute" class="ml-2 mt-2">For unlimited, leave null</CoText>
                    </CoFormGroup>
                </div>
                <div v-else class="">
                    <div v-if="!loadingUpgrades && (!allUpgrades || allUpgrades.length === 0)" class="text-center">
                        <CoText>no upgrades available</CoText>
                        <div class="d-flex align-items-center justify-content-center w-100">
                            <CoButton @click="getUpgrades()" class="mr-1">{{ $t('labels.refresh') }}</CoButton>
                            <CoButton variant="primary" class="ml-1" @click="goToCreateUpgrade()">{{
                                $t('labels.create')
                            }}</CoButton>
                        </div>
                    </div>
                    <div v-for="(upgrade, index) in allUpgrades" :key="upgrade.ID" class="mb-3">
                        <CoCardUpgrade :upgrade="upgrade" :withAdd="true" @addUpgrade="selectUpgrade"></CoCardUpgrade>
                    </div>
                </div>
            </template>
            <template slot="footer">
                <div class="mb-0 mt-auto d-flex align-items-center justify-content-between">
                    <CoButton @click="discardUpgradeCandidate">{{ $t('labels.discard') }}</CoButton>
                    <CoButton @click="saveUpdateCandidate" :disabled="!canditateUpgrade" variant="primary">{{
                        $t('labels.save')
                    }}</CoButton>
                </div>
            </template>
        </CoModal>
    </div>
</template>
<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<style lang="less" scoped>
.resource-image {
    object-fit: cover;
}

.custom-file-label {
    border-radius: 50rem !important;
}
</style>
<script>
import axios from 'axios';
import clone from 'lodash/clone';
import EventBus from '@/eventBus';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoTable from '@/components/Molecules/co-table/CoTable.vue';
import Nuki from '../Settings/Integrations/Nuki.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';

export default {
    name: 'CreateBookingResource',
    components: { CoCard, CoText, CoInput, CoButton, CoTable, CoDropdown, CoDropdownItem },
    data() {
        return {
            from: '{{from}}',
            to: '{{to}}',
            comment: '{{comment}}',
            resource_name: '{{resource_name}}',
            locations: [],
            loading: false,
            resources: [],
            dateOverride: [],

            form: {
                LocationID: '',
                PricePerHour: 0.0, // deprecated
                TaxRate: 16.0, // deprecated
                PriceInCentsPerHour: 0,
                Currency: 'EUR',
                Capacity: 1,
                CancellationPeriod: 0,
                MinBookingDuration: 0,
                MaxBookingDuration: 0,
                BookingTimes: [],
                NotifyEmails: [''],
                ChargeForLikedResources: false,
                AfterBookingEmailContent: `Thanks for your booking!\n\nWhat: {{resource_name}}\nFrom: {{from}}\nTo: {{to}}\nComment: {{comment}}`,
                AfterBooking: {
                    Title: '',
                    Text: '',
                    CallToActionURL: '',
                    CallToActionTitle: '',
                },
                Upgrades: [],
            },
            previewImg: null,
            previewImgURL: null,
            intervalKey: 0,

            taxRates: [],
            FinalPrice: 0.0,
            PricePerHour: 0.0,

            // list of resource to block on booking of this resource
            resourcesToBlock: [],
            newBlockingRule: {
                resource: null,
                slots: 1,
            },

            // list of tokens granted by user to access external calendars
            tokens: [],
            selectedToken: null,

            // list of external calendars available for selected token
            calendars: [],
            calendarsLoading: false,
            selectedCalendar: null,
            isBillingTurnedOn: this.$store.state.billingTurnedOn,

            plans: [],

            allUpgrades: [],
            canditateUpgrade: null, // selected upgrade to add to list
            loadingUpgrades: false,
        };
    },

    computed: {
        isTherePlans() {
            if (!this.plans || this.plans.length === 0) {
                return false;
            }

            const filtered = this.plans.filter((p) => p.Active);
            if (filtered && filtered.length > 0) {
                return true;
            }

            return false;
        },
    },

    created() {
        if (this.$store.state.space.Currency) {
            this.form.Currency = this.$store.state.space.Currency;
        }

        this.addInterval();
        this.listResources();
        this.listLocations();
        this.getTaxRates();
        this.getTokens();
        this.fetchPlans();
        this.getUpgrades();
    },
    methods: {
        goToCreateUpgrade() {
            window.open(`https://${window.location.hostname}/admin/monetization/plan-upgrade/create`, '_blank');
        },
        editUpgrade(val) {
            this.canditateUpgrade = val;
            this.$refs['add-booking-upgrade-modal'].show();
        },
        removeUpgradeFromForm(id) {
            if (!this.form.Upgrades) return;

            this.form.Upgrades = this.form.Upgrades.filter((up) => up.OriginalID !== id);
        },
        saveUpdateCandidate() {
            if (!this.form.Upgrades) {
                this.form['Upgrades'] = [clone(this.canditateUpgrade)];
                this.discardUpgradeCandidate();
                return;
            }

            const found = this.form.Upgrades.find((up) => up.OriginalID === this.canditateUpgrade.OriginalID);
            if (found) {
                this.form.Upgrades = this.form.Upgrades.map((up) => {
                    if (up.OriginalID === this.canditateUpgrade.OriginalID) {
                        return this.canditateUpgrade;
                    }
                    return up;
                });
                this.discardUpgradeCandidate();
                return;
            }

            this.form.Upgrades.push(clone(this.canditateUpgrade));
            this.discardUpgradeCandidate();
        },
        discardUpgradeCandidate() {
            this.canditateUpgrade = null;
            this.$refs['add-booking-upgrade-modal'].hide();
        },
        deleteUpgrade(val) {
            this.canditateUpgrade = null;
        },
        selectUpgrade(event, vals) {
            if (!vals || vals.length === 0) return;
            [this.canditateUpgrade] = vals;
            this.canditateUpgrade['MinAmount'] = 0;
            this.canditateUpgrade['MaxAmount'] = 0;
            this.canditateUpgrade['OriginalID'] = this.canditateUpgrade.ID;
        },
        getUpgrades() {
            this.loadingUpgrades = true;
            axios({
                method: 'GET',
                url: `/admin/extra/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.allUpgrades = response.data.Extras ? response.data.Extras : [];
                    }
                    this.loadingUpgrades = false;
                })
                .catch((error) => {
                    this.loadingUpgrades = false;
                });
        },
        // fetches community membership plans
        fetchPlans() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/plan/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.plans = response.data.Plans;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        hasHistory() {
            return window.history.length > 2;
        },

        formatTimestamp(timestamp) {
            if (!timestamp) return 'n/a';
            const dateTime = new Date(timestamp * 1000).toString();
            return dateTime;
        },

        onTokenChange(token) {
            this.getCalendars(this.selectedToken.ID);
        },

        /**
         * Get calendars accessable by selected token
         * @param {string} tokenID
         * @return {void}
         */
        getCalendars(tokenID) {
            this.calendarsLoading = true;

            axios({
                method: 'GET',
                url: `/admin/booking/resource/external-calendars/${tokenID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.calendars = response.data.Calendars;
                    }
                    this.calendarsLoading = false;
                })
                .catch((error) => {
                    this.calendarsLoading = false;
                    EventBus.$emit('ERROR', {
                        Message: "Can't list external calendars",
                    });
                });
        },

        /**
         * Get tokens granted by user to access external calendar
         * @function getTokens
         * @return {void}
         * @param {void}
         */
        getTokens() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/oauth2/google/calendar/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Tokens) {
                        this.tokens = response.data.Tokens;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        priceChange() {
            if (this.form.TaxRateID) {
                this.calculateFinalPrice(this.form.TaxRateID);
            }
        },
        calculateFinalPrice(taxID) {
            const tax = this.taxRates.find((item) => item.ID === taxID);
            if (tax.Inclusive) {
                this.FinalPrice = this.PricePerHour;
            } else {
                this.FinalPrice = (this.PricePerHour * (tax.Percentage / 100 + 1)).toFixed(2);
            }
        },

        addDateOverride() {
            this.dateOverride.push({
                date: null,
                from: '09:00',
                to: '19:00',
            });
        },

        removeDateOverride(index) {
            this.dateOverride.splice(index, 1);
        },

        prepearDateOverrides() {
            const bookingTimeOverride = {};
            this.dateOverride.forEach((entry) => {
                bookingTimeOverride[entry.date] = {
                    from: entry.from,
                    to: entry.to,
                };
            });
            this.form.BookingTimeOverride = bookingTimeOverride;
        },

        prepareExternalCalendars() {
            const externalCalendars = [];

            if (this.selectedCalendar) {
                externalCalendars.push({
                    ID: this.selectedCalendar.ID,
                    Name: this.selectedCalendar.Name,
                    tokenID: this.selectedToken.ID,
                });
            }

            return externalCalendars;
        },

        removeEmail(index) {
            this.form.NotifyEmails.splice(index, 1);
        },
        addInterval() {
            const newInterval = {
                From: '09:00',
                To: '17:00',
                Weekdays: [1],
                buttons: [
                    { caption: 'Mo', state: true, value: 1 },
                    { caption: 'Tue', state: true, value: 2 },
                    { caption: 'Wed', state: true, value: 3 },
                    { caption: 'Th', state: true, value: 4 },
                    { caption: 'Fr', state: true, value: 5 },
                    { caption: 'Sa', state: false, value: 6 },
                    { caption: 'Su', state: false, value: 7 },
                ],
            };
            if (this.form.BookingTimes != null) {
                this.form.BookingTimes.push(newInterval);
            } else {
                this.form.BookingTimes = [newInterval];
            }
            this.intervalKey++;
        },
        removeInterval(index) {
            this.form.BookingTimes.splice(index, 1);
            this.intervalKey--;
        },
        previewImage(event) {
            this.previewImg = event.target.files[0];
            this.previewImgURL = URL.createObjectURL(this.previewImg);
        },

        handleCreateSubmit(event) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }

            this.loading = true;
            if (this.previewImg) {
                this.uploadImage();
            } else {
                this.createdResource();
            }
        },
        checkFormValidity() {
            const valid = this.$refs.createResource.checkValidity();
            return valid;
        },

        uploadImage() {
            const url = '/upload/image/booking-resources';
            const data = new FormData();
            data.append('file', this.previewImg);
            axios({
                method: 'POST',
                url,
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'image/*',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.url) {
                        this.form.ImageURL = response.data.url;
                        this.createdResource();
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not upload image.',
                            Details: error,
                        });
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    const message = {
                        Message: 'Could not upload image.',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', {
                        Message: 'Could not upload image.',
                        Details: error,
                    });
                    this.loading = false;
                });
        },

        prepearBookingTimes() {
            const newBookingTimes = [];
            this.form.BookingTimes.forEach((entry) => {
                const weekdays = [];
                entry.buttons.forEach((item) => {
                    if (item.state) {
                        weekdays.push(item.value);
                    }
                });

                if (weekdays.length != 0) {
                    newBookingTimes.push({
                        From: entry.From,
                        To: entry.To,
                        Weekdays: weekdays,
                    });
                }
            });
            this.form.BookingTimes = newBookingTimes;
        },
        createdResource() {
            this.prepearBookingTimes();
            this.prepearDateOverrides();
            this.form.ExternalCalendars = this.prepareExternalCalendars();
            this.form.Rules = this.prepateBlockingRules();

            this.form.NotifyEmails = this.form.NotifyEmails.filter((el) => el != '');

            this.form.PriceInCentsPerHour = this.PricePerHour * 100;
            const data = JSON.stringify(this.form);
            axios({
                method: 'POST',
                url: '/admin/booking/resource',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const message = {
                        Message: 'Resource created',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);

                    this.$router.push(`/admin/booking/resource/edit/${response.data.ID}`);
                })
                .catch((error) => {
                    console.log(error);
                    // todo error processing
                    const message = {
                        Message: 'Could not create resource',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    this.loading = false;
                });
        },

        /**
         * listResources - return list of resources for space
         */
        listResources() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (
                        response.data != null &&
                        response.data.Resources != null &&
                        response.data.Resources.length != 0
                    ) {
                        this.resources = response.data.Resources;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                        if (this.taxRates.length > 0) {
                            this.form.TaxRateID = this.taxRates[0].ID;
                        }
                        if (this.taxRates.length > 1) {
                            EventBus.$emit('INFO', {
                                Message: 'Tax rates pre-filled automatically. You can change it if you want.',
                            });
                        }
                    }
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },

        prepateBlockingRules() {
            const rules = this.resourcesToBlock.map((item) => ({
                BlockResourceID: item.resource.ID,
                NumberOfSlots: item.slots,
            }));
            return rules;
        },
        removeBlockingRule(index) {
            this.resourcesToBlock.splice(index, 1);
        },
        cancelAddBlockingRule() {
            this.$refs['modal-add-resource'].hide();
            this.newBlockingRule = {
                resource: null,
                slots: 1,
            };
        },
        // add blocking rule
        addBlockingRule() {
            const rule = this.resourcesToBlock.find((item) => item.resource.ID === this.newBlockingRule.resource.ID);

            if (rule) {
                const message = {
                    Message: 'This resource already present in blocking rules',
                    Details: '',
                };
                EventBus.$emit('ERROR', message);
                return;
            }
            this.resourcesToBlock.push(this.newBlockingRule);
            this.$refs['modal-add-resource'].hide();
            this.newBlockingRule = {
                resource: null,
                slots: 1,
            };
        },
        listLocations() {
            this.$store
                .dispatch('listLocations')
                .then((locations) => {
                    this.locations = locations;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
};
</script>
