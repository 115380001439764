




import { isString, isNumber, isBoolean, isArray, isPlainObject, isEmpty, uniq } from 'lodash';
import coRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';

export default {
    name: 'CoDownloadAsCsv',
    components: {
        coRoundButton,
    },
    props: {
        title: String,
        data: [Array],
        clickMethod: Function,
    },
    computed: {
        downloadData() {
            return this.data;
        },
    },
    methods: {
        clickHandler(event) {
            if (this.clickMethod && typeof this.clickMethod === 'function') {
                this.clickMethod();
            } else if (this.downloadData && this.downloadData.length) {
                this.exportAsCSV(this.downloadData);
            }
            this.$emit('click', event);
        },
        exportAsCSV(data: Array<Object>) {
            var columns = {};
            var rows = [];
            var csvList = [];
            var csvString = '';

            data.map((el, i) => {
                let flatItem = this.flattenObject(el);
                rows.push(flatItem);
                // collect all occurring keys in columns Object
                Object.keys(flatItem).map((i) => {
                    columns[i] = true;
                });
            });

            csvList.push(Object.keys(columns).sort());
            // 2. map each row to the columns
            rows.map((row) => {
                const line = csvList[0].map((i) => row[i]);
                csvList.push(line);
            });

            csvString += csvList
                .map(
                    (row) =>
                        row
                            .map(String) // convert every value to String
                            .map((v) => v.replaceAll('"', '""')) // escape double colons
                            .map((v) => v.replaceAll('#', '')) // remove all '#' because it breaks urls!!
                            .map((v) => `"${v}"`) // quote it
                            .join(',') // comma-separated
                )
                .join('\r\n'); // rows starting on new lines

            // create the csv file as blob
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

            // initiate file download in the clients browser
            if (navigator && navigator['msSaveBlob']) {
                // fallback for IE 10+
                navigator['msSaveBlob'](blob, 'download.csv');
            } else {
                //download in modern browsers
                var link = document.createElement('a');
                if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', 'download.csv');
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    //important: free memory when finished
                    URL.revokeObjectURL(url);
                }
            }
        },
        flattenObject(o: any, prefix?: string, result?: any): any {
            prefix = prefix ? prefix : '';
            result = result ? result : {};

            if (isString(o) || isNumber(o) || isBoolean(o)) {
                result[prefix] = o;
                return result;
            }

            if (isArray(o) || isPlainObject(o)) {
                for (let i in o) {
                    let pref = prefix;
                    if (isArray(o)) {
                        pref = pref + `[${i}]`;
                    } else {
                        if (isEmpty(prefix)) {
                            pref = i;
                        } else {
                            pref = prefix + '.' + i;
                        }
                    }

                    this.flattenObject(o[i], pref, result);
                }
                return result;
            }
            return result;
        },
    },
};
