




































































































import { PropType } from 'vue';

import { add, isSameDay } from 'date-fns';
import { get, orderBy, update } from 'lodash';

import Vue from 'vue';
import i18n from 'vue-i18n';
Vue.use(i18n);

import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoInvoiceDetail from '../../Molecules/co-invoice-detail/CoInvoiceDetail.vue';
import CoModal from '../co-modal/CoModal.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';

import { UpcommingInvoice } from './models.ts';

import { format, isToday } from 'date-fns';

export default {
    name: 'CoSubscriptionNextInvoicePreview',
    components: {
        CoIcon,
        CoHeadline,
        CoCard,
        CoText,
        CoSkeleton,
        CoModal,
        CoInvoiceDetail,
        CoButton,
        CoDate,
        CoLink,
    },
    i18n: {
        messages: {
            en: {
                labels: {
                    now: 'Now',
                    invoicepreview: 'Invoice Preview',
                    freetrial: 'Free Trial',
                },
            },
        },
    },
    props: {
        invoice: {
            type: Object as PropType<UpcommingInvoice>,
            default: {} as UpcommingInvoice,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rendringKey: 0,
            invoiceToPreview: null,
        };
    },
    watch: {
        invoice: {
            handler(value: UpcommingInvoice) {
                this.rendringKey += 1;
            },
            deep: true,
        },
    },

    computed: {
        freeTrialEndDate() {
            if (this.invoice.freeTrialEnd) {
                return this.timestampToDate(this.invoice.freeTrialEnd);
            }

            // add free trial days to the subscription start date using date-fns
            return this.timestampToDate(this.invoice.subscriptionStart + this.invoice.freeTrialDays * 24 * 60 * 60);
        },
        nextBillingCycleDate() {
            let thisBillingCycleDate = null;
            // when subscription is created
            if (this.invoice.subscriptionCreation) {
                thisBillingCycleDate = this.invoice.anchorDate ? this.invoice.anchorDate : this.invoice.Created;
            } else {
                thisBillingCycleDate = this.invoice.Created;
            }

            if (this.invoice.TimeInterval === 1) {
                return Math.round(add(new Date(thisBillingCycleDate * 1000), { years: 1 }).getTime() / 1000);
            } else {
                return Math.round(add(new Date(thisBillingCycleDate * 1000), { months: 1 }).getTime() / 1000);
            }
        },
        computedTimelineEvents() {
            const events = [];
            const now = Math.round(new Date().getTime() / 1000);
            if (!this.invoice) {
                return events;
            }
            // subscription start event
            if (this.invoice.subscriptionCreation) {
                if (this.invoice.anchorDate && this.invoice.anchorDate > now) {
                    events.push({
                        title: 'Subscription start',
                        date: now,
                    });
                } else if (this.invoice.Created) {
                    events.push({
                        title: 'Subscription start',
                        date: this.invoice.Created,
                    });
                }
            } else if (this.invoice.subscriptionStart) {
                events.push({
                    title: 'Subscription start',
                    date: this.invoice.subscriptionStart,
                });
            }
            // free trial days
            if (this.invoice.freeTrialDays) {
                events[0].freeTrialDays = this.invoice.freeTrialDays;
            }
            // plan update event is today
            if (this.invoice.scheduledNow || isToday(this.invoice.scheduledDate)) {
                events.push({
                    title: 'Plan update',
                    date: now,
                });
            } else if (this.invoice.scheduledDate) {
                events.push({
                    title: 'Plan update',
                    date: this.invoice.scheduledDate,
                });
            }
            // next invoice event
            events.push({
                title: this.invoice.subscriptionCreation ? 'First invoice' : 'Next invoice',
                date: this.invoice.Created,
                invoice: this.invoice,
            });
            // recurring invoice event
            if (
                this.invoice.firstRegularInvoice &&
                !isSameDay(this.invoice.Created * 1000, this.invoice.firstRegularInvoice.Created * 1000)
            ) {
                events.push({
                    title: this.invoice.TimeInterval === 1 ? 'Renews annually' : 'Renews monthly',
                    date: get(this.invoice.firstRegularInvoice, 'Created', this.nextBillingCycleDate),
                    invoice: this.invoice.firstRegularInvoice,
                    type: 'recurring',
                });
            } else if (!this.invoice.subscriptionEnd || this.nextBillingCycleDate < this.invoice.subscriptionEnd) {
                events.push({
                    title: this.invoice.TimeInterval === 1 ? 'Renews annually' : 'Renews monthly',
                    date: this.nextBillingCycleDate,
                    type: 'recurring',
                });
            }
            // subscription end event
            if (this.invoice.subscriptionEnd) {
                events.push({
                    title: 'Subscription end',
                    date: this.invoice.subscriptionEnd,
                });
            }

            // aggregate events that are on the same day
            events.forEach((event, index) => {
                const nextEvent = events[index + 1];
                if (nextEvent && isSameDay(event.date * 1000, nextEvent.date * 1000)) {
                    // merge the next event into the current event
                    event.titles = [];
                    event.title ? event.titles.push(event.title) : null;
                    nextEvent.title ? event.titles.push(nextEvent.title) : null;
                    event.invoices = [];
                    event.invoice ? event.invoices.push(event.invoice) : null;
                    nextEvent.invoice ? event.invoices.push(nextEvent.invoice) : null;

                    // remove the next event
                    events.splice(index + 1, 1);
                } else {
                    event.titles = [event.title];
                    event.invoices = [event.invoice];
                }
            });
            // sort events by date and return
            return orderBy(events, 'date', 'asc');
        },
    },

    methods: {
        isSameDay,
        get,
        isToday,
        price(priceInCents: number) {
            if (!priceInCents) {
                return '0.00';
            }

            if (priceInCents < 0) {
                return `-${this.price(-priceInCents)}`;
            }
            return (priceInCents / 100).toFixed(2);
        },
        currencyToSymbol(currency: string) {
            if (!currency) return '';
            // lower case to avoid issues with the switch
            currency = currency.toLowerCase();

            switch (currency) {
                case 'eur':
                    return '€';
                case 'usd':
                    return '$';
                default:
                    return currency.toUpperCase();
            }
        },
        timestampToDate(timestamp: number = 0) {
            if (timestamp === 0) {
                return 'unavailable';
            }
            return format(new Date(timestamp * 1000), 'dd MMMM yyyy');
        },
        previewInvoice(invoice) {
            if (!invoice) {
                return;
            }
            this.invoiceToPreview = invoice.raw ? invoice.raw : invoice;
            this.$refs.universalInvoicePreviewModal.show();
        },
    },
};
