
















































































import EventBus from '@/eventBus';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import axios from 'axios';
import { sortBy, filter } from 'lodash';
import Vue from 'vue';

export default {
    name: 'ProjectTypes',
    i18n: {
        messages: {
            en: {
                deleteChannelInfo:
                    'Once you delete a channel, there is no going back. Please move all pages that use this channel to another channel.',
                isLastChannelWarning:
                    'You are about to delete the last channel. Pages can only be created as long as there are Channels available in which the user has the right to create a page. Deleting this channel will make it impossible to create new pages until you create a new channel.',
            },
            de: {
                deleteChannelInfo:
                    'Sobald du einen Kanal löschst, gibt es kein Zurück. Bitte verschiebe alle Seiten, die diesen Kanal verwenden, in einen anderen Kanal.',
                isLastChannelWarning:
                    'Du bist dabei, den letzten Kanal zu löschen. Seiten können nur erstellt werden, wenn Kanäle verfügbar sind, in denen der Benutzer das Recht hat, eine Seite zu erstellen. Das Löschen dieses Kanals macht es unmöglich, neue Seiten zu erstellen, bis du einen neuen Kanal erstellst.',
            },
        },
    },
    components: { CoIcon, CoConfirmation, CoDropdown, CoDropdownItem },
    data() {
        return {
            search: '',
            filtered: [],
            objects: [],

            loading: false,
            deleting: false,

            // create form data
            name: '',
            nameState: null,

            toDelete: null,
            toEdit: null,
        };
    },
    computed: {
        isLastChannel() {
            return this.objects?.length === 1;
        },
    },
    mounted() {
        this.listProjectTypes();
    },
    watch: {
        search() {
            this.filter();
        },
    },
    methods: {
        filter() {
            this.filtered = this.objects.filter((obj) => obj.Value.toLowerCase().includes(this.search.toLowerCase()));
        },
        editChannel(obj) {
            this.toEdit = obj;
            this.$refs.channelEdit.show();
        },
        showDeleteModal(obj) {
            this.toDelete = obj;
            this.$bvModal.show('deleting-modal');
        },

        listProjectTypes() {
            this.objects = [];
            axios({
                method: 'GET',
                url: '/admin/community/project-type/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.objects && response.data.objects.length != 0) {
                        response.data.objects.forEach((element) => {
                            this.objects.push(element.object);
                        });
                        this.objects = sortBy(this.objects, [(channel) => channel.Value.toLowerCase()], ['desc']);
                        this.filter();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        deleteProjectType(object) {
            this.deleting = true;
            const data = JSON.stringify(object);
            axios({
                method: 'DELETE',
                url: '/admin/community/project-type',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Channel deleted',
                    });
                    this.listProjectTypes();
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Could not delete channel',
                    });
                    console.log(error);
                })
                .finally(() => {
                    this.deleting = false;
                });
        },

        createProjectType(obj) {
            const data = JSON.stringify(obj);

            axios({
                method: 'POST',
                url: '/admin/community/project-type',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.name = '';
                    this.listProjectTypes();

                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-create-project-type');
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        resetCreateModal(event) {
            this.name = '';
        },

        handelCreateModalOk(event) {
            // Prevent modal from closing
            event.preventDefault();
            // Trigger submit handler
            this.handleCreateSubmit();
        },
        handleCreateSubmit(event) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }

            this.createProjectType({ Value: this.name });
        },
        checkFormValidity() {
            const valid = this.$refs.createform.checkValidity();
            this.nameState = valid;
            return valid;
        },
    },
};
