<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.grey {
    color: #888888;
}

.primary {
    color: @purple;
}

.back-grey {
    background-color: #e0e0e0;
}

.wider {
    min-width: 160px;
}

.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

.noShow {
    display: none;
}

.list-item {
    padding: 0;
    background: white;
    margin: 0 0 10px;
    cursor: pointer;
    overflow: visible;

    .picture {
        width: 96px;
        height: 96px;
        margin-left: 0;
        object-fit: cover;

        @media (min-width: 768px) {
            width: 134px;
            height: 134px;
            object-fit: cover;
        }
    }
}
</style>

<template>
    <div class="w-75 max-width-700">
        <div class="list-item round-unify w-100 d-flex align-items-start overflow-hidden justify-content-between">
            <div class="d-flex w-100 flex-column align-items-start item-data">
                <div class="row w-100 p-0 m-0">
                    <div class="justify-content-between col-12">
                        <div class="pb-3 pl-2 pt-2">
                            <div
                                class="
                                    d-flex
                                    flex-column flex-lg-row
                                    justify-content-between
                                    align-items-start align-items-lg-center
                                "
                            >
                                <h1 class="title mt-1 mt-lg-2 mb-2 mb-xs-3">
                                    {{ timepass.Name }}
                                </h1>
                                <div class="d-flex align-items-center mt-1 ml-0 ml-lg-2 mt-lg-2">
                                    <CoButton v-if="active" variant="primary" label="Currently active" disabled />
                                    <CoConfirmation
                                        v-else
                                        :message="$t('usetimepassconfirmation')"
                                        :confirmLabel="$t('usetimepass')"
                                        confirmVariant="primary"
                                        @confirm="
                                            admin
                                                ? applyTimePassAdmin(timepass['ID'])
                                                : applyTimePassUser(timepass['ID'])
                                        "
                                    >
                                        <CoButton variant="primary" :disabled="disabled" :label="$t('usetimepass')" />
                                    </CoConfirmation>
                                </div>
                            </div>

                            <div
                                class="
                                    d-flex
                                    flex-column flex-lg-row
                                    justify-content-between
                                    align-items-start align-items-lg-center
                                    mt-4
                                "
                            >
                                <div class="d-flex mt-3 mt-lg-1 mb-0 mb-lg-2 align-items-end">
                                    {{ fromCentsToFullUnit(timepass['Price']) }}
                                    {{ timepass['Currency'] ? timepass['Currency'] : $store.state.space.Currency }}
                                </div>
                                <div>
                                    <span class="grey mr-3">{{ left }} Left </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                    <div class="d-none d-xl-block col-6 p-0 m-0 justify-content-between back-grey pt-2 pl-4">-->
                    <!--                        <div class="d-flex align-items-center mt-lg-3">-->
                    <!--                            <div class="d-flex flex-column p-1">-->
                    <!--                                <span>History</span>-->
                    <!--                                <span class="mt-3 grey">Bought</span>-->
                    <!--                                <span v-if="active" class="mt-3 grey">Applied</span>-->
                    <!--                                <span v-else class="mt-3 primary gret">Last applied</span>-->
                    <!--                            </div>-->
                    <!--                            <div class="d-flex flex-column ml-5 pl-3">-->
                    <!--                                <span>Date</span>-->
                    <!--                                <span class="mt-3 grey">{{ createdAt }}</span>-->
                    <!--                                <span v-if="active" class="mt-3 grey">Today</span>-->
                    <!--                                <span v-else class="mt-3 primary grey">{{ updatedAt }}</span>-->
                    <!--                            </div>-->
                    <!--                            <div class="d-none d-md-flex flex-column ml-5 pl-3">-->
                    <!--                                <span>Time</span>-->
                    <!--                                <span class="mt-3 grey">{{ createdTime }}</span>-->
                    <!--                                <span v-if="active" class="mt-3 primary"-->
                    <!--                                    >Valid until {{ validTilDate }} {{ validTilTime }}-->
                    <!--                                </span>-->
                    <!--                                <span v-else class="mt-3 primary grey"> - </span>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
        <!--        <div class="d-block d-xl-none col-12 p-0 m-0 justify-content-between pt-0 pb-2 pl-2 pl-lg-4">-->
        <!--            <div class="d-flex align-items-center mt-lg-3">-->
        <!--                <div class="d-flex flex-column p-1">-->
        <!--                    <span>History</span>-->
        <!--                    <span class="mt-3 grey">Bought</span>-->
        <!--                    <span v-if="active" class="mt-3 grey">Applied</span>-->
        <!--                    <span v-else class="mt-3 primary gret">Last applied</span>-->
        <!--                </div>-->
        <!--                <div class="d-flex flex-column ml-5 pl-3">-->
        <!--                    <span>Date</span>-->
        <!--                    <span class="mt-3 grey">{{ createdAt }}</span>-->
        <!--                    <span v-if="active" class="mt-3 grey">Today</span>-->
        <!--                    <span v-else class="mt-3 primary grey">{{ updatedAt }}</span>-->
        <!--                </div>-->
        <!--                <div class="d-none d-md-flex flex-column ml-5 pl-3">-->
        <!--                    <span>Time</span>-->
        <!--                    <span class="mt-3 grey">{{ createdTime }}</span>-->
        <!--                    <span v-if="active" class="mt-3 primary">Valid until {{ validTilDate }} {{ validTilTime }} </span>-->
        <!--                    <span v-else class="mt-3 primary grey"> - </span>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
import i18n from 'vue-i18n';
import axios from 'axios';
import Router from 'vue-router';
import { fromUnixTime, format, getUnixTime } from 'date-fns';
import { get } from 'lodash';
import EventBus from '../../eventBus';

import CoButton from '../../components/Atoms/co-button/CoButton.vue';
import CoConfirmation from '../../components/Molecules/co-confirmation/CoConfirmation.vue';

export default {
    name: 'TimepassCardWallet',
    i18n: {
        messages: {
            en: {
                usetimepass: 'Use now',
                usetimepassconfirmation:
                    'If you proceed this pass will be activated immediately and redeemed. Are you sure?',
            },
            de: {
                usetimepass: 'Jetzt einlösen',
                usetimepassconfirmation:
                    'Wenn du fortfährst wird dieser Pass sofort aktiviert und entwertet. Bist du sicher?',
            },
        },
    },
    components: { CoButton, CoConfirmation },
    emits: ['updatetimepass'],
    props: {
        timepass: { type: Object, required: true },
        active: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        admin: {
            type: Boolean,
            default: false,
        },
        userID: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            loading: false,
            taxRate: null,
            createdAt: '',
            createdTime: '',
            expiredAt: '',
            updatedAt: '',
            validYear: '',
            validDate: '',
            currentYear: '',
            currentDate: '',
            validTilDate: '',
            validTilTime: '',

            applyTimepassRequest: {},
        };
    },
    watch: {},
    computed: {
        left() {
            const total = get(this.timepass, 'Number', 0);
            const used = get(this.timepass, 'UsedNumber', 0);
            const left = total - used;
            if (left < 0) {
                return 0;
            }
            return left;
        },
    },
    mounted() {
        const createdDate = fromUnixTime(this.timepass.CreatedAt);
        const updatedDate = fromUnixTime(this.timepass.UpdatedAt);
        const validTil = fromUnixTime(this.timepass.UpdatedAt + this.timepass.Duration * 3600);
        // const expiredDateInt = get(this.timepass, 'ExpirationDate', 0);
        // if (expiredDateInt) {
        //     const expiredDate = fromUnixTime(expiredDateInt);
        //     if (expiredDate) {
        //         this.expiredAt = format(expiredDate, 'dd.MM.yyyy');
        //     }
        // }

        this.currentYear = format(new Date(), 'yyyy');
        this.validYear = format(validTil, 'yyyy');

        this.currentDate = format(new Date(), 'dd.MM.yyyy');
        this.validDate = format(validTil, 'dd.MM.yyyy');
        if (this.currentDate !== this.validDate) {
            this.validTilDate = format(validTil, 'dd.MM');
        }
        this.createdAt = format(createdDate, 'dd.MM.yyyy');
        this.createdTime = format(createdDate, 'HH:mm');
        this.updatedAt = format(updatedDate, 'dd.MM.yyyy');

        this.validTilTime = format(validTil, 'HH:mm');
    },
    methods: {
        fromCentsToFullUnit(cents) {
            if (cents) {
                return (cents / 100).toFixed(2);
            }
            return 0;
        },
        applyTimePassAdmin(ID) {
            this.loading = true;
            this.applyTimepassRequest = {
                WalletItemID: ID,
                SpaceID: this.$store.state.spaceID,
                ForUser: this.userID,
                UserID: this.userID,
                ByUser: this.$store.state.me.ID,
            };
            axios({
                method: 'POST',
                url: 'admin/time-pass/apply',
                withCredentials: true,
                data: JSON.stringify(this.applyTimepassRequest),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Successfully applied Time Pass for User',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.$emit('updatetimepass');
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to apply Time Pass for User',
                        Details: error,
                    };
                    if (error.response.data.message === 'user has active time pass') {
                        msg.Message = 'User already have an active Time Pass';
                    }
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
        },
        applyTimePassUser(ID) {
            this.loading = true;
            this.applyTimepassRequest = {
                WalletItemID: ID,
                SpaceID: this.$store.state.spaceID,
                ForUser: this.$store.state.me.ID,
                UserID: this.$store.state.me.ID,
                ByUser: this.$store.state.me.ID,
            };
            axios({
                method: 'POST',
                url: 'user/time-pass/apply',
                withCredentials: true,
                data: JSON.stringify(this.applyTimepassRequest),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Successfully applied Time Pass',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    // this.$router.push(`/account/time-passes`);
                    this.$router.go(0);
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to apply Time Pass',
                        Details: error.response.data,
                    };
                    if (error.response.data.message === 'user has active time pass') {
                        msg.Message = 'You already have an active Time Pass';
                    }
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
        },
        calculatePrice(item) {
            if (!item.taxRate || item.TaxRate.Inclusive) {
                const price = item.PriceInCents / 100.0;
                return price;
            }
            const price = item.PriceInCents / 100.0;
            const totalPrice = price * (item.TaxRate.Percentage / 100 + 1);
            return totalPrice;
        },
    },
};
</script>
