<template>
    <div class="d-flex justify-content-center align-items-md-center w-100">
        <vue-headful
            :title="'Login -' + this.$store.state.space.Name + 'Community Platform'"
            description=""
            :image="this.$store.state.space.LogoURL"
        />
        <!-- user login -->
        <div class="login-form">
            <div class="mt-4 mt-md-0 p-3 bg-white round-unify text-left">
                <h2 class="my-2 text-center">{{ $t('labels.passwordsetnew') }}</h2>

                <div class="w-100 pt-4">
                    <div class="alert alert-danger bg-white text-orange" role="alert" v-if="error">
                        <p class="mb-0">{{ $t('codeexpired') }}</p>
                        <CoLink to="/reset-password" :title="$t('requestnewcode')" />
                    </div>

                    <b-form @submit.prevent="setPassword" v-if="step == 1">
                        <b-form-group id="input-group-2">
                            <div class="mb-1">
                                <span class="label-align">{{ $t('labels.password') }}</span
                                ><span class="ml-2 clickable" @click="switchPasswordView"
                                    ><b-icon variant="primary" :icon="passwortViewIcon"></b-icon
                                ></span>
                            </div>
                            <b-form-input
                                id="input-2"
                                v-model="password"
                                :type="passwortInputType"
                                class="form-control rounded-pill"
                                required
                                :placeholder="this.$t('placeholders.password')"
                                :disabled="loading ? true : false"
                                :formatter="checkLength"
                            ></b-form-input>
                        </b-form-group>
                        <div>
                            <coButton
                                variant="primary"
                                class="ml-auto"
                                @click="setPassword"
                                :loading="loading"
                                :disabled="loading || !password ? true : false"
                                :label="$t('labels.passwordsave')"
                            />
                        </div>
                    </b-form>
                    <div v-else>
                        {{ $t('newpasswordsetsuccessfully') }}
                    </div>
                </div>
            </div>
        </div>
        <!-- user login -->
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

// login view
.login-form {
    width: 100%;
    max-width: 400px;
}

input[type='text'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

.bg {
    background-color: #fff;
    @media (min-width: 768px) {
        background-color: #dedfde;
    }
}
</style>
<script>
import Axios from 'axios';
import Vuei18n from 'vue-i18n';
import EventBus from '@/eventBus';
import { debounce } from 'lodash';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';

export default {
    components: {
        CoLink,
        CoButton,
        CoIcon,
    },
    i18n: {
        messages: {
            en: {
                passwordlength: 'Password can not be longer than 56 characters',
                resetlinksent:
                    'Please check your email inbox. We have just sent you a link to reset your password.  If it doesn’t appear within a few minutes, check your spam folder.',
                codeexpired: 'Password could not be changed because the link is invalid or expired.',
                requestnewcode: 'Request a new link',
                newpasswordsetsuccessfully: 'Your new password has been set successfully.',
            },
            de: {
                passwordlength: 'Passwort darf nicht länger als 56 Zeichen sein',
                codeexpired: 'Das Passwort konnte nicht geändert werden, da der Link ungültig oder abgelaufen ist.',
                requestnewcode: 'Neuen Link anfordern',
                newpasswordsetsuccessfully: 'Dein neues Passwort wurde erfolgreich gesetzt.',
            },
        },
    },
    data() {
        return {
            code: this.$route.params.code,
            password: null,
            error: null,
            loading: false,
            passwortInputType: 'password',
            passwortViewIcon: 'eye-slash',
            step: 1,
        };
    },

    created() {
        this.debouncedCheckLength = debounce(this.notifyPasswordLength, 500);
    },

    methods: {
        switchPasswordView() {
            if (this.passwortInputType === 'password') {
                this.passwortInputType = 'text';
                this.passwortViewIcon = 'eye';
            } else {
                this.passwortInputType = 'password';
                this.passwortViewIcon = 'eye-slash';
            }
        },

        setPassword() {
            this.loading = true;
            this.error = null;
            const data = JSON.stringify({
                Password: this.password,
                OTP: this.code,
            });
            Axios({
                method: 'POST',
                url: '/user/set-new-password',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.step = 2;
                })
                .catch((error) => {
                    this.error = error;
                    this.loading = false;
                    console.log(error);
                });
        },
        notifyPasswordLength() {
            EventBus.$emit(`ERROR`, {
                Message: this.$t('messages.passwordlength'),
            });
        },
        checkLength(value) {
            if (value.length > 56) {
                this.debouncedCheckLength();
                return value.substring(0, 56);
            }
            return value;
        },
    },
};
</script>
