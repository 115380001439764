<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div></div>

            <b-button variant="primary" v-b-modal.create-api-key-modal>Create</b-button>
        </div>

        <div class="px-sm-4 w-100 bg-white round-unify-xs p-3 mt-3">
            <h3>
                coapp.io integration with Zapier is not public yet. Click on invite link below to start using
                integration.
            </h3>
            <a
                href="https://zapier.com/developer/public-invite/122819/e0589e32086f830970449db1591a6d05/"
                target="_blank"
                >https://zapier.com/developer/public-invite/122819/e0589e32086f830970449db1591a6d05/</a
            >
        </div>
        <!-- search and actions end -->
        <div class="pl-sm-4">
            <div v-if="loading" class="d-flex justify-content-center pt-5">
                <b-spinner variant="success" label="Spinning"></b-spinner>
            </div>
            <div v-else class="mt-4">
                <b-table
                    v-if="apiKeys && apiKeys.length > 0"
                    striped
                    hover
                    :items="apiKeys"
                    :fields="fields"
                    sort-icon-left
                    stacked="md"
                    class="table table-borderless table-responsive w-100 d-md-table"
                >
                    <!--            TODO: uncomment next block of code:-->
                    <!--            <template v-slot:cell(Delete)="row">-->
                    <!--              <b-button size="sm" class="mr-2" variant="danger" @click="showDeleteModal(row.item)">-->
                    <!--                Delete-->
                    <!--              </b-button>-->
                    <!--            </template>-->
                </b-table>
                <div v-else class="my-3 text-center">You don't have any api keys yet. Create one.</div>
            </div>
        </div>
        <!-- Creating modal -->
        <b-modal id="create-api-key-modal" title="Create api key" @ok="createNewApiKey()" centered>
            Are you sure to create a new api key ?
        </b-modal>
        <!-- Creating modal -->

        <!--  Showing api key modal  -->
        <b-modal id="show-api-key-modal" ref="showApiKeyModal" title="Api key created" centered ok-only size="lg">
            <div v-if="loadingNewApiKey">
                <div class="pb-3">Creating api key....</div>
                <b-spinner variant="success" label="Spinning"></b-spinner>
            </div>
            <div v-else>
                <div class="pb-3">Use this key to authorize access to you space by Zapier</div>
                <p>NOTE: The key won't be shown again.</p>
                <label for="space-id-value" class="label-align">Space ID</label>
                <div class="form-control wizard-form-control d-flex align-items-center testing-code rounded-pill">
                    <span class="code" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                        $store.state.space.ID
                    }}</span>
                    <input type="hidden" id="space-id-value" :value="$store.state.space.ID" />
                    <b-icon-files
                        class="ml-auto"
                        @click="copySpaceIDToClipboard"
                        style="cursor: pointer"
                        font-scale="1.2"
                    >
                    </b-icon-files>
                </div>
                <label for="api-key-value" class="label-align">API Key</label>
                <div class="form-control wizard-form-control d-flex align-items-center testing-code rounded-pill mb-2">
                    <span class="code">{{ newApiKeyValue }}</span>
                    <input type="hidden" id="api-key-value" :value="newApiKeyValue" />
                    <b-icon-files class="ml-auto" @click="copyToClipboard" style="cursor: pointer" font-scale="1.2">
                    </b-icon-files>
                </div>
            </div>
        </b-modal>
        <!--  Showing api key modal  -->

        <!-- deleting modal -->
        <b-modal
            id="deleting-modal"
            size="md"
            title="Removing api key"
            @ok="deleteApiKey"
            ok-title="Delete"
            ok-variant="primary"
            centered
            :busy="deleting"
            no-fade
        >
            <b-overlay :show="deleting">
                <p class="mb-1">
                    Once you delete this api key type, you won't be able to use it anymore in your application.
                </p>
            </b-overlay>
        </b-modal>
        <!-- deleting modal -->
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'ZapierApiKeys',
    data() {
        return {
            loading: false,
            apiKeys: [],
            fields: [
                {
                    key: 'ApiKeyMask',
                    label: 'Api Key',
                },
                {
                    key: 'Timestamp',
                    label: 'Created at',
                    formatter: (value) => moment.unix(value).format('DD-MM-YYYY'),
                },
                // TODO: uncomment next block of code:
                // {
                //   key: 'Delete',
                //   label: 'Actions',
                // },
            ],
            keyToDelete: null,
            newApiKeyValue: null,
            loadingNewApiKey: false,
            deleting: false,
        };
    },
    methods: {
        createNewApiKey() {
            this.loadingNewApiKey = true;
            this.$bvModal.show('show-api-key-modal');
            this.$store
                .dispatch('createZapierApiKey')
                .then((response) => {
                    this.loadingNewApiKey = false;
                    this.newApiKeyValue = response.ApiKey;
                    this.getApiKeys();
                })
                .catch((error) => {
                    this.loadingNewApiKey = false;
                    console.error(error);
                });
        },
        copyToClipboard() {
            const testingCodeToCopy = document.querySelector('#api-key-value');
            testingCodeToCopy.setAttribute('type', 'text');
            testingCodeToCopy.select();
            document.execCommand('copy');
            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
        },
        copySpaceIDToClipboard() {
            const testingCodeToCopy = document.querySelector('#space-id-value');
            testingCodeToCopy.setAttribute('type', 'text');
            testingCodeToCopy.select();
            document.execCommand('copy');
            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
        },
        getApiKeys() {
            this.loading = true;
            this.$store
                .dispatch('listZapierApiKeys')
                .then((apiKeys) => {
                    this.loading = false;
                    this.apiKeys = apiKeys;
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                });
        },
        showDeleteModal(item) {
            this.keyToDelete = item;
            this.$bvModal.show('deleting-modal');
        },
        deleteApiKey() {
            // TODO: code the delete logic here
        },
    },
    mounted() {
        this.getApiKeys();
    },
};
</script>
