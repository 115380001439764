<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <h2>New plan</h2>
            </div>
            <div class="d-flex align-items-center">
                <CoButton
                    class="flex-shrink-0 mr-2 text-muted"
                    type="text"
                    @click="hasHistory() ? $router.go(-1) : $router.push('/admin/monetization/plan')"
                    label="Cancel"
                ></CoButton>
                <CoButton
                    class="flex-shrink-0"
                    variant="primary"
                    @click="create()"
                    :disabled="clicked"
                    label="Create"
                ></CoButton>
            </div>
        </div>
        <CoPlanEdit
            :plan="form"
            :is-with-section-padding="true"
            :is-with-custom-sign-button="true"
            :tax-rates="taxRates"
            :selected-extras="selectedExtras"
            :upgrades="allExtras"
            :resources="resources"
        />
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';
import CoPlanEdit from '@/components/Organisms/co-plan-edit/CoPlanEdit.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';

export default {
    name: 'CreatePlan',
    components: { CoPlanEdit, CoButton },
    data() {
        return {
            loading: false,
            form: {
                CancelationNotice: 0,
                Currency: 'EUR',
                TaxRateID: null,
                TimeInteval: 0,
                IntervalCount: 1,
            },

            taxRates: [],

            clicked: false,

            // data for resources
            resources: [],

            // data for extras
            allExtras: [],
            selectedExtras: [],
        };
    },
    created() {
        const { ID } = this.$route.params;
        if (this.$store.state.space.Currency) {
            this.form.Currency = this.$store.state.space.Currency;
        }

        this.listAllExtras();
        this.getTaxRates();
        this.getResources();
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },

        listAllExtras() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/extra/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.allExtras = response.data.Extras;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        create() {
            this.clicked = true;
            this.loading = true;
            if (this.form.ImageURL) {
                this.image = this.form.ImageURL;
            }
            if (this.image) {
                this.uploadImages(this.postPlan);
            } else {
                this.postPlan();
            }
        },

        uploadImages(callback) {
            const infoMsg = {
                Message: 'Uploading images',
                Details: null,
            };
            EventBus.$emit('INFO', infoMsg);

            this.$store
                .dispatch('imageUpload', {
                    file: this.image,
                    url: '/dashboard/image/plans',
                })
                .then((response) => {
                    this.form.ImageURL = response;
                    callback();
                })
                .catch((error) => {
                    console.log(error);
                    const errorMsg = {
                        Message: 'Could not upload one or more images, please try again or check details',
                        Details: '',
                    };
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', errorMsg);
                });
        },

        postPlan() {
            const object = this.form;
            if (this.form.TaxRate && this.form.TaxRate.Value) {
                object.TaxRateID = this.form.TaxRate.Value.ID;
                object.TaxRate = null;
            }

            axios({
                method: 'POST',
                url: '/admin/plan/create',
                data: object,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Plan created',
                        Details: '',
                    });
                    this.form = response;
                    this.loading = false;
                    this.$router.push(`/admin/monetization/plan/${response.data.ID}`);
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to create plan',
                        Details: error,
                    });
                    this.loading = false;
                    this.clicked = false;
                });

            this.loading = false;
        },

        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
        getResources() {
            // get all resources available for in space
            this.loadingResources = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Resources) {
                        this.resources = response.data.Resources;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingResources = false;
                });
        },
    },
};
</script>
