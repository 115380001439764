



























































































import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import { get } from 'lodash';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';

import i18n from 'vue-i18n';

export default {
    name: 'CoTopNavBarPublic',
    components: {
        CoLink,
        CoHeadline,
        CoButton,
    },
    i18n: {
        messages: {
            en: {
                haveanaccount: 'Already have an account?',
                notamember: 'Not a member yet?',
            },
            de: {
                haveanaccount: 'Bereits ein Konto?',
                notamember: 'Noch kein Mitglied?',
            },
        },
    },
    props: {
        logoURL: {
            type: String,
            default: '',
            required: false,
        },
        spaceName: {
            type: String,
            default: '',
            required: false,
        },
        path: {
            type: String,
            default: '',
            required: false,
        },
        hideSignUp: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        isRegisterRoute() {
            return (
                this.path.match(/^\/register/gi) ||
                this.path.match(/^\/reset-password/gi) ||
                this.path.match(/^\/plans/gi) ||
                this.path.match(/^\/confirm-email/gi)
            );
        },
    },
    data() {
        return {};
    },
    methods: {
        get,
    },
};
