


































































































































































import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';

import EventBus from '../../../eventBus.js';

export default {
    name: 'CoTopNavBarMobile',
    components: {
        CoThumbnail,
        CoRoundButton,
        CoDropdown,
        CoDropdownItem,
        CoLink,
        CoHeadline,
        CoText,
        CoIcon,
        CoConfirmation,
    },
    props: {
        logo: {
            type: String,
            default: '',
            required: false,
        },
        spaceName: {
            type: String,
            default: '',
            required: false,
        },
        userProfilePicture: {
            type: String,
            default: '',
            required: false,
        },
        isHome: {
            // isHome is a boolean prop that is used to determine if the user is on the home page
            type: Boolean,
            default: true,
            required: false,
        },
        supportURL: {
            type: String,
            default: null,
        },
        showUpgradeBar: {
            type: Boolean,
            default: false,
            required: false,
        },
        isAdmin: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            loading: true,

            newMessagesCount: 0,
            newNotificationsCount: 0,
        };
    },
    watch: {
        showUpgradeBar(val) {
            if (val) {
                // update --header-height var value
                const root = document.documentElement;
                root.style.setProperty('--header-height', '5.75rem');
            } else {
                const root = document.documentElement;
                root.style.setProperty('--header-height', '4.25rem');
            }
        },
    },
    mounted() {
        // subscribe to new messages and new notifications events
        EventBus.$on(
            `NEWMESSAGES`,
            (count) => {
                this.newMessagesCount = count;
            },
            this
        );
        EventBus.$on(
            `NEWNOTIFICATIONS`,
            (count) => {
                this.newNotificationsCount = count;
            },
            this
        );

        this.getNewNotifications();
        this.getNewMessages();

        if (this.showUpgradeBar) {
            // update --header-height var value
            const root = document.documentElement;
            root.style.setProperty('--header-height', '5.75rem');
        } else {
            const root = document.documentElement;
            root.style.setProperty('--header-height', '4.25rem');
        }
    },
    methods: {
        showUpgradeModal() {
            // if admin show modal
            if (this.isAdmin) {
                this.$refs['remove-badge'].show();
            } else {
                // else redirect to coapp landing page
                window.open(
                    'https://www.coapp.io?utm_source=in-app&utm_medium=in-app&utm_campaign=powered-by',
                    '_blank'
                );
            }
        },
        goToUpgrade() {
            this.$router.push('/admin/account');
        },
        // opensupportURL function opens the support url in a new tab
        openSupportURL() {
            if (!this.supportURL) return;
            window.open(this.supportURL, '_blank');
            return;
        },
        goToSearch() {
            this.$router.push('/search/v2');
        },
        // back function emitts a go back event
        back() {
            this.$emit('goBack');
        },

        // logout function emitts a logout event
        logout() {
            this.$emit('logout');
        },

        // showPauseNotificationsModal function emitts a show pause notifications modal event
        showPauseNotificationsModal() {
            this.$emit('showPauseNotificationsModal');
        },

        getNewNotifications() {
            if (!this.$store) {
                return;
            }
            this.$store
                .dispatch('getGetNewNotifications')
                .then((response) => {}, this)
                .catch((error) => {});
        },
        getNewMessages() {
            if (!this.$store) {
                return;
            }
            this.$store
                .dispatch('getNewMessagesNumbers', null)
                .then((response) => {}, this)
                .catch((error) => {});
        },
    },
};
