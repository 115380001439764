
















































































































import { Vue } from 'vue-property-decorator';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoForm from '@/components/Molecules/co-form/CoForm.vue';

interface BillingAddress {
    Phone?: string;
    Company?: string;
    AdditionalInfo?: string;
    Address: string;
    PostalCode: string;
    City: string;
    Country: string;
    VATNumber?: string;
}

export default Vue.extend({
    name: 'CoCardBillingAddress',
    components: { CoIcon, CoInput, CoButton, CoModal, CoCard, CoForm },
    props: {
        billingAddress: {
            type: Object as () => BillingAddress,
            default: null,
        },
        userName: {
            type: String,
            default: '',
        },
        saveBillingAddressData: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            isValid: false,
        };
    },
    methods: {
        openBillingAddressModal() {
            this.$refs['modal-edit-billing-address'].show();
        },
        async saveBillingAddress(formData: BillingAddress) {
            await this.saveBillingAddressData(formData);
            this.$refs['modal-edit-billing-address'].hide();
        },
        cancelBillingAddress() {
            this.$refs['modal-edit-billing-address'].hide();
        },
    },
});
