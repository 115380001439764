<template>
    <div class="">
        <!-- content list -->
        <div id="container">
            <div class="text-center round-unify p-4">
                <illustration type="404" class="my-0 illu"></illustration>
                <h2>Error 404 - Content not found</h2>
                <div class="mb-4">
                    The content you requested could not be found. You can use the navigation or search to find what you
                    are looking for.
                </div>
                <b-button to="/" variant="primary">go to feed</b-button>
            </div>
        </div>
        <!-- content list -->
    </div>
</template>
<script>
export default {
    name: 'Error404',

    data() {
        return {};
    },
    beforeCreate() {},
    created() {},
    destroyed() {},
    mounted() {},
    computed: {},
    methods: {},
};
</script>
