var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('CoCard',{staticClass:"mb-3",attrs:{"variant":"border"}},[_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('CoHeadline',{attrs:{"level":2,"trunc":""}},[_vm._v(_vm._s(_vm.$t('labels.timepass')))]),(_vm.timepassesSpace && _vm.timepassesSpace.length != 0)?_c('CoButton',{staticClass:"ml-auto",attrs:{"variant":"primary","label":_vm.$t('labels.buy')},nativeOn:{"click":function($event){return _vm.$refs.buyTimePasses.show()}}}):_vm._e()],1)]),_c('div',{},[(_vm.activeTimepass && _vm.activeTimepass.WalletItemID)?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"mb-3"},[_c('CoText',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.$t('activetimepass')))]),_c('CoMyTicket',{attrs:{"ticket":{
                        Name: _vm.activeTimepass.WalletItem.Name,
                        ID: _vm.activeTimepass.WalletItemID,
                        validUntil: _vm.activeTimepass.ValidUntil,
                    },"myName":_vm.$store.state.me.Profile.Name,"title":_vm.$t('labels.redeemed') +
                        ' ' +
                        _vm.$t('datetime', { date: _vm.activeTimepass.Timestamp, format: 'relative' }),"hideContextMenu":true}})],1)]):_vm._e(),(_vm.wallet && _vm.wallet.length != 0)?_c('div',[_c('CoText',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.$t('redeemablepasses')))]),_c('CoTable',{staticClass:"w-100 px-3",attrs:{"items":_vm.wallet,"loading":_vm.loading,"columns":[
                    { key: 'Name', title: _vm.$t('labels.name') },
                    { key: 'UsedNumber', title: _vm.$t('labels.redeemed') },
                    { key: 'Actions', style: 'text-align: right' } ],"sortable":false,"showItemsCount":false},scopedSlots:_vm._u([{key:"UsedNumber",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.item.UsedNumber || 0)+" / "+_vm._s(slotProps.item.Number)+" ")]}},{key:"Actions",fn:function(slotProps){return [(!_vm.activeTimepass || !_vm.activeTimepass.WalletItemID)?_c('CoConfirmation',{attrs:{"message":_vm.$t('usetimepassconfirmation'),"confirmLabel":_vm.$t('usetimepass'),"confirmVariant":"primary"},on:{"confirm":function($event){return _vm.applyTimePassUser(slotProps.item['ID'])}}},[_c('CoLink',{staticClass:"float-right",attrs:{"styles":"primary","title":_vm.$t('usetimepass'),"isExternalLink":""}})],1):_c('CoButton',{staticClass:"float-right",attrs:{"variant":"primary","label":_vm.$t('usetimepass'),"disabled":""}})]}}],null,false,3745504361)})],1):_c('CoText',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.$t('nopasses')))]),(!_vm.timepassesSpace || _vm.timepassesSpace.length == 0)?_c('CoText',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.$t('notimepassestobuy')))]):_vm._e()],1),(_vm.timepassesSpace && _vm.timepassesSpace.length != 0)?_c('CoModal',{ref:"buyTimePasses",staticClass:"mt-4",attrs:{"title":_vm.$t('buytimepasses'),"background":"grey"},scopedSlots:_vm._u([{key:"body",fn:function(){return _vm._l((_vm.timepassesSpace),function(timepass,index){return _c('div',{key:timepass.ID},[_c('TimepassCard',{staticClass:"w-100",attrs:{"timepass":timepass,"active":false,"isAdminSpace":false,"user":_vm.me},on:{"checkPayment":function($event){return _vm.checkPaymentMethods(timepass.ID)},"refreshWallet":function($event){return _vm.updateWallet()}},nativeOn:{"click":function($event){}}})],1)})},proxy:true}],null,false,792434441)}):_vm._e(),(_vm.errorMsg && _vm.index == 0)?_c('div',{staticClass:"d-flex align-items-center pt-4"},[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.errorMsg))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }