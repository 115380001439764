











import CoImage from '../../Atoms/co-image/CoImage.vue';

export default {
    components: { CoImage },
    name: 'CoCard',
    props: {
        variant: {
            type: String,
            default: 'normal',
            validator: (value: string) =>
                ['normal', 'gray', 'midgrey', 'border', 'lila', 'warning', 'error', 'border'].includes(value), // gray is a light gray color, midgrey is a medium gray color, normal is white
            required: false,
        },

        bodyClass: {
            type: [String, Object, Array],
            required: false,
            default: '',
        },

        image: {
            type: String,
            required: false,
            default: null,
        },
        imageSize: {
            type: String,
            required: false,
            default: 'landscape',
        },
        overflow: {
            // if true, the modal will have overflow-y: auto
            // and the content will be scrollable
            type: String,
            required: false,
            default: 'auto',
            validator(value: string) {
                return ['auto', 'visible', 'hidden'].includes(value);
            },
        },
    },
    computed: {
        classes() {
            return {
                [`${this.variant}`]: true,
                'overflow-visible': this.overflow === 'visible',
            };
        },
        hasDefaultSlot() {
            return !!this.$slots.default;
        },
    },
};
