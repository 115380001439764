










































































import axios from 'axios';

import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';

interface EventTicket {
    ID?: string;
    Name: string;
    Description?: string;
    PriceInCents: number;
    TaxRateID?: string;
    Limit: number;
    CreatedAt?: number;
    UpdatedAt?: number;
    validUntil?: number;
    CancellationNotice?: number;
    CancellationFee?: number;
    TermsAndConditions?: string;
    AssociatedSessionID?: string;
    PaymentURL: string;
}

export default {
    name: 'CoMyTicket',
    components: {
        CoIcon,
        CoCard,
        CoHeadline,
        CoText,
        CoDropdown,
        CoDropdownItem,
        CoRoundButton,
        CoModal,
        CoDate,
    },
    props: {
        ticket: {
            type: Object as () => EventTicket,
            required: true,
        },
        myName: {
            type: String,
            required: true,
        },
        eventID: {
            type: String,
            default: null,
        },
        eventStartDate: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        hideContextMenu: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        iconName() {
            return this.selected ? 'circle-fill' : 'circle';
        },
        classes() {
            return {
                default: !this.ticket.SoldOut,
                'sold-out': this.ticket.SoldOut,
            };
        },

        canTicketBeRefunded() {
            if (!this.eventID || !this.eventStartDate) {
                return false;
            }
            if (!this.ticket.CancellationNotice) {
                return true;
            }
            const eventStartDate = parseInt(this.eventStartDate, 10) * 1000;
            const now = new Date().getTime();
            const diff = eventStartDate - now;
            const days = diff / (1000 * 60 * 60 * 24);

            if (days < 0) {
                // event has already started
                return false;
            }

            return this.ticket.CancellationNotice < days;
        },
    },
    watch: {},
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        showRefundModal() {
            this.$refs.refundmodal.show();
        },
        toInvoices() {
            this.$router.push('/account/billing/invoices');
        },
        // refund ticket
        refundTicket() {
            this.loading = true;
            const req = {
                ticket_id: this.ticket.ID,
                event_id: this.eventID,
            };

            axios
                .post(`/event/ticket/refund`, req)
                .then(() => {
                    this.$emit('refunded');
                })
                .catch((error) => {
                    console.error(error);
                    this.$emit('refundfailed');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
