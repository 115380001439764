











































import i18n from 'vue-i18n';
import { get } from 'lodash';
import coLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';

export default {
    name: 'CreateSpaceSuccess',
    components: {
        coLoadingIndicator,
        CoHeadline,
        CoText,
    },
    i18n: {
        messages: {
            en: {
                success: 'Community successfully created!',
                wait: 'We’ve prepared everything, now it’s time to fill this place with life...',
                help: 'If you’re not redirected automatically, click <a style="text-decoration: underline !important; color: var(--c-dark) !important;"  href="https://{0}">here</a>.',
            },
            de: {
                success: 'Community erfolgreich erstellt!',
                wait: 'Wir haben alles vorbereitet, jetzt ist es an der Zeit, diesen Ort mit Leben zu füllen...',
                help: 'Wenn du nicht automatisch weitergeleitet wirst, klicke <a style="text-decoration: underline !important; color: var(--c-dark) !important;"  href="https://{0}">hier</a>.',
            },
        },
    },
    data() {
        return {
            domain: get(this.$route, 'params.domain', null),
        };
    },
    mounted() {
        setTimeout(
            (domain) => {
                // if domain has query params, or ? in the domain add another &intercomEvent=community-created
                // else add ?intercomEvent to the domain
                const queryParam = `intercomevent=community-created`;

                if (domain.includes('?')) {
                    window.location.href = `https://${domain}&${queryParam}`;
                    return;
                }
                window.location.href = `https://${domain}?${queryParam}`;
            },
            3000,
            this.domain
        );
    },
};
