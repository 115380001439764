



















































import { isEqual } from 'lodash';

import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoInput from '../co-input/CoInput.vue';
import CoFormGroup from '../co-form-group/CoFormGroup.vue';

interface Input {
    Label?: string;
    Type?: string;
    Name: string;
    Value: any;
    Required?: boolean;
    Placeholder?: string;
    Disabled?: boolean;
    validationHint?: string;
    validationRegExp?: RegExp;
    suggester?: Function;
    Autocomplete?: string;
}

export default {
    name: 'CoForm',
    components: {
        CoButton,
        CoInput,
        CoFormGroup,
    },
    props: {
        inputs: {
            type: Array as () => Input[],
            default: () => [],
        },
        hideReset: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
        submitLabel: {
            type: String,
            required: false,
            default: null,
        },
        submitBtnVariant: {
            type: String,
            required: false,
            default: 'primary',
        },
        resetLabel: {
            type: String,
            required: false,
            default: null,
        },
        resetBtnVariant: {
            type: String,
            required: false,
            default: 'default',
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        submitMethod: {
            type: Function,
            required: false,
            default: null,
        },
        resetMethod: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            form: null,
            formData: new Object(),
            isValid: null,
            originalData: {},
            hasChanges: false,
            inputComponents: [],
            loadingLocal: this.loading,
        };
    },
    mounted() {
        this.inputs.map((input) => {
            // build the form data object from the inputs prop
            this.originalData[input.Name] = input.Value ? JSON.parse(JSON.stringify(input.Value)) : undefined;
            this.$set(this.formData, input.Name, input.Value);
        });
        this.originalData = JSON.parse(JSON.stringify(this.formData));
        this.checkValidity();
    },
    watch: {
        formData: {
            handler: function (newVal) {
                // check if the form data has changed
                this.hasChanges = !isEqual(newVal, this.originalData);
            },
            deep: true,
        },
        loading(newVal) {
            this.loadingLocal = newVal;
        },
    },
    methods: {
        checkValidity() {
            // check the form validity
            for (const [key, value] of Object.entries(this.formData)) {
                if (this.$refs[key] && this.$refs[key][0].isValid === false) {
                    this.isValid = false;
                    return;
                }
            }
            // if no invalid inputs are found, set the form to valid
            this.isValid = true;
        },
        submit() {
            //handler to programmatically submit the form from outside the component
            if (this.$refs.form) this.$refs.form.submit();
        },
        async handleSubmit(event: SubmitEvent) {
            this.loadingLocal = true;
            if (this.submitMethod) {
                // if a submit method is provided, call it
                // supports promises and async functions
                await this.submitMethod(this.formData);
            }
            // emit the form data on submit
            this.$emit('submit', this.formData);
            this.loadingLocal = false;
        },
        async resetForm() {
            if (this.resetMethod) {
                // if a reset method is provided, call it
                // supports promises and async functions
                await this.resetMethod(this.formData);
            } else {
                // default: reset the form to its original state
                for (const [key, value] of Object.entries(this.originalData)) {
                    this.formData[key] = value;
                }
            }
            this.$emit('reset', this.formData);
        },
    },
};
