<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.grey {
    color: var(--c-lighter);
}

.h-50 {
    min-height: 50vh !important;
}

.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

.list-item {
    padding: 0;
    background: white;
    margin: 0 0 10px;
    cursor: pointer;
    overflow: visible;

    .picture {
        width: 96px;
        height: 96px;
        margin-left: 0;
        object-fit: cover;

        @media (min-width: 768px) {
            width: 134px;
            height: 134px;
            object-fit: cover;
        }
    }
}
</style>

<template>
    <div class="w-75 max-width-700" @click="buyTimepass()">
        <div class="list-item round-unify w-100 d-flex align-items-start overflow-hidden justify-content-between">
            <div class="d-flex w-100 flex-column align-items-start item-data">
                <div class="row w-100 p-0 m-0">
                    <div class="col justify-content-between">
                        <div class="pb-3 pl-2 pt-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <h1 class="title mt-1 mt-lg-2 mb-2 mb-xs-3">
                                    {{ timepass.Name }}
                                </h1>
                                <div class="d-flex align-items-center mt-1 mt-lg-2">
                                    <CoButton
                                        v-if="this.$store.state.isInMobile"
                                        variant="primary"
                                        @click="openTimePassesInBrowser()"
                                        >{{ $t('labels.buynow') }}
                                    </CoButton>

                                    <CoButton v-else variant="primary" @click="buyTimepass()">{{
                                        $t('labels.buynow')
                                    }}</CoButton>
                                </div>
                            </div>
                            <!-- show duration and included redemptions -->
                            <CoText>
                                {{ timepass.Number }} x {{ $t('labels.redeemable').toLowerCase() }} <br />
                                {{ $t('labels.validfor') }} {{ timepass.Duration }}
                                {{ $tc('labels.hour', timepass.Duration) }}
                            </CoText>
                            <div class="d-flex mt-4 mb-1 align-items-end">
                                {{ calculatePrice(this.timepass).toFixed(2) }} {{ $store.state.space.Currency }} -
                                {{ this.taxRate.Inclusive ? 'incl.' : 'excl.' }} {{ this.taxRate.Percentage }}% VAT
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CoModal ref="purchasing-modal" color="grey" :title="timepass.Name" @hidden="prevStep()">
            <template v-slot:body>
                <div class="h-30">
                    <BuyPassCheckout
                        :isAdminSpace="isAdminSpace"
                        :timepass="timepass"
                        :taxRate="taxRate"
                        :userID="user.ID"
                        @loading="loading = $event"
                        @priceOverride="isPriceOverride"
                        @expirationOverride="overrideExpiration"
                        @pricePreview="setPricePreview"
                        @isAccepted="isAccepted()"
                    />
                    <div class="d-flex flex-column flex-grow-1 align-items-end">
                        <div class="d-flex justify-content-between w-100 m-0 mb-3">
                            <!-- Emulate built in modal footer ok and cancel button actions -->
                            <b-button variant="outline-primary" @click="cancel">{{ $t('labels.discard') }}</b-button>
                            <CoButton
                                v-if="loading"
                                variant="primary"
                                class="mb-0"
                                disabled
                                loading
                                :label="$t('labels.loading')"
                            />
                            <b-button v-else-if="isAdminSpace" variant="primary" @click="buyPassAdmin()">{{
                                $t('labels.buynow')
                            }}</b-button>
                            <b-button v-else-if="activeMembershipRequired" variant="primary" @click="addMembership()"
                                >{{ $t('labels.planchoose') }}
                            </b-button>
                            <b-button v-else-if="paymentMethodeRequired" variant="primary" @click="addPaymentMethod()"
                                >{{ $t('labels.paymentmethodadd') }}
                            </b-button>
                            <b-button v-else variant="primary" @click="buyPassUser()" :disabled="!termsAndConditions"
                                >{{ $t('labels.buynow') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </CoModal>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import BuyPassCheckout from '@/components/buyPass/BuyPassCheckout.vue';
import { getUnixTime } from 'date-fns';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';

export default {
    name: 'TimepassCard',
    components: { BuyPassCheckout, CoButton },
    props: ['timepass', 'isAdminSpace', 'user'],
    data() {
        return {
            taxRate: 0,
            step: 1,
            priceOverride: false,
            overridedPrice: null,
            termsAndConditions: false,
            admin: this.isAdminSpace,
            purchaseRequest: {},
            expirationDate: null,
            loading: true,
            activeMembershipRequired: false,
            paymentMethodeRequired: false,
        };
    },
    watch: {},
    computed: {
        isDesktop() {
            return this.windowWidth >= 768;
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });

        this.getTaxRate(this.timepass.TaxRateID);
    },
    methods: {
        openTimePassesInBrowser() {
            // replace url quiry params with resource id
            const urltotimepasses = `/account/time-passes`;
            const queryParam = {
                ...this.$route.query,
                openinbrowser: urltotimepasses,
                m: 'mobile',
                t: new Date().getTime(),
            };
            this.$router.push({ query: queryParam });
            return;
        },
        isAccepted() {
            this.termsAndConditions = !this.termsAndConditions;
        },
        isPriceOverride(newPrice, isOverride) {
            this.priceOverride = isOverride;
            this.overridedPrice = newPrice;
        },
        setPricePreview(newPrice) {
            this.timepass.PriceInCents = newPrice.PriceInCents;
            this.activeMembershipRequired = newPrice.ActiveMembershipRequired;
            this.paymentMethodeRequired = newPrice.PaymentMethodeRequired;
        },

        overrideExpiration(newExpiration) {
            this.expirationDate = newExpiration;
        },

        addPaymentMethod() {
            const authWindow = window.open('', '_blank');
            authWindow.location.href = '/account/billing/add-payment-method?closeAfterSuccess=true';
        },

        addMembership() {
            const authWindow = window.open('', '_blank');
            authWindow.location.href = '/account/plan';
        },

        next() {
            this.step += 1;
        },
        cancel() {
            this.step = 1;
            this.$refs['purchasing-modal'].hide();
        },
        back() {
            if (this.step > 1) {
                this.step -= 1;
                this.prevStep();
            } else {
                this.$router.push('/account/time-passes');
            }
        },
        getTaxRate(taxID) {
            axios({
                method: 'GET',
                url: `/space/tax/${taxID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRate = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        buyPassUser() {
            this.loading = true;
            this.purchaseRequest = {
                TimePassID: this.timepass.ID,
                ForUser: this.user.ID,
                ExpirationDate: this.expirationDate ? getUnixTime(this.expirationDate) : null,
                Currency: this.timepass.Currency,
            };
            axios({
                method: 'POST',
                url: 'user/time-pass/purchase',
                withCredentials: true,
                data: JSON.stringify(this.purchaseRequest),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Successfully bought Time Pass!',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.step = 1;
                    this.$refs['purchasing-modal'].hide();
                    this.$emit('refreshWallet');
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to buy Time Pass',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
        },
        prevStep() {
            this.priceOverride = false;
            this.termsAndConditions = false;
        },
        buyPassAdmin() {
            this.loading = true;
            this.purchaseRequest = {
                TimePassID: this.timepass.ID,
                ForUser: this.user.ID,
                ByUser: this.$store.state.me.ID,
                OverridePrice: this.priceOverride,
                PriceOverride: this.overridedPrice,
                ExpirationDate: this.expirationDate ? getUnixTime(this.expirationDate) : null,
                Currency: this.timepass.Currency,
            };
            axios({
                method: 'POST',
                url: 'admin/time-pass/purchase',
                withCredentials: true,
                data: JSON.stringify(this.purchaseRequest),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Successfully bought Time Pass for user!',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.step = 1;
                    this.$refs['purchasing-modal'].hide();
                    this.$emit('refreshWallet');
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to buy Time Pass for user',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
        },
        calculatePrice(item) {
            if (!item.taxRate || item.TaxRate.Inclusive) {
                const price = item.PriceInCents / 100.0;
                return price;
            }
            const price = item.PriceInCents / 100.0;
            const totalPrice = price * (item.TaxRate.Percentage / 100 + 1);
            return totalPrice;
        },
        buyTimepass() {
            if (this.$store.state.isInMobile) {
                this.openTimePassesInBrowser();
                return;
            }
            this.$bvModal.show(`purchasing-modal-${this.timepass.ID}`);
            this.$refs['purchasing-modal'].show();
        },
    },
};
</script>
