























































































































































import { fromUnixTime, format } from 'date-fns';
import axios from 'axios';
import { get } from 'lodash';

import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoFormGroup from '../../Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoCheckbox from '../../Atoms/co-checkbox/CoCheckbox.vue';
import CoAlert from '../../Molecules/co-alert/CoAlert.vue';
import { SubscriptionSchedule } from './models.ts';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';

export default {
    name: 'CoSubscriptionScheduleCard',
    components: {
        CoIcon,
        CoCard,
        CoHeadline,
        CoText,
        CoFormGroup,
        CoInput,
        CoRoundButton,
        CoCheckbox,
        CoAlert,
        CoDate,
    },
    props: {
        mode: {
            type: String,
            // if edit mode, start date can not be changed
            // if create mode, start date can be changed
            default: 'edit',
            validator: (value) => ['edit', 'create'].includes(value),
        },
        start: {
            type: Number,
            default: 0,
        },
        end: {
            type: Number,
            default: 0,
        },
        anchor: {
            type: Number,
            default: 0,
        },
        paymentCollectionMethod: {
            type: String,
            default: 'CHARGE_AUTOMATICALLY',
            validator: (value) => ['CHARGE_AUTOMATICALLY', 'SEND_INVOICE'].includes(value),
        },
        trialEnd: {
            type: Number,
            default: 0,
        },
        paymentMethods: {
            type: Array,
            default: () => [],
        },
        userID: {
            type: String,
        },
    },

    data() {
        return {
            startDate: '2020-01-01',
            endDate: '',
            anchorDate: '2020-01-01',
            trialDays: 0,
            showTrialDaysInput: false,
            automaticChargingPaymentMethod: true,
            sendInvoice: false,
            paymentCheckboxRerenderKey: 0,
            resetBillingCycle: false,

            subscriptionSchedule: {} as SubscriptionSchedule,

            selectedOption: 'now',
            request: {
                Now: false,
                EndOfBillingCycleWithCancellationPeriod: false,
                EndOfBillingCycle: false,
                CustomDate: false,
            },
            customDate: format(new Date(), 'yyyy-MM-dd'),
            minDate: format(new Date(), 'yyyy-MM-dd'),
            cancelDateLoading: true,
            possibleCancelAt: null,
        };
    },

    computed: {
        isActiveTrial() {
            const now = new Date().getTime() / 1000;
            if (this.trialEnd > now) {
                return true;
            }
            return false;
        },

        endDateMin() {
            return this.startDate;
        },
        anchorDateMin() {
            return this.startDate;
        },
    },
    watch: {
        startDate(value) {
            if (!value) {
                this.subscriptionSchedule.startDate = 0;
                return;
            }

            const startDate = new Date(value);

            // check if start date is same day as today
            const today = new Date();
            if (startDate.toDateString() === today.toDateString()) {
                this.subscriptionSchedule.startDate = 0;
                return;
            }
            this.subscriptionSchedule.startDate = new Date(value).getTime() / 1000;
            if (this.anchorDate < value) {
                this.anchorDate = value;
            }
            this.emitUpdate();
        },
        endDate(value) {
            if (!value) {
                this.subscriptionSchedule.endDate = 0;
                return;
            }
            this.subscriptionSchedule.endDate = new Date(value).getTime() / 1000;
            this.emitUpdate();
        },
        anchorDate(value) {
            if (!value) {
                this.subscriptionSchedule.anchorDate = 0;
                return;
            }

            const anchorDate = new Date(value);
            const today = new Date();

            // check if anchor date is same day as today
            if (anchorDate.toDateString() === today.toDateString()) {
                this.subscriptionSchedule.anchorDate = 0;
                this.emitUpdate();
                return;
            }

            this.subscriptionSchedule.anchorDate = new Date(value).getTime() / 1000;
            this.emitUpdate();
        },
        trialDays(value) {
            if (!value) {
                this.subscriptionSchedule.trialDays = 0;
                this.emitUpdate();
                return;
            }

            if (this.mode === 'edit' && value > 0) {
                this.subscriptionSchedule.trialDays = value;
                this.emitUpdate();
                return;
            }

            this.subscriptionSchedule.trialDays = value;

            // add trial days to start date using date-fns
            const startDate = new Date();
            this.subscriptionSchedule.startDate = Math.round(startDate.getTime() / 1000);

            const trialDays = Number(value);
            const endDate = new Date(startDate.setDate(startDate.getDate() + trialDays));
            // assign end date to anchor date
            this.anchorDate = endDate.toISOString().split('T')[0];
            this.subscriptionSchedule.anchorDate = new Date(this.anchorDate).getTime() / 1000;
            this.emitUpdate();
        },
        automaticChargingPaymentMethod(value) {
            if (value) {
                this.subscriptionSchedule.paymentCollectionMethod = 'CHARGE_AUTOMATICALLY';
            }
            this.emitUpdate();
        },
        sendInvoice(value) {
            if (value) {
                this.subscriptionSchedule.paymentCollectionMethod = 'SEND_INVOICE';
            }
            this.emitUpdate();
        },
        resetBillingCycle(value) {
            this.subscriptionSchedule.resetBillingCycle = value;
            this.emitUpdate();
        },

        subscriptionSchedule: {
            handler(value: SubscriptionSchedule) {
                this.emitUpdate();
            },
            deep: true,
        },
        // for schedule changes
        customDate(val) {
            if (val) {
                this.setType('CustomDate', true);
            }
        },
    },
    mounted() {
        if (this.start === 0) {
            this.startDate = new Date().toISOString().split('T')[0];
        } else {
            this.startDate = fromUnixTime(this.start).toISOString().split('T')[0];
        }

        if (this.end === 0) {
            this.endDate = '';
        } else {
            this.endDate = fromUnixTime(this.end).toISOString().split('T')[0];
        }

        if (this.anchor === 0) {
            this.anchorDate = new Date().toISOString().split('T')[0];
        } else {
            this.anchorDate = fromUnixTime(this.anchor).toISOString().split('T')[0];
        }

        if (this.paymentCollectionMethod === 'CHARGE_AUTOMATICALLY') {
            this.automaticChargingPaymentMethod = true;
            this.sendInvoice = false;
        } else {
            this.sendInvoice = true;
            this.automaticChargingPaymentMethod = false;
        }
        if (this.paymentMethods && this.paymentMethods.length > 0 && !this.sendInvoice) {
            this.automaticChargingPaymentMethod = true;
        } else {
            this.automaticChargingPaymentMethod = false;
            this.paymentCollectionMethod = 'SEND_INVOICE';
            this.sendInvoice = true;
        }

        // for schedule changes
        this.getCancelPossibleDate();
        this.setType('Now', true);
    },
    methods: {
        emitUpdate() {
            this.$emit('update', this.subscriptionSchedule);
        },
        changeAnchorDateHandler(value) {
            this.$nextTick(() => {
                if (this.trialDays > 0) {
                    // add trial days to start date using date-fns
                    const startDate = new Date(this.startDate);
                    const trialDays = Number(this.trialDays);
                    const trialEndDate = new Date(startDate.setDate(startDate.getDate() + trialDays + 1));
                    // assign end date to anchor date
                    // if anchor date is less than trial end date, then assign trial end date to anchor date
                    if (new Date(value) < trialEndDate) {
                        this.anchorDate = trialEndDate.toISOString().split('T')[0];
                        this.subscriptionSchedule.anchorDate = trialEndDate.getTime() / 1000;
                    }
                }
            });
        },
        changePaymentMethodAutoHandler(value) {
            if (value) {
                this.automaticChargingPaymentMethod = value;
                this.sendInvoice = false;
            } else {
                this.automaticChargingPaymentMethod = true;
            }
            this.paymentCheckboxRerenderKey += 1;
        },
        changePaymentMethodInvoiceHandler(value) {
            if (value) {
                this.sendInvoice = value;
                this.automaticChargingPaymentMethod = false;
            } else {
                this.sendInvoice = true;
            }

            // rerender checkbox
            this.paymentCheckboxRerenderKey += 1;
        },
        focusOnEndDateHandler() {
            // add one month to start date
            const startDate = new Date(this.startDate);
            const endDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
            this.endDate = endDate.toISOString().slice(0, 10);
        },
        timeStampToDateString(timeStamp: number) {
            if (timeStamp === 0) {
                return '';
            }
            return fromUnixTime(timeStamp).toISOString().split('T')[0];
        },
        // for schedule changes
        format,
        setType(type: string, event: boolean) {
            this.request[type] = event;
            this.selectedOption = type;

            switch (type) {
                case 'Now':
                    this.request.EndOfBillingCycle = false;
                    this.request.EndOfBillingCycleWithCancellationPeriod = false;
                    this.request.CustomDate = false;

                    this.subscriptionSchedule = {
                        ...this.subscriptionSchedule,
                        ...{
                            Now: true,
                            NextBillingCycle: false,
                            Custom: false,
                            CustomDate: null,
                        },
                    };

                    break;
                case 'EndOfBillingCycle':
                    this.request.Now = false;
                    this.request.EndOfBillingCycleWithCancellationPeriod = false;
                    this.request.CustomDate = false;

                    this.subscriptionSchedule = {
                        ...this.subscriptionSchedule,
                        ...{
                            Now: false,
                            NextBillingCycle: true,
                            Custom: false,
                            CustomDate: null,
                        },
                    };

                    break;
                case 'EndOfBillingCycleWithCancellationPeriod':
                    this.request.Now = false;
                    this.request.EndOfBillingCycle = false;
                    this.request.CustomDate = false;

                    this.subscriptionSchedule = {
                        ...this.subscriptionSchedule,
                        ...{
                            Now: false,
                            NextBillingCycle: false,
                            Custom: true,
                            CustomDate: new Date(this.possibleCancelAt).getTime(),
                        },
                    };
                    break;
                case 'CustomDate':
                    this.request.Now = false;
                    this.request.EndOfBillingCycle = false;
                    this.request.EndOfBillingCycleWithCancellationPeriod = false;

                    this.subscriptionSchedule = {
                        ...this.subscriptionSchedule,
                        ...{
                            Now: false,
                            NextBillingCycle: false,
                            Custom: true,
                            CustomDate: new Date(this.customDate).getTime(),
                        },
                    };
                    break;
            }
        },
        getCancelPossibleDate() {
            if (!this.userID) return;
            this.cancelDateLoading = true;
            axios
                .get(`/admin/community/member/${this.userID}/plan/cancel-date`)
                .then((response) => {
                    this.possibleCancelAt = get(response, 'data.CancelPossibleAt');
                    this.cancelDateLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
